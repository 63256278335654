import React, { useState } from 'react';
import { /* Backdrop, Button, */ Box, Typography } from '@mui/material';
import { CustomMenu, CustomMenuItem } from '../../common/StyledComponents';
import { GamifyToast } from '../../common/CustomToasts';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import './MemberOptionsMenu.css';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';

export default function MemberOptionsMenu(props) {
  const {
    // setMemberOptionsOpen,
    anchorEl,
    setAnchorEl,
    team,
    user,
    selectedTeamMember,
    setCheckedTeamMembers,
    setIsMemberListLoading,
    setShowRoleModal,
    onTriggerMemberRefetch,
    onRemoveMemberFromState,
    onRemoveMembers,
    teamMembers,
    isOnTeamForm,
  } = props;
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { checkPermission, hasTeamPermission } = usePermissions();

  const changeRolePermission = checkPermission(
    PermissionKeys.TeamsPeople_ChangePersonsRole
  );
  const showRemovePerson = hasTeamPermission(
    PermissionKeys.TeamsPeople_RemoveSomeoneFromTeam,
    [team.id]
  );

  const handleRemoveClick = () => {
    setShowConfirmRemove(true);
  };

  const handleConfirmRemoveClick = async (teamId, user) => {
    // console.log('handleConfirmRemoveClick teamId, user: ', teamId, user);
    setIsSaving(true);
    setShowConfirmRemove(false);
    // setMemberOptionsOpen(false);
    setAnchorEl(null);

    // onRemoveMember returns true if successfull, false if no
    const success = await onRemoveMembers(
      [user],
      teamMembers,
      teamId,
      (res) => {
        setIsSaving(false);
        if (res) {
          setShowConfirmRemove(false);
        }
      }
    );

    // Cancel the saving animation and function if onRemoveMember fails
    if (!success) {
      setIsSaving(false);
      return;
    }

    setTimeout(() => {
      GamifyToast.success(`${user.name} removed successfully`);
      if (onTriggerMemberRefetch) onTriggerMemberRefetch();
      setIsMemberListLoading(false);
    }, 1000);

    // Filter out the selectedTeamMember that was just deleted from checkedTeamMembers
    if (setCheckedTeamMembers) {
      setCheckedTeamMembers((prevCheckedTeamMembers) =>
        prevCheckedTeamMembers.filter(
          (checkedTeamMember) => checkedTeamMember.id !== selectedTeamMember.id
        )
      );
    }
  };

  // For removing members while creating a team before the team has an id:
  const handleConfirmRemoveClickNoTeamId = (user) => {
    // console.log('handleConfirmRemoveClickNoTeamId user: ', user);
    setIsMemberListLoading(true);
    onRemoveMemberFromState(user.id);
    GamifyToast.success(
      `${user.name} removed successfully. Save changes below to ensure ${user.name} is removed.`
    );
    setIsMemberListLoading(false);
  };

  // const handleCancelRemoveClick = () => {
  //   setShowConfirmRemove(false);
  // };

  return (
    <>
      {/* NOTE: DynamicConfirmModal has replaced the custom Backdrop component commented out below */}
      <DynamicConfirmModal
        showDynamicConfirmModal={showConfirmRemove}
        setShowDynamicConfirmModal={setShowConfirmRemove}
        zIndex={100002}
        title={`Remove ${user?.name}?`}
        subtitle={`You are able to add members back\nafter removal.`}
        confirmButtonColor={'#E35050'}
        isSaving={isSaving}
        onCancel={() => {
          setShowConfirmRemove(false);
        }}
        onConfirm={() => {
          console.log('onConfirm team: ', team);
          team?.id
            ? handleConfirmRemoveClick(team.id, user)
            : handleConfirmRemoveClickNoTeamId(user);
        }}
      ></DynamicConfirmModal>
      {/* <Backdrop
        sx={{ zIndex: 100001 }}
        open={showConfirmRemove}
        onClick={(e) => {
          e.stopPropagation();
          handleCancelRemoveClick();
        }}
      >
        <Box className={'remove-member-confirmation-container'}>
          <Box className={'remove-member-confirmation-top-container'}>
            <Typography className={'remove-member-confirmation-top-text'}>
              Are you sure you want to remove this user?
            </Typography>
            <Typography className={'remove-member-confirmation-bottom-text'}>
              {`Member to remove: ${user.name}`}
            </Typography>
          </Box>
          <Box className={'remove-member-confirmation-bottom-container'}>
            <Button
              className={'cancel-remove-member-button'}
              onClick={(e) => {
                e.stopPropagation();
                handleCancelRemoveClick();
              }}
              disableRipple={true}
            >
              <Typography className={'cancel-remove-member-button-text'}>
                Cancel
              </Typography>
            </Button>
            <Button
              className={'confirm-remove-member-button'}
              onClick={(e) => {
                e.stopPropagation();
                team
                  ? handleConfirmRemoveClick(team.id, user)
                  : handleConfirmRemoveClickNoTeamId(user);
              }}
              disableRipple={true}
            >
              <Typography className={'remove-member-button-text'}>
                Remove
              </Typography>
            </Button>
          </Box>
        </Box>
      </Backdrop> */}

      {/* NOTE: CustomMenu and CustomMenuItem components have replaced the custom options menu commented out below */}
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => {
          e.stopPropagation();
          setAnchorEl(null);
        }}
      >
        {/* NOTE: No need to change role from the TeamFormPage, user must also have the permission on the TeamPage */}
        {!isOnTeamForm && changeRolePermission && (
          <CustomMenuItem
            sx={{
              background: 'white',
              color: 'black',
              paddingTop: '9px',
              paddingBottom: '9px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowRoleModal(true);
              setAnchorEl(null);
            }}
            disableRipple={true}
          >
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>Change Role</Typography>
              {/* <GamifyIcon icon={'role'} color={'black'}></GamifyIcon> */}
            </Box>
          </CustomMenuItem>
        )}
        {showRemovePerson && (
          <CustomMenuItem
            sx={{
              background: 'white',
              color: 'black',
              paddingTop: '9px',
              paddingBottom: '9px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveClick();
              setAnchorEl(null);
            }}
            disableRipple={true}
          >
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                style={{
                  color: '#e35050',
                }}
              >
                Remove
              </Typography>
              {/* <GamifyIcon
              icon={
                user.status === 'active' ? 'deactivateUser' : 'activateUser'
              }
              color={user.status === 'active' ? '' : 'black'}
            ></GamifyIcon> */}
            </Box>
          </CustomMenuItem>
        )}
      </CustomMenu>
      {/* <Box className={'option-menu-container'}>
        {!isOnTeamForm && (
          <Box className={'option-menu-change-role-permissions-container'}>
            <Button
              disableRipple={true}
              className={'option-menu-top-button'}
              onClick={(e) => {
                e.stopPropagation();
                setShowRoleModal(true);
                setMemberOptionsOpen(false);
              }}
            >
              <Typography className={'option-menu-text'}>
                Change Position
              </Typography>
            </Button>
          </Box>
        )}
        <Box className={'option-menu-delete-container'}>
          <Button
            disableRipple={true}
            className={
              isOnTeamForm
                ? 'option-menu-only-button'
                : 'option-menu-bottom-button'
            }
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveClick();
            }}
          >
            <Typography className={'option-menu-delete-text'}>
              Remove
            </Typography>
          </Button>
        </Box>
      </Box> */}
    </>
  );
}
