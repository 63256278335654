import React, { createContext, useState, useContext, ReactNode } from 'react';

type SettingTypes = 'roles' | 'deals' | 'hierarchy';
interface SettingsContextModel {
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (unsaved: boolean) => void;
  selectedSetting: SettingTypes;
  setSelectedSetting: (settingType: SettingTypes) => void;
}

const SettingsContext = createContext<SettingsContextModel | undefined>(
  undefined
);

export const SettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState<SettingTypes>('roles');

  const value = {
    hasUnsavedChanges,
    setHasUnsavedChanges,
    selectedSetting,
    setSelectedSetting,
  };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error(
      'useSettingsContext must be used within a SettingsProvider'
    );
  }
  return context;
};
