import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import gql from 'graphql-tag';
import { Role } from '../components/RolesAndPermissions/RoleAndPermissions.types';

// Define the GraphQL mutation
const updateUserRoleMutation = gql`
  mutation UpdateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      id
      roleName
      permissions
      isSuper
      iconName
      createdAt
      updatedAt
      __typename
    }
  }
`;

// Define the structure of the mutation input
interface UpdateUserRoleInput {
  id: string;
  roleName: string;
  permissions: string; // permissions need to be stringified
}

// Define the structure of the mutation response
interface UpdateUserRoleResponse {
  updateUserRole: {
    id: string;
    roleName: string;
    permissions: string;
    isSuper: boolean;
    iconName: string;
    createdAt: string;
    updatedAt: string;
  };
}

// Function to save roles in the database
export async function updateUserRoles(roles: Role[]): Promise<void> {
  try {
    // Loop over each role and perform the update mutation
    for (const role of roles) {
      const input: UpdateUserRoleInput = {
        id: role.id,
        roleName: role.roleName,
        permissions: JSON.stringify(role.permissions), // Stringify the permissions object
      };

      // Perform the mutation for each role
      const response = (await API.graphql(
        graphqlOperation(updateUserRoleMutation, { input })
      )) as GraphQLResult<UpdateUserRoleResponse>;

      const updatedRole = response.data?.updateUserRole;
      if (updatedRole) {
        console.log(`Role ${updatedRole.roleName} updated successfully.`);
      } else {
        console.error(`Failed to update role with ID: ${role.id}`);
      }
    }
  } catch (error) {
    console.error('Error saving roles:', error);
  }
}
