import React, { useMemo } from 'react';
import { FormControl } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import '../../../settingsStyles.css';
import { permissionValue } from '../RoleAndPermissions.types';
import { GamifyIcon } from '../../../../../components/GamifyIcon';
import {
  StyledPermissionSelect,
  StyledMenuItem,
  getLabelColor,
} from './styledComponents';

interface PermissionSelectProps {
  value: permissionValue | null | undefined; // Allow null or undefined
  locked?: boolean; // Disable the select
  onChange: (value: permissionValue) => void;
  defaultValue?: permissionValue | undefined; // Default value if value is null or undefined
  excludeOptions?: permissionValue[] | undefined; // Exclude certain options
}

interface PermissionMenuOption {
  value: permissionValue;
  label: string;
}

const iconMapper = {
  everyone: 'globe',
  onlyMe: 'userSmall',
  myTeam: 'teams',
  none: 'lock',
};

const PermissionSelect = ({
  value,
  onChange,
  locked,
  defaultValue,
  excludeOptions,
}: PermissionSelectProps) => {
  // Default to 'none' if value is null or undefined
  const selectedValue = value ?? defaultValue ?? 'none';
  const options = useMemo(() => {
    const allOptions: PermissionMenuOption[] = [
      { value: 'none', label: 'None' },
      { value: 'onlyMe', label: 'Only me' },
      { value: 'myTeam', label: 'My team' },
      { value: 'everyone', label: 'Everyone' },
    ];
    return allOptions.filter(
      (option) => !excludeOptions?.includes(option.value)
    );
  }, [excludeOptions]);

  // Custom MenuProps to remove padding and adjust the border radius
  const MenuProps = {
    PaperProps: {
      style: {
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: 0,
        marginTop: 0,
        overflow: 'hidden',
      },
    },
    MenuListProps: {
      disablePadding: true, // Disable default padding
    },
  };

  return (
    <FormControl>
      <StyledPermissionSelect
        labelId="permission-select-label"
        MenuProps={MenuProps}
        disabled={locked || false}
        value={selectedValue}
        onChange={(e: SelectChangeEvent<unknown>) =>
          onChange(e.target.value as permissionValue)
        }
        selectedValue={selectedValue}
      >
        {options.map((option) => (
          <StyledMenuItem
            key={option.value}
            value={option.value}
            menuItemValue={option.value}
          >
            <GamifyIcon
              icon={iconMapper[option.value]}
              color={getLabelColor(option.value)}
            />
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledPermissionSelect>
    </FormControl>
  );
};

export default PermissionSelect;
