import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { permissionValue } from '../RoleAndPermissions.types';

const getBackgroundColor = (val: permissionValue) => {
  switch (val) {
    case 'everyone':
      return '#DFF2C2';
    case 'onlyMe':
      return '#DED6F6';
    case 'myTeam':
      return '#FCCC9F';
    case 'none':
      return '#F8D7DA';
    default:
      return 'green';
  }
};
export const getLabelColor = (val: permissionValue) => {
  switch (val) {
    case 'everyone':
      return '#4C6708';
    case 'onlyMe':
      return '#22166B';
    case 'myTeam':
      return '#863737';
    case 'none':
      return '#A94442';
    default:
      return 'green';
  }
};

export const StyledButton = React.memo(
  styled(Button)({
    borderRadius: '10px',
    backgroundColor: '#FF6A00',
    padding: '12px',
    color: 'white',
    fontWeight: 800,
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#FAC8B2',
    },
    '&:disabled': {
      color: 'white',
      backgroundColor: '#A0A0A0',
    },
  })
);
export const StyledOutlinedButton = React.memo(
  styled(Button)({
    borderRadius: '10px',
    backgroundColor: 'white',
    border: '1px solid #FF6A00',
    padding: '12px',
    color: '#FF6A00',
    fontWeight: 800,
    fontSize: '16px',
  })
);

export const StyledPermissionSelect = styled(Select)(
  ({ selectedValue }: { selectedValue: permissionValue }) => ({
    width: '273px',
    height: '48px',
    padding: '12px 16px',
    backgroundColor: getBackgroundColor(selectedValue),
    display: 'flex',
    color: getLabelColor(selectedValue),
    borderRadius: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
    '& fieldset': {
      borderRadius: '18px',
      border: '0',
    },
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
  })
);

export const StyledMenuItem = styled(MenuItem)(
  ({ menuItemValue }: { menuItemValue: permissionValue }) => ({
    width: '273px',
    height: '48px',
    padding: '12px 16px',
    margin: 0,
    gap: '8px',
    backgroundColor: 'white',
    display: 'flex',
    color: getLabelColor(menuItemValue),
    alignItems: 'center',
    borderRadius: 0,
    '&.Mui-selected': {
      backgroundColor: getBackgroundColor(menuItemValue),
      opacity: 1,
      '&:hover': {
        backgroundColor: getBackgroundColor(menuItemValue),
      },
    },
    '&:hover': {
      backgroundColor: getBackgroundColor(menuItemValue),
      cursor: 'pointer',
    },
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '&:last-of-type': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  })
);

export const StyledRoleSelect = styled(Select)({
  width: '100%',
  height: '48px',
  padding: '12px 16px',
  backgroundColor: '#ECEDEC',
  display: 'flex',
  color: 'black',
  borderRadius: '8px',
  '&:hover': {
    cursor: 'pointer',
  },
  '& fieldset': {
    borderRadius: '18px',
    border: '0',
  },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
});
export const StyledRoleMenuItem = styled(MenuItem)({
  width: '100%',
  height: '48px',
  padding: '12px 16px',
  margin: 0,
  gap: '8px',
  backgroundColor: 'white',
  display: 'flex',
  color: 'black',
  alignItems: 'center',
  borderRadius: 0,
  '&.Mui-selected': {
    backgroundColor: '#FAC8B2',
    opacity: 1,
    '&:hover': {
      backgroundColor: '#FAC8B2',
    },
  },
  '&:hover': {
    backgroundColor: '#FAC8B2',
    cursor: 'pointer',
  },
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
});
