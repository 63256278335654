import React from 'react';
import { Box, Typography } from '@mui/material';
import { Areas } from '../../../../components/GamifyIcon';
import PermissionWithSwitch from './formComponents/PermissionWithSwitch';
import PermissionWithSelect from './formComponents/PermissionWithSelect';
import { useRolesPermissionsContext } from '../../context/RolesAndPermissionsContext';
import {
  permissionValue,
  PermissionKeys,
  PermissionCategoryProps,
} from './RoleAndPermissions.types';
import '../../settingsStyles.css';

const TeamsPeople = React.memo(({ selectedRole }: PermissionCategoryProps) => {
  if (!selectedRole) return <></>;

  const { updateRole } = useRolesPermissionsContext();

  const handleRoleUpdate = (
    permissionKey: PermissionKeys,
    newValue: permissionValue | boolean
  ) => {
    updateRole(
      selectedRole.id,
      // PermissionCategory.GamesRewards,
      permissionKey,
      newValue
    );
  };
  return (
    <Box sx={{ px: 3, py: 2 }}>
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <Areas color="#878787" />
        <Typography sx={{ fontWeight: 700, color: '#878787' }}>
          Users
        </Typography>
      </Box>
      <Box className="permission-section">
        <PermissionWithSwitch
          value={
            selectedRole?.permissions[PermissionKeys.TeamsPeople_CreateUser]
          }
          label="Create users"
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.TeamsPeople_CreateUser, val)
          }
        />
        <PermissionWithSelect
          label="View user"
          value={selectedRole?.permissions[PermissionKeys.TeamsPeople_ViewUser]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.TeamsPeople_ViewUser, val)
          }
        />
        <PermissionWithSelect
          label="View user stat card"
          value={
            selectedRole?.permissions[
              PermissionKeys.TeamsPeople_ViewUserStatCard
            ]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.TeamsPeople_ViewUserStatCard, val)
          }
        />
        <PermissionWithSelect
          label="Update user"
          value={
            selectedRole?.permissions[PermissionKeys.TeamsPeople_UpdateUser]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.TeamsPeople_UpdateUser, val)
          }
        />
        <PermissionWithSelect
          label="Delete user"
          value={
            selectedRole?.permissions[PermissionKeys.TeamsPeople_DeleteUser]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.TeamsPeople_DeleteUser, val)
          }
        />
      </Box>
      <Box className="permission-section">
        {/* permission with select - TeamsPeople_UpdateManualKPIs -- exclude onlyMe */}
        <PermissionWithSelect
          label="Update manual KPIs"
          value={
            selectedRole?.permissions[
              PermissionKeys.TeamsPeople_UpdateManualKPIs
            ]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.TeamsPeople_UpdateManualKPIs, val)
          }
          excludeOptions={['onlyMe']}
        />
        <PermissionWithSwitch
          value={
            selectedRole?.permissions[
              PermissionKeys.TeamsPeople_ChangePersonsRole
            ]
          }
          label="Change user's role"
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.TeamsPeople_ChangePersonsRole, val)
          }
        />
        <PermissionWithSwitch
          value={
            selectedRole?.permissions[
              PermissionKeys.TeamsPeople_ChangePersonsStatus
            ]
          }
          label="Change user's status"
          onUpdate={(val) =>
            handleRoleUpdate(
              PermissionKeys.TeamsPeople_ChangePersonsStatus,
              val
            )
          }
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <Areas color="#878787" />
        <Typography sx={{ fontWeight: 700, color: '#878787' }}>
          Teams
        </Typography>
      </Box>
      <Box className="permission-section">
        <PermissionWithSwitch
          value={
            selectedRole?.permissions[PermissionKeys.TeamsPeople_CreateTeam]
          }
          label="Create teams"
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.TeamsPeople_CreateTeam, val)
          }
        />
        <PermissionWithSelect
          label="View team details"
          value={
            selectedRole?.permissions[
              PermissionKeys.TeamsPeople_ViewTeamDetails
            ]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.TeamsPeople_ViewTeamDetails, val)
          }
          excludeOptions={['onlyMe']}
        />
        <PermissionWithSelect
          label="Update team"
          value={
            selectedRole?.permissions[PermissionKeys.TeamsPeople_UpdateTeam]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.TeamsPeople_UpdateTeam, val)
          }
          excludeOptions={['onlyMe']}
        />
        <PermissionWithSelect
          label="Delete team"
          value={
            selectedRole?.permissions[PermissionKeys.TeamsPeople_DeleteTeam]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.TeamsPeople_DeleteTeam, val)
          }
          excludeOptions={['onlyMe']}
        />
        {/* AddPersonToTeam */}
        <PermissionWithSelect
          label="Add person to team"
          value={
            selectedRole?.permissions[
              PermissionKeys.TeamsPeople_AddPersonToTeam
            ]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.TeamsPeople_AddPersonToTeam, val)
          }
          excludeOptions={['onlyMe']}
        />
        {/* RemoveSomeoneFromTeam */}
        <PermissionWithSelect
          label="Remove person from team"
          value={
            selectedRole?.permissions[
              PermissionKeys.TeamsPeople_RemoveSomeoneFromTeam
            ]
          }
          onUpdate={(val) =>
            handleRoleUpdate(
              PermissionKeys.TeamsPeople_RemoveSomeoneFromTeam,
              val
            )
          }
          excludeOptions={['onlyMe']}
        />
      </Box>
    </Box>
  );
});

TeamsPeople.displayName = 'TeamsPeople';
export default TeamsPeople;
