import React, { useState } from 'react';
// import gql from 'graphql-tag';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
// import { StyledSelect, StyledMenuItem } from '../teams/StyledComponent';
import { StyledSelect, StyledMenuItem } from '../../common/StyledComponents';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import { GamifyToast } from '../../common/CustomToasts';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateUserAction,
  updateProfileAction,
  selectAllUsers,
  selectAllProfiles,
} from '../../store/reducers/users';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';

// NOTE: Role is an org/user-specifc attribute while Position is a team-specific attribute!

export default function RoleModal(props) {
  const {
    user,
    rolePositionObj,
    setRolePositionObj,
    showRoleModal,
    setShowRoleModal,
  } = props;
  const dispatch = useDispatch();

  const { orgRoles } = usePermissions();

  const normalizedOrgRoles = orgRoles.reduce((acc, role) => {
    acc[role.id] = role;
    return acc;
  }, {});

  const reduxUsers = useSelector(selectAllUsers);
  const reduxUser = reduxUsers[user.id];
  const reduxProfiles = useSelector(selectAllProfiles);
  const reduxProfile = reduxProfiles[user.id];

  const [isSaving, setIsSaving] = useState(false);
  const [dynamicModalObj, setDynamicModalObj] = useState(null);
  const [showDynamicConfirmModal, setShowDynamicConfirmModal] = useState(false);
  // console.log('RoleModal user: ', user);
  // console.log('RoleModal team: ', team);
  // console.log('RoleModal rolePositionObj: ', rolePositionObj);

  //
  //  Modify a persons role
  //
  async function modifyPersonRole(orgMemberID, roleID, onComplete) {
    // console.log("modify PersonRole: ", orgerMemberID, role);

    try {
      const orgMember = {
        id: orgMemberID,
        roleID,
      };
      // console.log('RoleModal orgMember: ', orgMember);
      await API.graphql({
        query: mutations.updateOrgMember,
        variables: { input: orgMember },
      });
      onComplete(true);
      // onRefresh();
    } catch (err) {
      console.error(
        'modifyPersonRole(): error updating Person Role:',
        JSON.stringify(err)
      );
      onComplete(false);
    }
  }

  const handleCancelChangeClick = () => {
    setShowRoleModal(false);
  };

  return (
    <>
      <Backdrop
        sx={{ zIndex: 100002 }}
        open={showRoleModal}
        onClick={(e) => {
          if (
            !e.target.closest('.save-role-position-button') &&
            !e.target.closest('.MuiSelect-select') &&
            !e.target.closest('.MuiMenuItem-root')
          ) {
            handleCancelChangeClick();
          }
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            width: 624,
            maxHeight: '65vh',
            padding: '24px 40px 32px 24px',
            borderRadius: 8,
            overflowY: 'auto',
          }}
        >
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              style={{
                color: '#222428',
                fontSize: '28px',
                fontWeight: 700,
                lineHeight: '48px',
                letterSpacing: '-0.02em',
                marginBottom: '16px',
              }}
            >
              {'Change Role'}
            </Typography>
          </Box>
          <Box
            style={{
              position: 'relative',
              width: '100%',
              marginBottom: 16,
            }}
          >
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: '#0B0B0C',
                marginBottom: 8,
              }}
            >
              Role
            </Typography>
            <StyledSelect
              style={{ width: '100%' }}
              // placeholder="HELLO!!"
              onChange={(e) => {
                setRolePositionObj((p) => ({
                  ...p,
                  form: e.target.value,
                }));
              }}
              value={rolePositionObj.form}
              name="role"
              MenuProps={{
                style: { zIndex: 100002 },
                MenuListProps: {
                  sx: {
                    maxHeight: 300,
                    overflowY: 'auto',
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderRadius: '8px',
                    border: '1px solid #D0D2D8',
                  },
                },
              }}
            >
              {orgRoles.map((opt, i) => (
                <StyledMenuItem value={opt.id} key={i}>
                  {opt.roleName}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              onClick={() => handleCancelChangeClick()}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 262,
                padding: 12,
                border: '1px solid #cfcfcf',
                borderRadius: 8,
              }}
            >
              <Typography style={{ color: '#323232', fontWeight: 700 }}>
                Cancel
              </Typography>
            </Box>
            <Box
              className={'save-role-position-button'}
              onClick={() => {
                // Don't repeat if saving in progress
                if (isSaving) return;

                const { original, form } = rolePositionObj;

                if (normalizedOrgRoles[form]?.isSuper) {
                  setIsSaving(false);
                  GamifyToast.error(
                    'You may not change to a Super Admin role. Please select another role and try again.'
                  );
                  return;
                }

                const originalRoleName =
                  normalizedOrgRoles[original]?.roleName || 'No Role';
                const formRoleName = normalizedOrgRoles[form]?.roleName;

                // Don't submit when no change to role, just close the modal
                if (original === form) {
                  setShowRoleModal(false);
                  return;
                }

                const messages = [];

                if (original !== form) {
                  messages.push(`role to ${formRoleName}`);
                }

                const message = `Change ${user.name}'s ${messages.join(
                  ' and '
                )}?`;

                let changeAdmin = false;
                let subtitle = 'You can change position and roles anytime';
                let subtitleColor;

                if (originalRoleName !== 'Admin' && formRoleName === 'Admin') {
                  changeAdmin = true;
                  subtitle = `${user.name} will have admin access to all your accounts`;
                  // subtitleColor = '#FF5C77';
                  subtitleColor = '#E35050';
                } else if (
                  originalRoleName === 'Admin' &&
                  formRoleName !== 'Admin'
                ) {
                  changeAdmin = true;
                  subtitle = `${user.name} will lose all admin permissions!`;
                  // subtitleColor = '#FF5C77';
                  subtitleColor = '#E35050';
                } else if (
                  normalizedOrgRoles[original]?.isSuper &&
                  !normalizedOrgRoles[form]?.isSuper
                ) {
                  changeAdmin = true;
                  subtitle = `${user.name} will lose all super admin permissions!`;
                  // subtitleColor = '#FF5C77';
                  subtitleColor = '#E35050';
                }

                // console.log('onClick Save message: ', message);

                const modalObject = {
                  // icon: "enable",
                  title: message,
                  subtitle,
                  subtitleColor,
                  // confirmButtonColor: changeAdmin ? '#FF5C77' : '#FF6A00',
                  confirmButtonColor: changeAdmin ? '#E35050' : '#FF6A00',
                  // iconBackground: changeAdmin ? "#FFDCF3" : "#FAC8B2",
                  onConfirm: async () => {
                    setShowDynamicConfirmModal(false);
                    setDynamicModalObj(null);
                    //   setCustomTab(null);
                    setIsSaving(true);

                    let resMessage = '';
                    let errors = false;
                    const errorFields = [];

                    if (original !== form) {
                      await modifyPersonRole(
                        user.orgs.items[0].id,
                        form,
                        (success) => {
                          if (success) {
                            resMessage += `${user.name}'s role changed to ${normalizedOrgRoles[form].roleName}.`;
                          } else {
                            errors = true;
                            errorFields.push('role');
                          }
                        }
                      );

                      // Update redux user and profile if they're in redux to avoid need to fetch
                      if (reduxUser) {
                        const updatedReduxUser = {
                          ...reduxUser,
                          orgs: {
                            ...reduxUser.orgs,
                            items: [
                              {
                                ...reduxUser.orgs.items[0],
                                roleID: form,
                              },
                            ],
                          },
                        };
                        dispatch(updateUserAction(updatedReduxUser));
                      }

                      if (reduxProfile) {
                        const updatedReduxProfile = {
                          ...reduxProfile,
                          user: {
                            ...reduxProfile.user,
                            orgs: {
                              ...reduxProfile.user.orgs,
                              items: [
                                {
                                  ...reduxProfile.user.orgs.items[0],
                                  roleID: form,
                                },
                              ],
                            },
                          },
                        };
                        dispatch(updateProfileAction(updatedReduxProfile));
                      }
                    }

                    setIsSaving(false);
                    setShowRoleModal(false);

                    if (errors) {
                      if (errorFields.includes('role')) {
                        GamifyToast.error(
                          `There was an error updating ${user.name}'s role, please try again`
                        );
                      }
                    } else {
                      // Update rolePositionObj.original on successful update
                      setRolePositionObj((p) => ({
                        ...p,
                        original: form,
                      }));
                      GamifyToast.success(resMessage);
                    }
                  },
                };

                setDynamicModalObj(modalObject);
                setShowDynamicConfirmModal(true);
                //   setIsSaving(true);
              }}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FF6A00',
                width: 262,
                padding: 12,
                borderRadius: 8,
              }}
            >
              {isSaving ? (
                <CircularProgress
                  style={{ color: 'white', width: 24, height: 24 }}
                ></CircularProgress>
              ) : (
                <Typography style={{ color: 'white', fontWeight: 700 }}>
                  Save
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Backdrop>
      <DynamicConfirmModal
        showDynamicConfirmModal={showDynamicConfirmModal}
        setShowDynamicConfirmModal={setShowDynamicConfirmModal}
        zIndex={100002}
        title={dynamicModalObj?.title}
        subtitle={dynamicModalObj?.subtitle}
        subtitleColor={dynamicModalObj?.subtitleColor}
        // icon={dynamicModalObj?.icon}
        // iconBackground={dynamicModalObj?.iconBackground}
        confirmButtonColor={dynamicModalObj?.confirmButtonColor}
        onConfirm={dynamicModalObj?.onConfirm}
      ></DynamicConfirmModal>
    </>
  );
}
