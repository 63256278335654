import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Button, Typography, Skeleton } from '@mui/material';
import { Text } from '@aws-amplify/ui-react';
import { BackIcon, GamifyIcon } from '../../../components/GamifyIcon';
import ProfileAboutCardSkeleton from './ProfileAboutCardSkeleton';
import ProfileTeamCardsSkeleton from './ProfileTeamCardsSkeleton';
import { usePermissions } from '../../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../../context/PermissionsContext/permissionTypes';

export default function ProfileAboutPageSkeleton(props) {
  const { routeBackToTeamPage, teamId, routeBackToLeaderboard } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userId } = useParams();

  const { hasPermission, checkPermission } = usePermissions();

  const showEdit = hasPermission(PermissionKeys.TeamsPeople_UpdateUser, [
    userId,
  ]);
  const showCalendar = hasPermission(
    PermissionKeys.Scheduling_ViewAppointments,
    [userId]
  );
  const showChangeRole = checkPermission(
    PermissionKeys.TeamsPeople_ChangePersonsRole
  );
  const showChangeStatus = checkPermission(
    PermissionKeys.TeamsPeople_ChangePersonsStatus
  );

  const showPermissions = showChangeRole || showChangeStatus;

  const showTeams = hasPermission(PermissionKeys.TeamsPeople_ViewTeamDetails, [
    userId,
  ]);

  const subPage = searchParams.get('subPage');

  const handleBack = () => {
    if (routeBackToTeamPage) {
      navigate(`/teams/${teamId}`);
    } else if (routeBackToLeaderboard) {
      navigate('/performance/leaderboard');
    } else {
      navigate('/people');
    }
  };

  const profileButtons = [];

  if (showEdit) {
    profileButtons.push({
      // icon: '/images/Edit_Pencil.svg',
      icon: 'edit',
      title: 'Edit',
    });
  }

  if (showCalendar) {
    profileButtons.push({
      // icon: '/images/add-people-icon.svg',
      icon: 'calendar',
      title: 'Availability',
    });
  }

  if (showPermissions) {
    profileButtons.push({
      // icon: '/images/link-icon.svg',
      icon: 'role',
      title: 'Permissions',
    });
  }

  return (
    <>
      <Box
        style={{
          // height: '52vh',
          height: 480,
          width: '100vw',
          paddingTop: '90px',
          paddingLeft: '60px',
          paddingRight: '60px',
          margin: 0,
          background:
            'radial-gradient(circle at 50% 80%, #FFD773 0%, #FF8630 50%, #FF6A00 100%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%',
            paddingTop: 34,
          }}
        >
          <Button
            className={'team-page-back-button'}
            onClick={() => handleBack()}
            disableRipple={true}
          >
            <BackIcon></BackIcon>
            <Typography
              style={{
                color: '#0B0B0C',
                fontWeight: 600,
                fontSize: 16,
                marginLeft: 16,
              }}
            >
              {routeBackToTeamPage
                ? 'Back to team'
                : routeBackToLeaderboard
                ? 'Back to leaderboard'
                : 'Back to people'}
            </Typography>
          </Button>
        </Box>
        <Box
          style={{
            width: '40vw',
            height: '100%',
            padding: '15px 15px 40px 15px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box style={{ borderRadius: '8px', overflow: 'hidden' }}>
            <Skeleton
              sx={{ bgcolor: '#868686', marginBottom: '21px' }}
              variant={'circular'}
              width={240}
              height={240}
            ></Skeleton>
          </Box>
          <Skeleton
            sx={{ bgcolor: 'black' }}
            variant={'rounded'}
            width={300}
            height={34}
          ></Skeleton>
          <Skeleton
            sx={{ bgcolor: 'black', marginTop: '12px' }}
            variant={'rounded'}
            width={150}
            height={27}
          ></Skeleton>
        </Box>
        {/* Box below keeps team image, name, and description centered */}
        <Box
          style={{
            display: 'flex',
            minWidth: routeBackToTeamPage
              ? 155.86
              : routeBackToLeaderboard
              ? 210.48
              : 159.45,
          }}
        >
          <Typography style={{ color: 'transparent' }}>.</Typography>
        </Box>
      </Box>
      <Box
        style={{
          position: 'relative',
          top: '-30px',
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            display: 'flex',
            width: '30vw',
            minWidth: 250,
            justifyContent: 'space-around',
          }}
        >
          {profileButtons.map((button, i) => {
            return (
              <Box
                key={i}
                className="hov"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 91,
                }}
              >
                <Box
                  style={{
                    height: '56px',
                    width: '56px',
                    borderRadius: '50%',
                    boxShadow: '-1px 1px 4px 0 rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    marginBottom: '10px',
                  }}
                >
                  <GamifyIcon icon={button.icon} color={'#FF6A00'}></GamifyIcon>
                </Box>
                <Text
                  style={{
                    color: '#FF6A00',
                    fontWeight: '600',
                    fontSize: 14,
                  }}
                >
                  {button.title}
                </Text>
              </Box>
            );
          })}
        </Box>
        {showTeams && (
          <Box style={{ width: '40vw', height: '100%', marginTop: '4ch' }}>
            <Box style={{ borderBottom: '1px solid #868686', display: 'flex' }}>
              <Box
                className="team-page-section-button hov"
                style={{
                  borderBottom:
                    subPage === 'about' ? '3px solid #FF6A00' : 'none',
                  color: subPage === 'about' ? '#FF6A00' : '#868686',
                }}
              >
                About
              </Box>

              <Box
                className="team-page-section-button hov"
                style={{
                  borderBottom:
                    subPage === 'teams' ? '3px solid #FF6A00' : 'none',
                  color: subPage === 'teams' ? '#FF6A00' : '#868686',
                }}
              >
                Teams
              </Box>
            </Box>
          </Box>
        )}
        <Box
          style={{
            width: '40vw',
            marginTop: '2ch',
          }}
        >
          {subPage === 'about' && (
            <ProfileAboutCardSkeleton></ProfileAboutCardSkeleton>
          )}
          {subPage === 'teams' && (
            <ProfileTeamCardsSkeleton></ProfileTeamCardsSkeleton>
          )}
        </Box>
      </Box>
    </>
  );
}
