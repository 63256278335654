import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Avatar,
  Box,
  Button,
  CardMedia,
  Checkbox,
  Typography,
} from '@mui/material';
import { EllipsisIcon } from '../../components/GamifyIcon';
import MemberOptionsMenu from './MemberOptionsMenu';
import RoleModal from '../profile/RoleModal';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';

export default function TeamMemberCard({
  team,
  member,
  setIsMemberListLoading,
  onTriggerMemberRefetch,
  onRemoveMemberFromState,
  onRemoveMembers,
  teamMembers,
  selectedTeamMember,
  setSelectedTeamMember,
  setCheckedTeamMembers,
  handleTeamMemberCheck,
  checkedTeamMembers,
  isOnTeamForm,
}) {
  const navigate = useNavigate();

  const { checkPermission, hasTeamPermission } = usePermissions();

  const changeRolePermission = checkPermission(
    PermissionKeys.TeamsPeople_ChangePersonsRole
  );
  const showRemovePerson = hasTeamPermission(
    PermissionKeys.TeamsPeople_RemoveSomeoneFromTeam,
    [team.id]
  );

  // Show menu if either changeRolePermission or showRemovePerson is true
  const showMenu = changeRolePermission || showRemovePerson;

  // const [user, setUser] = useState(null);
  // const [memberOptionsOpen, setMemberOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [rolePositionObj, setRolePositionObj] = useState({
    original: member?.orgs?.items[0]?.roleID,
    form: member?.orgs?.items[0]?.roleID,
  });
  // console.log('TeamMemberCard member: ', member);

  // const handleClickOffMemberOptionMenu = () => {
  //   setMemberOptionsOpen(false);
  // };

  let memberRoleColor;
  let memberRoleBackgroundColor;

  if (member.circleMember?.memberRole === 'closer') {
    memberRoleBackgroundColor = '#FF9C4166';
    memberRoleColor = '#DA4100';
  } else if (member.circleMember?.memberRole === 'setter') {
    memberRoleBackgroundColor = '#B3F6FF';
    memberRoleColor = '#005897';
  } else if (member.circleMember?.memberRole === 'manager') {
    memberRoleBackgroundColor = '#AFF3CE';
    memberRoleColor = '#017E6A';
  } else if (member.circleMember?.memberRole === 'scheduler') {
    memberRoleBackgroundColor = '#E8E8E8';
    memberRoleColor = '#060606';
  } else if (member.circleMember?.memberRole === 'other') {
    memberRoleBackgroundColor = '#FF6A00';
    memberRoleColor = '#FFFFFF';
  }

  // console.log('TeamMemberCard rolePositionObj: ', rolePositionObj);
  return (
    <>
      <RoleModal
        user={member}
        // team={team}
        roleOnly={true}
        rolePositionObj={rolePositionObj}
        setRolePositionObj={setRolePositionObj}
        showRoleModal={showRoleModal}
        setShowRoleModal={setShowRoleModal}
        onTriggerRefetch={onTriggerMemberRefetch}
      ></RoleModal>
      {/* NOTE: This background was only needed for custom built options menu, CustomMenu and CustomMenuItem components do not need this */}
      {/* {memberOptionsOpen && (
        <Box
          onClick={handleClickOffMemberOptionMenu}
          className="option-menu-container-background"
        ></Box>
      )} */}
      <Box
        sx={{ cursor: isOnTeamForm ? 'default' : 'pointer' }}
        onClick={(e) => {
          // NOTE: For now, only have checkboxes on TeamPage
          if (!isOnTeamForm) setSelectedTeamMember(member);
          // Do not navigate to any profile pages from the TeamFormPage
          if (isOnTeamForm) return;
          // Check if the click is not on the team-member-option-button
          if (
            !e.target.closest('.team-member-option-button') &&
            !e.target.closest('.MuiCheckbox-root')
          ) {
            navigate(`/people/about/${member.id}`, {
              state: { teamPage: true, teamId: team.id },
            });
          }
        }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: isOnTeamForm ? 'white' : '#f0f0f3',
          padding: 15,
          minWidth: 'fit-content',
          borderRadius: 8,
          marginBottom: isOnTeamForm ? 8 : 5,
        }}
      >
        <Box className={'team-member-list-item-left-container'}>
          {/* {console.log('TeamMemberCard member: ', member)} */}
          {/* NOTE: Need permission to remove members and must not be on team form */}
          {!isOnTeamForm && showRemovePerson && (
            <Checkbox
              sx={{
                // color: '#FF6A00',
                color: '#868686',
                '&.Mui-checked': {
                  color: '#FF6A00',
                },
                '&:hover': {
                  backgroundColor: 'transparent !important',
                },
              }}
              checked={checkedTeamMembers.some(
                (checkedTeamMember) => checkedTeamMember.id === member.id
              )}
              onChange={(e) => {
                e.stopPropagation();
                handleTeamMemberCheck(e, member);
              }}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )}
          {/* Display member avatar if they have one, otherwise display initials */}
          {/* Account for image type to account for potential gifs */}
          {member.avatarFile ? (
            member.imageType === 'picture' ||
            member.imageType.split('/')[0] === 'image' ? (
              <Avatar
                src={member.avatarFile}
                sx={{
                  margin: '5px 12px 5px 5px',
                  cursor: isOnTeamForm ? 'default' : 'pointer',
                }}
                onClick={(e) => {
                  // Do not navigate to any profile pages from the TeamFormPage
                  if (isOnTeamForm) return;
                  e.stopPropagation();
                  navigate(`/people/stats/${member.id}`, {
                    state: { teamPage: true, teamId: team.id },
                  });
                }}
              />
            ) : (
              <CardMedia
                image={member.imageName}
                component="img"
                sx={{
                  backgroundColor: 'black',
                  borderRadius: 1000,
                  height: 40,
                  width: 40,
                  objectFit: 'contain',
                  margin: '5px 12px 5px 5px',
                  cursor: isOnTeamForm ? 'default' : 'pointer',
                }}
                onClick={(e) => {
                  // Do not navigate to any profile pages from the TeamFormPage
                  if (isOnTeamForm) return;
                  e.stopPropagation();
                  navigate(`/people/stats/${member.id}`, {
                    state: { teamPage: true, teamId: team.id },
                  });
                }}
              />
            )
          ) : (
            <Avatar
              sx={{
                backgroundColor: '#868686',
                margin: '5px 12px 5px 5px',
                cursor: isOnTeamForm ? 'default' : 'pointer',
              }}
              onClick={(e) => {
                // Do not navigate to any profile pages from the TeamFormPage
                if (isOnTeamForm) return;
                e.stopPropagation();
                navigate(`/people/stats/${member.id}`, {
                  state: { teamPage: true, teamId: team.id },
                });
              }}
            >
              {member.initials}
            </Avatar>
          )}
          <Box className={'team-member-list-item-left-text-container'}>
            <Box style={{ display: 'flex' }}>
              <Typography
                style={{
                  color: '#222428',
                  fontSize: 16,
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                }}
              >
                {member.name}
              </Typography>
              {member.status === 'disabled' && (
                <Typography
                  style={{
                    // color: '#FF5C77',
                    color: '#E35050',
                    fontSize: 16,
                    fontWeight: 600,
                    marginLeft: 4,
                    whiteSpace: 'nowrap',
                  }}
                >
                  Deactivated
                </Typography>
              )}
            </Box>
            <Typography
              style={{
                color: '#868686',
                fontSize: 14,
                fontWeight: 500,
                whiteSpace: 'nowrap',
              }}
            >
              {member.title}
            </Typography>
          </Box>
        </Box>
        <Box
          //   className={
          //     !isOnTeamForm ? 'team-member-list-item-right-container' : ''
          //   }
          style={{
            position: 'relative',
            height: 45,
            display: 'flex',
            alignItems: isOnTeamForm ? 'center' : 'flex-start',
          }}
        >
          {/* NOTE: member.functionalRoles is for user-specific which isn't used for roles anymore. member.circleMember.memberRole is the team-specific role to be displayed */}
          {member.circleMember?.memberRole && !isOnTeamForm && (
            <Box
              style={{
                backgroundColor: memberRoleBackgroundColor || '#FAC8B2',
                padding: '4px 8px 4px 8px',
                borderRadius: '1000px',
              }}
            >
              <Typography
                style={{
                  color: memberRoleColor || '#FF6A00',
                  fontSize: 12,
                  fontWeight: 700,
                }}
              >
                {/* {member.functionalRoles} */}
                {member.circleMember?.memberRole[0].toUpperCase() +
                  member.circleMember?.memberRole.slice(1)}
              </Typography>
            </Box>
          )}
          {showMenu && (
            <>
              <Button
                className={'team-member-option-button'}
                onClick={(e) => {
                  // setMemberOptionsOpen(!memberOptionsOpen)
                  setAnchorEl(e.currentTarget);
                }}
                disableRipple={true}
              >
                <EllipsisIcon color={'#868686'}></EllipsisIcon>
              </Button>

              <MemberOptionsMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                team={team}
                user={member}
                selectedTeamMember={selectedTeamMember}
                setCheckedTeamMembers={setCheckedTeamMembers}
                setIsMemberListLoading={setIsMemberListLoading}
                setShowRoleModal={setShowRoleModal}
                onTriggerMemberRefetch={onTriggerMemberRefetch}
                onRemoveMemberFromState={onRemoveMemberFromState}
                onRemoveMembers={onRemoveMembers}
                teamMembers={teamMembers}
                isOnTeamForm={isOnTeamForm}
              ></MemberOptionsMenu>
            </>
          )}
          {/* {memberOptionsOpen && (
            <MemberOptionsMenu
              setMemberOptionsOpen={setMemberOptionsOpen}
              team={team}
              user={member}
              selectedTeamMember={selectedTeamMember}
              setCheckedTeamMembers={setCheckedTeamMembers}
              setIsMemberListLoading={setIsMemberListLoading}
              setShowRoleModal={setShowRoleModal}
              onTriggerMemberRefetch={onTriggerMemberRefetch}
              onRemoveMemberFromState={onRemoveMemberFromState}
              onRemoveMembers={onRemoveMembers}
              isOnTeamForm={isOnTeamForm}
            ></MemberOptionsMenu>
          )} */}
        </Box>
      </Box>
    </>
  );
}
