import { Typography, Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useSettingsContext } from '../context/SettingsContext';
import { useParams } from 'react-router-dom';
import RolesAndPermissions from './RolesAndPermissions/RolesAndPermissions';
import { RolesPermissionsProvider } from '../context/RolesAndPermissionsContext';

const SettingsContainer = () => {
  const { selectedSetting, setSelectedSetting } = useSettingsContext();
  const { setting } = useParams<{ setting: string }>(); // Get the current setting from the URL params

  useEffect(() => {
    if (setting) {
      setSelectedSetting(setting as any);
    }
  }, [setting, setSelectedSetting]);

  const renderSetting = () => {
    switch (selectedSetting) {
      case 'roles':
        return (
          <RolesPermissionsProvider>
            <RolesAndPermissions />;
          </RolesPermissionsProvider>
        );

      case 'deals':
        return (
          <Typography style={{ color: 'red' }}>
            Deals and Properties Settings
          </Typography>
        );
      case 'hierarchy':
        return (
          <Typography style={{ color: 'red' }}>Hierarchy Settings</Typography>
        );
      default:
        return (
          <Typography style={{ color: 'red' }}>
            Select a setting from the sidebar
          </Typography>
        );
    }
  };

  return <Box sx={{ mt: 17, mx: 4, flexGrow: 1 }}>{renderSetting()}</Box>;
};

export { SettingsContainer };
