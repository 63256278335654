import React from 'react';
import useGames from './hooks/useGames';
import GamesView from './Games.view';
import { useGetUserInformationQuery } from '../../store/api/GraphQlQuery';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';

const GamesContainer = () => {
  const { data, isLoading: isLoadingGetUserInformation } =
    useGetUserInformationQuery({});
  const { gamesState, onChangeGamesState } = useGames({
    error: null,
    loading: true,
    gameDimensionType: 'Individual',
    games: [],
    showCompleted: false,
    currentGameIndex: 0,
  });
  // const showAdd = Boolean(
  //   !isLoadingGetUserInformation && data && data.me.canCreateMissions
  // );
  const { checkPermission } = usePermissions();
  const showAdd = Boolean(
    !isLoadingGetUserInformation &&
      data &&
      checkPermission(PermissionKeys.GamesRewards_CreateGame)
  );

  return (
    <GamesView
      showAdd={showAdd}
      gamesState={gamesState}
      onChangeGamesState={onChangeGamesState}
    />
  );
};

export default GamesContainer;
