import React from 'react';
import { FormControl } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import '../../../settingsStyles.css';
import { StyledRoleSelect, StyledRoleMenuItem } from './styledComponents';
import { useRolesPermissionsContext } from '../../../context/RolesAndPermissionsContext';

interface RoleSelectProps {
  value: string;
  onChange: (value: string) => void;
}

interface roleMenuItem {
  id: string;
  label: string;
}
export default function RoleSelect({ value, onChange }: RoleSelectProps) {
  const { roles } = useRolesPermissionsContext();
  const options = roles.reduce(
    (acc, role) => {
      if (!role.isSuper) acc.push({ id: role.id, label: role.roleName });
      return acc;
    },
    [{ id: 'none', label: 'Select role' }] as roleMenuItem[]
  );

  const MenuProps = {
    PaperProps: {
      style: {
        border: '1px solid #ccc',
        borderRadius: '8px',
        paddingTop: 0,
        padding: 0,
      },
    },
    MenuListProps: {
      disablePadding: true, // Disable default padding
    },
  };

  return (
    <FormControl sx={{ width: '100%', padding: 0 }}>
      <StyledRoleSelect
        value={value}
        MenuProps={MenuProps}
        onChange={(e: SelectChangeEvent<unknown>) =>
          onChange(e.target.value as string)
        }
      >
        {options.map((option) => (
          <StyledRoleMenuItem key={option.id} value={option.id}>
            {option.label}
          </StyledRoleMenuItem>
        ))}
      </StyledRoleSelect>
    </FormControl>
  );
}
