import React from 'react';
import { Box, Typography } from '@mui/material';
import { Areas } from '../../../../components/GamifyIcon';
// import PermissionWithSwitch from './formComponents/PermissionWithSwitch';
import PermissionWithSelect from './formComponents/PermissionWithSelect';
import { useRolesPermissionsContext } from '../../context/RolesAndPermissionsContext';
import {
  permissionValue,
  PermissionCategoryProps,
  PermissionKeys,
} from './RoleAndPermissions.types';
import '../../settingsStyles.css';

const Scheduling = React.memo(({ selectedRole }: PermissionCategoryProps) => {
  if (!selectedRole) return <></>;

  const { updateRole } = useRolesPermissionsContext();

  const handleRoleUpdate = (
    permissionKey: PermissionKeys,
    newValue: permissionValue | boolean
  ) => {
    updateRole(selectedRole.id, permissionKey, newValue);
  };
  return (
    <Box sx={{ px: 3, py: 2 }}>
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <Areas color="#878787" />
        <Typography sx={{ fontWeight: 700, color: '#878787' }}>
          Scheduling
        </Typography>
      </Box>
      <Box className="permission-section">
        {/* Scheduling_BookAppointment */}
        <PermissionWithSelect
          label="Book appointment"
          value={
            selectedRole?.permissions[PermissionKeys.Scheduling_BookAppointment]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Scheduling_BookAppointment, val)
          }
        />
        {/* <PermissionWithSwitch
          label="Book appointment"
          value={
            selectedRole?.permissions[PermissionKeys.Scheduling_BookAppointment]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Scheduling_BookAppointment, val)
          }
        /> */}
        {/* Scheduling_ViewAppointments */}
        <PermissionWithSelect
          label="View appointments"
          value={
            selectedRole?.permissions[
              PermissionKeys.Scheduling_ViewAppointments
            ]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Scheduling_ViewAppointments, val)
          }
        />
        {/* Scheduling_ReassingAppointmnet */}
        <PermissionWithSelect
          label="Reassign appointment"
          value={
            selectedRole?.permissions[
              PermissionKeys.Scheduling_ReassignAppointment
            ]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Scheduling_ReassignAppointment, val)
          }
        />
      </Box>
      <Box className="permission-section">
        {/* Scheduling_MaintainWorkHours */}
        <PermissionWithSelect
          label="Maintain work hours"
          value={
            selectedRole?.permissions[
              PermissionKeys.Scheduling_MaintainWorkHours
            ]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Scheduling_MaintainWorkHours, val)
          }
        />
        {/* Scheduling_ManageRoundRobin */}
        <PermissionWithSelect
          label="Manage round robin"
          value={
            selectedRole?.permissions[
              PermissionKeys.Scheduling_ManageRoundRobin
            ]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Scheduling_ManageRoundRobin, val)
          }
        />
      </Box>
    </Box>
  );
});

Scheduling.displayName = 'Scheduling';
export default Scheduling;
