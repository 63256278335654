import React, { useState } from 'react';
import Item from '../_types/Item';
import FeedItemView from './FeedItem.view';
import Me from '../../../_types/Me';
import MyPerson from '../_types/MyPerson';
import EditPost from '../EditPost';

interface Props {
  me: Me;
  feedItem: Item;
  onDeleteFeedItem: (deletedFeedItem: Item) => void;
  myPersons: MyPerson[];
}

const FeedItemContainer = ({
  feedItem: originalFeedItem,
  onDeleteFeedItem,
  me,
  myPersons,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [feedItem, setFeedItem] = useState(originalFeedItem);
  if (isEditing) {
    return (
      <EditPost
        isEditing={isEditing}
        onChangeIsEditing={(newIsEditing: boolean) =>
          setIsEditing(newIsEditing)
        }
        myPersons={myPersons}
        me={me}
        feedItem={feedItem}
        onSave={(newPost: Item) => {
          setFeedItem({
            ...feedItem,
            activityFeed: { ...feedItem.activityFeed, ...newPost },
          });
        }}
      />
    );
  }

  return (
    <FeedItemView
      isEditing={isEditing}
      onChangeIsEditing={(newIsEditing: boolean) => setIsEditing(newIsEditing)}
      myPersons={myPersons}
      // me={me}
      feedItem={feedItem}
      onDeleteFeedItem={onDeleteFeedItem}
    />
  );
};

export default FeedItemContainer;
