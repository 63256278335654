import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Avatar,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  TextareaAutosize,
  CircularProgress,
} from '@mui/material';
import {
  AddIcon,
  CalendarIcon,
  EllipsisIcon,
  XIcon,
  GamifyIcon,
} from '../../../components/GamifyIcon';
import Dropzone from 'react-dropzone';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../../../../graphql/mutations';
import DealOptionsMenu from '../DealOptionsMenu';
import DealNoteOptionsMenu from '../DealNoteOptionsMenu';
import Moment from 'moment/moment';
import DisplayMedia from '../../../components/DisplayMedia';
import { Formik } from 'formik';
import { GamifyToast } from '../../../common/CustomToasts';
import {
  getTZ,
  getCurrentUTCDate,
  generateRandomImageFileNameString,
  newLocalToISODateFormat,
  getLocalDateTime,
} from '../../../common/Utilities';
import DynamicConfirmModal from '../../../common/DynamicConfirmModal';
import ImageCarousel from '../../../common/ImageCarousel';
import { usePermissions } from '../../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../../context/PermissionsContext/permissionTypes';

export default function SelectedDealDisplay({ props }) {
  // console.log('selected deal display props: ', props);
  const {
    deal,
    isOnKanban,
    mainDisplayAttrs,
    fullName,
    dealStage,
    appointments,
    comments,
    metaData,
    setAddDealOpen,
    setSortOpen,
    // selectedDealOptionsOpen,
    // setSelectedDealOptionsOpen,
    selectedDealMetaDataOpen,
    setSelectedDealMetaDataOpen,
    // setShowEditDealFormModal,
    handleOpenOwnerDealStageModal,
    handleDeleteDeal,
    handleRefetchDeals,
    onTriggerSelectedDealRefetch,
    userId,
    dealId,
    categoryId,
    stageId,
  } = props;

  const { hasPermission } = usePermissions();

  // create an array of owner ids and filter out any falsey values
  const ownersIds = [deal?.setter?.id, deal?.closer?.id].filter(Boolean);

  const showEdit = hasPermission(PermissionKeys.Sales_UpdateDeal, ownersIds);
  const showDelete = hasPermission(PermissionKeys.Sales_DeleteDeal, ownersIds);
  // const showSchedule = hasPermission(
  //   PermissionKeys.Scheduling_BookAppointment,
  //   ownersIds
  // );
  const showMenu = showEdit || showDelete; /* || showSchedule; */

  //   const [selectedDealOptionsOpen, setSelectedDealOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addNoteFocused, setAddNoteFocused] = useState(false);
  const [notesAnchorElMap, setNotesAnchorElMap] = useState({});
  const [editNoteId, setEditNoteId] = useState(null);
  // const [notesFocusedMap, setNotesFocusedMap] = useState({});
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [imageIndex, setImageIndex] = useState(null);
  const [showImageCarousel, setShowImageCarousel] = useState(false);
  const [showConfirmDeleteImage, setShowConfirmDeleteImage] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // console.log('SelectedDealDisplay deal: ', deal);

  let contactType = props.contactType;
  if (contactType === undefined) {
    contactType = '';
  }
  let dispositionID = props.dispositionID;
  if (dispositionID === undefined) {
    dispositionID = '';
  }

  const newDealContact = {
    contactType,
    dispositionID,
    newStageID: stageId,
    notes: '',
    notesMap: {},
    value: 0,
    imageName: '',
    imageType: '',
    dealID: dealId,
    categoryID: categoryId,
  };
  const [initialValues, setInitialValues] = useState(newDealContact);

  useEffect(() => {
    const newNotesMap = {};

    for (const comment of comments) {
      newNotesMap[comment.id] = comment.comment;
    }

    setInitialValues({
      contactType,
      dispositionID,
      newStageID: stageId,
      notes: '',
      notesMap: newNotesMap,
      value: 0,
      imageName: '',
      imageType: '',
      dealID: dealId,
      categoryID: categoryId,
    });
  }, [dealId]);

  useEffect(() => {
    // Need to reset the edit note id, notes focused map, and notes anchor el map whenver comments change so each map is always up to date
    const newNotesAnchorElMap = {};
    // const newNotesFocusedMap = {};
    const newNotesMap = {};

    for (const comment of comments) {
      newNotesAnchorElMap[comment.id] = null;
      // newNotesFocusedMap[comment.id] = false;
      newNotesMap[comment.id] = comment.comment;
    }

    setEditNoteId(null);
    // setNotesFocusedMap(newNotesFocusedMap);
    setNotesAnchorElMap(newNotesAnchorElMap);
    setInitialValues({
      contactType,
      dispositionID,
      newStageID: stageId,
      notes: '',
      notesMap: newNotesMap,
      value: 0,
      imageName: '',
      imageType: '',
      dealID: dealId,
      categoryID: categoryId,
    });
  }, [comments]);

  // console.log('SelectedDealDisplay appointments: ', appointments);
  // console.log('SelectedDealDisplay comments: ', comments);
  // console.log('SelectedDealDisplay editNoteId: ', editNoteId);
  // console.log('SelectedDealDisplay notesAnchorElMap: ', notesAnchorElMap);
  // console.log('SelectedDealDisplay notesFocusedMap: ', notesFocusedMap);
  // console.log('SelectedDealDisplay notesMap: ', initialValues.notesMap);
  // console.log('SelectedDealDisplay metaData: ', metaData);
  // console.log('SelectedDealDisplay ownerProfilePicture: ', ownerProfilePicture);
  // console.log('SelectedDealDisplay currentOwnerID: ', currentOwnerID);

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
    if (setAddDealOpen) {
      setAddDealOpen(false);
    }
    if (setSortOpen) {
      setSortOpen(false);
    }
  };

  // NOTE: Appointment related functionaliy commented out until calendar/scheduling built for web
  // const handleAddAppointment = () => {
  //   console.log('handleAddAppointment placeholder');
  // };

  // const handleAppointmentOptionsClick = () => {
  //   console.log('handleAppointmentOptionsClick placeholder');
  // };

  const handleNoteOptionsOpen = (e, dealNoteId, formikProps) => {
    // console.log('notesCheck handleNoteOptionsOpen dealNoteId: ', dealNoteId);
    // console.log('notesCheck handleNoteOptionsOpen e: ', e);
    setNotesAnchorElMap((prev) => ({
      ...prev,
      [dealNoteId]: e.target,
    }));
    if (setAddDealOpen) {
      setAddDealOpen(false);
    }
    if (setSortOpen) {
      setSortOpen(false);
    }
    setEditNoteId(null);
    formikProps.resetForm();
  };

  const onDropDealImage = async (droppedFile) => {
    setIsImageUploading(true);

    const formats = ['jpeg', 'jpg', 'png', 'gif'];
    const image = droppedFile[0]; // the imageFile
    // console.log('onDropDealImage image: ', image);
    const imageName = image.name;
    if (!formats.includes(imageName.split('.').pop().toLowerCase())) {
      GamifyToast.error(
        'Deal image format must be one of the following - png, gif, jpeg'
      );
      setIsImageUploading(false);
      return;
    }

    // Parse deal's flexAttributes to update the pictures flexAttribute before updating the deal
    const flexAttributes = JSON.parse(deal.flexAttributes);

    // Generate random string and take image format to create new file name to match file names on deals in lyferize
    const imageNameFormat = imageName.split('.').pop().toLowerCase();
    const randomString = generateRandomImageFileNameString();
    const newImageName = `${randomString}.${imageNameFormat}`;

    try {
      const uploadedImage = await Storage.put(newImageName, image, {
        contentType: image.type,
      });

      // NOTE: For GG-1000, some deals had the pictures attribute removed for some reason. To account for this, set flexAttributes.pictures to an empty array if that key doesn't exist in the flexAttributes
      if (!flexAttributes.pictures) flexAttributes.pictures = [];

      // Update pictures in flexAttributes w/ randomly generated uploaded image names
      flexAttributes.pictures = [uploadedImage.key, ...flexAttributes.pictures];

      // Update deal with newly updated flexAttributes stringified
      deal.flexAttributes = JSON.stringify(flexAttributes);

      // Create ourDeal with newly updated deal to match updating deal from DealFormPage
      const ourDeal = {
        id: deal.id,
        name: deal.name,
        // description: values.description,
        // notes: values.notes,
        status: deal.status,
        imageName: deal.imageName,
        imageType: deal.imageType,
        currentStageID: deal.currentStageID,
        currentOwnerID: deal.currentOwnerID,
        currentOwnerType: deal.currentOwnerType,
        orgID: deal.orgID,
        // value: values.value,
        isDeleted: false,
        flexAttributes: deal.flexAttributes,
        categoryID: deal.categoryID,
        // currentDispositionID: props.dispositionID, // NOTE: from MaintainDealScreenFromFeed component in lyferize
        tmst: newLocalToISODateFormat(getLocalDateTime()),
      };

      // NOTE: completeMission is now done with maintainDealV3, the commented out code below is no longer needed
      // const completeMissionInput = {
      //   userMissionID: '',
      //   completedFlag: false,
      //   // myDoneActionCount,
      //   // comments: values.notes,
      //   // followupDate: shortLocalToISODateFormat(values.followupDate),
      //   doneImage: '',
      //   doneImageType: '',
      //   status: 'completed',
      //   extID: '',
      //   dealID: deal.id,
      //   stageID: deal.currentStageID,
      //   tmst: newLocalToISODateFormat(getLocalDateTime()),
      //   // dispositionID: props.dispositionID
      //   //   ? props.dispositionID
      //   //   : defaultDispositionID,
      // };

      // console.log('onDropDealImage ourDeal: ', ourDeal);
      // console.log('onDropDealImage completeMissionInput: ', completeMissionInput);

      // Use maintainDealV3 to update the deal with newly updated flexAttributes
      await API.graphql(
        graphqlOperation(mutations.maintainDealV3, {
          dealInfo: JSON.stringify(ourDeal),
        })
      );

      // NOTE: completeMission is now done with maintainDealV3, the commented out code below is no longer needed
      // // completeMission will update the timestamp on the deal
      // await API.graphql({
      //   query: mutations.completeMission,
      //   variables: {
      //     missionInfo: JSON.stringify(completeMissionInput),
      //   },
      // });
    } catch (err) {
      console.error('onDropDealImage error: ', err);
      GamifyToast.error(
        'There was an error adding your image, please try again'
      );
      setIsImageUploading(false);
      return;
    }

    onTriggerSelectedDealRefetch();

    // Refetch deals doesn't need args for deals list, kanban needs the stage id and to put false for showLoading
    if (!isOnKanban) {
      handleRefetchDeals();
    } else {
      // NOTE: null sortOrder results in using the current selected sortOrder of that dealStage
      handleRefetchDeals([deal.currentStageID], null, false);
    }

    setTimeout(() => {
      GamifyToast.success('Image added to deal successfully');
      setIsImageUploading(false);
    }, 1250);
  };

  const handleImageClick = (index) => {
    setImageIndex(index);
    setTimeout(() => {
      setShowImageCarousel(true);
    }, 250);
  };

  const handleDeleteImageClick = (index) => {
    setImageIndex(index);
    setShowConfirmDeleteImage(true);
  };

  const handleConfirmDeleteImage = async (index) => {
    setIsSaving(true);
    setIsImageUploading(true);

    // Parse deal's flexAttributes to update the pictures flexAttribute before updating the deal
    const flexAttributes = JSON.parse(deal.flexAttributes);

    // Remove the 1 image at the desired index
    flexAttributes.pictures.splice(index, 1);

    // console.log('handleConfirmDeleteImage images: ', flexAttributes.pictures);

    // Update deal with newly updated flexAttributes stringified
    deal.flexAttributes = JSON.stringify(flexAttributes);

    // Create ourDeal with newly updated deal to match updating deal from DealFormPage
    const ourDeal = {
      id: deal.id,
      name: deal.name,
      // description: values.description,
      // notes: values.notes,
      status: deal.status,
      imageName: deal.imageName,
      imageType: deal.imageType,
      currentStageID: deal.currentStageID,
      currentOwnerID: deal.currentOwnerID,
      currentOwnerType: deal.currentOwnerType,
      orgID: deal.orgID,
      // value: values.value,
      isDeleted: false,
      flexAttributes: deal.flexAttributes,
      categoryID: deal.categoryID,
      // currentDispositionID: props.dispositionID, // NOTE: from MaintainDealScreenFromFeed component in lyferize
      tmst: newLocalToISODateFormat(getLocalDateTime()),
    };

    // NOTE: completeMission is now done with maintainDealV3, the commented out code below is no longer needed
    // const completeMissionInput = {
    //   userMissionID: '',
    //   completedFlag: false,
    //   // myDoneActionCount,
    //   // comments: values.notes,
    //   // followupDate: shortLocalToISODateFormat(values.followupDate),
    //   doneImage: '',
    //   doneImageType: '',
    //   status: 'completed',
    //   extID: '',
    //   dealID: deal.id,
    //   stageID: deal.currentStageID,
    //   tmst: newLocalToISODateFormat(getLocalDateTime()),
    //   // dispositionID: props.dispositionID
    //   //   ? props.dispositionID
    //   //   : defaultDispositionID,
    // };

    // console.log('handleConfirmDeleteImage ourDeal: ', ourDeal);
    // console.log('handleConfirmDeleteImage completeMissionInput: ', completeMissionInput);

    try {
      // Use maintainDealV3 to update the deal with newly updated flexAttributes
      await API.graphql(
        graphqlOperation(mutations.maintainDealV3, {
          dealInfo: JSON.stringify(ourDeal),
        })
      );

      // NOTE: completeMission is now done with maintainDealV3, the commented out code below is no longer needed
      // // completeMission will update the timestamp on the deal
      // await API.graphql({
      //   query: mutations.completeMission,
      //   variables: {
      //     missionInfo: JSON.stringify(completeMissionInput),
      //   },
      // });
    } catch (err) {
      console.error('onDropDealImage error: ', err);
      GamifyToast.error(
        'There was an error removing your image, please try again'
      );
      setIsSaving(false);
      setIsImageUploading(false);
      return;
    }

    onTriggerSelectedDealRefetch();

    // Refetch deals doesn't need args for deals list, kanban needs the stage id and to put false for showLoading
    if (!isOnKanban) {
      handleRefetchDeals();
    } else {
      // NOTE: null sortOrder results in using the current selected sortOrder of that dealStage
      handleRefetchDeals([deal.currentStageID], null, false);
    }

    setTimeout(() => {
      GamifyToast.success('Image removed from deal successfully');
      setIsImageUploading(false);
    }, 1250);

    setIsSaving(false);
    setShowConfirmDeleteImage(false);
  };

  const handleMetaDataClick = () => {
    setSelectedDealMetaDataOpen(!selectedDealMetaDataOpen);
  };

  const formatTime = (unformatedTime) => {
    // Need to account for timezone offset before formatting
    // Convert unformattedTime to the user's timezone (need to offset the tempLocalDate so that way .toISOString() converts to the local time when converting adjustedDate to UTC)
    const tempUTCDate = new Date(unformatedTime);
    // NOTE: getTimezoneOffset gives the offset from UTC in minutes, converting to ms by multiplying by 60000 is needed
    const timezoneOffset = tempUTCDate.getTimezoneOffset() * 60000;
    // NOTE: timezoneOffset is positive for west of UTC, negative for east of UTC -> subtract this offset to get local time
    const adjustedDate = new Date(tempUTCDate.getTime() - timezoneOffset);
    // NOTE: toISOString converts the Date object to UTC, since adjustedDate was offset this conversion will make adjustedDateTime the user's local date time
    const adjustedDateTime = adjustedDate.toISOString();

    const unformattedSplitTime = adjustedDateTime.split('T');
    const dealYear = unformattedSplitTime[0].slice(0, 4);
    const dealMonth = unformattedSplitTime[0].slice(5, 7);
    const dealDay = unformattedSplitTime[0].slice(8, 10);
    const dealHour = unformattedSplitTime[1].slice(0, 2);
    const dealMinute = unformattedSplitTime[1].slice(3, 5);
    const formattedTime = `${dealMonth}/${dealDay}/${dealYear} ${dealHour}:${dealMinute}`;

    return formattedTime;
  };

  function formatScheduleDate(date, minutesPastMidnight) {
    const zeroPad = (num, places) => String(num).padStart(places, '0');

    const hours = Math.trunc(minutesPastMidnight / 60);
    const minutes = minutesPastMidnight - hours * 60;
    const timeStr = `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}`;
    const momentDate = new Moment(`${date}T${timeStr}`);
    // console.log('momentDate: ', momentDate);
    return momentDate.format('dddd, MMMM Do, h:mm a');
  }

  let powerMainDisplayAttrs;
  const kwsMainDisplayAttrs = [];
  let imagesMainDisplayAttrs = [];

  // put power and kws and images attrs into separate objects, they won't render w/ the rest of mainDisplayAttrs later b/c their vals are objects not strings
  for (let i = 0; i < mainDisplayAttrs.length; i++) {
    const attr = mainDisplayAttrs[i];
    // console.log('SelectedDealDisplay attr.val: ', attr.val);

    if (typeof attr.val === 'object') {
      if (attr.val?.type === 'Power') {
        powerMainDisplayAttrs = attr;
      }
      if (attr.val?.type === 'kws') {
        if (attr.val.val !== '' && attr.val.val !== '$ ') {
          kwsMainDisplayAttrs.push({ label: [attr.label], val: attr.val.val });
        }
      }
      if (attr.val?.type === 'ImageList') {
        // console.log('SelectedDealDisplay ImageList attr: ', attr);
        imagesMainDisplayAttrs = attr.val.images;
      }
    }
  }

  // console.log('SelectedDealDisplay mainDisplayAttrs: ', mainDisplayAttrs);
  // console.log(
  //   'SelectedDealDisplay powerMainDisplayAttrs: ',
  //   powerMainDisplayAttrs
  // );
  // console.log('SelectedDealDisplay kwsMainDisplayAttrs: ', kwsMainDisplayAttrs);
  // console.log(
  //   'SelectedDealDisplay imagesMainDisplayAttrs: ',
  //   imagesMainDisplayAttrs
  // );

  async function createFeedItem(values) {
    // console.log('createFeedItem: ', values);

    let myDoneActionCount;

    if (values.value === '' || values.value === '0') {
      myDoneActionCount = Number(1);
    } else {
      myDoneActionCount = Number(values.value);
    }

    try {
      let dealID = values.dealID;
      if (dealID === '') {
        dealID = 'NO_DEAL';
      }
      const now = new Date();
      const completeMissionInput = {
        currentOwnerID: values.currentOwnerID,
        userMissionID: '',
        completedFlag: false,
        myDoneActionCount,
        doneImage: values.imageName,
        doneImageType: values.imageType,
        status: 'completed',
        extID: '',
        comments: values.notes,
        dealID,
        // stageID: newStageID,
        dispositionID: values.dispositionID,
        followupTZ: getTZ(),
        contactType: values.contactType,
        categoryID: values.categoryID,
        tmst: now.toISOString(),
      };
      // console.log('completeMissionInput: ', completeMissionInput);
      const completeMissionRes = await API.graphql({
        query: mutations.completeMission,
        variables: {
          missionInfo: JSON.stringify(completeMissionInput),
        },
      });
      try {
        const addressRcd = {
          id: dealID,
          lastVisited: getCurrentUTCDate(),
        };
        API.graphql(
          graphqlOperation(mutations.updateAddress, { input: addressRcd })
        );
      } catch (err) {
        console.warn('completeMission() - updateAddress error: ', err);
      }
      // console.log ("completeMissionRes: ", completeMissionRes);
      const completeMissionStatus = JSON.parse(
        completeMissionRes.data.completeMission
      );
      console.log('status: ', completeMissionStatus);
      if (completeMissionStatus.status === 'failed') {
        GamifyToast.error(
          global.appSettings.labels.mission.proper + ' save failed'
        );
        return false;
      } else if (completeMissionStatus.status === 'available') {
        GamifyToast.error(
          global.appSettings.labels.mission.proper + ' Abandoned'
        );
        // props.fetch(true);
        return true;
      } else if (completeMissionStatus.status === 'success') {
        GamifyToast.success('Note added successfully');
        return true;
      } else if (completeMissionStatus.status.startsWith('WARNING:')) {
        console.error(completeMissionStatus.status);
        GamifyToast.error(completeMissionStatus.status);
        return false;
      } else {
        console.error(
          'error in completeMission(): ' + completeMissionStatus.status
        );
        GamifyToast.error(
          completeMissionStatus.status + '.   Gamify support has been notified.'
        );
        return false;
      }
    } catch (err) {
      console.error('!!error in completeMission(): ', JSON.stringify(err));
      GamifyToast.error(
        "We couldn't add your notes",
        'Please check your internet connection and try again'
      );
    }
  }

  async function doSubmit(values, actions) {
    // console.log('doSubmit: ', values, actions);
    // if ( values.dispositionID == "" ) {
    //   GamifyToast.error ("Please select a disposition before saving");
    //   actions.setSubmitting(false) ;
    //   return (false);
    // }

    if (values.notes !== '') {
      // Add a note
      const ret = await createFeedItem(values);
      if (ret) {
        // NOTE: Toasts are handled in createFeedItem
        actions.resetForm(values);

        // Refetch deals doesn't need args for deals list, kanban needs the stage id and to put false for showLoading
        if (!isOnKanban) {
          handleRefetchDeals();
        } else {
          // NOTE: null sortOrder results in using the current selected sortOrder of that dealStage
          handleRefetchDeals([deal.currentStageID], null, false);
        }

        onTriggerSelectedDealRefetch();
      }
      actions.setSubmitting(false);
    } else {
      try {
        //
        // Edit a note
        //
        await API.graphql(
          graphqlOperation(mutations.updateDealComments, {
            input: {
              id: editNoteId,
              comment: values.notesMap[editNoteId],
              updatedAt: new Date(),
            },
          })
        );

        GamifyToast.success('Note updated successfully');
        setEditNoteId(null);
        onTriggerSelectedDealRefetch();
      } catch (err) {
        console.error(err);
        GamifyToast.error(
          'There was an error updating the note, please try again'
        );
      }
    }
  }

  return (
    <>
      <ImageCarousel
        showImageCarousel={showImageCarousel}
        setShowImageCarousel={setShowImageCarousel}
        imagesArr={imagesMainDisplayAttrs}
        imageIndex={imageIndex}
        setImageIndex={setImageIndex}
        zIndex={100002}
      ></ImageCarousel>

      <DynamicConfirmModal
        showDynamicConfirmModal={showConfirmDeleteImage}
        setShowDynamicConfirmModal={setShowConfirmDeleteImage}
        zIndex={100002}
        title={`Are you sure you want to remove this image?`}
        subtitle={`This action cannot be undone.`}
        leftAlignText={true}
        confirmButtonColor={'#E35050'}
        isSaving={isSaving}
        onCancel={() => {
          setShowConfirmDeleteImage(false);
        }}
        onConfirm={() => {
          handleConfirmDeleteImage(imageIndex);
        }}
      ></DynamicConfirmModal>

      <Box>
        {/* <Box>
        <Typography className={'selected-deal-about-title-text'}>
          About:
        </Typography>
        <Typography className={'selected-deal-about-id-text'}>
          #{metaData.id}
        </Typography>
      </Box> */}
        {/* TO DO: pass the whole deal to props as dealItem so can better match mobile */}
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, actions) => doSubmit(values, actions)}
        >
          {(formikProps) => (
            <>
              <Box className="selected-deal-section">
                <Box className={'selected-deal-name-stage-section'}>
                  <Typography className={'selected-deal-full-name-text'}>
                    {fullName}
                  </Typography>
                  <Box className={'selected-deal-name-stage-section-right'}>
                    <Box
                      style={{
                        width: 'fit-content',
                        minWidth: 'fit-content',
                        backgroundColor: '#FAC8B2',
                        borderRadius: 1000,
                      }}
                    >
                      <Typography className={'selected-deal-stage-text'}>
                        {dealStage}
                      </Typography>
                    </Box>
                    {showMenu && (
                      <>
                        <Button
                          disableRipple={true}
                          className={'ellipsis-button'}
                          // onClick={handleSelectedDealOptionsClick}
                          onClick={(e) => handleMenuOpen(e)}
                        >
                          <EllipsisIcon></EllipsisIcon>
                        </Button>
                        <DealOptionsMenu
                          props={{
                            dealId,
                            // setDealOptionsOpen: setSelectedDealOptionsOpen,
                            anchorEl,
                            setAnchorEl,
                            // setShowEditDealFormModal,
                            handleOpenOwnerDealStageModal,
                            stageId: deal.currentStageID,
                            handleDeleteDeal,
                            userId,
                            setter: deal.setter,
                            closer: deal.closer,
                          }}
                        ></DealOptionsMenu>
                      </>
                    )}
                    {/* {selectedDealOptionsOpen && (
              <DealOptionsMenu
                props={{
                  setDealOptionsOpen: setSelectedDealOptionsOpen,
                  getData,
                  onTriggerSelectedDealRefetch,
                  userId,
                  searchStage,
                  dealFilter,
                  categoryId,
                  searchString,
                  fromDealPage,
                  setIsLoaded,
                  metaData,
                  currentOwnerID,
                }}
              ></DealOptionsMenu>
            )} */}
                  </Box>
                </Box>
                <Typography className={'selected-deal-section-header-text'}>
                  ABOUT
                </Typography>
                <List>
                  {mainDisplayAttrs.length > 0 &&
                    mainDisplayAttrs.map((attr, i) => (
                      <ListItem
                        key={i}
                        sx={{
                          padding: 0,
                        }}
                      >
                        {(typeof attr.val === 'string' ||
                          typeof attr.val === 'number') &&
                          attr.val !== '' && (
                            <Box
                              className={'selected-deal-attribute-container'}
                            >
                              <Typography
                                className={'selected-deal-attribute-label-text'}
                              >
                                {attr.label}
                                {attr.label !== '' ? ':' : ''}
                              </Typography>
                              <Typography
                                className={'selected-deal-attribute-val-text'}
                              >
                                {attr.val}
                              </Typography>
                            </Box>
                          )}
                      </ListItem>
                    ))}
                  <Box className={'selected-deal-attribute-container'}>
                    <Typography
                      className={'selected-deal-attribute-label-text'}
                    >
                      Setter
                    </Typography>
                    <Box className={'selected-deal-owner-right'}>
                      {deal.setter?.profilePicture ? (
                        <Avatar
                          className={'selected-deal-owner-profile-picture'}
                          src={deal.setter?.profilePicture}
                        />
                      ) : (
                        <Avatar
                          className={'selected-deal-owner-profile-picture'}
                        >
                          <Typography style={{ fontSize: 12, fontWeight: 600 }}>
                            {deal.setter?.user?.initials}
                          </Typography>
                        </Avatar>
                      )}
                      <Typography className={'selected-deal-owner-text'}>
                        {deal.setter?.user?.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={'selected-deal-attribute-container'}>
                    <Typography
                      className={'selected-deal-attribute-label-text'}
                    >
                      Closer
                    </Typography>
                    <Box className={'selected-deal-owner-right'}>
                      {deal.closer ? (
                        deal?.closer?.profilePicture ? (
                          <Avatar
                            className={'selected-deal-owner-profile-picture'}
                            src={deal?.closer?.profilePicture}
                          />
                        ) : (
                          <Avatar
                            className={'selected-deal-owner-profile-picture'}
                          >
                            <Typography
                              style={{ fontSize: 12, fontWeight: 600 }}
                            >
                              {deal?.closer?.user?.initials}
                            </Typography>
                          </Avatar>
                        )
                      ) : (
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#bdbdbd',
                            width: 24,
                            height: 24,
                            borderRadius: 1000,
                            marginRight: 8,
                          }}
                        >
                          <GamifyIcon
                            icon={'user'}
                            width={'14'}
                            height={'16'}
                          ></GamifyIcon>
                        </Box>
                      )}
                      <Typography className={'selected-deal-owner-text'}>
                        {deal.closer ? deal?.closer?.user?.name : 'Unassigned'}
                      </Typography>
                    </Box>
                  </Box>
                  {/* NOTE: The following was replaced to display both setter and closer of the deal instead of most recent owner */}
                  {/* <Box className={'selected-deal-attribute-container'}>
                    <Typography
                      className={'selected-deal-attribute-label-text'}
                    >
                      Owner
                    </Typography>
                    <Box className={'selected-deal-owner-right'}>
                      {ownerProfilePicture ? (
                        <Avatar
                          className={'selected-deal-owner-profile-picture'}
                          src={ownerProfilePicture}
                        />
                      ) : (
                        <Avatar
                          className={'selected-deal-owner-profile-picture'}
                        >
                          {initials}
                        </Avatar>
                      )}
                      <Typography className={'selected-deal-owner-text'}>
                        {assigneeName}
                      </Typography>
                    </Box>
                  </Box> */}
                </List>
                {powerMainDisplayAttrs && (
                  <>
                    <Typography className={'selected-deal-section-header-text'}>
                      {powerMainDisplayAttrs.label.toUpperCase()}
                    </Typography>
                    <List>
                      <ListItem
                        key={1}
                        sx={{
                          padding: 0,
                        }}
                      >
                        <Box className={'selected-deal-attribute-container'}>
                          <Typography
                            className={'selected-deal-attribute-label-text'}
                          >
                            Utility Company
                          </Typography>
                          <Typography
                            className={'selected-deal-attribute-val-text'}
                          >
                            {powerMainDisplayAttrs.val.name}
                          </Typography>
                        </Box>
                      </ListItem>
                      <ListItem
                        key={0}
                        sx={{
                          padding: 0,
                        }}
                      >
                        <Box className={'selected-deal-attribute-container'}>
                          <Typography
                            className={'selected-deal-attribute-label-text'}
                          >
                            {powerMainDisplayAttrs.val.utilityInfo}
                          </Typography>
                          {/* {console.log(
                            'powerMainDisplayAttrs.val.info: ',
                            powerMainDisplayAttrs.val.info
                          )} */}
                          {powerMainDisplayAttrs.val.utilityInfo ===
                          'Monthly Usage' ? (
                            <Box>
                              <Typography
                                className={'selected-deal-attribute-val-text'}
                              >
                                {`Jan: ${powerMainDisplayAttrs.val.info.jan} KWs, Feb: ${powerMainDisplayAttrs.val.info.feb} KWs, Mar: ${powerMainDisplayAttrs.val.info.mar} KWs`}
                              </Typography>
                              <Typography
                                className={'selected-deal-attribute-val-text'}
                              >
                                {`Apr: ${powerMainDisplayAttrs.val.info.apr} KWs, May: ${powerMainDisplayAttrs.val.info.may} KWs, Jun: ${powerMainDisplayAttrs.val.info.jun} KWs`}
                              </Typography>

                              <Typography
                                className={'selected-deal-attribute-val-text'}
                              >
                                {`Jul: ${powerMainDisplayAttrs.val.info.jul} KWs, Aug: ${powerMainDisplayAttrs.val.info.aug} KWs, Sep: ${powerMainDisplayAttrs.val.info.sep} KWs`}
                              </Typography>
                              <Typography
                                className={'selected-deal-attribute-val-text'}
                              >
                                {`Oct: ${powerMainDisplayAttrs.val.info.oct} KWs, Nov: ${powerMainDisplayAttrs.val.info.nov} KWs, Dec: ${powerMainDisplayAttrs.val.info.dec} KWs`}
                              </Typography>
                            </Box>
                          ) : (
                            <Typography
                              className={'selected-deal-attribute-val-text'}
                            >
                              {powerMainDisplayAttrs.val.utilityInfo ===
                              'Annual Usage'
                                ? `${powerMainDisplayAttrs.val.info} KWs`
                                : powerMainDisplayAttrs.val.utilityInfo ===
                                  'Average Monthly Cost'
                                ? `${powerMainDisplayAttrs.val.info} KWs/month`
                                : `[MONTHLY USAGE DISPLAY]`}
                            </Typography>
                          )}
                        </Box>
                      </ListItem>
                    </List>
                  </>
                )}
                {kwsMainDisplayAttrs.length > 0 && (
                  <>
                    <Typography className={'selected-deal-section-header-text'}>
                      DEAL
                    </Typography>
                    {kwsMainDisplayAttrs.map((attr, i) => (
                      <ListItem
                        key={i}
                        sx={{
                          padding: 0,
                        }}
                      >
                        <Box className={'selected-deal-attribute-container'}>
                          <Typography
                            className={'selected-deal-attribute-label-text'}
                          >
                            {attr.label}
                          </Typography>
                          <Typography
                            className={'selected-deal-attribute-val-text'}
                          >
                            {attr.val}
                          </Typography>
                        </Box>
                      </ListItem>
                    ))}
                  </>
                )}
                {/* NOTE: Only admin, setter, or closer can edit anything involving a deal */}
                {(global.me.isAdmin ||
                  global.me.id === deal.setter?.user?.id ||
                  global.me.id === deal.closer?.user?.id) && (
                  <>
                    {' '}
                    <Typography className={'selected-deal-section-header-text'}>
                      ADD NOTES
                    </Typography>
                    <TextareaAutosize
                      className={'free-notes-text-area'}
                      style={{
                        backgroundColor: '#F0F0F3',
                        border: 'none',
                        borderRadius: 8,
                        width: '100%',
                        resize: 'none',
                        padding: 12,
                      }}
                      placeholder={'Click to add'}
                      value={formikProps.values.notes}
                      onChange={(e) => {
                        formikProps.setFieldValue('notes', e.target.value);
                      }}
                      onFocus={() => {
                        setAddNoteFocused(true);
                        if (editNoteId) {
                          formikProps.setFieldValue(
                            `notesMap[${editNoteId}]`,
                            formikProps.initialValues.notesMap[editNoteId]
                          );
                          setEditNoteId(null);
                        }
                      }}
                      onBlur={() => {
                        setAddNoteFocused(false);
                      }}
                    ></TextareaAutosize>
                    {(addNoteFocused ||
                      formikProps.values.notes.length > 0) && (
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '100%',
                          marginTop: '16px',
                        }}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 163,
                            height: 52,
                            backgroundColor:
                              formikProps.values.notes.length > 0
                                ? '#FF6A00'
                                : '#868686',
                            padding: '24px 16px 24px 16px',
                            borderRadius: 8,
                          }}
                          sx={{
                            cursor:
                              formikProps.values.notes.length === 0
                                ? 'default'
                                : 'pointer',
                          }}
                          onClick={() => {
                            // Do nothing if no note inputted
                            if (formikProps.values.notes.length === 0) return;
                            formikProps.handleSubmit();
                          }}
                        >
                          {formikProps.isSubmitting ? (
                            <CircularProgress
                              style={{ color: 'white', width: 24, height: 24 }}
                            ></CircularProgress>
                          ) : (
                            <Typography
                              style={{
                                color: 'white',
                                fontSize: '18px',
                                fontWeight: 700,
                              }}
                            >
                              Leave Note
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Box>
              <Box className={'selected-deal-section-title-container'}>
                <Typography className={'selected-deal-section-title-text'}>
                  Appointments
                </Typography>
                {/* NOTE: Until calendar/scheduling is built on web comment this out */}
                {/* NOTE: Only admin, setter, or closer can edit anything involving a deal */}
                {/* {global.me.isAdmin ||
                global.me.id === deal.setter?.user?.id ||
                global.me.id === deal.closer?.user?.id ? (
                  <Button
                    className={'selected-deal-add-button'}
                    onClick={() => {
                      handleAddAppointment();
                      if (editNoteId) {
                        formikProps.setFieldValue(
                          `notesMap[${editNoteId}]`,
                          formikProps.initialValues.notesMap[editNoteId]
                        );
                        setEditNoteId(null);
                      }
                    }}
                    disableRipple={true}
                  >
                    <AddIcon></AddIcon>
                  </Button>
                ) : (
                  <></>
                )} */}
              </Box>
              {appointments.length > 0 ? (
                appointments.map((appointment, i) => (
                  <Box key={i} className={'selected-deal-appointment-section'}>
                    <Box className={'selected-deal-appointment-top-section'}>
                      <Box
                        className={
                          'selected-deal-appointment-top-left-container'
                        }
                      >
                        <CalendarIcon></CalendarIcon>
                        <Typography
                          className={'selected-deal-appointment-date-text'}
                        >
                          {formatScheduleDate(
                            appointment.appointmentDate,
                            appointment.appointmentStartTime
                          )}
                        </Typography>
                      </Box>
                      <Box
                        className={
                          'selected-deal-appointment-top-right-container'
                        }
                      >
                        {/* NOTE: Commented out until scheduling/calendar built for web... */}
                        {/* <Button
                          disableRipple={true}
                          className={'ellipsis-button-no-background'}
                          onClick={() => {
                            handleAppointmentOptionsClick();
                            if (editNoteId) {
                              formikProps.setFieldValue(
                                `notesMap[${editNoteId}]`,
                                formikProps.initialValues.notesMap[editNoteId]
                              );
                              setEditNoteId(null);
                            }
                          }}
                        >
                          <EllipsisIcon color={'#868686'}></EllipsisIcon>
                        </Button> */}
                      </Box>
                    </Box>
                    <Box className={'selected-deal-appointment-bottom-section'}>
                      <Typography
                        className={'selected-deal-appointment-details-text'}
                      >
                        {appointment.deal.name}
                      </Typography>
                      <Typography
                        className={'selected-deal-appointment-details-text'}
                      >
                        Assigned to {appointment.user?.name}
                      </Typography>
                      <Typography
                        className={'selected-deal-appointment-details-text'}
                      >
                        Setter: {appointment.setter?.name}
                      </Typography>
                      <Typography
                        className={'selected-deal-appointment-details-text'}
                      >
                        Status: {appointment.status}
                      </Typography>
                    </Box>
                  </Box>
                ))
              ) : (
                <Box className={'selected-deal-no-appointments-section'}>
                  <Typography className={'no-appointments-text'}>
                    No appointments yet.
                  </Typography>
                </Box>
              )}

              <Box className={'selected-deal-section-title-container'}>
                <Typography className={'selected-deal-section-title-text'}>
                  Photos
                </Typography>
                {showEdit ? (
                  isImageUploading ? (
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 24,
                        height: 24,
                        backgroundColor: '#FF6A00',
                        borderRadius: 1000,
                      }}
                    >
                      <CircularProgress
                        style={{ color: 'white', width: 12, height: 12 }}
                      ></CircularProgress>
                    </Box>
                  ) : (
                    <Dropzone
                      onDrop={(droppedFile) => {
                        onDropDealImage(droppedFile);

                        if (editNoteId) {
                          formikProps.setFieldValue(
                            `notesMap[${editNoteId}]`,
                            formikProps.initialValues.notesMap[editNoteId]
                          );
                          setEditNoteId(null);
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Box
                          className={'selected-deal-add-button'}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'transparent',
                            width: 24,
                            height: 24,
                            borderRadius: 1000,
                          }}
                          sx={{ cursor: 'pointer' }}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <AddIcon></AddIcon>
                        </Box>
                      )}
                    </Dropzone>
                  )
                ) : (
                  <></>
                )}
              </Box>
              {isImageUploading ? (
                <Box className={'selected-deal-no-images-loading-section'}>
                  <CircularProgress
                    style={{ color: 'black', width: 48, height: 48 }}
                  ></CircularProgress>
                </Box>
              ) : imagesMainDisplayAttrs.length > 0 ? (
                <Box className={'selected-deal-images-section'}>
                  <ImageList
                    cols={
                      imagesMainDisplayAttrs.length > 8
                        ? imagesMainDisplayAttrs.length
                        : 8
                    }
                    gap={8}
                    sx={{
                      width: '100%',
                      marginBottom: 0,
                      marginTop: 0,
                    }}
                  >
                    {imagesMainDisplayAttrs.map((image, i) => (
                      <ImageListItem
                        key={i}
                        sx={{
                          width: 'fit-content',
                        }}
                      >
                        <Button
                          className={'image-modal-button'}
                          onClick={() => {
                            handleImageClick(i);

                            if (editNoteId) {
                              formikProps.setFieldValue(
                                `notesMap[${editNoteId}]`,
                                formikProps.initialValues.notesMap[editNoteId]
                              );
                              setEditNoteId(null);
                            }
                          }}
                          disableRipple={true}
                        >
                          <DisplayMedia
                            imageName={image}
                            imageType={'img'}
                            height={'80px'}
                            width={'80px'}
                            objectFit={'cover'}
                            borderRadius={'8px'}
                          ></DisplayMedia>
                        </Button>
                        {showEdit && (
                          <Box
                            // className={'delete-image-modal-button'}
                            style={{
                              backgroundColor: '#060606',
                              display: 'flex',
                              position: 'absolute',
                              zIndex: 1,
                              left: '60px',
                              bottom: '60px',
                              boxSizing: 'border-box',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '16px',
                              width: '16px',
                              minWidth: '16px',
                              padding: 0,
                              borderRadius: '2000px',
                            }}
                            sx={{
                              '&:hover': {
                                border: 'white 1px solid',
                              },
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              handleDeleteImageClick(i);

                              if (editNoteId) {
                                formikProps.setFieldValue(
                                  `notesMap[${editNoteId}]`,
                                  formikProps.initialValues.notesMap[editNoteId]
                                );
                                setEditNoteId(null);
                              }
                            }}
                            // disableRipple={true}
                          >
                            <XIcon></XIcon>
                          </Box>
                        )}
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Box>
              ) : (
                <Box className={'selected-deal-no-images-section'}>
                  <Typography style={{ marginTop: 17, marginBottom: 17 }}>
                    No photos yet.
                  </Typography>
                </Box>
              )}
              <Box className={'selected-deal-section-title-container'}>
                <Typography className={'selected-deal-section-title-text'}>
                  Notes
                </Typography>
              </Box>
              {/* NOTE: any reference to comments are to notes, this code was made before terminology was solidified. */}
              <Box>
                {comments.length > 0 ? (
                  comments.map((comment, i) => {
                    return (
                      <Box key={i} className={'selected-deal-notes-section'}>
                        <Box className={'selected-deal-comment-top-section'}>
                          <Box
                            className={'selected-deal-comment-owner-container'}
                          >
                            {comment.user.profilePicture ? (
                              <Avatar
                                className={
                                  'selected-deal-comment-owner-profile-picture'
                                }
                                src={comment.user.profilePicture}
                              />
                            ) : (
                              <Avatar
                                className={
                                  'selected-deal-comment-owner-profile-picture'
                                }
                              >
                                {comment.user.initials}
                              </Avatar>
                            )}
                            <Typography
                              className={'selected-deal-comment-owner-text'}
                            >
                              {comment.user.name}
                            </Typography>
                          </Box>
                          <Box
                            className={
                              'selected-deal-comment-created-at-container'
                            }
                          >
                            <Typography
                              // NOTE: created at no longer used here as notes can be edited/ updated
                              className={
                                'selected-deal-comment-created-at-text'
                              }
                            >
                              {formatTime(comment.updatedAt)}
                            </Typography>
                            {/* {console.log('note formikProps: ', formikProps)} */}
                            {showEdit && (
                              <>
                                <Button
                                  disableRipple={true}
                                  className={'ellipsis-button-no-background'}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // console.log(
                                    //   'notesCheck comment.id onClick: ',
                                    //   comment.id
                                    // );
                                    handleNoteOptionsOpen(
                                      e,
                                      comment.id,
                                      formikProps
                                    );
                                  }}
                                >
                                  <EllipsisIcon
                                    color={'#868686'}
                                  ></EllipsisIcon>
                                </Button>
                                <DealNoteOptionsMenu
                                  props={{
                                    anchorEl: notesAnchorElMap[comment.id],
                                    setAnchorEl: (el) =>
                                      setNotesAnchorElMap((prev) => ({
                                        ...prev,
                                        [comment.id]: el,
                                      })),
                                    setEditNoteId,
                                    onTriggerSelectedDealRefetch,
                                    dealNoteId: comment.id,
                                  }}
                                ></DealNoteOptionsMenu>
                              </>
                            )}
                          </Box>
                        </Box>
                        {editNoteId === comment.id ? (
                          <>
                            <TextareaAutosize
                              className={'free-notes-text-area'}
                              style={{
                                backgroundColor: '#F0F0F3',
                                border: 'none',
                                borderRadius: 8,
                                width: '100%',
                                resize: 'none',
                                padding: 12,
                              }}
                              placeholder={
                                formikProps.initialValues.notesMap[comment.id]
                              }
                              value={formikProps.values.notesMap[comment.id]}
                              onChange={(e) => {
                                e.stopPropagation();
                                formikProps.setFieldValue(
                                  `notesMap.${comment.id}`,
                                  e.target.value
                                );
                              }}
                              // NOTE: Left out to prevent over complicating the editing functionality
                              // onFocus={() => {
                              //   // console.log('notesCheck onFocus');
                              //   setNotesFocusedMap((prev) => ({
                              //     ...prev,
                              //     [comment.id]: true,
                              //   }));
                              // }}
                              // onBlur={() => {
                              //   // console.log('notesCheck onBlur');
                              //   setNotesFocusedMap((prev) => ({
                              //     ...prev,
                              //     [comment.id]: false,
                              //   }));
                              // }}
                            ></TextareaAutosize>

                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                                marginTop: '16px',
                              }}
                            >
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: 163,
                                  height: 52,
                                  backgroundColor:
                                    formikProps.initialValues.notesMap[
                                      comment.id
                                    ] !==
                                    formikProps.values.notesMap[comment.id]
                                      ? '#FF6A00'
                                      : '#868686',
                                  padding: '24px 16px 24px 16px',
                                  marginRight: 16,
                                  borderRadius: 8,
                                }}
                                sx={{
                                  cursor:
                                    formikProps.initialValues.notesMap[
                                      comment.id
                                    ] !==
                                    formikProps.values.notesMap[comment.id]
                                      ? 'pointer'
                                      : 'default',
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // console.log('notesCheck Clicked Update Note');
                                  // Do nothing if no note change
                                  if (
                                    formikProps.initialValues.notesMap[
                                      comment.id
                                    ] ===
                                    formikProps.values.notesMap[comment.id]
                                  )
                                    return;

                                  formikProps.handleSubmit();
                                }}
                              >
                                {formikProps.isSubmitting ? (
                                  <CircularProgress
                                    style={{
                                      color: 'white',
                                      width: 24,
                                      height: 24,
                                    }}
                                  ></CircularProgress>
                                ) : (
                                  <Typography
                                    style={{
                                      color: 'white',
                                      fontSize: '18px',
                                      fontWeight: 700,
                                    }}
                                  >
                                    Update Note
                                  </Typography>
                                )}
                              </Box>
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: 163,
                                  height: 52,
                                  backgroundColor: 'white',
                                  padding: '24px 16px 24px 16px',
                                  borderRadius: 8,
                                  border: '1px solid #868686',
                                }}
                                sx={{
                                  cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // console.log(
                                  //   'notesCheck Clicked cancel on note id#: ',
                                  //   comment.id
                                  // );
                                  setEditNoteId(null);
                                  // Reset the new note to the original if canceling the edit.
                                  formikProps.resetForm();
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 700,
                                  }}
                                >
                                  Cancel
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <Box
                            className={
                              'selected-deal-comment-content-container'
                            }
                          >
                            <Typography
                              className={'selected-deal-comment-content-text'}
                            >
                              {comment.comment}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    );
                  })
                ) : (
                  <Box className={'selected-deal-notes-section'}>
                    <Typography className={'no-notes-text'}>
                      No notes yet.
                    </Typography>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Formik>
        <Box className={'selected-deal-meta-data-container'}>
          <Button
            className={'selected-deal-meta-data-button'}
            onClick={handleMetaDataClick}
            disableRipple={true}
          >
            <Typography className={'selected-deal-meta-data-button-text'}>
              {selectedDealMetaDataOpen
                ? 'Hide Deal Meta Data'
                : 'Show Deal Meta Data'}
            </Typography>
          </Button>
          {selectedDealMetaDataOpen && (
            <Box className={'selected-deal-meta-data-content-container'}>
              <Box className={'selected-deal-meta-data-attribute-container'}>
                <Typography
                  className={'selected-deal-meta-data-attribute-text'}
                >
                  Deal ID:
                </Typography>
                <Typography
                  className={'selected-deal-meta-data-attribute-text'}
                >
                  {metaData.id}
                </Typography>
              </Box>
              <Box className={'selected-deal-meta-data-attribute-container'}>
                <Typography
                  className={'selected-deal-meta-data-attribute-text'}
                >
                  Created At:
                </Typography>
                <Typography
                  className={'selected-deal-meta-data-attribute-text'}
                >
                  {metaData.createdAt}
                </Typography>
              </Box>
              <Typography className={'selected-deal-meta-data-label'}>
                Created By:
              </Typography>
              <Box className={'selected-deal-meta-data-attribute-container'}>
                <Typography className={'selected-deal-meta-data-sub-label'}>
                  Name:
                </Typography>
                <Typography
                  className={'selected-deal-meta-data-attribute-text'}
                >
                  {metaData.createdBy.name}
                </Typography>
              </Box>
              <Box className={'selected-deal-meta-data-attribute-container'}>
                <Typography className={'selected-deal-meta-data-sub-label'}>
                  Email:
                </Typography>
                <Typography
                  className={'selected-deal-meta-data-attribute-text'}
                >
                  {metaData.createdBy.email}
                </Typography>
              </Box>
              <Box className={'selected-deal-meta-data-attribute-container'}>
                <Typography className={'selected-deal-meta-data-sub-label'}>
                  User ID:
                </Typography>
                <Typography
                  className={'selected-deal-meta-data-attribute-text'}
                >
                  {metaData.createdBy.id}
                </Typography>
              </Box>
              <Box className={'selected-deal-meta-data-attribute-container'}>
                <Typography className={'selected-deal-meta-data-sub-label'}>
                  User Ext ID:
                </Typography>
                <Typography
                  className={'selected-deal-meta-data-attribute-text'}
                >
                  {metaData.createdBy.extID}
                </Typography>
              </Box>
              <Box className={'selected-deal-meta-data-attribute-container'}>
                <Typography className={'selected-deal-meta-data-label'}>
                  Updated At:
                </Typography>
                <Typography
                  className={'selected-deal-meta-data-attribute-text'}
                >
                  {metaData.updatedAt}
                </Typography>
              </Box>
              {metaData.updatedBy && (
                <>
                  <Typography className={'selected-deal-meta-data-label'}>
                    Updated By:
                  </Typography>
                  <Box
                    className={'selected-deal-meta-data-attribute-container'}
                  >
                    <Typography className={'selected-deal-meta-data-sub-label'}>
                      Name:
                    </Typography>
                    <Typography
                      className={'selected-deal-meta-data-attribute-text'}
                    >
                      {metaData.updatedBy.name}
                    </Typography>
                  </Box>
                  <Box
                    className={'selected-deal-meta-data-attribute-container'}
                  >
                    <Typography className={'selected-deal-meta-data-sub-label'}>
                      Email:
                    </Typography>
                    <Typography
                      className={'selected-deal-meta-data-attribute-text'}
                    >
                      {metaData.updatedBy.email}
                    </Typography>
                  </Box>
                  <Box
                    className={'selected-deal-meta-data-attribute-container'}
                  >
                    <Typography className={'selected-deal-meta-data-sub-label'}>
                      User ID:
                    </Typography>
                    <Typography
                      className={'selected-deal-meta-data-attribute-text'}
                    >
                      {metaData.updatedBy.id}
                    </Typography>
                  </Box>
                  <Box
                    className={'selected-deal-meta-data-attribute-container'}
                  >
                    <Typography className={'selected-deal-meta-data-sub-label'}>
                      User Ext ID:
                    </Typography>
                    <Typography
                      className={'selected-deal-meta-data-attribute-text'}
                    >
                      {metaData.updatedBy.extID}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
        <Box className="selected-deal-bottom-section"></Box>
      </Box>
    </>
  );
}
