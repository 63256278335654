import React, { useEffect, useState, useReducer } from 'react';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import * as queries from '../../../../graphql/queries';
import { API } from 'aws-amplify';
import fetchDealStages from '../fetches/fetchDealStages';
import fetchDealTypes from '../fetches/fetchDealTypes';
import fetchDealTypeConfigs from '../fetches/fetchDealTypeConfigs';
import fetchPeople from '../../teams/fetchPeople';
import fetchCirclesOrPeople from '../fetches/fetchCirclesOrPeople';
import getUserAvatar from '../../../common/utils/getUserAvatar';
import { useNavigate, useParams } from 'react-router-dom';
import DealItem from '../selectedDeal/DealItem';
// import DealFormModal from './DealFormModal';
import OwnerDealStageModal from '../OwnerDealStageModal';
import { GamifyToast } from '../../../common/CustomToasts';
import '../Index.css';
// import StyledTextField from '../../components/StyledTextField';
// import SelectStage from '../../components/Select/SelectStage';
// import SelectCircleOrPerson from '../../components/Select/SelectCircleOrPerson';
import {
  SearchInput,
  // StyledSelect,
  // StyledMenuItem,
} from '../../users/StyledInputs';
import { StyledSelect, StyledMenuItem } from '../../../common/StyledComponents';
import { EllipsisIcon, GamifyIcon } from '../../../components/GamifyIcon';
import DealCard from '../DealCard';
import DealCardSkeleton from '../DealCardSkeleton';
import DealsListSkeleton from './DealsListSkeleton';
import {
  selectDealTypeId,
  selectDealTypes,
  selectDealTypesConfigMap,
  selectDealTypesStagesMap,
} from '../../../store/reducers/deals';
import {
  Deal,
  DealType,
  DealTypeConfig,
  DealStage,
  DealsListDeals,
} from '../kanban/DealsKanban.types';
// import BetaIcon from '../../common/BetaIcon';
import { usePermissions } from '../../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../../context/PermissionsContext/permissionTypes';
import { useCurrentUserStore } from '../../../store/zustand/useCurrentUserStore';

export default function DealsList(props: any) {
  console.log('props: ', props);
  const params = useParams();
  // console.log('params: ', params);

  const { checkPermission } = usePermissions();
  const { isUserOrTeamMember } = useCurrentUserStore();

  const viewPermission = checkPermission(PermissionKeys.Sales_ViewDeal);
  const createPermission = checkPermission(PermissionKeys.Sales_CreateDeal);

  const reduxDealTypeId = useSelector(selectDealTypeId);
  const reduxDealTypes = useSelector(selectDealTypes);
  const reduxDealTypesConfigMap = useSelector(selectDealTypesConfigMap);
  const reduxDealTypesStagesMap = useSelector(selectDealTypesStagesMap);

  const [userId, setUserId] = useState<string>(
    // @ts-ignore
    global.me.isAdmin
      ? 'all'
      : // @ts-ignore
        global.me.id
  );
  const [searchStage, setSearchStage] = useState<string>('');
  const [categoryId, setCategoryId] = useState<string>(reduxDealTypeId || '');

  const [dealTypes, setDealTypes] = useState<{ [id: string]: DealType }>(
    Object.keys(reduxDealTypes).length > 0 ? reduxDealTypes : {}
  );

  const [dealTypesConfigMap, setDealTypesConfigMap] = useState<{
    [id: string]: DealTypeConfig;
  }>(
    Object.values(reduxDealTypesConfigMap).length > 0
      ? reduxDealTypesConfigMap
      : {}
  );
  const [selectedDealId, setSelectedDealId] = useState<string | null>(null);
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);
  // console.log('SELECTED DEAL list: ', selectedDeal);
  // Add 'All' deal stage to dealStages for the Stage filter
  const allDealStage: DealStage = {
    label: 'All',
    id: '',
    value: '',
    categoryID: '',
    display: '',
    name: 'All',
    requireCloser: false,
    userSelectable: false,
    sequence: 0,
  };
  const [dealStages, setDealStages] = useState<DealStage[] | []>(
    Object.keys(reduxDealTypesStagesMap).length > 0 && reduxDealTypeId
      ? [allDealStage, ...reduxDealTypesStagesMap[reduxDealTypeId]]
      : []
  );
  const [normalizedDealStages, setNormalizedDealStages] = useState<any>(
    Object.keys(reduxDealTypesStagesMap).length > 0 && reduxDealTypeId
      ? reduxDealTypesStagesMap[reduxDealTypeId].reduce((acc, dealStage) => {
          // @ts-ignore
          acc[dealStage.id] = dealStage;
          return acc;
        }, {})
      : {}
  );
  const [dealPeople, setDealPeople] = useState<any[]>([]);

  const [fromDealPage, setFromDealPage] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [maxPage, setMaxPage] = useState<number>(0);
  const [searchString, setSearchString] = useState<string>('');
  const [addDealOpen, setAddDealOpen] = useState<boolean>(false);
  const [dealFilter, setDealFilter] = useState<any>({
    dealType: 'all', // TO DO: Is all even possible? Default to first valid dealType in dealTypes if not.
    dealSort: 'DESC',
    dealStage: 'all',
    // @ts-ignore
    dealOwner: viewPermission === 'everyone' ? 'all' : global.me.id,
  });
  const [sortOpen, setSortOpen] = useState<boolean>(false);
  const [newestSort, setNewestSort] = useState<boolean>(true);
  const [oldestSort, setOldestSort] = useState<boolean>(false);
  // const [aZSort, setAZSort] = useState(false);
  // const [zASort, setZASort] = useState(false);
  // const [ownerAZSort, setOwnerAZSort] = useState(false);
  // const [ownerZASort, setOwnerZASort] = useState(false);
  // const [selectedDealOptionsOpen, setSelectedDealOptionsOpen] = useState(false);
  const [selectedDealMetaDataOpen, setSelectedDealMetaDataOpen] =
    useState<boolean>(false);
  const [showOwnerDealStageModal, setShowOwnerDealStageModal] =
    useState<boolean>(false);
  // const [showNewDealFormModal, setShowNewDealFormModal] = useState(false);
  // const [showEditDealFormModal, setShowEditDealFormModal] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const initialState: DealsListDeals = {
    deals: [],
    currentOffset: 0,
    canLoadMore: false,
    isLoading: true,
  };

  const UPDATE_DEALS = 'UPDATE_DEALS';
  const RESET_DEALS = 'RESET_DEALS';

  function dealsReducer(state: DealsListDeals, action: any) {
    switch (action.type) {
      case UPDATE_DEALS: {
        const { updates } = action.payload;
        return {
          ...state,
          ...updates,
        };
      }
      case RESET_DEALS: {
        return {
          ...initialState,
        };
      }
      default:
        return state;
    }
  }

  const [dealsListDeals, dispatch] = useReducer(dealsReducer, initialState);

  // console.log('dealsListDeals: ', dealsListDeals);

  const [triggerDealsRefetch, setTriggerDealsRefetch] = useState<number>(0);
  const onTriggerDealsRefetch = () => {
    setTriggerDealsRefetch((refetch) => refetch + 1);
  };

  const [triggerSelectedDealRefetch, setTriggerSelectedDealRefetch] =
    useState<number>(0);
  const onTriggerSelectedDealRefetch = () => {
    setTriggerSelectedDealRefetch((refetch) => refetch + 1);
  };

  const resetPaginator = () => {
    setFromDealPage(0);
    setMaxPage(0);
  };

  useEffect(() => {
    // @ts-ignore
    setSelectedDealId(params.dealId);
  }, [params?.dealId]);

  const handleAddDealClick = () => {
    setAddDealOpen(!addDealOpen);
    setSortOpen(false);
    // setSelectedDealOptionsOpen(false);
  };

  const handleNewDeal = (e: any) => {
    e.preventDefault();

    // NOTE: If a modal is used there's no need to navigate to /pipeline/new... modal still doesn't work so still navigate
    // setShowNewDealFormModal(true);
    navigate('/pipeline/new', {
      // TO DO: add in other props for DealFormPage, see CreateDealScreen component in lyferize MyDealScreen.tsx for reference
      state: {
        isOnKanban: false,
      },
    });
  };

  const handleClearSearch = () => {
    setSearchString('');
    resetPaginator();
  };

  // NOTE: Do NOT use for Autocomplete components as e is different, this function works with Select components
  const handleDealFilterChange = (e: any) => {
    e.preventDefault();
    setDealFilter((p: any) => ({ ...p, [e.target.name]: e.target.value }));
    // console.log('***deal filter: ', dealFilter);

    resetPaginator();
  };

  const handleSortClick = () => {
    setSortOpen(!sortOpen);
    setAddDealOpen(false);
    // setSelectedDealOptionsOpen(false);
  };

  const handleNewestFirstClick = () => {
    setNewestSort(true);
    setOldestSort(false);
    // setAZSort(false);
    // setZASort(false);
    // setOwnerAZSort(false);
    // setOwnerZASort(false);
    setSortOpen(false);

    setDealFilter((prev: any) => ({ ...prev, dealSort: 'DESC' }));

    resetPaginator();
  };

  const handleOldestFirstClick = () => {
    setOldestSort(true);
    setNewestSort(false);
    // setAZSort(false);
    // setZASort(false);
    // setOwnerAZSort(false);
    // setOwnerZASort(false);
    setSortOpen(false);

    setDealFilter((prev: any) => ({ ...prev, dealSort: 'ASC' }));

    resetPaginator();
  };

  // const handleAZClick = () => {
  //   setAZSort(true);
  //   setNewestSort(false);
  //   setOldestSort(false);
  //   setZASort(false);
  //   setOwnerAZSort(false);
  //   setOwnerZASort(false);
  //   setSortOpen(false);

  //   setDealFilter((prev) => ({ ...prev, dealSort: 'name-asc' }));

  //   resetPaginator();
  // };

  // const handleZAClick = () => {
  //   setZASort(true);
  //   setNewestSort(false);
  //   setOldestSort(false);
  //   setAZSort(false);
  //   setOwnerAZSort(false);
  //   setOwnerZASort(false);
  //   setSortOpen(false);

  //   setDealFilter((prev) => ({ ...prev, dealSort: 'name-desc' }));

  //   resetPaginator();
  // };

  // const handleOwnerAZClick = () => {
  //   setOwnerAZSort(true);
  //   setNewestSort(false);
  //   setOldestSort(false);
  //   setAZSort(false);
  //   setZASort(false);
  //   setOwnerZASort(false);
  //   setSortOpen(false);

  //   setDealFilter((prev) => ({ ...prev, dealSort: 'owner-asc' }));

  //   resetPaginator();
  // };

  // const handleOwnerZAClick = () => {
  //   setOwnerZASort(true);
  //   setNewestSort(false);
  //   setOldestSort(false);
  //   setAZSort(false);
  //   setZASort(false);
  //   setOwnerAZSort(false);
  //   setSortOpen(false);

  //   setDealFilter((prev) => ({ ...prev, dealSort: 'owner-desc' }));

  //   resetPaginator();
  // };

  const handleDealSelection = (e: any, dealId: string, deal: Deal) => {
    e.preventDefault();
    setSelectedDealMetaDataOpen(false);
    setSelectedDeal(deal);

    // setSelectedDealOptionsOpen(false);
    navigate(`/pipeline/list/${dealId}`);
  };

  const handleOpenOwnerDealStageModal = () => {
    setShowOwnerDealStageModal(true);
  };

  // const handleDealStageChange = (dealId: string, currentStageId: string) => {
  //   // Get the original deals list deals to return in case maintainDealOwners fails in OwnerDealStageModal
  //   const originalDealsListDeals: DealsListDeals = { ...dealsListDeals };

  //   // Optimistically update deal with dealId from originalStageId to currentStageId
  //   const updatedDeals: Deal[] = [...dealsListDeals.deals];

  //   const dealIndex = updatedDeals.findIndex(
  //     (deal: Deal) => deal.id === dealId
  //   );

  //   // findIndex returns -1 when index not found, return
  //   if (dealIndex !== -1) {
  //     // Splice the deal out of the deals copy, updated the stageId, and add it to the start of the current stage deals copy to dispatch to dealsByDealStage
  //     let [updatedDeal] = updatedDeals.splice(dealIndex, 1);

  //     updatedDeal = {
  //       ...updatedDeal,
  //       stageId: currentStageId,
  //     };

  //     updatedDeals.unshift(updatedDeal);
  //   } else {
  //     return [originalDealsListDeals, false];
  //   }

  //   const updatedDealsListDealsPayload = {
  //     updates: {
  //       deals: updatedDeals,
  //       currentOffset: updatedDeals.length,
  //       canLoadMore: dealsListDeals.canLoadMore,
  //       isLoading: false,
  //     },
  //   };

  //   dispatch({ type: UPDATE_DEALS, payload: updatedDealsListDealsPayload });

  //   // Need to figure out a way to refetch all deals, maybe have limit as a parameter to fetchDeals that defaults to 25/limit, refetch for full length

  //   return [originalDealsListDeals, true];
  // };

  // const handleDealStageChangeRevert = (
  //   originalDealsListDeals: DealsListDeals
  // ) => {
  //   // Dispatch the updates to revert dealsByDealStage to its original state before the failed stage/owner change
  //   const originalDealsPayload = {
  //     updates: {
  //       ...originalDealsListDeals,
  //     },
  //   };

  //   dispatch({ type: UPDATE_DEALS, payload: originalDealsPayload });
  // };

  const handleRefetchDeals = () => {
    dispatch({ type: 'RESET_DEALS' });
    resetPaginator();

    // Give a 500 ms delay to give db time to update
    setTimeout(() => {
      onTriggerDealsRefetch();
    }, 1000);
  };

  const handleDeleteDeal = (dealId: string) => {
    const deals = dealsListDeals.deals;

    // Filter out the deleted deal before dispatching the update to that stage
    const filteredDeals = deals.filter((deal: Deal) => deal.id !== dealId);

    const deletedDealPayload = {
      updates: {
        deals: filteredDeals,
        currentOffset:
          dealsListDeals.currentOffset > 0
            ? dealsListDeals.currentOffset - 1
            : 0,
        canLoadMore: dealsListDeals.canLoadMore,
        isLoading: false,
      },
    };

    dispatch({ type: UPDATE_DEALS, payload: deletedDealPayload });
  };

  const capitalizeFirstLetter = (word: string) => {
    const n = word.length;

    if (n < 1) return word;

    return `${word.slice(0, 1).toUpperCase()}${word.slice(1, n)}`;
  };

  const cancelIconAdornment =
    searchString.length > 0 ? (
      <Button
        className={'clear-search-button'}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        onClick={handleClearSearch}
        disableRipple={true}
      >
        <InputAdornment position="end">
          <GamifyIcon
            icon={'delete'}
            color={'white'}
            backgroundColor={'#D0D2D8'}
            height={24}
            width={24}
          ></GamifyIcon>
        </InputAdornment>
      </Button>
    ) : (
      <></>
    );

  // console.log('searchString: ', searchString);

  async function prepareFiltersWithoutRedux() {
    // NOTE: getDealTypes sets default deal type in dealFilter's type key and sets categoryID to the defaultCategoryID when more than one possible deal type
    const [tempDefaultCategoryID, tempDealTypes] = await fetchDealTypes();

    const tempDealTypesConfigMap: any = await fetchDealTypeConfigs(
      Object.values(tempDealTypes)
    );

    const tempDealStages = await fetchDealStages(
      tempDefaultCategoryID,
      true, // showAll
      false, // activeOptiom
      false, // allOption
      null, // minSequence
      true // ignoreNoDealStages
    );

    const allOption = viewPermission === 'everyone';
    const useChildCirclesList = true;

    if (viewPermission === 'everyone') {
      const tempPeople = await fetchPeople('', allOption);
      setDealPeople(tempPeople || []);
    } else {
      let pickUsers, pickCircles, useCirclesList;

      // @ts-ignore
      if (!global.appSettings.features.deal.disableUserFilter) {
        pickUsers = true;
      }
      // @ts-ignore
      if (!global.appSettings.features.deal.disableCircleFilter) {
        pickCircles = true;
        useCirclesList = true;
      }

      const tempCirclesOrPeople = await fetchCirclesOrPeople(
        '',
        allOption,
        pickUsers,
        pickCircles,
        useCirclesList,
        useChildCirclesList
      );

      let filteredDealPeople = tempCirclesOrPeople;

      // NOTE: This should be redone as it is O(m*n) efficient
      if (viewPermission === 'myTeam') {
        filteredDealPeople = tempCirclesOrPeople.filter((dealPerson) =>
          isUserOrTeamMember(dealPerson.id)
        );
      }

      setDealPeople(filteredDealPeople || []);
    }

    const tempNormalizedDealStages = tempDealStages.reduce(
      (acc: any, stage: any) => {
        acc[stage.id] = stage;
        return acc;
      },
      {}
    );

    // Set the dealType filter based on defaultCategoryID
    for (const dealType of Object.values(tempDealTypes)) {
      // @ts-ignore
      if (dealType.id === tempDefaultCategoryID) {
        setDealFilter((p: any) => ({
          ...p,
          // @ts-ignore
          dealType: dealType.title,
        }));
        break;
      }
    }

    setCategoryId(tempDefaultCategoryID);
    setDealTypes(tempDealTypes);
    setDealTypesConfigMap(tempDealTypesConfigMap);
    // @ts-ignore
    setDealStages(tempDealStages);
    setNormalizedDealStages(tempNormalizedDealStages);
  }

  async function prepareFiltersWithRedux() {
    const allOption = viewPermission === 'everyone';
    const useChildCirclesList = true;

    if (viewPermission === 'everyone') {
      const tempPeople = await fetchPeople('', allOption);
      setDealPeople(tempPeople || []);
    } else {
      let pickUsers, pickCircles, useCirclesList;

      // @ts-ignore
      if (!global.appSettings.features.deal.disableUserFilter) {
        pickUsers = true;
      }
      // @ts-ignore
      if (!global.appSettings.features.deal.disableCircleFilter) {
        pickCircles = true;
        useCirclesList = true;
      }

      const tempCirclesOrPeople = await fetchCirclesOrPeople(
        '',
        allOption,
        pickUsers,
        pickCircles,
        useCirclesList,
        useChildCirclesList
      );

      let filteredDealPeople = tempCirclesOrPeople;

      // NOTE: This should be redone as it is O(m*n) efficient
      if (viewPermission === 'myTeam') {
        filteredDealPeople = tempCirclesOrPeople.filter((dealPerson) =>
          isUserOrTeamMember(dealPerson.id)
        );
      }

      setDealPeople(filteredDealPeople || []);
    }
  }

  // showLoading defaults to true (filtering/searching), but should be set to false for changing deal stages (optimistic loading)
  async function fetchDeals(showLoading = true) {
    const displayFields =
      // @ts-ignore
      dealTypesConfigMap[categoryId].pickerDisplayFields;

    // NOTE: If the deals aren't loading already and showLoading is true, update it so it is
    if (!dealsListDeals?.isLoading && showLoading) {
      const dealsListDealsLoadingPayload = {
        updates: {
          isLoading: true,
        },
      };

      dispatch({
        type: UPDATE_DEALS,
        payload: dealsListDealsLoadingPayload,
      });
    }

    let deals = [];
    let canLoadMore;

    try {
      const res = await API.graphql({
        query: queries.listAllDeals,
        variables: {
          request: JSON.stringify({
            categoryID: categoryId,
            stageID: searchStage,
            assigneeID:
              dealFilter.dealOwner === 'all' ? '' : dealFilter.dealOwner,
            searchString,
            limit: 25, // TO DO: replace with limit state variable
            offset: dealsListDeals.currentOffset,
            displayFields,
            sortOrder: dealFilter.dealSort,
          }),
        },
      });
      // @ts-ignore
      const parsedData = JSON.parse(res.data.listAllDeals);
      deals = parsedData.deals || [];
      canLoadMore = parsedData.canLoadMore;
    } catch (err) {
      console.error(err);

      GamifyToast.error(`There was an error loading more deals.`);

      // Stop the loading animation, set deals to previous deals
      const dealsListDealsStopLoadingPayload = {
        updates: {
          deals: dealsListDeals.deals,
          currentOffset: 0,
          canLoadMore: false,
          isLoading: false,
        },
      };

      dispatch({
        type: UPDATE_DEALS,
        payload: dealsListDealsStopLoadingPayload,
      });
    }

    // Include all previous deals in updated dealsArr
    const dealsArr = [...dealsListDeals.deals];

    for (const deal of deals) {
      // Need to get setter/closer profile pictures from imageName (if no imageName, will be an empty string)
      const setterProfilePicture = await getUserAvatar(
        deal.setter.imageName,
        '',
        true
      );
      // @ts-ignore
      deal.setter.profilePicture = setterProfilePicture;

      let closerProfilePicture;

      if (deal.closer) {
        closerProfilePicture = await getUserAvatar(
          deal.closer.imageName,
          '',
          true
        );
        // @ts-ignore
        deal.closer.profilePicture = closerProfilePicture;
      }

      // Need to make updatedAt formatted in local tiemzone
      const tempLocalDate = new Date(deal.updatedAt);
      // NOTE: getTimezoneOffset gives the offset from UTC in minutes, converting to ms by multiplying by 60000 is needed
      const timezoneOffset = tempLocalDate.getTimezoneOffset() * 60000;
      // NOTE: timezoneOffset is positive for west of UTC, negative for east of UTC -> subtract this offset to get local time
      const adjustedDate = new Date(tempLocalDate.getTime() - timezoneOffset);
      // NOTE: toISOString converts the Date object to UTC, since adjustedDate was offset this conversion will make adjustedUpdatedAt the user's local date time
      const adjustedUpdatedAt = adjustedDate.toISOString();

      const unformattedUpdatedAt = adjustedUpdatedAt.split('T');
      const dealYear = unformattedUpdatedAt[0].slice(0, 4);
      const dealMonth = unformattedUpdatedAt[0].slice(5, 7);
      const dealDay = unformattedUpdatedAt[0].slice(8, 10);
      const dealHour = unformattedUpdatedAt[1].slice(0, 2);
      const dealMinute = unformattedUpdatedAt[1].slice(3, 5);
      const updatedAt = `${dealMonth}/${dealDay}/${dealYear} ${dealHour}:${dealMinute}`;

      deal.updatedAt = updatedAt;

      dealsArr.push(deal);
    }

    const dealsListDealsUpdatePayload = {
      updates: {
        deals: dealsArr,
        currentOffset: dealsListDeals.currentOffset + deals.length,
        canLoadMore,
        isLoading: false,
      },
    };

    dispatch({
      type: UPDATE_DEALS,
      payload: dealsListDealsUpdatePayload,
    });

    // TO DO: Remove this for the full loading skeleton and replace with just a column of loading deal cards or a CircularProgress
    setIsLoaded(true);
  }

  function Paginator(props: any) {
    const { currentPage, setPage, showNextButton } = props;
    const rows = [];
    let displayedRows: any[] = [];
    for (let i = 0; i <= maxPage; i++) {
      rows.push(
        <Button
          key={i}
          className={
            currentPage === i ? 'pagination-active' : 'pagination-inactive'
          }
          onClick={() => {
            setPage(i);
          }}
        >
          {currentPage === i && paginationLoading ? (
            <CircularProgress
              style={{ color: '#FF6A00', width: 16, height: 16 }}
            ></CircularProgress>
          ) : (
            <Typography
              className={
                currentPage === i
                  ? 'pagination-active-text'
                  : 'pagination-inactive-text'
              }
            >
              {i + 1}
            </Typography>
          )}
        </Button>
      );
    }

    if (rows.length > 5) {
      if (currentPage > rows.length - 4) {
        displayedRows = [
          rows[0],
          <Box
            key={'ellipsis'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EllipsisIcon color={'#FF6A00'}></EllipsisIcon>
          </Box>,
          rows[rows.length - 4],
          rows[rows.length - 3],
          rows[rows.length - 2],
          rows[rows.length - 1],
        ];
      } else if (currentPage < 3) {
        displayedRows = [
          rows[0],
          rows[1],
          rows[2],
          rows[3],
          <Box
            key={'ellipsis'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EllipsisIcon color={'#FF6A00'}></EllipsisIcon>
          </Box>,
          rows[rows.length - 1],
        ];
      } else {
        displayedRows = [
          rows[0],
          <Box
            key={'ellipsis'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EllipsisIcon color={'#FF6A00'}></EllipsisIcon>
          </Box>,
          rows[currentPage - 1],
          rows[currentPage],
          rows[currentPage + 1],
          <Box
            key={'ellipsis'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EllipsisIcon color={'#FF6A00'}></EllipsisIcon>
          </Box>,
          rows[rows.length - 1],
        ];
      }
    }
    return (
      <Box
        style={{ display: 'flex', flexDirection: 'row' }}
        className="pagination"
      >
        {currentPage > 0 && (
          <Button
            className={'pagination-previous-page-button'}
            onClick={() => {
              setPage(currentPage - 1);
            }}
          >
            &laquo;
          </Button>
        )}
        {rows.length <= 5 ? rows : displayedRows}
        {showNextButton && (
          <Button
            className={'pagination-next-page-button'}
            onClick={() => {
              setPage(currentPage + 1);
            }}
          >
            &raquo;
          </Button>
        )}
      </Box>
    );
  }

  // Default value for Stage filter
  let defaultStage = '';
  for (const stage of dealStages) {
    if (stage.id === searchStage) {
      defaultStage = stage.label;
      break;
    }
  }

  // Default value for Owner filter
  let defaultOwner = '';
  if (viewPermission === 'everyone') {
    // @ts-ignore
    defaultOwner = 'All ' + global.appSettings.labels.person.plural;
  } else {
    // @ts-ignore
    defaultOwner = global.me.name;
  }

  useEffect(() => {
    if (
      reduxDealTypeId &&
      Object.keys(reduxDealTypes).length > 0 &&
      Object.keys(reduxDealTypesConfigMap).length > 0 &&
      Object.keys(reduxDealTypesStagesMap).length > 0
    ) {
      prepareFiltersWithRedux();
    } else {
      prepareFiltersWithoutRedux();
    }
  }, []);

  useEffect(() => {
    // When searching or filtering, all deal pages and paginator must be reset and deals refetched
    handleRefetchDeals();
  }, [dealStages, dealTypes, searchString, dealFilter]);

  useEffect(() => {
    if (
      dealStages.length > 0 &&
      Object.keys(dealTypes).length > 0 &&
      categoryId
    ) {
      // NOTE: No need for delay on fetch if searchString is empty string as user isn't typing in a search string but reather clearing the search
      if (searchString !== '') {
        const fetchSearchedDeals = setTimeout(fetchDeals, 1000);

        return () => clearTimeout(fetchSearchedDeals);
      } else {
        fetchDeals();
      }
    } else {
      console.error(
        'Missing either dealStages, dealTypes, or categoryId: ',
        dealStages,
        dealTypes,
        categoryId
      );
    }
  }, [triggerDealsRefetch]);

  if (!isLoaded) {
    return (
      <DealsListSkeleton
        pipelineTitle={dealTypes[categoryId]?.title}
      ></DealsListSkeleton>
    );
  }
  return (
    <>
      {/* NOTE: all will not be an option for dealType in dealFilter as it doesn't make sense to combine deals across dealTypes/CRMs, it starts as 'all' until it can fetch the first enabled dealType/CRM */}
      {/* NOTE: Also need dealTypesConfigMap to be populated to avoid error in FlexFields */}
      {dealFilter.dealType !== 'all' &&
        Object.keys(dealTypesConfigMap).length > 0 && (
          <>
            {/* NOTE: Until issue with resetting modal form after new deal creation and clicking into text inputs without tabbing twice for editing deals is resolved, the deal form will not be a modal */}
            {/* <DealFormModal
              showDealFormModal={showNewDealFormModal}
              setShowDealFormModal={setShowNewDealFormModal}
              onTriggerDealsRefetch={onTriggerDealsRefetch}
              onTriggerSelectedDealRefetch={onTriggerSelectedDealRefetch}
              dealId={null}
              dealType={dealFilter.dealType}
              dealTypesConfigMap={dealTypesConfigMap}
              zIndex={100000}
            ></DealFormModal>
            {selectedDealId && (
              <DealFormModal
                showDealFormModal={showEditDealFormModal}
                setShowDealFormModal={setShowEditDealFormModal}
                onTriggerDealsRefetch={onTriggerDealsRefetch}
                onTriggerSelectedDealRefetch={onTriggerSelectedDealRefetch}
                dealId={selectedDealId}
                dealType={dealFilter.dealType}
                dealTypesConfigMap={dealTypesConfigMap}
                zIndex={100000}
              ></DealFormModal>
            )} */}
          </>
        )}
      {selectedDeal && (
        <OwnerDealStageModal
          deal={selectedDeal}
          isOnKanban={false}
          dealStages={dealStages}
          dealPeople={dealPeople}
          showOwnerDealStageModal={showOwnerDealStageModal}
          setShowOwnerDealStageModal={setShowOwnerDealStageModal}
          fetchDeals={fetchDeals}
          handleRefetchDeals={handleRefetchDeals}
          // handleDealStageChange={handleDealStageChange}
          // handleDealStageChangeRevert={handleDealStageChangeRevert}
          triggerSelectedDealRefetch={triggerSelectedDealRefetch}
          onTriggerRefetch={onTriggerSelectedDealRefetch}
        ></OwnerDealStageModal>
      )}
      <Box className={'deal-list-container'}>
        <Box className={'deal-list-overlay-left'}>
          <Box className={'deal-title-and-add-deal-section'}>
            <Box style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Typography
                className={'deal-title'}
              >{`${dealTypes[categoryId].title} Pipeline`}</Typography>
            </Box>

            {createPermission && (
              <Button
                disableRipple={true}
                onClick={(e) =>
                  Object.keys(dealTypes).length > 1
                    ? handleAddDealClick()
                    : handleNewDeal(e)
                }
                className={'add-deal-button'}
              >
                <GamifyIcon
                  icon={'add'}
                  color={'white'}
                  backgroundColor={'#FF6A00'}
                  height={26}
                  width={26}
                ></GamifyIcon>
                New deal
              </Button>
            )}
            {addDealOpen && (
              <Box className={'add-deal-menu-container'}>
                {Object.values(dealTypes).map((dealType, i, arr) => {
                  if (i === 0) {
                    return (
                      <Box key={i} className={'add-deal-menu-first-container'}>
                        <Button
                          disableRipple={true}
                          className={'add-deal-menu-button'}
                          onClick={(e) => handleNewDeal(e)}
                        >
                          {/* <DollarIcon></DollarIcon> */}
                          <Typography className={'add-deal-menu-text'}>
                            {dealType.title}
                          </Typography>
                        </Button>
                      </Box>
                    );
                  } else if (i === arr.length - 1) {
                    return (
                      <Box key={i} className={'add-deal-menu-last-container'}>
                        <Button
                          disableRipple={true}
                          className={'add-deal-menu-button'}
                          onClick={(e) => handleNewDeal(e)}
                        >
                          <Typography className={'add-deal-menu-text'}>
                            {dealType.title}
                          </Typography>
                        </Button>
                      </Box>
                    );
                  } else {
                    return (
                      <Box key={i} className={'add-deal-menu-middle-container'}>
                        <Button
                          disableRipple={true}
                          className={'add-deal-menu-button'}
                          onClick={(e) => handleNewDeal(e)}
                        >
                          {/* <DollarIcon></DollarIcon> */}
                          <Typography className={'add-deal-menu-text'}>
                            {dealType.title}
                          </Typography>
                        </Button>
                      </Box>
                    );
                  }
                })}
              </Box>
            )}
          </Box>
          <Box className={'lead-search-overlay'} justifyContent="center">
            <SearchInput
              id="leadSearch"
              name="leadSearch"
              // label="Search"
              placeholder="Search"
              type="text"
              fullWidth
              size="small"
              onChange={(key) => {
                // @ts-ignore
                setSearchString(key.nativeEvent.target.value);

                resetPaginator();
              }}
              value={searchString}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'} sx={{ paddingLeft: '0' }}>
                    <GamifyIcon
                      icon={'search'}
                      color={'#868686'}
                      backgroundColor={'transparent'}
                      height={24}
                      width={24}
                    />
                  </InputAdornment>
                ),
                endAdornment: cancelIconAdornment,
              }}
              InputLabelProps={{
                shrink: false,
                sx: { marginLeft: '24px' },
              }}
            />
          </Box>

          <Box
            className={'deal-list-settings-overlay'}
            // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            // rowSpacing={2}
            gridTemplateColumns={
              Object.keys(dealTypes).length > 1
                ? 'repeat(3, minmax(0, 1fr))'
                : 'repeat(2, minmax(0, 1fr))'
            }
          >
            {Object.keys(dealTypes).length > 1 && (
              <Box className={'deal-list-settings-container'}>
                <Typography className={'deal-settings-title-text'}>
                  Type
                </Typography>
                <StyledSelect
                  // style={{ maxWidth: '157px' }}
                  // placeholder="HELLO!!"
                  onChange={(e) => handleDealFilterChange(e)}
                  value={dealFilter.dealType}
                  name="dealType"
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        boxShadow: '0px 4px 24px 0px #ababab5e;',
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        paddingTop: '0px',
                        marginTop: '8px',
                      },
                    },
                  }}
                >
                  {Object.values(dealTypes).map((opt, i) => (
                    <StyledMenuItem value={opt.title} key={i}>
                      {capitalizeFirstLetter(opt.title)}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </Box>
            )}
            <Box className={'deal-list-settings-container'}>
              <Typography className={'deal-settings-title-text'}>
                Stage
              </Typography>
              <Box>
                <>
                  {dealStages.length > 0 && (
                    <Autocomplete
                      id={'deal-autocomplete'}
                      classes={{
                        inputRoot: 'scorecard-dropdown-box',
                        paper: 'autocomplete-menu',
                      }}
                      sx={{ width: 270 }}
                      onChange={(e, val) => {
                        // @ts-ignore
                        if (searchStage !== val.id) {
                          // LOG.debug ("@@stageFilter: ", val.id);
                          // console.log('***setSearchStage: ', val);

                          setDealFilter((p: any) => ({
                            ...p,
                            // @ts-ignore
                            dealStage: val.id,
                          }));
                          // @ts-ignore
                          setSearchStage(val.id);

                          resetPaginator();
                        }
                      }}
                      // @ts-ignore
                      options={dealStages}
                      defaultValue={defaultStage}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  )}
                </>
              </Box>
            </Box>

            {viewPermission !== 'onlyMe' && viewPermission !== 'none' && (
              <Box className={'deal-list-settings-container'}>
                <Typography className={'deal-settings-title-text'}>
                  Who
                </Typography>
                <Box>
                  <Autocomplete
                    id={'deal-autocomplete'}
                    classes={{
                      inputRoot: 'scorecard-dropdown-box',
                      paper: 'autocomplete-menu',
                    }}
                    sx={{ width: 270 }}
                    onChange={(e, val) => {
                      // @ts-ignore
                      if (userId !== val.value) {
                        // LOG.debug ("@@stageFilter: ", val.id);
                        // console.log('***setSearchStage: ', val);

                        setDealFilter((p: any) => ({
                          ...p,
                          // @ts-ignore
                          dealOwner: val.value,
                        }));
                        // @ts-ignore
                        setUserId(val.value);

                        resetPaginator();
                      }
                    }}
                    options={dealPeople}
                    defaultValue={defaultOwner}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box className={'deal-count-and-sort-section-container'}>
            <Typography className={'deal-count'}>
              {/* {dealsListDeals.deals.length} */}
              {fromDealPage === maxPage &&
              dealsListDeals.deals.length < (maxPage + 1) * 25
                ? dealsListDeals.deals.length - maxPage * 25
                : 25}
              {dealsListDeals.canLoadMore && '+'} Results
            </Typography>
            <Button
              disableRipple={true}
              onClick={handleSortClick}
              className={'sort-deals-button'}
            >
              <GamifyIcon
                icon={'sort'}
                color={'#FF6A00'}
                backgroundColor={''}
                height={24}
                width={24}
              ></GamifyIcon>
            </Button>
            {sortOpen && (
              <Box className={'sort-menu-container'}>
                <Box className={'sort-menu-first-container'}>
                  <Button
                    disableRipple={true}
                    className={'sort-menu-top-button'}
                    onClick={handleNewestFirstClick}
                  >
                    <Typography
                      className={
                        newestSort
                          ? 'selected-sort-menu-text'
                          : 'sort-menu-text'
                      }
                    >
                      Newest first
                    </Typography>
                  </Button>
                </Box>
                <Box className={'sort-menu-last-container'}>
                  <Button
                    disableRipple={true}
                    className={'sort-menu-button'}
                    onClick={handleOldestFirstClick}
                  >
                    <Typography
                      className={
                        oldestSort
                          ? 'selected-sort-menu-text'
                          : 'sort-menu-text'
                      }
                    >
                      Oldest First
                    </Typography>
                  </Button>
                </Box>
                {/* <Box className={'sort-menu-middle-container'}>
                  <Button
                    disableRipple={true}
                    className={'sort-menu-button'}
                    onClick={handleAZClick}
                  >
                    <Typography
                      className={
                        aZSort ? 'selected-sort-menu-text' : 'sort-menu-text'
                      }
                    >
                      From A-Z
                    </Typography>
                  </Button>
                </Box>
                <Box className={'sort-menu-middle-container'}>
                  <Button
                    disableRipple={true}
                    className={'sort-menu-button'}
                    onClick={handleZAClick}
                  >
                    <Typography
                      className={
                        zASort ? 'selected-sort-menu-text' : 'sort-menu-text'
                      }
                    >
                      From Z-A
                    </Typography>
                  </Button>
                </Box>
                <Box className={'sort-menu-middle-container'}>
                  <Button
                    disableRipple={true}
                    className={'sort-menu-button'}
                    onClick={handleOwnerAZClick}
                  >
                    <Typography
                      className={
                        ownerAZSort
                          ? 'selected-sort-menu-text'
                          : 'sort-menu-text'
                      }
                    >
                      Owner From A-Z
                    </Typography>
                  </Button>
                </Box>
                <Box className={'sort-menu-last-container'}>
                  <Button
                    disableRipple={true}
                    className={'sort-menu-bottom-button'}
                    onClick={handleOwnerZAClick}
                  >
                    <Typography
                      className={
                        ownerZASort
                          ? 'selected-sort-menu-text'
                          : 'sort-menu-text'
                      }
                    >
                      Owner From Z-A
                    </Typography>
                  </Button>
                </Box> */}
              </Box>
            )}
          </Box>

          <Grid
            container
            className={'deal-list-column-container'}
            // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            // rowSpacing={2}
            // justifyContent="flex-start"
            width="100%"
          >
            <Box className={'deal-list-column'}>
              {/* TO DO: Replace 25 with limit state variable */}
              {dealsListDeals.isLoading
                ? Array(25)
                    .fill('')
                    .map((deal: string, i: number) => {
                      if (i === 26) console.log(deal);
                      return (
                        <Box
                          key={i}
                          style={{
                            backgroundColor: 'white',
                            padding: 8,
                            borderRadius: 8,
                            border: '1px solid #D0D2D8',
                            marginBottom: 12,
                          }}
                        >
                          <DealCardSkeleton
                            isOnKanban={true}
                          ></DealCardSkeleton>
                        </Box>
                      );
                    })
                : dealsListDeals.deals
                    .slice(fromDealPage * 25, fromDealPage * 25 + 25)
                    .map((deal: Deal, i: number) => (
                      <DealCard
                        key={i}
                        isOnKanban={false}
                        deal={deal}
                        selectedDealId={selectedDealId}
                        handleDealSelection={handleDealSelection}
                        setAddDealOpen={setAddDealOpen}
                        handleOpenOwnerDealStageModal={
                          handleOpenOwnerDealStageModal
                        }
                        stageId={deal.stageId}
                        dealStage={normalizedDealStages[deal.stageId]?.name}
                        handleDeleteDeal={handleDeleteDeal}
                        page={fromDealPage}
                        userId={userId}
                        categoryId={categoryId}
                      ></DealCard>
                    ))}
            </Box>
          </Grid>
          {/* Paginator only renders when more deals can load and when on first page */}
          {fromDealPage === 0 && !dealsListDeals.canLoadMore ? (
            <></>
          ) : (
            <Paginator
              showNextButton={dealsListDeals.canLoadMore}
              currentPage={fromDealPage}
              setPage={(val: number) => {
                // TO DO: Add in refetching when switching pages after deal deletion or a stage change?
                // Only fetch deals/ set pagination loading if the page hasn't already been fetched
                if (!dealsListDeals.deals[val * 25 + 24]) {
                  const dealsListDealsLoadingPayload = {
                    updates: {
                      canLoadMore: false,
                      isLoading: true,
                    },
                  };

                  dispatch({
                    type: 'UPDATE_DEALS',
                    payload: dealsListDealsLoadingPayload,
                  });
                  fetchDeals(true);

                  setPaginationLoading(true);
                  setTimeout(() => {
                    setFromDealPage(val);
                    if (val > maxPage) {
                      setMaxPage(val);
                    }
                    setPaginationLoading(false);
                  }, 1000);
                } else {
                  setFromDealPage(val);
                  if (val > maxPage) {
                    setMaxPage(val);
                  }
                }
              }}
            />
          )}
        </Box>
        <Box className={'deal-list-overlay-right'}>
          {selectedDealId ? (
            <Box className={'deal-selected-card'}>
              <Box style={{ position: 'absolute', top: 188, right: 24 }}>
                <Button
                  className={'ellipsis-button'}
                  disableRipple={true}
                  onClick={() => {
                    setSelectedDealId(null);
                    setSelectedDeal(null);
                    navigate('/pipeline/list');
                  }}
                >
                  <GamifyIcon
                    icon={'x'}
                    color={'white'}
                    backgroundColor={''}
                    height={12}
                    width={12}
                  />
                </Button>
              </Box>
              <DealItem
                // className={'deal-selected-card-item'}
                props={{
                  dealId: selectedDealId,
                  searchStage,
                  searchUserId: userId,
                }}
                stateProps={{
                  isOnKanban: false,
                  setAddDealOpen,
                  setSortOpen,
                  // selectedDealOptionsOpen,
                  // setSelectedDealOptionsOpen,
                  selectedDealMetaDataOpen,
                  setSelectedDealMetaDataOpen,
                  // setShowEditDealFormModal,
                  handleOpenOwnerDealStageModal,
                  handleRefetchDeals,
                  handleDeleteDeal,
                  // onTriggerDealsRefetch,
                  triggerSelectedDealRefetch,
                  onTriggerSelectedDealRefetch,
                  userId,
                  categoryId,
                }}
              />
            </Box>
          ) : (
            <Box className={'select-to-see-preview-container'}>
              <Box className={'preview-icon-container'}>
                <GamifyIcon
                  icon={'preview'}
                  color={'#FF6A00'}
                  backgroundColor={''}
                  height={24}
                  width={24}
                ></GamifyIcon>
              </Box>
              <Typography className={'preview-text'}>
                Select a deal to preview
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
