import React, { useState, useEffect /* , useCallback */, useMemo } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  Box,
  Button,
  CardMedia,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  Avatar,
  Checkbox,
  InputAdornment,
  Typography,
  CircularProgress,
  // CircularProgress,
} from '@mui/material';
import {
  UsersTableCell,
  UsersTableRow,
  TitleTableCell,
  SearchInput,
  NavigationButton,
  // CustomMenu,
  // CustomMenuItem,
  // ResultsText,
  // StyledSelect,
  // StyledMenuItem,
} from './StyledComponent';
import {
  CustomMenu,
  CustomMenuItem,
  StyledSelect,
  StyledMenuItem,
} from '../../common/StyledComponents';
import { Search, MoreHoriz } from '@mui/icons-material';
import gql from 'graphql-tag';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import getUserAvatar from '../../common/utils/getUserAvatar';
import Fuse from 'fuse.js';
// import { debounce } from 'lodash';
import * as utils from '../../common/Utilities';
import { useNavigate } from 'react-router';
import fetchTeamMembers from './fetchTeamMembers';
import { GamifyIcon } from '../../components/GamifyIcon';
import { GamifyToast } from '../../common/CustomToasts';
import AddMemberLinkTeamModal from './AddMemberLinkTeamModal';
// import DeleteTeamsModal from './DeleteTeamsModal';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
// import BetaIcon from '../../common/BetaIcon';
import TeamFormModal from './TeamFormModal';
import fetchTeamLevels from './fetchTeamLevels';
// import TeamsTableView from './TeamsTableView';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';
import { useCurrentUserStore } from '../../store/zustand/useCurrentUserStore';

export default function TeamsScreen() {
  // NOTE:
  const navigate = useNavigate();

  const { checkPermission, hasTeamPermission } = usePermissions();

  const canCreate = checkPermission(PermissionKeys.TeamsPeople_CreateTeam);

  const canMultiDeleteTeams =
    checkPermission(PermissionKeys.TeamsPeople_DeleteTeam) === 'everyone';

  const [searchString, setSearchString] = useState('');
  const [nextToken, setNextToken] = useState(null);

  const [myTeams, setMyTeams] = useState([]);
  const [teamLevels, setTeamLevels] = useState(['all']);
  const [searchData, setSearchData] = useState(myTeams);
  const options = {
    keys: ['circle.name', 'circle.description'],
    includeScore: true,
    threshold: 0.2,
  };

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [listFilters, setListFilters] = useState({
    level: 'all',
    role: 'all',
    type: 'all',
  });
  const [limit, setLimit] = useState(10);
  const [filteredList, setFilteredList] = useState(null);
  const [checkedTeams, setCheckedTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [showNewTeamFormModal, setShowNewTeamFormModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMemberListLoading, setIsMemberListLoading] = useState(false);
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const [refetchMembersUpdateTrigger, setRefetchMembersUpdateTrigger] =
    useState(0);
  // console.log('TeamsScreen checkedTeams: ', checkedTeams);
  // console.log('TeamsScreen selectedTeam: ', selectedTeam);

  // const deleteThisFunctionLater = () => {
  //   console.log(filteredList);
  //   setFilteredList(null);
  // };

  const handleTeamCheck = (event, team) => {
    if (event.target.checked) {
      setCheckedTeams((prevCheckedTeams) => [...prevCheckedTeams, team]);
    } else {
      setCheckedTeams((prevCheckedTeams) =>
        prevCheckedTeams.filter((checkedTeam) => checkedTeam.id !== team.id)
      );
    }
  };

  const handleFilterChange = (e) => {
    setListFilters((p) => ({ ...p, [e.target.name]: e.target.value }));
    // Move user back to page 1
    setCurrentPage(1);
  };

  // Need to get org's team levels on first render to populate the Team Level filter
  useEffect(() => {
    async function getTeamLevels() {
      const tempTeamLevels = await fetchTeamLevels();
      const tempTeamLevelsLabels = ['all'];

      // NOTE: Need to iterate backwards to ensure correct order in Team Level filter
      for (let i = tempTeamLevels.length - 1; i >= 0; i--) {
        tempTeamLevelsLabels.push(tempTeamLevels[i].label);
      }

      setTeamLevels(tempTeamLevelsLabels);
    }
    getTeamLevels();
  }, []);

  useEffect(() => {
    if (!myTeams) return;
    if (
      listFilters.level + listFilters.role + listFilters.type ===
      'allallall'
    ) {
      setFilteredList(null);
      setTotalPages(Math.ceil(myTeams.length / limit));
    }
    // Use searchData when there's a searchString
    let list = searchString.length > 0 ? searchData : myTeams;
    console.log('filter change myTeams:', myTeams);
    if (listFilters.level !== 'all') {
      list = list.filter(
        (team) => team.circle.level.name === listFilters.level
      );
    }
    if (listFilters.role !== 'all') {
      list = list.filter((team) => {
        if (team?.user?.functionalRoles)
          return team.user.functionalRoles.includes(listFilters.role);
        return false;
      });
    }
    if (listFilters.type !== 'all') {
      list = list.filter((team) => team.memberType === listFilters.type);
    }
    setTotalPages(Math.ceil(list.length / limit));
    setFilteredList(list);
  }, [listFilters, myTeams, limit]);

  // useEffect(() => {
  //   if (myTeams === 50) {
  //     deleteThisFunctionLater();
  //   }
  // }, []);

  const fetchTeams = async (searchString, myNextToken, resetList) => {
    // console.log('searchString: ', searchString);
    setIsLoading(true);
    const ourSearchString = searchString + '*';

    if (!resetList && myNextToken == null) {
      console.log('!resetList && myNextToken == null');
      setIsLoading(false);
      return;
    }
    let ourNextToken = myNextToken;
    if (nextToken === '' && !resetList) {
      //   LOG.debug('***End of list***');
      setIsLoading(false);
      return;
    }
    if (resetList) {
      ourNextToken = null;
    }

    try {
      const myQuery = gql`
        query myQuery($orgID: ID!, $searchString: String!, $nextToken: String) {
          searchCircles(
            filter: {
              name: { wildcard: $searchString }
              orgID: { eq: $orgID }
              isDeleted: { eq: false }
            }
            limit: 999
            nextToken: $nextToken
            sort: { direction: asc, field: name }
          ) {
            nextToken
            items {
              approverType
              avatarImageName
              description
              endDate
              id
              imageName
              imageType
              inviterType
              isActive
              launchDate
              location
              level {
                id
                name
                description
                levelNum
                orgID
                circles {
                  nextToken
                }
                createdBy
                lastUpdatedBy
                createdAt
                updatedAt
                isDeleted
              }
              name
              scope
              startDate
              status
              parentCircleID
              levelID
              parent {
                id
                name
                parentCircleID
              }
              children {
                items {
                  id
                  name
                  parentCircleID
                }
              }
              users {
                items {
                  id
                  isActive
                  lifetimeCirclePoints
                  memberType
                  status
                  circlePoints
                  orgID
                  circleID
                  user {
                    id
                    functionalRoles
                    status
                  }
                }
              }
            }
            nextToken
            total
          }
        }
      `;
      let teamsList = [];
      let teamsNextToken = null;

      do {
        const res = await API.graphql({
          query: myQuery,
          variables: {
            orgID: global.me.orgID,
            searchString: ourSearchString || '*',
            nextToken: teamsNextToken,
          },
        });

        teamsList = [...teamsList, ...(res?.data?.searchCircles?.items || [])];
        teamsNextToken = res?.data?.searchCircles?.nextToken;
      } while (teamsNextToken);

      const circles = teamsList;
      const totalPages = Math.ceil(circles.length / limit);
      setTotalPages(totalPages);

      //   setTeams(circles);
      // console.log('TOTAL', res.data.searchCircles.total);
      // console.log('TOTAL PAGES', totalPages);
      // console.log('circles from query --> ', circles);
      const now = new Date();

      let newCircleMembers = [];
      // const circleLevels = {};
      //
      //  We will reset our list of our next token is null (meaning that we're at the start) or if
      //  the resetList parm was set
      //
      if (!(resetList || ourNextToken == null)) {
        console.log('**retaining list**');
        newCircleMembers = myTeams;
      } else {
        console.log('**starting new list**');
      }

      //    Query to get names of circleLevels
      // const circleLvlQuery = gql`
      //   query GetCircleLevel($id: ID!) {
      //     getCircleLevel(id: $id) {
      //       id
      //       name
      //       description
      //       levelNum
      //     }
      //   }
      // `;

      for (const circle of circles) {
        const startDate = new Date(circle.startDate);
        const endDate = new Date(circle.endDate);

        //  Fetching avatar image
        circle.avatarFile = await getUserAvatar(
          circle.imageName,
          circle.imageType,
          true
        );
        // console.log(
        //   'Circle name + avatarFile + image type: ',
        //   circle.name,
        //   circle.avatarFile,
        //   circle.imageType
        // );

        // adding circleLevel object to circle
        // if (!circleLevels[circle.levelID]) {
        //   const circleLvlRes = await API.graphql({
        //     query: circleLvlQuery,
        //     variables: { id: circle.levelID },
        //   });
        //   const circleLevel = circleLvlRes.data.getCircleLevel;
        //   circleLevels[circle.levelID] = circleLevel;
        // }
        // circle.circleLevel = circleLevels[circle.levelID];

        // use fetchTeamMembers to get all members and not just first 100:
        let teamMembers;
        if (circle.users.items.length === 100) {
          teamMembers = await fetchTeamMembers(circle.id);
          teamMembers = teamMembers.filter(
            (member) => member.status === 'active'
          );
        }

        // console.log('TeamsScreen teamMembers: ', teamMembers);
        // console.log('TeamsScreen circle.users.items: ', circle.users.items);

        if (circle.users.items.length > 0) {
          const circleMember = circle.users.items[0];
          circleMember.remainingDaysInCircle = utils.daysBetween(now, endDate);
          circleMember.daysBeforeStart = utils.daysBetween(now, startDate);
          circleMember.circle = circle;
          circleMember.memberType = 'member'; // default
          // check if teamMembers exists so members # is accurate if >100 users
          if (teamMembers) {
            circleMember.circle.users = teamMembers;
            for (const cm of teamMembers) {
              if (cm.id === global.me.id) {
                circleMember.memberType = cm.circleMember.memberType;
              }
            }
          } else {
            // need to filter out removed users, teamMembers above already filters out removed members
            circleMember.circle.users = circle.users.items.filter(
              (user) => user.status !== 'removed'
            );
            for (const cm of circle.users) {
              if (cm.id === global.me.id) {
                circleMember.memberType = cm.memberType;
              }
            }

            // Filter out user when cm doesn't have user property and user.status is active (teamMembers already has this filtered out)
            circleMember.circle.users = circleMember.circle.users.filter(
              (userItem) => {
                return userItem.user && userItem.user?.status === 'active';
              }
            );
          }

          newCircleMembers[newCircleMembers.length] = circleMember;
        } else {
          const circleMember = {
            circlePoints: 0,
            id: '',
            isActive: true,
            lifetimeCirclePoints: 0,
            memberType: 'owner',
            orgID: global.me.orgID,
            status: 'NotaMember',
          };
          circleMember.remainingDaysInCircle = utils.daysBetween(now, endDate);
          circleMember.daysBeforeStart = utils.daysBetween(now, startDate);
          circleMember.circle = circle;
          // need to filter out removed users
          circleMember.circle.users = circle.users.items.filter(
            (user) => user.status !== 'removed'
          );
          newCircleMembers[newCircleMembers.length] = circleMember;
        }
      }
      // console.log('circles after function -->', newCircleMembers);
      setMyTeams(newCircleMembers);
      setSearchData(newCircleMembers);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('ERROR FETCHING TEAMS', error);
    }
  };

  const onTriggerRefetch = () => {
    setRefetchTrigger((refetchTrigger) => refetchTrigger + 1);
    setCurrentPage(1);
  };

  const onTriggerMembersUpdateRefetch = () => {
    setRefetchMembersUpdateTrigger(
      (refetchMembersUpdateTrigger) => refetchMembersUpdateTrigger + 1
    );
    setCurrentPage(1);
  };

  // NOTE: removed searchString from useEffect dependency array as Fuse is used for searching rather than doing a new query each search
  useEffect(() => {
    // console.log('useEffect searchString: ', searchString);
    async function searchTeams() {
      if (isLoading) return;
      setIsLoading(true);
      if (myTeams.length > 0) {
        setMyTeams([]); // reset teams when at least 1
      }
      setTimeout(async () => {
        await fetchTeams(searchString, nextToken, true);
        setIsLoading(false);
      }, 750); // need to set longer so team deletes before teams fetch again...
    }
    searchTeams();
  }, [/* searchString, */ refetchTrigger]);

  // Fetch without full table animation for when users are added to a team in the table, animation handled in the addMembers function
  useEffect(() => {
    async function searchTeams() {
      await fetchTeams(searchString, nextToken, true);
    }
    searchTeams();
  }, [/* searchString, */ refetchMembersUpdateTrigger]);

  const fuse = new Fuse(myTeams, options);

  // console.log('searchString and searchData: ', searchString, searchData);

  // NOTE: onSearch and debouncedSearch are used in lyferize... these are very laggy when implemented on web for some reason. Search functionality done in SearchInput onChange for now.
  // const onSearch = (text) => {
  //   setSearchString(text);
  //   if (text) {
  //     const results = fuse.search(text).map(({ item }) => item);
  //     setSearchData(results);
  //   } else {
  //     setSearchData(myTeams);
  //   }
  // };

  // const debouncedSearch = useCallback(debounce(onSearch, 300), [myTeams, fuse]);

  useEffect(() => {
    // NOTE: This is how search was successfully done in AddMemberLinkTeamModal
    const searchedData = fuse.search(searchString);
    //   console.log(
    //     'SearchTeams onChange searchedData: ',
    //     searchedData
    //   );

    const formattedData = searchedData.map((data) => ({
      ...data,
      ...data.item,
    }));

    setSearchData(formattedData);
    // Update total pages based on formattedData length if searchString has length
    if (searchString.length > 0)
      setTotalPages(Math.ceil(formattedData.length / limit));
  }, [searchString]);

  useEffect(() => {
    if (myTeams === 'REMOVE') {
      setSearchString('');
      setNextToken(null);
    }
  }, [myTeams]);

  // console.log('TeamsScreen isMemberListLoading: ', isMemberListLoading);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'circle.name',
        cell: ({ row }) => {
          const team = row.original;
          const { circle } = team;

          const canClickTeam = hasTeamPermission(
            PermissionKeys.TeamsPeople_ViewTeamDetails,
            [circle?.id]
          );

          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: '20px',
                paddingRight: '20px',
                cursor: canClickTeam ? 'pointer' : 'default',
              }}
              onClick={(e) => {
                // Check if the click is not on the Checkbox and current user can click team
                if (!e.target.closest('.MuiCheckbox-root') && canClickTeam) {
                  navigate(`/teams/${circle.id}`);
                }
              }}
            >
              {canMultiDeleteTeams && (
                <Checkbox
                  sx={{
                    // color: '#FF6A00',
                    color: '#868686',
                    '&.Mui-checked': {
                      color: '#FF6A00',
                    },
                    '&:hover': {
                      backgroundColor: 'transparent !important',
                    },
                  }}
                  checked={checkedTeams.some(
                    (checkedTeam) => checkedTeam.id === circle.id
                  )}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleTeamCheck(e, circle);
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              )}
              {/* Teams created on web get imageType 'image/<jpeg, png, etc...>', account for that */}
              {circle.avatarFile ? (
                circle.imageType === 'picture' ||
                circle.imageType.split('/')[0] === 'image' ? (
                  <Avatar
                    src={circle.avatarFile}
                    sx={{ marginRight: '12px' }}
                  />
                ) : (
                  <CardMedia
                    image={circle.imageName}
                    component="img"
                    sx={{
                      backgroundColor: 'black',
                      borderRadius: 1000,
                      height: 40,
                      width: 40,
                      objectFit: 'contain',
                      marginRight: '12px',
                    }}
                  />
                )
              ) : (
                <Avatar
                  sx={{ backgroundColor: '#868686', marginRight: '12px' }}
                >
                  {circle.name[0].toUpperCase()}
                </Avatar>
              )}
              <Typography
                style={{ color: '#222428', fontSize: 16, fontWeight: 500 }}
              >
                {circle.name}
              </Typography>
            </Box>
          );
        },
        header: () => (
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            Name
          </Typography>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'circle.parent.name',
        cell: ({ row }) => {
          const { circle } = row.original;

          const canClickTeam = hasTeamPermission(
            PermissionKeys.TeamsPeople_ViewTeamDetails,
            [circle?.id]
          );

          return (
            <Button
              className={'team-screen-to-team-page-button'}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                height: 72,
                padding: '16px 20px 16px 20px',
              }}
              sx={{
                cursor: canClickTeam ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (canClickTeam) {
                  navigate(`/teams/${circle.id}`);
                }
              }}
              disableRipple={true}
            >
              {circle.parent?.name ? (
                <Typography
                  style={{ color: '#323232', fontSize: 16, fontWeight: 500 }}
                >
                  {circle.parent.name}
                </Typography>
              ) : (
                <Typography
                  style={{ color: '#323232', fontSize: 16, fontWeight: 500 }}
                >
                  None
                </Typography>
              )}
            </Button>
          );
        },
        header: () => (
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            Parent Team
          </Typography>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'circle.level.name',
        cell: ({ row }) => {
          const { circle } = row.original;

          const canClickTeam = hasTeamPermission(
            PermissionKeys.TeamsPeople_ViewTeamDetails,
            [circle?.id]
          );

          return (
            <Button
              className={'team-screen-to-team-page-button'}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                height: 72,
                padding: '16px 20px 16px 20px',
              }}
              sx={{ cursor: canClickTeam ? 'pointer' : 'default' }}
              onClick={() => {
                if (canClickTeam) {
                  navigate(`/teams/${circle.id}`);
                }
              }}
              disableRipple={true}
            >
              <Typography
                style={{ color: '#323232', fontSize: 16, fontWeight: 500 }}
              >
                {circle.level?.name[0].toUpperCase() +
                  circle.level?.name.slice(1)}
              </Typography>
            </Button>
          );
        },
        header: () => (
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            Team Level
          </Typography>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'memberRole',
        cell: ({ row }) => {
          const circle = row.original;

          const canClickTeam = hasTeamPermission(
            PermissionKeys.TeamsPeople_ViewTeamDetails,
            [circle?.id]
          );

          // console.log('TeamsScreen circle: ', circle);
          const functionalRolesArr = circle?.user?.functionalRoles;

          // Format the functional role
          let teamFunctionalRole = '';
          if (functionalRolesArr) {
            for (const role of functionalRolesArr) {
              // Skip if blank role
              if (role === '') continue;

              // Put & for multiple roles
              if (teamFunctionalRole !== '') {
                teamFunctionalRole += ' & ';
              }
              teamFunctionalRole += role[0].toUpperCase() + role.slice(1);
            }
          }

          return (
            <Button
              className={'team-screen-to-team-page-button'}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                height: 72,
                padding: '16px 20px 16px 20px',
              }}
              sx={{ cursor: canClickTeam ? 'pointer' : 'default' }}
              onClick={() => {
                if (canClickTeam) {
                  navigate(`/teams/${circle?.circle?.id}`);
                }
              }}
              disableRipple={true}
            >
              <Typography
                style={{ color: '#323232', fontSize: 16, fontWeight: 500 }}
              >
                {teamFunctionalRole === '' ? 'None' : teamFunctionalRole}
              </Typography>
            </Button>
          );
        },
        header: () => (
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            My Role
          </Typography>
        ),
        footer: (props) => props.column.id,
        sortingFn: 'role',
      },
      {
        accessorKey: 'memberType',
        cell: ({ row }) => {
          const circle = row.original;
          const role = circle?.memberType;

          const canClickTeam = hasTeamPermission(
            PermissionKeys.TeamsPeople_ViewTeamDetails,
            [circle?.id]
          );

          return (
            <Button
              className={'team-screen-to-team-page-button'}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                height: 72,
                padding: '16px 20px 16px 20px',
              }}
              sx={{ cursor: canClickTeam ? 'pointer' : 'default' }}
              onClick={() => {
                if (canClickTeam) {
                  navigate(`/teams/${circle?.circle?.id}`);
                }
              }}
              disableRipple={true}
            >
              <Typography
                style={{ color: '#323232', fontSize: 16, fontWeight: 500 }}
              >
                {role[0].toUpperCase() + role.slice(1)}
              </Typography>
            </Button>
          );
        },
        header: () => (
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            My Member Type
          </Typography>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'members',
        cell: ({ row }) => {
          const { circle } = row.original;
          // console.log('Members Column circle: ', circle);

          const canClickTeam = hasTeamPermission(
            PermissionKeys.TeamsPeople_ViewTeamDetails,
            [circle?.id]
          );

          return (
            <Button
              className={'team-screen-to-team-page-button'}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                height: 72,
                padding: '16px 20px 16px 20px',
              }}
              sx={{ cursor: canClickTeam ? 'pointer' : 'default' }}
              onClick={() => {
                if (canClickTeam) {
                  navigate(`/teams/${circle.id}`);
                }
              }}
              disableRipple={true}
            >
              {isMemberListLoading && circle.id === selectedTeam.id ? (
                <CircularProgress
                  style={{ color: 'black', width: 24, height: 24 }}
                ></CircularProgress>
              ) : (
                <Typography
                  style={{ color: '#323232', fontSize: 16, fontWeight: 500 }}
                >
                  {circle.users?.length}
                </Typography>
              )}
            </Button>
          );

          // const numMembers = circle.users.length;
          // return numMembers;
        },
        header: () => (
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            Members
          </Typography>
        ),
        footer: (props) => props.column.id,
        sortingFn: 'members',

        // // sortType is sortingFn is TanStack Table v8
        // isSortable: true,
        // manualSortBy: true, // Enable custom sorting
        // sortType: (rowA, rowB, columnId, desc) => {
        //   // Extract the 'numMembers' values from rowA and rowB
        //   const numMembersA = rowA.values.members;
        //   const numMembersB = rowB.values.members;

        //   // Compare the values for sorting
        //   return desc ? numMembersB - numMembersA : numMembersA - numMembersB;
        // },
      },
    ],
    [isMemberListLoading, checkedTeams]
  );

  const handleNewTeam = () => {
    // NOTE: No longer need
    // navigate('/teams/new');
    setShowNewTeamFormModal(true);
  };

  const handleClearSearch = () => {
    setSearchString('');
    setTotalPages(Math.ceil(myTeams.length / limit));
    setCurrentPage(1);
  };

  const cancelIconAdornment =
    searchString.length > 0 ? (
      <Box
        sx={{ cursor: 'pointer' }}
        className={'clear-input-button'}
        onClick={handleClearSearch}
      >
        <InputAdornment position="end">
          <GamifyIcon icon={'delete'}></GamifyIcon>
        </InputAdornment>
      </Box>
    ) : (
      <></>
    );

  return (
    <>
      <TeamFormModal
        showTeamFormModal={showNewTeamFormModal}
        setShowTeamFormModal={setShowNewTeamFormModal}
        onTriggerTeamsRefetch={onTriggerRefetch}
        teamId={null}
        zIndex={100000}
      ></TeamFormModal>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '90px 100px 20px 100px',
        }}
      >
        <Box
          style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <h1
              // onClick={() => console.log('ggg----', filteredList)}
              style={{
                color: '#222428',
                fontSize: '28px',
                fontFamily: 'Manrope',
                lineHeight: '58.51px',
                fontWeight: '600px',
              }}
            >
              Teams
            </h1>
            {/* <Box style={{ marginLeft: 16 }}>
              <BetaIcon></BetaIcon>
            </Box> */}
          </Box>

          {canCreate && (
            <Button
              sx={{
                ' &.MuiButton-text:hover': {
                  backgroundColor: '#FF6A00',
                },
              }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#FF6A00',
                width: 180,
                height: 48,
                borderRadius: 8,
              }}
              onClick={() => handleNewTeam()}
              disableRipple={true}
            >
              <GamifyIcon icon={'add'}></GamifyIcon>
              <Typography
                style={{
                  color: 'white',
                  fontSize: 18,
                  fontWeight: 700,
                  marginLeft: 10,
                }}
              >
                New team
              </Typography>
            </Button>
          )}
        </Box>
        <Box
          style={{
            marginTop: '25px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            // alignItems: 'center',
            overflowX: 'auto',
            overflowY: 'hidden',
          }}
        >
          <Box style={{ position: 'relative', transform: 'translateY(9px)' }}>
            <SearchInput
              fullWidth
              id="search"
              name="new-search" // changed search to new-search to avoid Chrome autofill
              variant="outlined"
              placeholder="Search"
              value={searchString}
              onChange={(e) => {
                // Setting search string activates useEffect that does the search
                setSearchString(e.target.value);
                // Move user back to page 1
                setCurrentPage(1);

                // NOTE: This made the search very laggy, couldn't type without pausing between each keystroke...
                // debouncedSearch(e.target.value);
              }}
              sx={{ marginRight: '16px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ backgroundColor: 'transparent', width: '10px' }}
                  >
                    <Search sx={{ color: '#868686' }} />
                  </InputAdornment>
                ),
                endAdornment: cancelIconAdornment,
              }}
            />
          </Box>
          <Box display="flex">
            <Box style={{ marginRight: '15px' }}>
              <p
                style={{
                  margin: '0 0 5px 0',
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              >
                Team Level
              </p>
              <StyledSelect
                style={{ minWidth: '25ch' }}
                MenuProps={{
                  style: { zIndex: 100002 },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                    },
                  },
                }}
                // placeholder="HELLO!!"
                onChange={handleFilterChange}
                value={listFilters.level}
                name="level"
              >
                {teamLevels.map((opt, i) => (
                  <StyledMenuItem value={opt} key={i}>
                    {opt[0].toUpperCase() + opt.slice(1)}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
            <Box style={{ marginRight: '15px' }}>
              <p
                style={{
                  margin: '0 0 5px 0',
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              >
                Role
              </p>
              <StyledSelect
                style={{ minWidth: '25ch' }}
                MenuProps={{
                  style: { zIndex: 100002 },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                    },
                  },
                }}
                // placeholder="HELLO!!"
                onChange={handleFilterChange}
                value={listFilters.role}
                name="role"
              >
                {['all', 'manager', 'setter', 'closer'].map((opt, i) => (
                  <StyledMenuItem value={opt} key={i}>
                    {opt[0].toUpperCase() + opt.slice(1)}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
            <Box style={{ marginRight: '15px' }}>
              <p
                style={{
                  margin: '0 0 5px 0',
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              >
                Member Type
              </p>
              <StyledSelect
                style={{ minWidth: '25ch' }}
                MenuProps={{
                  style: { zIndex: 100002 },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                    },
                  },
                }}
                // placeholder="HELLO!!"
                onChange={handleFilterChange}
                value={listFilters.type}
                name="type"
              >
                {['all', 'member', 'admin', 'owner'].map((opt, i) => (
                  <StyledMenuItem value={opt} key={i}>
                    {opt[0].toUpperCase() + opt.slice(1)}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
            <Box>
              <p
                style={{
                  margin: '0 0 5px 0',
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              >
                Teams per page
              </p>
              <StyledSelect
                style={{ minWidth: '25ch' }}
                MenuProps={{
                  style: { zIndex: 100002 },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                    },
                  },
                }}
                // placeholder="HELLO!!"
                onChange={(e) => {
                  setLimit(e.target.value);
                  // Move user back to page 1
                  setCurrentPage(1);
                }}
                value={limit}
                name="resultsPerPage"
              >
                {[10, 25, 50, 100].map((opt, i) => (
                  <StyledMenuItem value={opt} key={i}>
                    {opt}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box style={{ padding: '10px 100px' }}>
        {/* {myTeams.length ? ( */}
        <Table
          // NOTE: check for filters for filtered data first before searchData because filteredList uses searchData when searchString.length > 0
          data={
            listFilters.level + listFilters.role + listFilters.type !==
            'allallall'
              ? filteredList
              : searchString.length > 0
              ? searchData
              : myTeams
          }
          columns={columns}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          checkedTeams={checkedTeams}
          setCheckedTeams={setCheckedTeams}
          isLoading={isLoading}
          limit={limit}
          onTriggerRefetch={onTriggerRefetch}
          onTriggerMembersUpdateRefetch={onTriggerMembersUpdateRefetch}
          setIsMemberListLoading={setIsMemberListLoading}
        />
        {/* ) : (
          <Box style={{ width: '100%' }}>
            <Box style={{}} />
          </Box>
        )} */}
      </Box>
    </>
  );
}

function Table({
  data,
  columns,
  selectedTeam,
  setSelectedTeam,
  checkedTeams,
  setCheckedTeams,
  //   triggerFetchUsers,
  //   setTriggerFetchUsers,
  //   onLoadMore,
  totalPages,
  currentPage,
  setCurrentPage,
  isLoading,
  limit,
  onTriggerRefetch,
  onTriggerMembersUpdateRefetch,
  setIsMemberListLoading,
}) {
  const navigate = useNavigate();

  const { hasTeamPermission } = usePermissions();

  const {
    addMembersToCurrentUserTeam,
    addCurrentUserTeams,
    removeCurrentUserTeams,
  } = useCurrentUserStore();

  const [sorting, setSorting] = useState([]);
  const [anchorElMap, setAnchorElMap] = useState({});
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showEditTeamFormModal, setShowEditTeamFormModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  // const deleteFunctionLater = () => {
  //   // console.log(selectedTeam);
  //   // console.log(checkedTeams);
  //   setCheckedTeams([]);
  //   // console.log()
  // };

  // useEffect(() => {
  //   if (currentPage === 50) {
  //     deleteFunctionLater();
  //   }
  // }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: limit,
      },
    },
    sortingFns: {
      role: (rowA, rowB) => {
        const rowAFunctionalRolesArr = rowA.original?.user?.functionalRoles;
        const rowBFunctionalRolesArr = rowB.original?.user?.functionalRoles;

        // Format row A's functional role
        let rowAFunctionalRole = '';
        if (rowAFunctionalRolesArr) {
          for (const role of rowAFunctionalRolesArr) {
            // Skip if blank role
            if (role === '') continue;

            // Put & for multiple roles
            if (rowAFunctionalRole !== '') {
              rowAFunctionalRole += ' & ';
            }
            rowAFunctionalRole += role[0].toUpperCase() + role.slice(1);
          }
        }

        if (rowAFunctionalRole === '') rowAFunctionalRole = 'None';

        // Format row B's functional role
        let rowBFunctionalRole = '';
        if (rowBFunctionalRolesArr) {
          for (const role of rowBFunctionalRolesArr) {
            // Skip if blank role
            if (role === '') continue;

            // Put & for multiple roles
            if (rowBFunctionalRole !== '') {
              rowBFunctionalRole += ' & ';
            }
            rowBFunctionalRole += role[0].toUpperCase() + role.slice(1);
          }
        }

        if (rowBFunctionalRole === '') rowBFunctionalRole = 'None';

        // console.log(
        //   'rowA and rowB functional role: ',
        //   rowAFunctionalRole,
        //   rowBFunctionalRole
        // );

        return rowAFunctionalRole.localeCompare(rowBFunctionalRole);
      },
      members: (rowA, rowB) => {
        // console.log('Members Sorting Fn: ', rowA, rowB, columnId);
        return (
          rowA.original?.circle?.users?.length -
          rowB.original?.circle?.users?.length
        );
      },
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    table.setPageSize(limit);
  }, [limit]);

  // console.log('TeamsScreen selectedTeam: ', selectedTeam);

  // list of all columns we can sort by
  const fieldsWithSorting = useMemo(
    () => [
      'circle_name',
      'role',
      'status',
      'circle_level.name',
      'members',
      'memberType',
      'memberRole',
      'circle_parent.name',
    ],
    []
  );

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    table.previousPage();
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    table.nextPage();
  };

  const handleSetPage = (i) => {
    setCurrentPage(i + 1);
    table.setPageIndex(i);
  };

  // Needed for when search/filter change resets currentPage to page 1
  useEffect(() => {
    // Conditional prevents page setting when there's no need for pagination
    if (data.length > limit) {
      table.setPageIndex(currentPage - 1);
    }
  }, [currentPage]);

  const handleMenuOpen = (event, team) => {
    setAnchorElMap((prev) => ({ ...prev, [team.id]: event.currentTarget }));
  };

  const handleMenuClose = (team) => {
    setAnchorElMap((prev) => ({ ...prev, [team.id]: null }));
  };

  async function addMembersToTeam(users) {
    if (!users.length) {
      return;
    }

    const requests = [];
    const storeUsers = [];

    let currentUserAddedToTeam = false;

    for (const user of users) {
      // Check if one of the users is the current user, if so the team will need to be added to teams in the currentUserStore
      if (user.id === global.me.id) {
        currentUserAddedToTeam = true;
      }

      // Create user object that matches User interface in current user store
      const storeUser = {
        colorCode: user.colorCode,
        id: user.id,
        imageName: user.imageName,
        isDeleted: user.isDeleted,
        name: user.name,
        status: user.status,
      };

      // Put storeUser in storeUsers to update current user store after loop
      storeUsers.push(storeUser);

      requests.push(
        API.graphql({
          query: mutations.modifyCircleMember,
          variables: {
            circleID: selectedTeam.id,
            personID: user.id,
            status: 'accepted',
            role: '',
          },
        })
      );
    }

    const allPromise = Promise.all(requests);

    allPromise
      .then(() => {
        // Update current user store once there are no errors after queries
        // If the current user was one of the new members added then add the team to the current user store's teams and add all members of currentSelectedTeam and the new members to the current user store's members
        // Otherwise can just add storeUsers to current user store's members
        if (currentUserAddedToTeam) {
          // Need all of selectedTeam's users to make users key on storeTeam
          const currentSelectedTeamStoreUsers = {};

          if (selectedTeam?.circle?.users?.items) {
            for (const item of selectedTeam.circle.users.items) {
              // NOTE: Will need to find a way to filter out removed AND deleted status in fetchMyCircles query
              if (item.user && item.user?.status !== 'deleted') {
                currentSelectedTeamStoreUsers[item.user.id] = {
                  colorCode: item.user.colorCode,
                  id: item.user.id,
                  imageName: item.user.imageName,
                  isDeleted: item.user.isDeleted,
                  name: item.user.name,
                  status: item.user.status,
                };
              }
            }
          }

          const normalizedStoreUsers = storeUsers.reduce((acc, storeUser) => {
            acc[storeUser.id] = storeUser;
            return acc;
          }, {});

          const totalUsersForStoreTeam = {
            ...currentSelectedTeamStoreUsers,
            ...normalizedStoreUsers,
          };

          const storeTeam = {
            colorCode: selectedTeam?.circle?.colorCode,
            id: selectedTeam?.circle?.id,
            imageName: selectedTeam?.circle?.imageName,
            isDeleted: selectedTeam?.circle?.isDeleted,
            name: selectedTeam?.circle?.name,
            status: selectedTeam?.circle?.status,
            users: {
              ...totalUsersForStoreTeam,
            },
          };

          addCurrentUserTeams([storeTeam]);
          // Take the values of totalUsersForStoreTeam as it is a normalized object of users
          addMembersToCurrentUserTeam(
            Object.values(totalUsersForStoreTeam),
            selectedTeam.circle.id
          );
        } else {
          addMembersToCurrentUserTeam(storeUsers, selectedTeam.circle.id);
        }

        // Don't need to refetch all teams when adding members to one team.
        // onTriggerRefetch();
      })
      .catch((error) => {
        console.error('addMembersToTeam error: ', error.message);
        GamifyToast.error(
          'There was a problem adding members. Please try again.'
        );
      });
  }

  const onSubmitMembers = async (newMembers) => {
    // console.log('onSubmitMembers newMembers: ', newMembers);
    // console.log('onSubmitMembers initialAcitveMembers: ', selectedTeam?.circle?.users);
    setIsMemberListLoading(true);
    await addMembersToTeam(newMembers);

    const numNewMembers = newMembers.length - selectedTeam?.users.length;

    setTimeout(() => {
      onTriggerMembersUpdateRefetch();
    }, 500);

    setTimeout(() => {
      if (numNewMembers === 0) {
        console.log('No new members added');
      } else if (numNewMembers === 1) {
        const newMember = newMembers[newMembers.length - 1]; // the last element of newMembers should always be the new member when there's only 1 new member
        GamifyToast.success(`${newMember.name} added successfully`);
      } else {
        GamifyToast.success(`${numNewMembers} members added successfully`);
      }
      setIsMemberListLoading(false);
    }, 3000); // set to 2500 from 750 to ensure enough time passes for the member update to be seen in the table
  };

  async function onDeleteTeams(teams = [], onComplete) {
    // console.log('onDeleteTeams teams: ', teams);
    if (!teams.length) return;

    const numDeletedTeams = teams.length;
    const deletedTeamIDsForStore = [];

    try {
      // const requests = [];
      for (const team of teams) {
        // console.log('onDeleteTeams team: ', team);
        const ourTeam = {
          id: team.id,
          isDeleted: true,
        };
        // requests.push(
        //   await API.graphql({
        //     query: mutations.updateCircle,
        //     variables: { input: ourTeam },
        //   })
        // );
        await API.graphql({
          query: mutations.updateCircle,
          variables: { input: ourTeam },
        });

        deletedTeamIDsForStore.push(team.circleID);
      }

      // Update current user store
      removeCurrentUserTeams(deletedTeamIDsForStore);
    } catch (err) {
      console.error('onDeleteTeams error: ', err);
      if (numDeletedTeams === 1) {
        GamifyToast.error(
          'There was a problem deleting your team. Please try again.'
        );
      } else {
        GamifyToast.error(
          'There was a problem deleting your teams. Please try again.'
        );
      }
      onComplete(false);
      return false;
    }

    onComplete(true);
    return true;
  }

  return (
    <>
      {selectedTeam?.id && (
        <TeamFormModal
          showTeamFormModal={showEditTeamFormModal}
          setShowTeamFormModal={setShowEditTeamFormModal}
          onTriggerTeamsRefetch={onTriggerRefetch}
          teamId={selectedTeam.id}
          zIndex={100000}
        ></TeamFormModal>
      )}
      {/* <DeleteTeamsModal
        showConfirmDelete={showConfirmDelete}
        setShowConfirmDelete={setShowConfirmDelete}
        onDeleteTeams={onDeleteTeams}
        teams={checkedTeams}
        isDeleteLoading={isDeleteLoading}
      ></DeleteTeamsModal> */}
      <DynamicConfirmModal
        showDynamicConfirmModal={showConfirmDelete}
        setShowDynamicConfirmModal={setShowConfirmDelete}
        zIndex={100002}
        title={
          isBulkDelete
            ? checkedTeams.length === 1
              ? `Delete ${checkedTeams[0].name}?`
              : `Delete ${checkedTeams.length} teams?`
            : `Delete ${selectedTeam?.name}?`
        }
        subtitle={`You won’t be able to revert this\naction.`}
        // subtitleColor={dynamicModalObj?.subtitleColor}
        // icon={dynamicModalObj?.icon}
        // iconBackground={dynamicModalObj?.iconBackground}
        // confirmButtonColor={'#FF5C77'}
        confirmButtonColor={'#E35050'}
        isSaving={isSaving}
        onCancel={
          isBulkDelete
            ? () => {
                setIsBulkDelete(false);
                setShowConfirmDelete(false);
              }
            : () => {
                setShowConfirmDelete(false);
              }
        }
        onConfirm={() => {
          setIsSaving(true);
          onDeleteTeams(isBulkDelete ? checkedTeams : [selectedTeam], (res) => {
            setIsSaving(false);
            setCurrentPage(1);
            if (res) {
              setShowConfirmDelete(false);

              setTimeout(() => {
                onTriggerRefetch();
              }, 750);

              GamifyToast.success(
                isBulkDelete
                  ? checkedTeams.length === 1
                    ? `${checkedTeams[0].name} deleted successfully`
                    : `${checkedTeams.length} teams deleted successfully`
                  : `${selectedTeam.name} deleted successfully`
              );

              if (isBulkDelete) {
                // Clear checkedTeams if a bulk team deletion was done
                setCheckedTeams([]);
              } else {
                // Otherwise, filter the one deleted selectedTeam from checkedTeams
                setCheckedTeams((prevCheckedTeams) =>
                  prevCheckedTeams.filter(
                    (checkedTeam) => checkedTeam.id !== selectedTeam.id
                  )
                );
              }
            }
          });
        }}
      ></DynamicConfirmModal>
      <TableContainer
        sx={{
          minHeight: data.length ? '50vh' : '0',
        }}
      >
        <MuiTable>
          <TableHead>
            {table?.getHeaderGroups()?.map((headerGroup) => (
              <UsersTableRow key={headerGroup.id} sx={{ borderBottom: 'none' }}>
                {headerGroup.headers?.map((header) => {
                  return (
                    <TitleTableCell
                      onClick={() => console.log(header.id)}
                      key={header.id}
                      colSpan={header.colSpan}
                      sx={{ borderBottom: 'none' }}
                    >
                      {header.isPlaceholder ? null : (
                        <Box
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            cursor: 'pointer',
                            borderBottom: 'none',
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {fieldsWithSorting.includes(header.id) && (
                            <img src="/images/sort-icon.svg" />
                          )}
                        </Box>
                      )}
                    </TitleTableCell>
                  );
                })}
                {/* NOTE: Like on users table, the column for the options button will always render until there's a solution for determining when there's at least one cell with the menu */}
                <TitleTableCell sx={{ borderBottom: 'none' }} />
              </UsersTableRow>
            ))}
            <Box style={{ height: '10px', width: '100%' }} />
          </TableHead>
          <TableBody>
            {table?.getRowModel().rows.map((row) => {
              const canClickTeam = hasTeamPermission(
                PermissionKeys.TeamsPeople_ViewTeamDetails,
                [row.original.circle?.id]
              );

              const showDelete = hasTeamPermission(
                PermissionKeys.TeamsPeople_DeleteTeam,
                [row.original.circle?.id]
              );
              const showEdit = hasTeamPermission(
                PermissionKeys.TeamsPeople_UpdateTeam,
                [row.original.circle?.id]
              );
              const showAddPerson = hasTeamPermission(
                PermissionKeys.TeamsPeople_AddPersonToTeam,
                [row.original.circle?.id]
              );

              // Show menu if either showEdit, showDelete, or showAddPerson is true
              const showMenu = showEdit || showAddPerson || showDelete;
              return (
                <>
                  <UsersTableRow
                    key={row.id}
                    sx={{ borderBottom: 'none' }}
                    onClick={() => console.log(row.original)}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <UsersTableCell
                          key={cell.id}
                          sx={{ borderBottom: 'none', padding: 0 }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </UsersTableCell>
                      );
                    })}
                    <UsersTableCell
                      sx={{
                        borderBottom: 'none',
                        cursor: canClickTeam ? 'pointer' : 'default',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedTeam(row.original.circle);
                        // Check if the click is not on the IconButton and if current user has permission to click team
                        if (
                          !e.target.closest('.MuiIconButton-root') &&
                          canClickTeam
                        ) {
                          navigate(row.original.circle.id);
                        }
                      }}
                    >
                      {showMenu && (
                        <>
                          <IconButton
                            style={{ position: 'relative', zIndex: 100 }}
                            color="white"
                            onClick={(event) =>
                              handleMenuOpen(event, row.original)
                            }
                          >
                            <MoreHoriz style={{ color: '#868686' }} />
                          </IconButton>
                          <CustomMenu
                            sx={{}}
                            anchorEl={anchorElMap[row.original.id]}
                            open={Boolean(anchorElMap[row.original.id])}
                            onClose={(e) => {
                              e.stopPropagation();
                              handleMenuClose(row.original);
                            }}
                          >
                            {showEdit && (
                              <CustomMenuItem
                                sx={{
                                  background: 'white',
                                  color: 'black',
                                  borderRadius: '4px 4px 0px 0px',
                                  paddingTop: '9px',
                                  paddingBottom: '9px',
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowEditTeamFormModal(true);
                                  handleMenuClose(row.original);

                                  // NOTE:
                                  // navigate(
                                  //   `/teams/edit/${row.original.circle.id}`
                                  // );
                                }}
                                disableRipple={true}
                              >
                                Edit
                              </CustomMenuItem>
                            )}
                            {showAddPerson && (
                              <CustomMenuItem
                                sx={{
                                  background: 'white',
                                  color: 'black',
                                  paddingTop: '9px',
                                  paddingBottom: '9px',
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowAddMemberModal(true);
                                  handleMenuClose(row.original);
                                }}
                                disableRipple={true}
                              >
                                Add members
                              </CustomMenuItem>
                            )}
                            {/* <CustomMenuItem
                                sx={{ background: 'white', color: 'black' }}
                                // onClick={() =>
                                //   onToggleStatus(
                                //     row.original,
                                //     row?.original.status === 'active'
                                //       ? 'disable'
                                //       : 'enable'
                                //   )
                                // }
                              >
                                {row?.original.status === 'active'
                                  ? 'Disable'
                                  : 'Enable'}
                              </CustomMenuItem> */}
                            {showDelete && (
                              <CustomMenuItem
                                sx={{
                                  background: 'white',
                                  color: 'red',
                                  borderRadius: '0px 0px 4px 4px',
                                  paddingTop: '9px',
                                  paddingBottom: '9px',
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // setCheckedTeams([selectedTeam]);
                                  setShowConfirmDelete(true);
                                  handleMenuClose(row.original);
                                }}
                                disableRipple={true}
                              >
                                Delete
                              </CustomMenuItem>
                            )}
                          </CustomMenu>
                        </>
                      )}
                    </UsersTableCell>
                  </UsersTableRow>
                  <Box style={{ height: '10px', width: '100%' }} />
                </>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {/* Only show pagination when there's more teams than teams per page */}
      {data.length > limit && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 2,
          }}
        >
          <NavigationButton
            onClick={handlePrevPage}
            disabled={!table.getCanPreviousPage()}
          >
            {/* <ChevronLeft style={{ fontSize: 30 }} /> */}
            <img
              src={
                table.getCanPreviousPage()
                  ? '/images/previous-page.svg'
                  : '/images/previous-page-disabled.svg'
              }
              style={{ height: '30px' }}
            />
          </NavigationButton>
          {Array.from({ length: totalPages }, (_, i) => (
            // <PageButton
            //   key={i}
            // isSelected={currentPage === i + 1}
            // onClick={() => handleSetPage(i)}
            // >
            //   {i + 1}
            // </PageButton>
            <Box
              key={i}
              // isSelected={currentPage === i + 1}
              onClick={() => handleSetPage(i)}
              className="hov"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
                margin: '0 10px',
                color: `${currentPage === i + 1 ? '#FF6A00' : 'gray'}`,
                border: `2px solid ${
                  currentPage === i + 1 ? '#FF6A00' : 'gray'
                }`,
                borderRadius: '2px',
                width: '40px',
                height: '40px',
              }}
            >
              {i + 1}
            </Box>
          ))}
          <NavigationButton
            onClick={handleNextPage}
            disabled={!table.getCanNextPage()}
          >
            {/* <ChevronRight style={{ fontSize: 30 }} /> */}
            <img
              src={
                table.getCanNextPage()
                  ? '/images/next-page.svg'
                  : '/images/next-page-disabled.svg'
              }
              style={{ height: '30px' }}
            />
          </NavigationButton>
        </Box>
      )}
      {/* While loading during search, there are 0 results. If loading, show animation. Otherwise display 'No results' */}
      {data.length === 0 && (
        <Box
          style={{
            backgroundColor: '#F0F0F3',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 72,
            borderRadius: 8,
          }}
        >
          {isLoading ? (
            <CircularProgress style={{ color: 'black' }}></CircularProgress>
          ) : (
            <Typography
              style={{ color: '#323232', fontSize: 16, fontWeight: 500 }}
            >
              No results
            </Typography>
          )}
        </Box>
      )}
      {showAddMemberModal && (
        <AddMemberLinkTeamModal
          title={'Add Members'}
          team={null} // not needed for adding members
          teamLevelNumIdMap={null} // not needed for adding members
          teamLevelNumNameMap={null} // not needed for adding members
          noAutoSelect={true}
          pickCircles={false}
          isLinkTeams={false}
          pickUsers={true}
          update={null}
          filter={global.me.orgID}
          itemValue={''}
          onSubmit={(newMembers) => onSubmitMembers(newMembers)}
          hideInitialChecked
          initialActiveMembers={selectedTeam?.users}
          setShowModal={setShowAddMemberModal}
          showModal={showAddMemberModal}
        ></AddMemberLinkTeamModal>
      )}
      {checkedTeams.length > 0 && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            height: 116,
            borderTop: '1px solid #D0D2D8',
            marginTop: '24px',
          }}
        >
          <Box
            onClick={() => {
              setIsBulkDelete(true);
              setShowConfirmDelete(true);
            }}
            style={{
              // backgroundColor: '#FF5C77',
              backgroundColor: '#E35050',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 228,
              height: 52,
              padding: '24px 16px 24px 16px',
              borderRadius: 8,
            }}
            sx={{ cursor: 'pointer' }}
          >
            <Typography
              style={{ color: 'white', fontSize: '16px', fontWeight: 700 }}
            >
              {checkedTeams.length === 1
                ? `Delete (${checkedTeams.length}) team`
                : `Delete (${checkedTeams.length}) teams`}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
