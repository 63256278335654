import { API, graphqlOperation } from 'aws-amplify';
import gql from 'graphql-tag';

interface User {
  id: string;
  colorCode: string;
  imageName: string;
  isDeleted: boolean;
  name: string;
  status: string;
}

interface TeamMember {
  user: User;
  isActive: boolean;
  isDeleted: boolean;
}

interface Team {
  id: string;
  name: string;
  isDeleted: boolean;
  isActive: boolean;
  colorCode: string;
  imageName: string;
  users: any;
}

interface CircleMemberByUserIDResponse {
  items: Array<{
    circle?: Team;
    isDeleted: boolean;
    isActive: boolean;
  }>;
  nextToken: string | null;
}

interface CircleMemberByCircleIDResponse {
  items: TeamMember[];
  nextToken: string | null;
}

interface GetTeamsResponse {
  data: {
    circleMemberByUserID: CircleMemberByUserIDResponse;
  };
}

interface GetTeamMembersResponse {
  data: {
    circleMemberByCircleID: CircleMemberByCircleIDResponse;
  };
}

// GraphQL Queries
const getCurrentUserTeamsWithMembersQuery = gql`
  query MyQuery($userID: ID!, $nextToken: String) {
    circleMemberByUserID(
      userID: $userID
      filter: {
        isDeleted: { eq: false }
        isActive: { eq: true }
        status: { ne: rejected }
        status: { ne: removed }
      }
      nextToken: $nextToken
    ) {
      items {
        circle {
          colorCode
          id
          imageName
          isDeleted
          isActive
          name
          users(
            filter: {
              isActive: { eq: true }
              isDeleted: { eq: false }
              status: { ne: rejected }
              status: { ne: removed }
            }
          ) {
            items {
              id
              isActive
              isDeleted
              user {
                colorCode
                id
                imageName
                isDeleted
                name
                status
              }
            }
            nextToken
          }
        }
        isDeleted
        isActive
      }
      nextToken
    }
  }
`;

const getTeamMembersQuery = gql`
  query MyQuery($circleID: ID!, $nextToken: String) {
    circleMemberByCircleID(
      circleID: $circleID
      filter: {
        isActive: { eq: true }
        isDeleted: { eq: false }
        status: { ne: rejected }
        status: { ne: removed }
      }
      nextToken: $nextToken
    ) {
      items {
        id
        isActive
        isDeleted
        user {
          colorCode
          id
          imageName
          isDeleted
          name
          status
        }
      }
      nextToken
    }
  }
`;

const fetchCurrentUserTeamsAndMembers = async (): Promise<{
  teams: { [key: string]: Team };
  members: { [key: string]: User };
} | null> => {
  try {
    let teams: any[] = [];
    let allCurrentUserMembers: { [key: string]: User } = {};
    let nextToken: string | null = null;

    do {
      // LOG.debug("Current User Store fetch global.me.id: ", global.me.id);
      const getTeamsRes = (await API.graphql(
        graphqlOperation(getCurrentUserTeamsWithMembersQuery, {
          // @ts-ignore
          userID: global.me.id,
          nextToken,
        })
      )) as GetTeamsResponse;

      // LOG.debug("FETCH TEST getTeamsRes: ", getTeamsRes);

      const teamsItems = getTeamsRes.data?.circleMemberByUserID?.items ?? [];

      // LOG.debug("FETCH TEST teamsItems: ", teamsItems);
      // LOG.debug("FETCH TEST teamsItems len: ", teamsItems.length);

      for (let i = 0; i < teamsItems.length; i++) {
        const team = teamsItems[i].circle;

        // Skip processing the "all" team (global.me.orgID), a deleted team or a not active team but still add them to teamsItems for filtering out later
        if (team) {
          // @ts-ignore
          if (team.id === global.me.orgID || team.isDeleted || !team.isActive) {
            teamsItems[i] = team;
            continue;
          }

          let members = team.users?.items ?? [];

          // Check if the members have a nextToken, need a new query to get all members for that team if so
          if (team.users?.nextToken) {
            let membersNextToken: string | null = team.users.nextToken;
            let allMembers: TeamMember[] = [...members];

            // Fetch all members until there is no more nextToken
            do {
              const getTeamMembersRes = (await API.graphql(
                graphqlOperation(getTeamMembersQuery, {
                  circleID: team.id,
                  nextToken: membersNextToken,
                })
              )) as GetTeamMembersResponse;

              const membersRes =
                getTeamMembersRes.data.circleMemberByCircleID?.items ?? [];
              allMembers = allMembers.concat(membersRes);
              membersNextToken =
                getTeamMembersRes.data.circleMemberByCircleID?.nextToken;
            } while (membersNextToken);

            members = allMembers;
          }

          // Once all members are fetched, filter out null, inactive, and deleted members
          for (let j = 0; j < members.length; j++) {
            const user = members[j].user;

            // Set current index to user to get rid of intermediate "user" key
            members[j] = user;
          }

          // Filter out nulls
          const nonNullMembers = members.filter((member: User) => member);

          const filteredMembers = nonNullMembers.filter(
            (member: User) =>
              !member.isDeleted &&
              member.status !== 'deleted' &&
              member.status !== 'disabled' &&
              member.name !== '**deleted**'
          );

          filteredMembers.sort((a: any, b: any) => a.name - b.name);

          const normalizedMembers = filteredMembers.reduce(
            (acc: { [key: string]: User }, member: User) => {
              acc[member.id] = member;
              return acc;
            },
            {}
          );

          // Spread the members into allCurrentUserMembers
          allCurrentUserMembers = {
            ...allCurrentUserMembers,
            ...normalizedMembers,
          };

          // Update the team with processed members
          team.users = normalizedMembers;

          teamsItems[i] = team;
        }
      }

      teams = teams.concat(teamsItems);

      nextToken = getTeamsRes.data?.circleMemberByUserID?.nextToken;
    } while (nextToken);

    // Filter out deleted or inactive teams and the "all" team and sort them
    const filteredTeams = teams.filter(
      // @ts-ignore
      (team) => !team.isDeleted && team.isActive && team.id !== global.me.orgID
    );
    filteredTeams.sort((a, b) => a.name - b.name);

    // Normalize the team list
    const normalizedTeams = filteredTeams.reduce(
      (acc: { [key: string]: Team }, team) => {
        acc[team.id] = team;
        return acc;
      },
      {}
    );

    return { teams: normalizedTeams, members: allCurrentUserMembers };
  } catch (err) {
    console.error('Error fetching teams and members:', err);
    return null;
  }
};

export default fetchCurrentUserTeamsAndMembers;
