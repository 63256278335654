import {
  Typography,
  Box,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React, { useState, useCallback } from 'react';
import { StyledTextField } from './formComponents/StyledTextField';

import '../../settingsStyles.css';

import Territory from './Territory';
import { useRolesPermissionsContext } from '../../context/RolesAndPermissionsContext';
import Sales from './Sales';
import Scheduling from './Scheduling';
import GamesRewards from './GamesRewards';
import TeamsPeople from './TeamsPeople';
import Other from './Other';
import { useSettingsContext } from '../../context/SettingsContext';
import { GamifyIcon } from '../../../../components/GamifyIcon';
import RoleForm from './RoleForm';
import {
  StyledButton,
  StyledOutlinedButton,
} from './formComponents/styledComponents';
// import updateRoleName from '../../utils/updateRoleName';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface DealFormPropsState {
  duplicateID: string;
  isOpen: boolean;
}

function RolesAndPermissions() {
  const {
    roles,
    setSelectedRole,
    selectedRole,
    saveRoleChanges,
    updateRoleName,
    restoreRole,
    createRole,
  } = useRolesPermissionsContext();
  const { hasUnsavedChanges } = useSettingsContext();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [editRoleName, setEditRoleName] = useState(false);
  const [dealFormProps, setDealFormProps] = useState<DealFormPropsState>({
    duplicateID: '',
    isOpen: false,
  });

  const handleCategoryChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setSelectedCategory(newValue);
    },
    []
  );

  const handleSubmit = () => {
    console.log('submit');
    setEditRoleName(false);
    saveRoleChanges();
  };

  const handleCreateRole = async (role: any) => {
    createRole(role.roleName, role.cloneID);
  };

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
      </div>
    );
  };

  console.log('rendered!!!');
  if (!selectedRole || !roles) return <></>;
  return (
    <Box style={{ display: 'flex' }}>
      <List sx={{ p: 0, m: 0, pr: 2 }} style={{ width: 200 }}>
        <Typography
          // @ts-ignore
          onClick={() => console.log(global.me.id)}
          variant="h6"
          sx={{ pt: 3, pb: 4, pl: 2, fontWeight: 780 }}
        >
          Roles
        </Typography>
        {roles.map((role) => (
          <ListItem key={role.id} disablePadding disableGutters>
            <ListItemButton
              onClick={() => setSelectedRole(role)}
              selected={selectedRole?.id === role.id}
              sx={{ gap: 1 }}
            >
              <GamifyIcon icon={role.iconName} color="#878787" />
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: selectedRole?.id === role.id ? 500 : 'normal',
                  sx: {
                    overflow: 'hidden', // Prevent text from overflowing
                    textOverflow: 'ellipsis', // Add ellipsis when text overflows
                    whiteSpace: 'nowrap', // Prevent wrapping
                    display: 'block', // Ensure block-level formatting for the text
                  },
                }}
                primary={role.roleName}
              />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding disableGutters>
          <ListItemButton
            onClick={() => setDealFormProps({ duplicateID: '', isOpen: true })}
            sx={{ gap: 1 }}
          >
            <GamifyIcon icon="add" backgroundColor="#FF6A00" color="white" />
            <ListItemText style={{ color: '#FF6A00' }} primary="Add new role" />
          </ListItemButton>
        </ListItem>
      </List>
      <Box className="permissions-console-container">
        <Box
          sx={{ px: 4, py: 3 }}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            {editRoleName ? (
              <StyledTextField
                key="roleTextField"
                value={selectedRole?.roleName}
                onChange={(e) =>
                  updateRoleName(selectedRole.id, e.target.value)
                }
                // value={currentRoleName}
                // onChange={(e) => setCurrentRoleName(e.target.value)}
              />
            ) : (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 10,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 780,
                    fontSize: 20,
                  }}
                >
                  {selectedRole?.roleName}
                </Typography>

                {!selectedRole.isSuper && (
                  <Box
                    onClick={() => setEditRoleName(true)}
                    style={{ justifyContent: 'center' }}
                  >
                    <GamifyIcon icon="edit" color={'#FF6A00'} />
                  </Box>
                )}
              </Box>
            )}
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 15,
                color: '#7C7C7C',
              }}
            >
              {`Features, actions and access for ${selectedRole?.roleName}'s`}
            </Typography>
          </Box>
          {!selectedRole.isSuper && (
            <Box style={{ gap: 15, display: 'flex' }}>
              {editRoleName ? (
                <StyledOutlinedButton
                  onClick={() => {
                    setEditRoleName(false);
                    restoreRole(selectedRole.id, true);
                  }}
                  variant="outlined"
                >
                  Cancel
                </StyledOutlinedButton>
              ) : (
                <StyledOutlinedButton
                  onClick={() =>
                    setDealFormProps({
                      duplicateID: selectedRole.id,
                      isOpen: true,
                    })
                  }
                  variant="outlined"
                >
                  Duplicate role
                </StyledOutlinedButton>
              )}
              <StyledButton
                onClick={handleSubmit}
                variant="contained"
                disabled={!hasUnsavedChanges || !selectedRole.roleName.length}
              >
                Save changes
              </StyledButton>
            </Box>
          )}
        </Box>

        <Box className="permission-tabs-container">
          <Tabs
            value={selectedCategory}
            onChange={handleCategoryChange}
            aria-label="nav"
            className="custom-tabs"
          >
            <Tab value={0} className="custom-tab" label="Territory" />
            <Tab value={1} className="custom-tab" label="Sales" />
            <Tab value={2} className="custom-tab" label="Scheduling" />
            <Tab value={3} className="custom-tab" label="Games &amp; Rewards" />
            <Tab value={4} className="custom-tab" label="Teams &amp; People" />
            <Tab value={5} className="custom-tab" label="Other" />
          </Tabs>
        </Box>
        <Box>
          <CustomTabPanel value={selectedCategory} index={0}>
            <Territory selectedRole={selectedRole} />
          </CustomTabPanel>
          <CustomTabPanel value={selectedCategory} index={1}>
            <Sales selectedRole={selectedRole} />
          </CustomTabPanel>
          <CustomTabPanel value={selectedCategory} index={2}>
            <Scheduling selectedRole={selectedRole} />
          </CustomTabPanel>
          <CustomTabPanel value={selectedCategory} index={3}>
            <GamesRewards selectedRole={selectedRole} />
          </CustomTabPanel>
          <CustomTabPanel value={selectedCategory} index={4}>
            <TeamsPeople selectedRole={selectedRole} />
          </CustomTabPanel>
          <CustomTabPanel value={selectedCategory} index={5}>
            <Other selectedRole={selectedRole} />
          </CustomTabPanel>
        </Box>
        <RoleForm
          isOpen={dealFormProps.isOpen}
          duplicateID={dealFormProps.duplicateID}
          onClose={() => setDealFormProps({ duplicateID: '', isOpen: false })}
          onSubmit={handleCreateRole}
        />
      </Box>
    </Box>
  );
}

export default RolesAndPermissions;
