import React from 'react';
import UserProfilePicture from '../../../components/UserProfilePicture';
import Item from '../_types/Item';
import {
  ProfilePictureAndVerticalLineContainer,
  RootContainer,
  VerticalLine,
  ViewContainer,
} from './FeedItem.styles';
import Header from './contents/Header';
import Details from './contents/Details';
import Media from './contents/Media';
import Actions from './contents/Actions';
import { useNavigate } from 'react-router';
import Comment from '../Comment';
import EditDeleteEllipseFeedItem from './contents/EditDeleteEllipseFeedItem';
// import Me from '../../../_types/Me';
import MyPerson from '../_types/MyPerson';
import { usePermissions } from '../../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../../context/PermissionsContext/permissionTypes';

interface Props {
  onDeleteFeedItem: (deletedFeedItem: Item) => void;
  feedItem: Item;
  // me: Me;
  myPersons: MyPerson[];
  isEditing: boolean;
  onChangeIsEditing: (newIsEditing: boolean) => void;
}

const FeedItemView = ({
  feedItem,
  onDeleteFeedItem,
  // me,
  myPersons,
  isEditing,
  onChangeIsEditing,
}: Props) => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const canEditPost = hasPermission(PermissionKeys.Other_EditComments, [
    feedItem.userID,
  ]);
  const canDeletePost = hasPermission(PermissionKeys.Other_RemoveComments, [
    feedItem.userID,
  ]);
  const showMenu = canEditPost || canDeletePost;

  return (
    <RootContainer>
      <ViewContainer>
        <ProfilePictureAndVerticalLineContainer>
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/profile/${feedItem.activityFeed.createdByUser?.id}`);
            }}
          >
            <UserProfilePicture
              imageName={feedItem.activityFeed.createdByUser?.imageName || ''}
            />
          </div>
          {feedItem.activityFeed.comments.items.length === 1 ? (
            <VerticalLine />
          ) : null}
        </ProfilePictureAndVerticalLineContainer>
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/profile/${feedItem.activityFeed.createdByUser?.id}`);
              }}
            >
              <Header
                shortName={feedItem.name}
                fullName={feedItem.user.name}
                createdAt={feedItem.createdAt}
              />
            </div>
            {showMenu && (
              <EditDeleteEllipseFeedItem
                isEditing={isEditing}
                onChangeIsEditing={onChangeIsEditing}
                onDeleteFeedItem={onDeleteFeedItem}
                // me={me}
                feedItem={feedItem}
              />
            )}
          </div>
          <div
            onClick={() => {
              navigate('/feed/comments', {
                state: {
                  activityFeedID: feedItem.activityFeed.id,
                  comments: feedItem.activityFeed.comments.items,
                  title: `Comments on ${feedItem.name}'s post`,
                  selectedComment:
                    feedItem.activityFeed.comments.items.length === 1
                      ? feedItem.activityFeed.comments.items[0]
                      : null,
                },
              });
            }}
          >
            <Details
              myPersons={myPersons}
              details={feedItem.activityFeed.header}
            />
            <Media
              imageName={feedItem.activityFeed.imageName}
              imageType={feedItem.activityFeed.imageType}
            />
          </div>
          <Actions
            totalComment={feedItem.activityFeed.comments.items.length}
            onNavigateToComments={() => {
              navigate('/feed/comments', {
                state: {
                  activityFeedID: feedItem.activityFeed.id,
                  comments: feedItem.activityFeed.comments.items,
                  title: `Comments on ${feedItem.name}'s post`,
                  selectedComment:
                    feedItem.activityFeed.comments.items.length === 1
                      ? feedItem.activityFeed.comments.items[0]
                      : null,
                },
              });
            }}
            emoticonList={feedItem.activityFeed.emoticons.items}
            activityFeedID={feedItem.activityFeed.id}
          />
        </div>
      </ViewContainer>
      {feedItem.activityFeed.comments.items.length === 1 ? (
        // @ts-ignore
        <Comment
          myPersons={myPersons}
          commentId={feedItem.activityFeed.comments.items[0].id}
        />
      ) : null}
    </RootContainer>
  );
};

export default FeedItemView;
