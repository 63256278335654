import React from 'react';
import { ViewContainer } from './Actions.styles';
import EmoticonsAction from './EmoticonsAction';
import CommentsAction from './CommentsAction';
import Emoticon from '../../../_types/Emoticon';
import { usePermissions } from '../../../../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../../../../context/PermissionsContext/permissionTypes';

interface Props {
  activityFeedID: string;
  emoticonList: Emoticon[];
  totalComment: number;
  onNavigateToComments: () => void;
  commentID?: string;
}

const ActionsView = ({
  emoticonList,
  activityFeedID,
  totalComment,
  onNavigateToComments,
  commentID,
}: Props) => {
  const { checkPermission } = usePermissions();
  const canComment = checkPermission(PermissionKeys.Other_CreateComments);
  return (
    <ViewContainer>
      {canComment && (
        <CommentsAction
          onNavigateToComments={onNavigateToComments}
          totalComment={totalComment}
        />
      )}
      <EmoticonsAction
        emoticonList={emoticonList}
        activityFeedID={activityFeedID}
        commentID={commentID}
      />
    </ViewContainer>
  );
};

export default ActionsView;
