import React from 'react';
import { Box, Typography } from '@mui/material';
import PermissionSelect from './PermissionSelect';
import { LockIcon } from '../../../../../components/GamifyIcon';
import { permissionValue } from '../RoleAndPermissions.types';
import { useRolesPermissionsContext } from '../../../context/RolesAndPermissionsContext';

interface PermissionWithSelectProps {
  label: string;
  disabled?: boolean;
  value: permissionValue;
  locked?: boolean;
  onUpdate: (value: permissionValue) => void;
  defaultValue?: permissionValue;
  excludeOptions?: permissionValue[];
}

const PermissionWithSelect = React.memo(
  ({
    label,
    value,
    disabled,
    onUpdate,
    defaultValue,
    excludeOptions,
  }: PermissionWithSelectProps) => {
    const { selectedRole } = useRolesPermissionsContext();

    const lockInput = selectedRole?.isSuper || disabled;

    return (
      <Box
        sx={{ p: 0, m: 0 }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ color: '#101010', fontWeight: 500 }}>
          {label}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <PermissionSelect
            locked={lockInput}
            value={value}
            onChange={onUpdate}
            defaultValue={defaultValue}
            excludeOptions={excludeOptions}
          />
          {lockInput && <LockIcon />}
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => prevProps.value === nextProps.value
);
PermissionWithSelect.displayName = 'PermissionWithSelect';
export default PermissionWithSelect;
