import React from 'react';
import { styled, TextField } from '@mui/material';

export const StyledTextField = React.memo(
  styled(TextField)({
    '&:hover': {
      cursor: 'pointer',
    },
    '& fieldset': {
      // fontWeight: 780,
      // fontSize: 20,
      // half opactiyred backgroudn
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      padding: '0px',
      margin: '0px',
      border: '0px',
      borderRadius: '2px !important',
    },
    '& .MuiOutlinedInput-input': {
      marginLeft: '14px',
      fontWeight: 780,
      fontSize: '20px',
      color: 'black',
      // Padding: '10px',
      // marging: '0px',
    },
    '& .MuiOutlinedInput-root.Mui-disabled fieldset': {
      fontSize: '20px',
      color: 'black !important',
    },
  })
);
