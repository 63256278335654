import React, { useState } from 'react';
// import Me from '../../../../_types/Me';
import Item from '../../_types/Item';
import { usePermissions } from '../../../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../../../context/PermissionsContext/permissionTypes';

interface Props {
  // me: Me;
  feedItem: Item;
  onDeleteFeedItem: (deletedFeedItem: Item) => void;
  isEditing: boolean;
  onChangeIsEditing: (newIsEditing: boolean) => void;
}

const EditDeleteEllipseFeedItem = ({
  // me,
  feedItem,
  onDeleteFeedItem,
  onChangeIsEditing,
}: Props) => {
  const [isEllipseClick, setIsEllipseClick] = useState(false);
  const { hasPermission } = usePermissions();
  const canEditPost = hasPermission(PermissionKeys.Other_EditComments, [
    feedItem.userID,
  ]);
  const canDeletePost = hasPermission(PermissionKeys.Other_RemoveComments, [
    feedItem.userID,
  ]);

  // NOTE: Check is no longer needed with new permissions
  // if (!me.isAdmin || feedItem.activityFeed.createdBy !== me.id) {
  //   return null;
  // }

  return (
    <div style={{ position: 'relative' }}>
      <img
        onClick={(e) => {
          e.stopPropagation();

          setIsEllipseClick(!isEllipseClick);
        }}
        src="/images/three-dots.svg"
        width={30}
        height={30}
        style={{ cursor: 'pointer' }}
      />
      {isEllipseClick ? (
        <div
          style={{
            position: 'absolute',
            backgroundColor: '#f5f5f5',
            padding: '10px 20px',
            borderRadius: 10,
            right: 0,
            top: 28,
          }}
        >
          {canEditPost && (
            <div
              style={{
                fontSize: 14,
                fontWeight: 500,
                cursor: 'pointer',
                marginBottom: 10,
              }}
              onClick={(e) => {
                e.stopPropagation();
                onChangeIsEditing(true);
              }}
            >
              Edit
            </div>
          )}
          {canDeletePost && (
            <div
              style={{
                fontSize: 14,
                color: '#FF5C77',
                fontWeight: 500,
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteFeedItem(feedItem);
                setIsEllipseClick(!isEllipseClick);
              }}
            >
              Remove
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default EditDeleteEllipseFeedItem;
