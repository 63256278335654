export interface Role {
  id: string;
  iconName: string;
  isSuper: boolean;
  roleName: string;
  permissions: Record<string, any>; // You can replace `any` with a more specific type for permissions if needed
}

export enum PermissionCategory {
  Territory = 'territory',
  Sales = 'sales',
  GamesRewards = 'gamesRewards',
  Other = 'other',
  Scheduling = 'scheduling',
  TeamsPeople = 'teamsPeople',
}

export type permissionValue = 'none' | 'onlyMe' | 'myTeam' | 'everyone';

export interface PermissionCategoryProps {
  selectedRole: Role | null;
}

enum TerritoryPermissions {
  Territory_CreateArea = 'createArea',
  Territory_UpdateArea = 'updateArea',
  Territory_DeleteArea = 'deleteArea',
  Territory_ViewArea = 'viewArea',
  Territory_DropPin = 'dropPin',
  Territory_EditPin = 'editPin',
  Territory_DeletePin = 'deletePin',
  Territory_ViewPin = 'viewPin',
  Territory_ExportAnyData = 'exportAnyData',
  Territory_CreateKPIs = 'createKPIs',
  Territory_CreateDealStages = 'createDealStages',
  Territory_CreateDispositions = 'createDispositions',
}

enum SalesPermissions {
  Sales_CreateDeal = 'createDeal',
  Sales_UpdateDeal = 'updateDeal',
  Sales_DeleteDeal = 'deleteDeal',
  Sales_ViewDeal = 'viewDeal',
  Sales_ReassignDeal = 'reassignDeal',
  Sales_SetDealStage = 'setDealStage',
  Sales_ImportLeads = 'importLeads',
  Sales_ExportLeads = 'exportLeads',
  Sales_CreateForms = 'createForms',
  Sales_ViewLeads = 'viewLeads',
}

enum SchedulingPermissions {
  Scheduling_ViewAppointments = 'viewAppointments',
  Scheduling_BookAppointment = 'bookAppointment',
  Scheduling_MaintainWorkHours = 'maintainWorkHours',
  Scheduling_ReassignAppointment = 'reassignAppointment',
  Scheduling_ManageRoundRobin = 'manageRoundRobin',
  Scheduling_CompareCalendarAvailability = 'compareCalendarAvailability',
  Scheduling_AddUnavailableTimesToCalendar = 'addUnavailableTimesToCalendar',
  Scheduling_ExportAppointments = 'exportAppointments',
}

enum GamesRewardsPermissions {
  GamesRewards_CreateGame = 'createGame',
  GamesRewards_UpdateGame = 'updateGame',
  GamesRewards_DeleteGame = 'deleteGame',
  GamesRewards_ViewGame = 'viewGame',
  GamesRewards_ManagePoints = 'managePoints',
  GamesRewards_ViewLeaderboards = 'viewLeaderboards',
  GamesRewards_CreateCustomLeaderboardViews = 'createCustomLeaderboardViews',
  GamesRewards_ExportReports = 'exportReports',
  GamesRewards_CreateReward = 'createReward',
  GamesRewards_UpdateReward = 'updateReward',
  GamesRewards_DeleteReward = 'deleteReward',
  GamesRewards_ViewReward = 'viewReward',
  GamesRewards_ManageCardAccount = 'manageCardAccount',
  GamesRewards_RemoveCardsGiftCardAccounts = 'removeCardsGiftCardAccounts',
  GamesRewards_AssignGiftCardAccountToTeams = 'assignGiftCardAccountToTeams',
  GamesRewards_AssignGiftCardToSpecificRewards = 'assignGiftCardToSpecificRewards',
  GamesRewards_ExportRewardsReport = 'exportRewardsReport',
}

enum TeamsPeoplePermissions {
  TeamsPeople_CreateUser = 'createUser',
  TeamsPeople_UpdateUser = 'updateUser',
  TeamsPeople_DeleteUser = 'deleteUser',
  TeamsPeople_ViewUser = 'viewUser',
  TeamsPeople_ViewUserStatCard = 'viewUserStatCard',
  TeamsPeople_ChangePersonsRole = 'changePersonsRole',
  TeamsPeople_ChangePersonsStatus = 'changePersonsStatus',
  TeamsPeople_ExportUserList = 'exportUserList',
  TeamsPeople_ImportUsers = 'importUsers',
  TeamsPeople_CreateTeam = 'createTeam',
  TeamsPeople_UpdateTeam = 'updateTeam',
  TeamsPeople_DeleteTeam = 'deleteTeam',
  TeamsPeople_AddPersonToTeam = 'addPersonToTeam',
  TeamsPeople_RemoveSomeoneFromTeam = 'removeSomeoneFromTeam',
  TeamsPeople_ViewTeamDetails = 'viewTeamDetails',
  TeamsPeople_UpdateManualKPIs = 'updateManualKPIs',
  TeamsPeople_ExportTeamList = 'exportTeamList',
  TeamsPeople_ImportTeamList = 'importTeamList',
}

enum OtherPermissions {
  Other_CreatePosts = 'createPosts',
  Other_EditPosts = 'editPosts',
  Other_RemovePosts = 'removePosts',
  Other_CreateComments = 'createComments',
  Other_EditComments = 'editComments',
  Other_RemoveComments = 'removeComments',
  Other_ManageTrainingVideos = 'manageTrainingVideos',
  Other_ViewTrainingStatus = 'viewTrainingStatus',
  Other_EditPermissions = 'editPermissions',
  Other_EditHierarchy = 'editHierarchy',
  Other_ChangeAccountSettings = 'changeAccountSettings',
}

export const PermissionKeys = {
  ...TerritoryPermissions,
  ...SalesPermissions,
  ...SchedulingPermissions,
  ...GamesRewardsPermissions,
  ...TeamsPeoplePermissions,
  ...OtherPermissions,
} as const;

export type PermissionKeys =
  (typeof PermissionKeys)[keyof typeof PermissionKeys];

// export enum PermissionKeys {
//   // TerritoryPermissions
//   Territory_CreateArea = 'createArea', // in -
//   Territory_UpdateArea = 'updateArea', // in -
//   Territory_DeleteArea = 'deleteArea', // in -
//   Territory_ViewArea = 'viewArea', // needs backend updates

//   Territory_DropPin = 'dropPin', // in - limited permission
//   Territory_EditPin = 'editPin', // onlyMe - everyone
//   Territory_DeletePin = 'deletePin', // onlyMe - everyone
//   Territory_ViewPin = 'viewPin',
//   Territory_ExportAnyData = 'exportAnyData',
//   Territory_CreateKPIs = 'createKPIs',
//   Territory_CreateDealStages = 'createDealStages',
//   Territory_CreateDispositions = 'createDispositions',

//   // SalesPermissions
//   Sales_CreateDeal = 'createDeal', // Boolean in
//   Sales_UpdateDeal = 'updateDeal',
//   Sales_DeleteDeal = 'deleteDeal', // in
//   Sales_ViewDeal = 'viewDeal', // in
//   Sales_ReassignDeal = 'reassignDeal',
//   Sales_SetDealStage = 'setDealStage',
//   Sales_ImportLeads = 'importLeads',
//   Sales_ExportLeads = 'exportLeads',
//   Sales_CreateForms = 'createForms',
//   Sales_ViewLeads = 'viewLeads',

//   // SchedulingPermissions
//   Scheduling_ViewAppointments = 'viewAppointments', // in
//   Scheduling_BookAppointment = 'bookAppointment', // in Boolean
//   Scheduling_MaintainWorkHours = 'maintainWorkHours', // in
//   Scheduling_ReassignAppointment = 'reassignAppointment', // in
//   Scheduling_ManageRoundRobin = 'manageRoundRobin', // in
//   Scheduling_CompareCalendarAvailability = 'compareCalendarAvailability', // in myTeam or everyone
//   Scheduling_AddUnavailableTimesToCalendar = 'addUnavailableTimesToCalendar', // in
//   Scheduling_ExportAppointments = 'exportAppointments',

//   // GamesRewardsPermissions
//   GamesRewards_CreateGame = 'createGame', // in -- boolean for now
//   GamesRewards_UpdateGame = 'updateGame', // in -- boolean for now
//   GamesRewards_DeleteGame = 'deleteGame', // in -- boolean for now
//   GamesRewards_ViewGame = 'viewGame', // in
//   GamesRewards_ManagePoints = 'managePoints', // in
//   GamesRewards_ViewLeaderboards = 'viewLeaderboards',
//   GamesRewards_CreateCustomLeaderboardViews = 'createCustomLeaderboardViews', // in boolean for now
//   GamesRewards_ExportReports = 'exportReports',
//   GamesRewards_CreateReward = 'createReward', // in -- boolean for now
//   GamesRewards_UpdateReward = 'updateReward', // in -- boolean for now
//   GamesRewards_DeleteReward = 'deleteReward', // in -- boolean for now
//   GamesRewards_ViewReward = 'viewReward',
//   GamesRewards_ManageCardAccount = 'manageCardAccount', // in -- boolean for now
//   GamesRewards_RemoveCardsGiftCardAccounts = 'removeCardsGiftCardAccounts', // in -- boolean for now
//   GamesRewards_AssignGiftCardAccountToTeams = 'assignGiftCardAccountToTeams',
//   GamesRewards_AssignGiftCardToSpecificRewards = 'assignGiftCardToSpecificRewards',
//   GamesRewards_ExportRewardsReport = 'exportRewardsReport',

//   // TeamsPeoplePermissions
//   TeamsPeople_CreateUser = 'createUser', // in boolean
//   TeamsPeople_UpdateUser = 'updateUser', // in
//   TeamsPeople_DeleteUser = 'deleteUser', // in
//   TeamsPeople_ViewUser = 'viewUser', // in
//   TeamsPeople_ViewUserStatCard = 'viewUserStatCard', // in
//   TeamsPeople_ChangePersonsRole = 'changePersonsRole', // in boolean
//   TeamsPeople_ChangePersonsStatus = 'changePersonsStatus', // in boolean
//   TeamsPeople_ExportUserList = 'exportUserList',
//   TeamsPeople_ImportUsers = 'importUsers',
//   TeamsPeople_CreateTeam = 'createTeam', // in boolean
//   TeamsPeople_UpdateTeam = 'updateTeam', // in
//   TeamsPeople_DeleteTeam = 'deleteTeam', // in
//   TeamsPeople_AddPersonToTeam = 'addPersonToTeam', // in [myTeam, everyone]
//   TeamsPeople_RemoveSomeoneFromTeam = 'removeSomeoneFromTeam', // in [myTeam, everyone]
//   TeamsPeople_ViewTeamDetails = 'viewTeamDetails', // in [myTeam, everyone]
//   TeamsPeople_UpdateManualKPIs = 'updateManualKPIs', // in [myTeam,everyone]
//   // TeamsPeople_ChangeUserStatus = 'changeUserStatus', // in [myTeam,everyone]
//   TeamsPeople_ExportTeamList = 'exportTeamList',
//   TeamsPeople_ImportTeamList = 'importTeamList',

//   // OtherPermissions
//   Other_CreatePosts = 'createPosts', // in [onlyMe,myTeam, everyone] onlyMe means user can only make a post for one person
//   Other_EditPosts = 'editPosts', // in
//   Other_RemovePosts = 'removePosts', // in
//   Other_CreateComments = 'createComments', // in boolean
//   Other_EditComments = 'editComments', // in [onlyMe,everyone]
//   Other_RemoveComments = 'removeComments', // in [onlyMe,everyone]
//   Other_ManageTrainingVideos = 'manageTrainingVideos', // in boolean
//   Other_ViewTrainingStatus = 'viewTrainingStatus', // in boolean
//   Other_EditPermissions = 'editPermissions',
//   Other_EditHierarchy = 'editHierarchy',
//   Other_ChangeAccountSettings = 'changeAccountSettings',
// }
