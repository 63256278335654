import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { SideBarTab } from './PerformanceSideBar.types';
import { GamifyIcon } from '../../components/GamifyIcon';
import { Outlet, useNavigate, useLocation } from 'react-router';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';

export default function PerformanceSideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    '/performance/leaderboard'
  );

  // NOTE: Until games are rebuilt, just make sure viewGames isn't 'none' before putting that tab into SideBarTabs
  const { checkPermission } = usePermissions();
  const viewGamesPermission = checkPermission(
    PermissionKeys.GamesRewards_ViewGame
  );

  // Need to navigate to leaderboard on initial click on the 'Performance' tab
  // Also need to set active tab if routing back to a tab in the side bar that isn't the leaderboard
  useEffect(() => {
    if (location.pathname === '/performance') {
      navigate('/performance/leaderboard');
    }

    // Navigate back to leaderboard if user doesn't have permission to view games
    if (
      location.pathname === '/performance/games' &&
      viewGamesPermission === 'none'
    ) {
      navigate('/performance/leaderboard');
    }

    if (
      location.pathname === '/performance/games' &&
      viewGamesPermission !== 'none'
    ) {
      setActiveTab('/performance/games');
    }

    if (location.pathname === '/performance/rewards') {
      setActiveTab('/performance/rewards');
    }

    if (location.pathname === '/performance/reports') {
      setActiveTab('/performance/reports');
    }
  }, [location]);

  const handleNavigate = (path: string) => {
    setActiveTab(path);
    navigate(path);
  };

  const sideBarTabs: SideBarTab[] = [
    {
      label: 'Leaderboard',
      val: 'leaderboard',
      icon: 'user',
      path: '/performance/leaderboard',
    },
  ];

  if (viewGamesPermission !== 'none') {
    sideBarTabs.push({
      label: 'Games',
      val: 'games',
      icon: 'about',
      path: '/performance/games',
    });
  }

  sideBarTabs.push({
    label: 'Rewards',
    val: 'rewards',
    icon: 'dollar',
    path: '/performance/rewards',
  });

  // @ts-ignore
  if (global.me.isAdmin) {
    sideBarTabs.push({
      label: 'Reports',
      val: 'reports',
      icon: 'kws',
      path: '/performance/reports',
    });
  }

  return (
    <>
      <Box
        style={{
          height: '100vh',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '88px',
        }}
      >
        <Box
          style={{
            height: '100%',
            width: 285,
            borderRight: '1px solid #7C7C7C',
          }}
        >
          <Box
            style={{
              marginTop: 50,
              marginLeft: 40,
            }}
          >
            <>
              <Typography style={{ fontSize: 20, fontWeight: 700 }}>
                Performance
              </Typography>
              {sideBarTabs.map((sideBarTab, i) => {
                return (
                  <Box
                    key={i}
                    sx={{ cursor: 'pointer' }}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: 'fit-content',
                      marginTop: 32,
                    }}
                    onClick={() => handleNavigate(sideBarTab.path)}
                  >
                    <Box
                      style={{
                        height: 24,
                        width: 24,
                        marginRight: 12,
                      }}
                    >
                      <GamifyIcon
                        icon={sideBarTab.icon}
                        color={
                          activeTab.includes(sideBarTab.val)
                            ? 'black'
                            : '#7C7C7C'
                        }
                        backgroundColor={'transparent'}
                        height={24}
                        width={24}
                      ></GamifyIcon>
                    </Box>
                    <Typography
                      style={{
                        fontSize: 16,
                        color: activeTab.includes(sideBarTab.val)
                          ? '#FF6A00'
                          : '#7C7C7C',
                        fontWeight: activeTab.includes(sideBarTab.val)
                          ? 600
                          : 400,
                      }}
                    >
                      {sideBarTab.label}
                    </Typography>
                  </Box>
                );
              })}
            </>
          </Box>
        </Box>
        <Box
          style={{
            width: '100%',
            overflowY: 'auto',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
