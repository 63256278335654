import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, Button, Typography, Skeleton } from '@mui/material';
import { Text } from '@aws-amplify/ui-react';
import { BackIcon } from '../../components/GamifyIcon';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';

export default function TeamPageSkeleton(props) {
  const { routeBackToProfileAboutPage, userId, routeBackToGames } = props;
  const { teamId } = useParams();
  const navigate = useNavigate();

  const { hasTeamPermission } = usePermissions();

  const showEdit = hasTeamPermission(PermissionKeys.TeamsPeople_UpdateTeam, [
    teamId,
  ]);
  const showAddPerson = hasTeamPermission(
    PermissionKeys.TeamsPeople_AddPersonToTeam,
    [teamId]
  );

  const handleBack = () => {
    if (routeBackToProfileAboutPage) {
      navigate(`/people/about/${userId}`);
    } else if (routeBackToGames) {
      navigate(`/games`);
    } else {
      navigate('/teams');
    }
  };

  const teamPageButtons = [];

  if (showEdit) {
    teamPageButtons.push({
      icon: '/images/Edit_Pencil.svg',
      title: 'Edit',
    });
  }

  if (showAddPerson) {
    teamPageButtons.push({
      icon: '/images/add-people-icon.svg',
      title: 'Add members',
    });
  }

  if (showEdit) {
    teamPageButtons.push({
      icon: '/images/link-icon.svg',
      title: 'Link team',
    });
  }

  return (
    <>
      <Box
        style={{
          // height: '50vh',
          height: 500,
          width: '100vw',
          paddingTop: '90px',
          paddingLeft: '60px',
          paddingRight: '60px',
          margin: 0,
          background:
            'radial-gradient(circle at 50% 80%, #FFD773 0%, #FF8630 50%, #FF6A00 100%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%',
            paddingTop: 34,
          }}
        >
          <Button
            className={'team-page-back-button'}
            onClick={() => handleBack()}
            disableRipple={true}
          >
            <BackIcon></BackIcon>
            <Typography
              style={{
                color: '#0B0B0C',
                fontWeight: 600,
                fontSize: 16,
                marginLeft: 16,
              }}
            >
              {routeBackToProfileAboutPage
                ? 'Back to profile'
                : routeBackToGames
                ? 'Back to games'
                : 'Back to teams'}
            </Typography>
          </Button>
        </Box>
        <Box
          style={{
            width: '40vw',
            height: '100%',
            padding: '15px 15px 40px 15px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            style={{
              borderRadius: '8px',
              overflow: 'hidden' /* , height: '70%' */,
            }}
          >
            <Skeleton
              sx={{ bgcolor: '#868686' }}
              variant={'circular'}
              width={240}
              height={240}
              // variant={'rounded'}
              // width={250}
              // height={'100%'}
            ></Skeleton>
          </Box>
          <Skeleton
            sx={{ bgcolor: 'black', marginTop: '15px' }}
            variant={'rounded'}
            width={340}
            height={54}
          ></Skeleton>
          <Skeleton
            sx={{ bgcolor: 'black', marginTop: '15px' }}
            variant={'rounded'}
            width={270}
            height={27}
          ></Skeleton>
        </Box>
        {/* Box below keeps team image, name, and description centered */}
        <Box
          style={{
            display: 'flex',
            minWidth: routeBackToProfileAboutPage
              ? 151.05
              : routeBackToGames
              ? 168.02
              : 164.59,
          }}
        >
          <Typography style={{ color: 'transparent' }}>.</Typography>
        </Box>
      </Box>
      <Box
        style={{
          position: 'relative',
          top: '-30px',
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            display: 'flex',
            width: '30vw',
            minWidth: 275,
            justifyContent: 'space-around',
          }}
        >
          {teamPageButtons.map((button, i) => (
            <Box
              key={i}
              className="hov"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 92,
              }}
            >
              <Box
                style={{
                  height: '56px',
                  width: '56px',
                  borderRadius: '50%',
                  boxShadow: '-1px 1px 4px 0 rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'white',
                  marginBottom: '10px',
                }}
              >
                <img
                  src={button.icon}
                  style={{ width: '50%', height: '50%' }}
                />
              </Box>
              <Text
                style={{ color: '#FF6A00', fontWeight: '600', fontSize: 14 }}
              >
                {button.title}
              </Text>
            </Box>
          ))}
        </Box>
        <Box style={{ width: '40vw', height: '100%', marginTop: '4ch' }}>
          <Box style={{ borderBottom: '1px solid #868686', display: 'flex' }}>
            <Box
              className="team-page-section-button hov"
              style={{
                borderBottom: '3px solid #FF6A00',
                color: '#FF6A00',
              }}
            >
              About
            </Box>

            <Box
              className="team-page-section-button hov"
              style={{
                borderBottom: 'none',
                color: '#868686',
              }}
            >
              Members
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            width: '40vw',
            marginTop: '2ch',
          }}
        >
          <Box style={{ borderRadius: '8px', overflow: 'hidden', height: 294 }}>
            <Skeleton
              sx={{ bgcolor: '#f0f0f3' }}
              variant={'rounded'}
              width={768}
              height={'100%'}
            ></Skeleton>
          </Box>
        </Box>
      </Box>
    </>
  );
}
