import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FeedMessage, ViewContainer } from './Feed.styles';
import CenteredProgress from '../../components/CenteredProgress';
import Item from './_types/Item';
import FeedItem from './FeedItem';
import CreatePost from './CreatePost';
import Me from '../../_types/Me';
import AppSettings from '../../_types/AppSettings';
import MyPerson from './_types/MyPerson';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';

interface Props {
  me: Me;
  appSettings: AppSettings;
  feedList: Item[];
  loadMore: (resetList: boolean) => void;
  refreshFeed: () => void;
  onDeleteFeedItem: (deletedFeedItem: Item) => void;
  myPersons: MyPerson[];
  shouldStopFetching: boolean;
}

const FeedView = ({
  me,
  appSettings,
  feedList,
  loadMore,
  refreshFeed,
  onDeleteFeedItem,
  myPersons,
  shouldStopFetching,
}: Props) => {
  const { checkPermission } = usePermissions();
  const canCreatePosts =
    checkPermission(PermissionKeys.Other_CreatePosts) !== 'none';

  return (
    <ViewContainer>
      {canCreatePosts && (
        <CreatePost
          me={me}
          appSettings={appSettings}
          refreshFeed={refreshFeed}
        />
      )}
      <InfiniteScroll
        dataLength={feedList.length}
        next={() => {
          loadMore(false);
        }}
        hasMore={!shouldStopFetching}
        loader={
          <div style={{ overflow: 'hidden' }}>
            <CenteredProgress />
          </div>
        }
        endMessage={
          feedList.length === 0 && <FeedMessage>Your feed is empty</FeedMessage>
        }
      >
        {feedList.map((feedItem) => {
          return (
            <FeedItem
              myPersons={myPersons}
              key={feedItem.id}
              feedItem={feedItem}
              me={me}
              onDeleteFeedItem={onDeleteFeedItem}
            />
          );
        })}
      </InfiniteScroll>
    </ViewContainer>
  );
};

export default FeedView;
