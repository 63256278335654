import React, { useState } from 'react';
import './ToggleSwitch.scss';
import clsx from 'clsx';

interface ToggleSwitchProps {
  checked: boolean;
  onChange?: (checked: boolean) => void;
  name?: string;
  small?: boolean;
  disabled?: boolean;
}

const ToggleSwitch = ({
  onChange,
  checked = false,
  small = false,
  disabled = false,
}: ToggleSwitchProps) => {
  const [isChecked, setIsChecked] = useState(checked);
  const id = (0 | (Math.random() * 9e6)).toString(36);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    if (onChange) {
      onChange(checked);
    }
  };

  return (
    <div
      className={clsx('toggle-switch', {
        'small-switch': small,
        checked: isChecked,
      })}
    >
      <input
        type="checkbox"
        name={id}
        className={clsx('toggle-switch-checkbox', { checked: isChecked })}
        id={id}
        checked={isChecked}
        onChange={handleChange}
        disabled={disabled}
      />
      <label className="toggle-switch-label" htmlFor={id}>
        <span
          className={clsx('toggle-switch-inner', {
            'toggle-switch-disabled': disabled,
            checked: isChecked,
          })}
        />
        <span
          className={clsx('toggle-switch-switch', {
            'toggle-switch-disabled': disabled,
            checked: isChecked,
          })}
        />
      </label>
    </div>
  );
};

export default ToggleSwitch;
