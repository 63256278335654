import { create } from 'zustand';
import fetchCurrentUserTeamsAndMembers from '../../common/utils/fetchCurrentUserTeamsAndMembers';

// Define the interface for User, Team, and the Zustand store
export interface User {
  colorCode: string;
  id: string;
  imageName: string;
  isDeleted: boolean;
  name: string;
  status: string;
}

export interface Team {
  colorCode: string;
  id: string;
  imageName: string;
  isActive: boolean;
  isDeleted: boolean;
  name: string;
  users: {
    [id: string]: Partial<User>;
  };
}

interface CurrentUserStore {
  userID: string | null;
  teams: {
    [id: string]: Partial<Team>;
  } | null;
  members: {
    [id: string]: Partial<User>;
  } | null;
  isLoading: boolean;
  getCurrentUserTeamsAndMembers: () => Promise<any>; // Return type should be Promise<any> to account for callback flexibility
  getCurrentUserID: () => void;
  isUserOrTeamMember: (...userIDs: string[]) => boolean;
  addMembersToCurrentUserTeam: (users: User[], teamID: string) => void;
  addMemberToCurrentUserTeams: (user: User, teamIDs: string[]) => void;
  removeMembersFromCurrentUserTeam: (
    usersIDs: string[],
    teamID: string
  ) => void;
  removeMembersFromAllCurrentUserTeam: (usersIDs: string[]) => void;
  removeMemberFromCurrentUserTeams: (userID: string, teamIDs: string[]) => void;
  addCurrentUserTeams: (newTeams: Partial<Team>[]) => void;
  updateCurrentUserTeams: (updatedTeams: Partial<Team>[]) => void;
  removeCurrentUserTeams: (teamIDs: string[]) => void;
  resetStore: () => void;
}

export const useCurrentUserStore = create<CurrentUserStore>((set, get) => ({
  userID: null,
  teams: null,
  members: null,
  isLoading: false,
  // Asynchronous function to fetch and get current user teams and members
  getCurrentUserTeamsAndMembers: async (): Promise<any> => {
    // Flexible return type with Promise<any>
    let { teams, members, isLoading } = get();

    // Prevent fetching if already loading
    if (isLoading) return;

    // If teamsAndMembersMap, teams, or members, haven't been fetched yet, fetch them
    if (teams === null || members === null) {
      set({ isLoading: true });

      const teamsAndMembersMap = await fetchCurrentUserTeamsAndMembers();

      if (teamsAndMembersMap) {
        teams = teamsAndMembersMap.teams;
        members = teamsAndMembersMap.members;
      }

      set({ teams, members, isLoading: false });
    } else {
      console.log('Saved a fetch!! teams and members already fetched');
    }

    return teams;
  },
  getCurrentUserID: (): void => {
    const { userID } = get();

    // @ts-ignore
    if (userID !== global.me.id) set({ userID: global.me.id });
  },
  isUserOrTeamMember: (...userIDs: string[]): boolean => {
    const { members } = get();
    return userIDs.some(
      // @ts-ignore
      (userID) => userID === global.me.id || members[userID]
    );
  },
  addMembersToCurrentUserTeam: (
    users: Partial<User>[],
    teamID: string
  ): void => {
    const { members, teams } = get();

    if (teams && members) {
      //   Check if the team the users are being added to is even in the current user's teams before
      for (const user of users) {
        if (user.id) {
          // Only add user to members if not already in members
          if (!members[user.id]) {
            members[user.id] = user;
          }

          // Add each user to team
          // @ts-ignore
          teams[teamID].users[user.id] = user;
        }
      }
    }

    set({ members, teams });
  },
  addMemberToCurrentUserTeams: (
    user: Partial<User>,
    teamIDs: string[]
  ): void => {
    const { members, teams } = get();

    // Use as a flag to indicate whether the current user is on at least one of the teamIDs passed to this function
    let isCurrentUserOnTeam = false;

    if (teams && members) {
      // Add user to each team
      for (const teamID of teamIDs) {
        // Check if the current user is on the team before trying to add the user
        if (teams[teamID]) {
          // Set this flag to true for adding the user to members later
          isCurrentUserOnTeam = true;
          // @ts-ignore
          teams[teamID].users[user.id] = user;
        }
      }

      // Only add user to members if not already in members AND at least one of the teamIDs is for a team the current user is on
      if (isCurrentUserOnTeam) {
        if (user.id) {
          if (!members[user.id]) {
            members[user.id] = user;
          }
        }
      }
    }

    set({ members, teams });
  },
  removeMembersFromCurrentUserTeam: (
    userIDs: string[],
    teamID: string
  ): void => {
    const { members, teams } = get();

    if (teams && members) {
      // No need for any store updates if the current user is not on the team that users are being removed from
      if (teams[teamID]) {
        for (const userID of userIDs) {
          // Remove user from team before checking to remove user from members
          // @ts-ignore
          delete teams[teamID].users[userID];

          // Flag for checking if the user is a member of another team before trying to remove user from members
          let isOnOtherTeam = false;

          // Check for member on each team, set flag to true if user is on another team
          for (const team of Object.values(teams)) {
            if (team.users) {
              if (team.users[userID]) {
                isOnOtherTeam = true;
                break;
              }
            }
          }

          // Remove user from members ONLY if all teams' users were checked and they are NOT on another team
          if (!isOnOtherTeam) {
            delete members[userID];
          }
        }
      }
    }

    set({ members, teams });
  },
  removeMembersFromAllCurrentUserTeam: (userIDs: string[]): void => {
    const { members, teams } = get();

    if (teams && members) {
      for (const userID of userIDs) {
        // Remove user from each team
        for (const team of Object.values(teams)) {
          if (team.users) {
            delete team.users[userID];
          }
        }
        // Remove user from members
        delete members[userID];
      }
    }

    set({ members, teams });
  },
  removeMemberFromCurrentUserTeams: (
    userID: string,
    teamIDs: string[]
  ): void => {
    const { members, teams } = get();

    if (teams && members) {
      // Remove user from users of all teams before checking members
      for (const teamID of teamIDs) {
        // Only remove user from the team if the current user is on that team
        if (teams[teamID]) {
          // @ts-ignore
          delete teams[teamID].users[userID];
        }
      }

      // Flag for checking if the user is a member of another team before trying to remove user from members
      let isOnOtherTeam = false;

      // Check for member on each team, set flag to true if user is on another team
      for (const team of Object.values(teams)) {
        if (team.users) {
          if (team.users[userID]) {
            isOnOtherTeam = true;
            break;
          }
        }
      }

      // Remove user from members ONLY if all teams' users were checked and they are NOT on another team
      if (!isOnOtherTeam) {
        delete members[userID];
      }
    }

    set({ members, teams });
  },
  addCurrentUserTeams: (newTeams: Partial<Team>[]): void => {
    const { members, teams } = get();

    let updatedMembers = { ...members };

    if (members && teams) {
      for (const newTeam of newTeams) {
        if (newTeam.id) {
          // Add new Team to teams in store and spread that new team's members into updatedMembers
          teams[newTeam.id] = newTeam;
          updatedMembers = { ...updatedMembers, ...newTeam.users };
        }
      }
    }
    set({ members: updatedMembers, teams });
  },
  updateCurrentUserTeams: (updatedTeams: Partial<Team>[]): void => {
    const { teams, members } = get();

    let updatedMembers = { ...members };

    if (teams && members) {
      for (const updatedTeam of updatedTeams) {
        if (updatedTeam.id) {
          if (teams[updatedTeam.id]) {
            // Add new Team to teams in store and spread that new team's members into updatedMembers
            teams[updatedTeam.id] = updatedTeam;
            updatedMembers = { ...updatedMembers, ...updatedTeam.users };
          }
        }
      }
    }

    set({ members: updatedMembers, teams });
  },
  removeCurrentUserTeams: (teamIDs: string[]): void => {
    const { members, teams } = get();

    let removedTeamsMembers: { [id: string]: Partial<User> } = {};

    if (teams && members) {
      for (const teamID of teamIDs) {
        // If removed team is in teams, accumulate the members of that team in removedTeamsMembers before deleting it from teams
        if (teams[teamID]) {
          removedTeamsMembers = {
            ...removedTeamsMembers,
            ...teams[teamID].users,
          };

          delete teams[teamID];
        }
      }

      // Check each of the removedMembers against the users on the remaining teams, if removedUser is not on any other teams then remove that user from members
      for (const removedMember of Object.values(removedTeamsMembers)) {
        let isOnOtherTeam = false;

        for (const team of Object.values(teams)) {
          if (team.users && removedMember.id) {
            if (team.users[removedMember.id]) {
              isOnOtherTeam = true;
              break;
            }
          }
        }

        if (!isOnOtherTeam) {
          if (removedMember.id) {
            delete members[removedMember.id];
          }
        }
      }
    }

    set({ members, teams });
  },
  resetStore: () => {
    set({
      userID: null,
      teams: null,
      members: null,
      isLoading: false,
    });
  },
}));
