import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import {
  PermissionKeys,
  permissionValue,
  Role,
} from '../components/RolesAndPermissions/RoleAndPermissions.types';
import { useSettingsContext } from './SettingsContext';
import _ from 'lodash';

import fetchUserRoles from '../utils/fetchUserRoles';
import { updateUserRoles } from '../utils/updateUserRoles';
import { GamifyToast } from '../../../common/CustomToasts';
import { createUserRole } from '../utils/createUserRole';
const { v4: uuidv4 } = require('uuid');

interface RolesPermissionsContextType {
  roles: Role[];
  updateRole: (
    id: string,
    // permissionCategory: string,
    permission: PermissionKeys,
    value: permissionValue | boolean
  ) => void;
  updateRoleName: (id: string, roleName: string) => void;
  restoreRole: (id: string, nameOnly?: boolean) => void;
  selectedRole: Role | null;
  setSelectedRole: React.Dispatch<React.SetStateAction<Role | null>>;
  saveRoleChanges: () => void;
  createRole: (roleName: string, cloneID: string) => void;
}

const RolesPermissionsContext = createContext<
  RolesPermissionsContextType | undefined
>(undefined);

function sortRolesComparator(a: Role, b: Role): number {
  if (a.isSuper && !b.isSuper) {
    return 1;
  }
  if (!a.isSuper && b.isSuper) {
    return -1;
  }
  return a.roleName.localeCompare(b.roleName);
}

export const RolesPermissionsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [originalRoles, setOriginalRoles] = useState<Role[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const { setHasUnsavedChanges } = useSettingsContext();

  const createRole = async (roleName: string, cloneID: string) => {
    const cloneRole = originalRoles.find((role) => role.id === cloneID);
    if (cloneRole) {
      const clonePremissions = _.cloneDeep(cloneRole.permissions);
      const newRole: Role = {
        id: uuidv4(),
        roleName,
        permissions: clonePremissions,
        isSuper: false,
        iconName: cloneRole.iconName,
      };

      console.log(
        `Creating new role: ${newRole.roleName} cloned from ${cloneRole.roleName}`
      );
      await createUserRole(newRole);
      // console.log(newRole);
      GamifyToast.success(newRole.roleName + ' role created successfully');
      const newRoles = [...roles, newRole];
      newRoles.sort(sortRolesComparator);
      setRoles(newRoles);
      setSelectedRole(newRole);
    }
  };
  const restoreRole = (id: string, nameOnly?: boolean) => {
    const newRoles = _.cloneDeep(roles);
    const roleToRestore = newRoles.find((role) => role.id === id);
    const originalRole = originalRoles.find((role) => role.id === id);

    if (roleToRestore && originalRole) {
      if (nameOnly) {
        roleToRestore.roleName = originalRole.roleName;
      } else {
        roleToRestore.roleName = originalRole.roleName;
        roleToRestore.permissions = originalRole.permissions;
      }
      setSelectedRole(roleToRestore);
      setRoles(newRoles);
    }
  };
  const updateRoleName = (id: string, roleName: string) => {
    const newRoles = _.cloneDeep(roles);
    const roleToUpdate = newRoles.find((role) => role.id === id);

    if (roleToUpdate) {
      roleToUpdate.roleName = roleName;
      setSelectedRole(roleToUpdate);
      setRoles(newRoles);
    }
  };
  const updateRole = (
    id: string,
    permission: PermissionKeys,
    value: permissionValue | boolean
  ) => {
    const newRoles = _.cloneDeep(roles);
    // console.log(`Updating ${permissionCategory} - ${permission} to ${value}`);
    const roleToUpdate = newRoles.find((role) => role.id === id);

    if (roleToUpdate) {
      if (roleToUpdate.permissions) {
        roleToUpdate.permissions[permission as PermissionKeys] = value;
      } else {
        roleToUpdate.permissions = {
          [permission as PermissionKeys]: value,
        };
      }
      setSelectedRole(roleToUpdate);
      setRoles(newRoles);
    }
  };

  const saveRoleChanges = async () => {
    const changedRoles: Role[] = [];
    const unchangedRoles: Role[] = [];

    // Compare roles with originalRoles
    roles.forEach((role, index) => {
      const originalRole = originalRoles[index];
      if (!_.isEqual(role, originalRole)) {
        changedRoles.push(role);
      } else {
        unchangedRoles.push(role);
      }
    });
    console.log('Changed Roles:', changedRoles);

    // Call a function to update the changed roles in the database
    await updateUserRoles(changedRoles);

    console.log('Changed Roles:', changedRoles);
    console.log('Unchanged Roles:', unchangedRoles);

    const allRoles = [...unchangedRoles, ...changedRoles];

    // Sort the roles by roleName and ensure isSuper (Super Admin) is always last
    allRoles.sort(sortRolesComparator);

    // Update both originalRoles and roles with the sorted array
    setOriginalRoles(allRoles);
    setRoles(allRoles);
    GamifyToast.success('Role changes saved successfully');
    // console.log('Sorted Roles:', allRoles);
  };

  useEffect(() => {
    // @ts-ignore
    fetchUserRoles(global.me.orgID).then((roles) => {
      console.log('Roles fetched', roles);
      setOriginalRoles(roles);
      setRoles(roles);
      if (roles.length > 0) {
        setSelectedRole(roles[0]);
      }
    });
  }, []);

  useEffect(() => {
    const hasChanges = !_.isEqual(originalRoles, roles);
    setHasUnsavedChanges(hasChanges);
  }, [roles]);

  return (
    <RolesPermissionsContext.Provider
      value={{
        roles,
        updateRole,
        updateRoleName,
        restoreRole,
        selectedRole,
        setSelectedRole,
        saveRoleChanges,
        createRole,
      }}
    >
      {children}
    </RolesPermissionsContext.Provider>
  );
};

export const useRolesPermissionsContext = () => {
  const context = useContext(RolesPermissionsContext);
  if (!context) {
    throw new Error(
      'useRolesPermissionsContext must be used within RolesPermissionsProvider'
    );
  }
  return context;
};
