import React, { Suspense } from 'react';
import CustomThemeProvider from './configuration/CustomThemeProvider';
import RouteConfiguration from './routes/RouteConfiguration';
import { Box, Typography } from '@mui/material';
import { Authenticator, View } from '@aws-amplify/ui-react';
import store from './store/Store';
import { Provider } from 'react-redux';
import { PermissionsProvider } from './context/PermissionsContext/PermissionsContext';
import './configuration/AmplifyConfiguration';
import '@aws-amplify/ui-react/styles.css';
import './Application.css';
import ApplicationStateBootstrap from './configuration/ApplicationStateBootstrap';
import './Internationalization';
import CenteredProgress from './components/CenteredProgress';
import { ToastContainer, toast } from 'react-toastify';
import styled from '@emotion/styled';
import 'react-toastify/dist/ReactToastify.minimal.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const StyledToastContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
  .Toastify__toast {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
  .Toastify__toast-body {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
  .Toastify__progress-bar {
    margin: 0px;
    padding: 0px;
    border-radius: 0px;
  }
`;

const components = {
  Header() {
    return (
      <View
        style={{ paddingBottom: 40, paddingTop: 100, justifyContent: 'center' }}
      >
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'black',
          }}
        >
          <img src="/images/paiv-logo-white.png" alt="Gamify" height={70} />
        </View>
        <View style={{ paddingTop: 20 }}>
          <Typography
            sx={{
              color: '#222428',
              textAlign: 'center',
              fontSize: 32,
              fontFamily: 'Manrope',
              fontWeight: 'bold',
            }}
          >
            Welcome Back!
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: 16,
              fontFamily: 'Manrope',
            }}
          >
            Log in to your account
          </Typography>
        </View>
      </View>
    );
  },
};

global.toast = toast;
const queryClient = new QueryClient();

class Application extends React.Component {
  render() {
    return (
      <Box>
        <QueryClientProvider client={queryClient}>
          <Authenticator
            components={components}
            loginMechanisms={['email']}
            hideSignUp={true}
          >
            <PermissionsProvider>
              <Provider store={store}>
                <CustomThemeProvider>
                  <Suspense fallback={<CenteredProgress />}>
                    <ApplicationStateBootstrap>
                      <Box>
                        <RouteConfiguration />
                      </Box>
                      <StyledToastContainer />
                    </ApplicationStateBootstrap>
                  </Suspense>
                </CustomThemeProvider>
              </Provider>
            </PermissionsProvider>
          </Authenticator>
        </QueryClientProvider>
      </Box>
    );
  }
}

export default Application;
