import React, { useEffect, useState } from 'react';
import {
  NavItemIndicator,
  ProfilePicture,
  SearchProfileContainer,
  ViewContainer,
} from './NavBar.styles';
import { CustomMenu, CustomMenuItem } from '../../common/StyledComponents';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router';
import featureFlag from '../../common/featureFlag';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';

interface Props {
  userProfileAvatar: string;
  activeTab: string;
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  // isProfileDropdownOpen: boolean;
  isMobileMenuOpen: boolean;
  // handleProfileClick: () => void;
  handleMobileMenuToggle: () => void;
  handleSignout: () => void;
  handleNavigate: (path: string) => void;
}

const NavBarView: React.FC<Props> = ({
  // handleProfileClick,
  activeTab,
  // isProfileDropdownOpen,
  anchorEl,
  setAnchorEl,
  // isMobileMenuOpen,
  userProfileAvatar,
  handleNavigate,
  // handleMobileMenuToggle,
  handleSignout,
}) => {
  const handleDropdownOpen = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorEl(e.currentTarget);
  };

  const navigate = useNavigate();
  const { isSuperAdmin } = usePermissions();
  const isSuper = isSuperAdmin();
  // @ts-ignore
  // console.log('343434IS SUPER ADMIN', isSuper, global?.me?.id);

  const [navItems, setNavItems] = useState(
    featureFlag('quicksight')
      ? featureFlag('payments')
        ? [
            { label: 'Feed', value: '/feed' },
            { label: 'Performance', value: '/performance' },
            { label: 'Training', value: '/training' },
            { label: 'People', value: '/people' },
            { label: 'Teams', value: '/teams' },
            { label: 'Pipeline', value: '/pipeline' },
            { label: 'Products', value: '/products' },
            { label: 'Dashboard', value: '/dashboard' },
            { label: 'Payments', value: '/payments' },
          ]
        : [
            { label: 'Feed', value: '/feed' },
            { label: 'Performance', value: '/performance' },
            { label: 'Training', value: '/training' },
            { label: 'People', value: '/people' },
            { label: 'Teams', value: '/teams' },
            { label: 'Pipeline', value: '/pipeline' },
            { label: 'Products', value: '/products' },
            { label: 'Dashboard', value: '/dashboard' },
          ]
      : [
          { label: 'Feed', value: '/feed' },
          { label: 'Performance', value: '/performance' },
          { label: 'Training', value: '/training' },
          { label: 'People', value: '/people' },
          { label: 'Teams', value: '/teams' },
          { label: 'Pipeline', value: '/pipeline' },
          { label: 'Products', value: '/products' },
        ]
  );

  useEffect(() => {
    setNavItems(
      featureFlag('quicksight')
        ? featureFlag('payments')
          ? [
              { label: 'Feed', value: '/feed' },
              { label: 'Performance', value: '/performance' },
              { label: 'Training', value: '/training' },
              { label: 'People', value: '/people' },
              { label: 'Teams', value: '/teams' },
              { label: 'Pipeline', value: '/pipeline' },
              { label: 'Products', value: '/products' },
              { label: 'Dashboard', value: '/dashboard' },
              { label: 'Payments', value: '/payments' },
            ]
          : [
              { label: 'Feed', value: '/feed' },
              { label: 'Performance', value: '/performance' },
              { label: 'Training', value: '/training' },
              { label: 'People', value: '/people' },
              { label: 'Teams', value: '/teams' },
              { label: 'Pipeline', value: '/pipeline' },
              { label: 'Products', value: '/products' },
              { label: 'Dashboard', value: '/dashboard' },
            ]
        : [
            { label: 'Feed', value: '/feed' },
            { label: 'Performance', value: '/performance' },
            { label: 'Training', value: '/training' },
            { label: 'People', value: '/people' },
            { label: 'Teams', value: '/teams' },
            { label: 'Pipeline', value: '/pipeline' },
            { label: 'Products', value: '/products' },
          ]
    );
  }, [featureFlag('payments'), featureFlag('quicksight')]);

  return (
    <ViewContainer>
      <div style={{ display: 'flex', gap: 60, alignItems: 'center' }}>
        <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/feed')}>
          <img
            src="/images/paiv-logo-with-text.svg"
            alt="Logo"
            width={120}
            height={120}
          />
        </Box>
        <div style={{ display: 'flex', gap: 20 }}>
          {navItems.map((navItem) => {
            return (
              <div
                key={navItem.value}
                onClick={() => handleNavigate(navItem.value)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    fontWeight: activeTab.includes(navItem.value) ? 600 : 400,
                    cursor: 'pointer',
                    fontSize: 14,
                    color: activeTab.includes(navItem.value)
                      ? '#FF6A00'
                      : '#737373',
                  }}
                >
                  {navItem.label}
                </div>
                <NavItemIndicator
                  isActive={activeTab.includes(navItem.value)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <SearchProfileContainer>
        <ProfilePicture onClick={(e) => handleDropdownOpen(e)}>
          <img
            src={userProfileAvatar}
            alt="Profile Icon"
            width={40}
            height={40}
            style={{ borderRadius: '50%', objectFit: 'cover' }}
          />
          <CustomMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              setAnchorEl(null);
            }}
          >
            {
              // @ts-ignore
              global?.me?.orgID === 'gamify' && (
                <CustomMenuItem
                  sx={{
                    background: 'white',
                    color: 'black',
                    paddingTop: '9px',
                    paddingBottom: '9px',
                  }}
                  onClick={() => handleNavigate('/admin-config')}
                  disableRipple={true}
                >
                  Paiv Admin Config
                </CustomMenuItem>
              )
            }
            {isSuper && (
              <CustomMenuItem
                sx={{
                  background: 'white',
                  color: 'black',
                  paddingTop: '9px',
                  paddingBottom: '9px',
                }}
                onClick={() => handleNavigate('/settings')}
                disableRipple={true}
              >
                Settings
              </CustomMenuItem>
            )}
            <CustomMenuItem
              sx={{
                background: 'white',
                color: 'black',
                paddingTop: '9px',
                paddingBottom: '9px',
              }}
              // @ts-ignore
              onClick={() => handleNavigate('/people/stats/' + global?.me?.id)}
              disableRipple={true}
            >
              Me
            </CustomMenuItem>
            <CustomMenuItem
              sx={{
                background: 'white',
                color: 'black',
                paddingTop: '9px',
                paddingBottom: '9px',
              }}
              onClick={handleSignout}
              disableRipple={true}
            >
              Sign out
            </CustomMenuItem>
          </CustomMenu>
        </ProfilePicture>
      </SearchProfileContainer>
    </ViewContainer>
  );
};

export default NavBarView;
