import React from 'react';
import { Box, Typography } from '@mui/material';
import { Areas } from '../../../../components/GamifyIcon';
import PermissionWithSwitch from './formComponents/PermissionWithSwitch';
import PermissionWithSelect from './formComponents/PermissionWithSelect';
import { useRolesPermissionsContext } from '../../context/RolesAndPermissionsContext';
import {
  permissionValue,
  PermissionKeys,
  PermissionCategoryProps,
} from './RoleAndPermissions.types';
import '../../settingsStyles.css';

const Sales = React.memo(({ selectedRole }: PermissionCategoryProps) => {
  if (!selectedRole) return <></>;

  const { updateRole } = useRolesPermissionsContext();

  const handleRoleUpdate = (
    permissionKey: PermissionKeys,
    newValue: permissionValue | boolean
  ) => {
    updateRole(
      selectedRole.id,
      // PermissionCategory.Sales,
      permissionKey,
      newValue
    );
  };
  return (
    <Box sx={{ px: 3, py: 2 }}>
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <Areas color="#878787" />
        <Typography sx={{ fontWeight: 700, color: '#878787' }}>
          Deals
        </Typography>
      </Box>
      <Box className="permission-section">
        {/* Seales_CreateDeal */}
        <PermissionWithSwitch
          label="Create deal"
          value={selectedRole?.permissions[PermissionKeys.Sales_CreateDeal]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Sales_CreateDeal, val)
          }
        />
        {/* Sales_ViewDeal */}
        <PermissionWithSelect
          label="View deals"
          value={selectedRole?.permissions[PermissionKeys.Sales_ViewDeal]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Sales_ViewDeal, val)
          }
        />
        {/* Sales_UpdateDeal */}
        <PermissionWithSelect
          label="Update deal"
          value={selectedRole?.permissions[PermissionKeys.Sales_UpdateDeal]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Sales_UpdateDeal, val)
          }
        />
        {/* Sales_DeleteDeal */}
        <PermissionWithSelect
          label="Delete deal"
          value={selectedRole?.permissions[PermissionKeys.Sales_DeleteDeal]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Sales_DeleteDeal, val)
          }
        />
      </Box>
    </Box>
  );
});

Sales.displayName = 'Sales';
export default Sales;
