// import React from 'react';
// // import { Role } from './RoleAndPermissions.types';
// import { Box, Typography } from '@mui/material';
// import ToggleSwitch from '../../../../../components/ToggleSwitch/ToggleSwitch';
// import { LockIcon } from '../../../../../components/GamifyIcon';
// import { useRolesPermissionsContext } from '../../../context/RolesAndPermissionsContext';

// interface ValueWithSwitchProps {
//   label: string;
//   subtitle?: string;
//   value: boolean;
//   disabled?: boolean;
//   locked?: boolean;
//   onUpdate?: (val: boolean) => void;
// }

// const PermissionWithSwitch = ({
//   label,
//   subtitle,
//   value,
//   disabled,
//   locked,
//   onUpdate,
// }: ValueWithSwitchProps) => {
//   const { selectedRole } = useRolesPermissionsContext();

//   const lockInput = selectedRole?.isSuper || disabled || locked;

//   return (
//     <Box
//       sx={{ p: 0 }}
//       style={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//       }}
//     >
//       <Box>
//         <Typography sx={{ fontWeight: 700, color: '#101010' }}>
//           {label}
//         </Typography>
//         {subtitle && (
//           <Typography sx={{ fontWeight: 400, color: '#565656' }}>
//             {subtitle}
//           </Typography>
//         )}
//       </Box>
//       <Box sx={{ display: 'flex', alignItems: 'center' }}>
//         <ToggleSwitch
//           onChange={onUpdate}
//           checked={value}
//           disabled={lockInput}
//         />
//         {lockInput && <LockIcon />}
//       </Box>
//     </Box>
//   );
// };

// export default PermissionWithSwitch;

import React from 'react';
import { Box, Typography } from '@mui/material';
import ToggleSwitch from '../../../../../components/ToggleSwitch/ToggleSwitch';
import { LockIcon } from '../../../../../components/GamifyIcon';
import { useRolesPermissionsContext } from '../../../context/RolesAndPermissionsContext';

interface ValueWithSwitchProps {
  label: string;
  subtitle?: string;
  value: boolean;
  disabled?: boolean;
  locked?: boolean;
  onUpdate?: (val: boolean) => void;
}

const PermissionWithSwitch = React.memo(
  ({
    label,
    subtitle,
    value,
    disabled,
    locked,
    onUpdate,
  }: ValueWithSwitchProps) => {
    const { selectedRole } = useRolesPermissionsContext();

    const lockInput = selectedRole?.isSuper || disabled || locked;

    return (
      <Box
        sx={{ p: 0 }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: 700, color: '#101010' }}>
            {label}
          </Typography>
          {subtitle && (
            <Typography sx={{ fontWeight: 400, color: '#565656' }}>
              {subtitle}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ToggleSwitch
            onChange={onUpdate}
            checked={value}
            disabled={lockInput}
          />
          {lockInput && <LockIcon />}
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => prevProps.value === nextProps.value
);
PermissionWithSwitch.displayName = 'PermissionWithSwitch';
export default PermissionWithSwitch;
