import React from 'react';
import { Box, Typography } from '@mui/material';
import { Areas } from '../../../../components/GamifyIcon';
import PermissionWithSwitch from './formComponents/PermissionWithSwitch';
import PermissionWithSelect from './formComponents/PermissionWithSelect';
import { useRolesPermissionsContext } from '../../context/RolesAndPermissionsContext';
import {
  permissionValue,
  PermissionCategoryProps,
  PermissionKeys,
} from './RoleAndPermissions.types';
import '../../settingsStyles.css';

const Other = React.memo(({ selectedRole }: PermissionCategoryProps) => {
  if (!selectedRole) return <></>;

  const { updateRole } = useRolesPermissionsContext();

  const handleRoleUpdate = (
    permissionKey: PermissionKeys,
    newValue: permissionValue | boolean
  ) => {
    updateRole(selectedRole.id, permissionKey, newValue);
  };
  return (
    <Box sx={{ px: 3, py: 2 }}>
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <Areas color="#878787" />
        <Typography sx={{ fontWeight: 700, color: '#878787' }}>
          Social feed
        </Typography>
      </Box>
      <Box className="permission-section">
        {/* Other_CreatePosts w Select */}
        <PermissionWithSelect
          label="Create posts"
          value={selectedRole?.permissions[PermissionKeys.Other_CreatePosts]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Other_CreatePosts, val)
          }
        />
        {/* Other_EditPosts w Select */}
        <PermissionWithSelect
          label="Edit posts"
          value={selectedRole?.permissions[PermissionKeys.Other_EditPosts]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Other_EditPosts, val)
          }
        />
        {/* Other_RemovePosts w Select */}
        <PermissionWithSelect
          label="Remove posts"
          value={selectedRole?.permissions[PermissionKeys.Other_RemovePosts]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Other_RemovePosts, val)
          }
        />
      </Box>
      <Box className="permission-section">
        {/* Other_CreateComments w Switch */}
        <PermissionWithSwitch
          label="Create comments"
          value={selectedRole?.permissions[PermissionKeys.Other_CreateComments]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Other_CreateComments, val)
          }
        />
        {/* Other_EditComments w Select */}
        <PermissionWithSelect
          label="Edit comments"
          value={selectedRole?.permissions[PermissionKeys.Other_EditComments]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Other_EditComments, val)
          }
          excludeOptions={['myTeam']}
        />
        {/* Other_RemoveComments w Select */}
        <PermissionWithSelect
          label="Remove comments"
          value={selectedRole?.permissions[PermissionKeys.Other_RemoveComments]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Other_RemoveComments, val)
          }
          // excludeOptions={['myTeam']}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <Areas color="#878787" />
        <Typography sx={{ fontWeight: 700, color: '#878787' }}>
          Training videos
        </Typography>
      </Box>
      <Box className="permission-section">
        {/*   Other_ManageTrainingVideos = 'manageTrainingVideos', // in boolean
  Other_ViewTrainingStatus = 'viewTrainingStatus', // in boolean */}
        {/* Other_ManageTrainingVideos w Switch */}
        <PermissionWithSwitch
          label="Manage training videos"
          value={
            selectedRole?.permissions[PermissionKeys.Other_ManageTrainingVideos]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Other_ManageTrainingVideos, val)
          }
        />
        {/* Other_ViewTrainingStatus w Switch */}
        <PermissionWithSwitch
          label="View training status"
          value={
            selectedRole?.permissions[PermissionKeys.Other_ViewTrainingStatus]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Other_ViewTrainingStatus, val)
          }
        />
      </Box>
    </Box>
  );
});

Other.displayName = 'Other';
export default Other;
