import { Typography, Box, Backdrop } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRolesPermissionsContext } from '../../context/RolesAndPermissionsContext';
import RoleSelect from './formComponents/RoleSelect';
import { StyledTextField } from './formComponents/StyledTextField';
import {
  StyledButton,
  StyledOutlinedButton,
} from './formComponents/styledComponents';

// interface for roleForm with isOpen(boolean) and roleData(object)

interface RoleFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (roleData: object) => void;
  duplicateID?: string;
  //   roleData: object;
}

// RoleForm component
function RoleForm({ isOpen, onClose, onSubmit, duplicateID }: RoleFormProps) {
  const { roles } = useRolesPermissionsContext();
  const [roleName, setRoleName] = useState('');
  const [cloneID, setCloneID] = useState('none');
  const handleClose = () => {
    if (roleName.length) {
      console.log('RESET');
      setRoleName('');
      setCloneID('none');
    }
    onClose();
  };

  const handleSubmit = () => {
    // console.log('submit');
    // console.log(roleName, cloneID);
    if (roleName.length && cloneID !== 'none') {
      onSubmit({ roleName, cloneID });
      setRoleName('');
      setCloneID('none');
      onClose();
    }
  };
  useEffect(() => {
    if (!isOpen) return;
    if (duplicateID) {
      const role = roles.find((role) => role.id === duplicateID);
      if (role) {
        setRoleName('new_' + role.roleName);
      }
      setCloneID(duplicateID);
    }
  }, [duplicateID, roles]);
  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
      onClick={(e) => {
        e.stopPropagation();
        handleClose();
      }}
    >
      <Box
        style={{
          backgroundColor: 'white',
          maxWidth: '40%',
          //   minHeight: '40%',
          borderRadius: '5px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflowY: 'auto',
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#FAC8B2',
            padding: '10px',
          }}
        >
          <Typography variant="h5">Create new role</Typography>
        </Box>
        {/* <Box onClick={handleClose}>
          <Typography>Role Modal</Typography>
        </Box> */}
        <Box
          style={{
            padding: '10px 15px',
            gap: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <Typography mb={1}>Role Name</Typography>
            <StyledTextField
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              placeholder="Enter role name"
            />
          </Box>
          <Box>
            <Typography mb={1}>Clone permissions from role</Typography>
            <RoleSelect
              value={cloneID}
              onChange={(value) => setCloneID(value)}
            />
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            padding: '10px 15px',
            justifyContent: 'space-between',
            gap: 10,
          }}
        >
          <StyledButton variant="contained" onClick={handleSubmit}>
            Create
          </StyledButton>
          <StyledOutlinedButton
            variant="outlined"
            onClick={() => {
              //   onSubmit({ roleName, roleClone });
              handleClose();
            }}
          >
            Cancel
          </StyledOutlinedButton>
        </Box>
      </Box>
    </Backdrop>
  );
}

export default RoleForm;
