export type Permission =
  | 'none'
  | 'onlyMe'
  | 'myTeam'
  | 'everyone'
  | null
  | boolean;

export enum PermissionScopes {
  none = 'none',
  onlyMe = 'onlyMe',
  myTeam = 'myTeam',
  everyone = 'everyone',
}

export enum PermissionKeys {
  // TerritoryPermissions
  Territory_CreateArea = 'createArea', // in -
  Territory_UpdateArea = 'updateArea', // in -
  Territory_DeleteArea = 'deleteArea', // in -
  Territory_ViewArea = 'viewArea', // needs backend updates
  Territory_DropPin = 'dropPin', // in - limited permission
  Territory_EditPin = 'editPin', // onlyMe - everyone
  Territory_DeletePin = 'deletePin', // onlyMe - everyone
  Territory_ViewPin = 'viewPin',
  Territory_ExportAnyData = 'exportAnyData',
  Territory_CreateKPIs = 'createKPIs',
  Territory_CreateDealStages = 'createDealStages',
  Territory_CreateDispositions = 'createDispositions',
  // SalesPermissions
  Sales_CreateDeal = 'createDeal', // Boolean in
  Sales_UpdateDeal = 'updateDeal',
  Sales_DeleteDeal = 'deleteDeal', // in
  Sales_ViewDeal = 'viewDeal', // in
  Sales_ReassignDeal = 'reassignDeal',
  Sales_SetDealStage = 'setDealStage',
  Sales_ImportLeads = 'importLeads',
  Sales_ExportLeads = 'exportLeads',
  Sales_CreateForms = 'createForms',
  Sales_ViewLeads = 'viewLeads',
  // SchedulingPermissions
  Scheduling_ViewAppointments = 'viewAppointments', // in
  Scheduling_BookAppointment = 'bookAppointment', // in
  Scheduling_MaintainWorkHours = 'maintainWorkHours', // in
  Scheduling_ReassignAppointment = 'reassignAppointment', // in
  Scheduling_ManageRoundRobin = 'manageRoundRobin', // in
  Scheduling_CompareCalendarAvailability = 'compareCalendarAvailability', // in myTeam or everyone
  Scheduling_AddUnavailableTimesToCalendar = 'addUnavailableTimesToCalendar', // in
  Scheduling_ExportAppointments = 'exportAppointments',
  // GamesRewardsPermissions
  GamesRewards_CreateGame = 'createGame', // in -- boolean for now
  GamesRewards_UpdateGame = 'updateGame', // in -- boolean for now
  GamesRewards_DeleteGame = 'deleteGame', // in -- boolean for now
  GamesRewards_ViewGame = 'viewGame', // in
  GamesRewards_ManagePoints = 'managePoints', // in
  GamesRewards_ViewLeaderboards = 'viewLeaderboards',
  GamesRewards_CreateCustomLeaderboardViews = 'createCustomLeaderboardViews', // in boolean for now
  GamesRewards_ExportReports = 'exportReports',
  GamesRewards_CreateReward = 'createReward', // in -- boolean for now
  GamesRewards_UpdateReward = 'updateReward', // in -- boolean for now
  GamesRewards_DeleteReward = 'deleteReward', // in -- boolean for now
  GamesRewards_ViewReward = 'viewReward',
  GamesRewards_ManageCardAccount = 'manageCardAccount', // in -- boolean for now
  GamesRewards_RemoveCardsGiftCardAccounts = 'removeCardsGiftCardAccounts', // in -- boolean for now
  GamesRewards_AssignGiftCardAccountToTeams = 'assignGiftCardAccountToTeams',
  GamesRewards_AssignGiftCardToSpecificRewards = 'assignGiftCardToSpecificRewards',
  GamesRewards_ExportRewardsReport = 'exportRewardsReport',
  // TeamsPeoplePermissions
  TeamsPeople_CreateUser = 'createUser', // in boolean
  TeamsPeople_UpdateUser = 'updateUser', // in
  TeamsPeople_DeleteUser = 'deleteUser', // in
  TeamsPeople_ViewUser = 'viewUser', // in
  TeamsPeople_ViewUserStatCard = 'viewUserStatCard', // in
  TeamsPeople_ChangePersonsRole = 'changePersonsRole', // in boolean
  TeamsPeople_ChangePersonsStatus = 'changePersonsStatus', // in boolean
  TeamsPeople_ExportUserList = 'exportUserList',
  TeamsPeople_ImportUsers = 'importUsers',
  TeamsPeople_CreateTeam = 'createTeam', // in boolean
  TeamsPeople_UpdateTeam = 'updateTeam', // in
  TeamsPeople_DeleteTeam = 'deleteTeam', // in
  TeamsPeople_AddPersonToTeam = 'addPersonToTeam', // in [myTeam, everyone]
  TeamsPeople_RemoveSomeoneFromTeam = 'removeSomeoneFromTeam', // in [myTeam, everyone]
  TeamsPeople_ViewTeamDetails = 'viewTeamDetails', // in [myTeam, everyone]
  TeamsPeople_UpdateManualKPIs = 'updateManualKPIs', // in [myTeam,everyone]
  TeamsPeople_ExportTeamList = 'exportTeamList',
  TeamsPeople_ImportTeamList = 'importTeamList',
  // OtherPermissions
  Other_CreatePosts = 'createPosts', // in [onlyMe,myTeam, everyone] onlyMe means user can only make a post for one person
  Other_EditPosts = 'editPosts', // in
  Other_RemovePosts = 'removePosts', // in
  Other_CreateComments = 'createComments', // in boolean
  Other_EditComments = 'editComments', // in [onlyMe,everyone]
  Other_RemoveComments = 'removeComments', // in [onlyMe,everyone]
  Other_ManageTrainingVideos = 'manageTrainingVideos', // in boolean
  Other_ViewTrainingStatus = 'viewTrainingStatus', // in boolean
  Other_EditPermissions = 'editPermissions',
  Other_EditHierarchy = 'editHierarchy',
  Other_ChangeAccountSettings = 'changeAccountSettings',
}

// Define the shape of the user role
export interface UserPermissions {
  id: string;
  name: string;
  iconName: string;
  isSuperAdmin: boolean;
  permissions: {
    [K in PermissionKeys]?: Permission;
  };
}

export interface OrgRole {
  id: string;
  roleName: string;
  iconName: string;
}

// Define the context shape
export interface PermissionsContextType {
  checkPermission: (permissionKey: string) => Permission | boolean;
  hasPermission: (permissionKey: PermissionKeys, userIDs: string[]) => boolean;
  hasTeamPermission: (
    permissionKey: PermissionKeys,
    teamIDs: string[]
  ) => boolean;
  hasAssignedRole: () => boolean;
  orgRoles: OrgRole[];
  isSuperAdmin: () => boolean | undefined;
}

export interface GetUserRoleResponse {
  getUserRole: {
    id: string;
    orgID: string;
    isDeleted: boolean;
    roleName: string;
    permissions: string; // permissions come as a JSON string
    isSuper: boolean;
    iconName: string;
    createdAt: string;
    updatedAt: string;
    __typename: string;
  };
}
