import React from 'react';
import { Box, Typography } from '@mui/material';
import { Areas } from '../../../../components/GamifyIcon';
import PermissionWithSwitch from './formComponents/PermissionWithSwitch';
import PermissionWithSelect from './formComponents/PermissionWithSelect';
import { useRolesPermissionsContext } from '../../context/RolesAndPermissionsContext';
import {
  permissionValue,
  PermissionCategoryProps,
  PermissionKeys,
} from './RoleAndPermissions.types';
import '../../settingsStyles.css';

const GamesRewards = React.memo(({ selectedRole }: PermissionCategoryProps) => {
  if (!selectedRole) return <></>;

  const { updateRole } = useRolesPermissionsContext();

  const handleRoleUpdate = (
    permissionKey: PermissionKeys,
    newValue: permissionValue | boolean
  ) => {
    updateRole(selectedRole.id, permissionKey, newValue);
  };
  return (
    <Box sx={{ px: 3, py: 2 }}>
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <Areas color="#878787" />
        <Typography sx={{ fontWeight: 700, color: '#878787' }}>
          Games
        </Typography>
      </Box>
      <Box className="permission-section">
        {/* GamesRewards_CreateGame */}
        <PermissionWithSwitch
          label="Create game"
          value={
            selectedRole?.permissions[PermissionKeys.GamesRewards_CreateGame]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.GamesRewards_CreateGame, val)
          }
        />
        {/* GamesRewards_UpdateGame Switch */}
        <PermissionWithSwitch
          label="Update game"
          value={
            selectedRole?.permissions[PermissionKeys.GamesRewards_UpdateGame]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.GamesRewards_UpdateGame, val)
          }
        />
        {/* GamesRewards_DeleteGame Switch */}
        <PermissionWithSwitch
          label="Delete game"
          value={
            selectedRole?.permissions[PermissionKeys.GamesRewards_DeleteGame]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.GamesRewards_DeleteGame, val)
          }
        />
        {/* GamesRewards_ViewGame */}
        <PermissionWithSelect
          label="View game"
          value={
            selectedRole?.permissions[PermissionKeys.GamesRewards_ViewGame]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.GamesRewards_ViewGame, val)
          }
        />
        {/* GamesRewards_ManagePoints */}
        <PermissionWithSelect
          label="Manage game points"
          value={
            selectedRole?.permissions[PermissionKeys.GamesRewards_ManagePoints]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.GamesRewards_ManagePoints, val)
          }
        />
      </Box>
      {/* section for Leaderboard with a single permission with switch for GamesRewards_CreateCustomLeaderboardViews */}
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <Areas color="#878787" />
        <Typography sx={{ fontWeight: 700, color: '#878787' }}>
          Leaderboard
        </Typography>
      </Box>
      <Box className="permission-section">
        {/* GamesRewards_CreateCustomLeaderboardViews Switch */}
        <PermissionWithSwitch
          label="Create custom leaderboard views"
          value={
            selectedRole?.permissions[
              PermissionKeys.GamesRewards_CreateCustomLeaderboardViews
            ]
          }
          onUpdate={(val) =>
            handleRoleUpdate(
              PermissionKeys.GamesRewards_CreateCustomLeaderboardViews,
              val
            )
          }
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <Areas color="#878787" />
        <Typography sx={{ fontWeight: 700, color: '#878787' }}>
          Rewards
        </Typography>
      </Box>
      <Box className="permission-section">
        {/* GamesRewards_CreateReward Switch */}
        <PermissionWithSwitch
          label="Create reward"
          value={
            selectedRole?.permissions[PermissionKeys.GamesRewards_CreateReward]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.GamesRewards_CreateReward, val)
          }
        />
        {/* GamesRewards_updateReward Switch */}
        <PermissionWithSwitch
          label="Update reward"
          value={
            selectedRole?.permissions[PermissionKeys.GamesRewards_UpdateReward]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.GamesRewards_UpdateReward, val)
          }
        />
        {/* GamesRewards_DeleteReward Switch */}
        <PermissionWithSwitch
          label="Delete reward"
          value={
            selectedRole?.permissions[PermissionKeys.GamesRewards_DeleteReward]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.GamesRewards_DeleteReward, val)
          }
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        <Areas color="#878787" />
        <Typography sx={{ fontWeight: 700, color: '#878787' }}>
          Gift cards
        </Typography>
      </Box>
      <Box className="permission-section">
        {/* GamesRewards_ManageCardAccount Switch */}
        <PermissionWithSwitch
          label="Manage gift card account"
          value={
            selectedRole?.permissions[
              PermissionKeys.GamesRewards_ManageCardAccount
            ]
          }
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.GamesRewards_ManageCardAccount, val)
          }
        />
        {/* GamesRewards_RemoveCardsGiftCardAccounts Switch */}
        <PermissionWithSwitch
          label="Remove cards from gift card account"
          value={
            selectedRole?.permissions[
              PermissionKeys.GamesRewards_RemoveCardsGiftCardAccounts
            ]
          }
          onUpdate={(val) =>
            handleRoleUpdate(
              PermissionKeys.GamesRewards_RemoveCardsGiftCardAccounts,
              val
            )
          }
        />
      </Box>
    </Box>
  );
});

GamesRewards.displayName = 'GamesRewards';
export default GamesRewards;
