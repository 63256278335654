import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  Tooltip,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomTextField } from './styledComponents';
import { hasUnsavedChanges } from '../utils/hasUnsavedChanges';
import { getPinsDict, newEmptyPin } from '../utils/tempsUtils';
import { StyledSelect, StyledMenuItem } from '../../../common/StyledComponents';
import {
  createStageDisposition,
  updateStageDisposition,
} from '../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { removeEmptyFields } from '../utils/removeEmptyFields';
const { v4: uuidv4 } = require('uuid');

const StageDispositionsEditor = ({
  dispositions,
  stageForms,
  stages,
  updateDispositions,
  orgID,
  categoryID,
}) => {
  const [workingDispositions, setWorkingDispositions] = useState([]);
  const [changedDispositions, setChangedDispositions] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [iconDialogOpen, setIconDialogOpen] = useState(false);
  const [deletePinIndex, setDeletePinIndex] = useState(null);
  const [newDefaultDisposition, setNewDefaultDisposition] = useState(null);
  const [selectedDispositionIndex, setSelectedDispositionIndex] =
    useState(null);
  const [draggedItemIndex, setDraggedItemIndex] = useState(null);
  const newPinRef = useRef(null);
  const pinItems = getPinsDict();

  useEffect(() => {
    setWorkingDispositions(dispositions);
  }, [dispositions]);

  const handleChange = (index, field, value) => {
    const updatedDispositions = [...workingDispositions];
    updatedDispositions[index] = {
      ...updatedDispositions[index],
      [field]: value,
    };
    setWorkingDispositions(updatedDispositions);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    event.stopPropagation();
    setExpanded(isExpanded ? panel : false);
  };

  const onDragStart = (index) => {
    setDraggedItemIndex(index);
  };

  const onDragOver = (index) => {
    if (draggedItemIndex === index) {
      return;
    }
    const items = workingDispositions.filter((item, idx) => {
      if (item.id === '123') console.log('ITEM'); // putting this line here just so i can use item variable
      return idx !== draggedItemIndex;
    });
    items.splice(index, 0, workingDispositions[draggedItemIndex]);
    setWorkingDispositions(items);
    setDraggedItemIndex(index);
  };

  const normalizeOrder = (dispositions) => {
    return dispositions.map((disposition, index) => ({
      ...disposition,
      order: index + 1,
    }));
  };

  const onDragEnd = () => {
    const updatedDispositions = normalizeOrder(workingDispositions);
    setWorkingDispositions(updatedDispositions);
    setDraggedItemIndex(null);
  };

  const saveChanges = async () => {
    const normalizedDispositions = normalizeOrder(workingDispositions);
    const changes = normalizedDispositions.filter((el) =>
      changedDispositions.includes(el.id)
    );
    const isValid = !changes.some(
      (el) => !el.title.length || !el.iconName.length
    );
    if (!isValid) {
      alert('All pins must have a title and an image name!');
      return;
    }
    const newPins = changes.filter((el) => el.newPin);

    const updatePins = changes.filter((el) => !el.newPin);
    for (const pin of newPins) {
      const submitPin = { ...pin };
      delete submitPin.newPin;
      await API.graphql(
        graphqlOperation(createStageDisposition, {
          input: removeEmptyFields(submitPin),
        })
      );
    }
    for (const pin of updatePins) {
      const submitPin = { ...pin };
      await API.graphql(
        graphqlOperation(updateStageDisposition, {
          input: removeEmptyFields(submitPin),
        })
      );
    }
    const newDispositions = [...normalizedDispositions];
    newDispositions.forEach((el) => {
      if (el.newPin) {
        delete el.newPin;
      }
    });
    updateDispositions(newDispositions);
    setChangedDispositions([]);
  };

  // -------

  const revertChanges = () => {
    setWorkingDispositions(dispositions);
    setChangedDispositions([]);
  };

  useEffect(() => {
    const updatedChangedDispositions = workingDispositions
      .filter((disposition, index) =>
        hasUnsavedChanges(dispositions[index], disposition)
      )
      .map((disposition) => disposition.id);
    setChangedDispositions(updatedChangedDispositions);
  }, [dispositions, workingDispositions]);

  const handleDefaultChange = (index, value) => {
    const currentDefault = workingDispositions.find(
      (disposition) => disposition.isDefault
    );
    if (currentDefault && currentDefault.id !== workingDispositions[index].id) {
      setNewDefaultDisposition({ index, value });
      setDialogOpen(true);
    } else {
      handleChange(index, 'isDefault', value);
    }
  };

  const confirmDefaultChange = () => {
    const updatedDispositions = workingDispositions.map(
      (disposition, index) => {
        if (disposition.isDefault) {
          return { ...disposition, isDefault: false };
        }
        if (index === newDefaultDisposition.index) {
          return { ...disposition, isDefault: newDefaultDisposition.value };
        }
        return disposition;
      }
    );
    setWorkingDispositions(updatedDispositions);
    setDialogOpen(false);
    setNewDefaultDisposition(null);
  };

  const openIconDialog = (index) => {
    setSelectedDispositionIndex(index);
    setIconDialogOpen(true);
  };

  const selectIcon = (iconName) => {
    handleChange(selectedDispositionIndex, 'iconName', iconName);
    setIconDialogOpen(false);
  };

  const createNewPin = () => {
    const newID = uuidv4();
    const newPin = newEmptyPin(newID, orgID, categoryID);
    newPin.order = workingDispositions.length + 1; // Order starts from 1
    newPin.newPin = true;
    setWorkingDispositions([...workingDispositions, newPin]);
    setExpanded(newID); // Expand the new pin's accordion
    setTimeout(() => {
      if (newPinRef.current) {
        newPinRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const deletePin = async () => {
    const updatedDispositions = [...workingDispositions];
    const pinID = updatedDispositions[deletePinIndex].id;

    await API.graphql(
      graphqlOperation(updateStageDisposition, {
        input: { id: pinID, isDeleted: true },
      })
    );
    updatedDispositions.splice(deletePinIndex, 1);
    setWorkingDispositions(updatedDispositions);
    setDeletePinIndex(null);
  };

  return (
    <Box border={'1px solid gray'}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="10px 35px"
        borderBottom="1px solid gray"
      >
        <Box display="flex" gap="16px">
          {changedDispositions.length > 0 && (
            <>
              <Button variant="contained" color="primary" onClick={saveChanges}>
                Save Changes
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={revertChanges}
              >
                Revert Changes
              </Button>
            </>
          )}
        </Box>
        <Button variant="contained" color="primary" onClick={createNewPin}>
          Create New Pin
        </Button>
      </Box>
      <Box>
        {workingDispositions.map((disposition, index) => {
          if (!disposition || !disposition.id) {
            console.error('Undefined disposition or missing id: ', disposition);
            return null; // Skip rendering this disposition if it's invalid
          }

          return (
            <Box
              key={disposition.id}
              draggable
              onDragStart={() => onDragStart(index)}
              onDragOver={() => onDragOver(index)}
              onDragEnd={onDragEnd}
              ref={disposition.id === expanded ? newPinRef : null}
            >
              <Accordion
                expanded={expanded === disposition.id}
                onChange={handleAccordionChange(disposition.id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`} // Ensure this is unique
                  style={{
                    backgroundColor:
                      expanded === disposition.id ? '#e0cfe8' : 'inherit',
                    padding: '10px 35px',
                    display: 'flex',
                    alignItems: 'center',
                    borderTop: '1px solid gray',
                  }}
                >
                  <Box
                    display="flex"
                    style={{
                      minWidth: '300px',
                      // backgroundColor: 'red'
                    }}
                  >
                    <img
                      className="single-pin-image"
                      style={{ width: '50px', height: '50px', margin: '5px' }}
                      src={pinItems[disposition.iconName]}
                      name={disposition.id}
                    />
                    <Typography
                      style={{
                        fontSize: 'large',
                        fontWeight: 700,
                        lineHeight: '5ch',
                        marginLeft: '10px',
                      }}
                    >
                      {disposition.title}
                    </Typography>
                  </Box>
                  {disposition.isSetupDeal && (
                    <Box>
                      <Typography style={{ lineHeight: '5ch' }}>{`Deal setup: ${
                        disposition.formName || 'deal'
                      }`}</Typography>
                    </Box>
                  )}
                </AccordionSummary>
                <AccordionDetails style={{ padding: '10px 35px' }}>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box display="flex" justifyContent="space-between">
                      <Box width="90%">
                        <Typography
                          style={{ fontSize: 'large', fontWeight: 500 }}
                          onClick={() => console.log(disposition)}
                        >
                          Title
                        </Typography>
                        <CustomTextField
                          value={disposition.title}
                          onChange={(e) =>
                            handleChange(index, 'title', e.target.value)
                          }
                          fullWidth
                          margin="normal"
                        />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <img
                          className="single-pin-image"
                          style={{
                            width: '90px',
                            height: '90px',
                            margin: '5px',
                          }}
                          src={pinItems[disposition.iconName]}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => openIconDialog(index)}
                        >
                          {disposition.iconName ? 'Change Icon' : 'Select Icon'}
                        </Button>
                      </Box>
                    </Box>
                    <Box
                      border={1}
                      borderColor="grey.500"
                      borderRadius={4}
                      p={2}
                      my={2}
                    >
                      <Typography variant="body2" color="textSecondary">
                        Only one disposition can be set as the default pin.
                        Changing the default pin will require confirmation.
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={disposition.isDefault}
                            onChange={(e) =>
                              handleDefaultChange(index, e.target.checked)
                            }
                          />
                        }
                        label="Set as Default"
                      />
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" flexDirection="column" width="48%">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={disposition.noRecording}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  'noRecording',
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="No Recording"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={disposition.requireDate}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  'requireDate',
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Require Date"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={disposition.isConversation}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  'isConversation',
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Is Conversation"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={disposition.isNotSelectable}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  'isNotSelectable',
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Is Not Selectable"
                        />
                        <FormControl component="fieldset" margin="normal">
                          <Typography
                            style={{ fontSize: 'large', fontWeight: 500 }}
                          >
                            Delete type
                          </Typography>
                          <RadioGroup
                            row
                            value={disposition.deleteType}
                            onChange={(e) =>
                              handleChange(index, 'deleteType', e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="always"
                              control={<Radio />}
                              label="Always"
                            />
                            <FormControlLabel
                              value="never"
                              control={<Radio />}
                              label="Never"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box display="flex" flexDirection="column" width="48%">
                        <FormControl fullWidth margin="normal">
                          <Typography
                            style={{ fontSize: 'large', fontWeight: 500 }}
                          >
                            Expires In
                          </Typography>
                          <Box
                            flexDirection={'row'}
                            display={'flex'}
                            alignItems={'center'}
                            gap={1}
                          >
                            <TextField
                              type="number"
                              sx={{
                                width: '15%',
                                fieldset: {
                                  padding: '0px',
                                  borderRadius: '2px !important',
                                },
                              }}
                              value={
                                disposition.expiresIn !== undefined
                                  ? disposition.expiresIn?.toString()
                                  : ''
                              }
                              onChange={(e) => {
                                let val = e.target.value;
                                if (val === '') {
                                  val = 0;
                                } else {
                                  val =
                                    parseInt(val.replace(/^0+/, ''), 10) || 0;
                                }
                                handleChange(index, 'expiresIn', val);
                              }}
                              fullWidth
                              margin="normal"
                            />
                            <Typography sx={{ fontWeight: '600' }}>
                              days
                            </Typography>
                          </Box>
                        </FormControl>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={disposition.isSetupDeal}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  'isSetupDeal',
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Is Setup Deal"
                        />
                        {disposition.isSetupDeal && (
                          <>
                            <FormControl fullWidth margin="normal">
                              <Typography
                                style={{ fontSize: 'large', fontWeight: 500 }}
                              >
                                Set to stage
                              </Typography>
                              <StyledSelect
                                MenuProps={{
                                  MenuListProps: {
                                    sx: {
                                      border: '1px solid gray',
                                      borderRadius: '4px',
                                      backgroundColor: 'white',
                                      padding: '0px 5px',
                                      marginTop: '2px',
                                      maxHeight: '250px',
                                      overflowY: 'auto',
                                    },
                                  },
                                }}
                                value={disposition.stageID || ''}
                                onChange={(e) =>
                                  handleChange(index, 'stageID', e.target.value)
                                }
                              >
                                {stages.map((stage) => (
                                  <StyledMenuItem
                                    key={stage.id}
                                    value={stage.id}
                                    style={{
                                      borderBottom: '1px solid lightgray',
                                    }}
                                  >
                                    {stage.title}
                                  </StyledMenuItem>
                                ))}
                              </StyledSelect>
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                              <Typography
                                style={{ fontSize: 'large', fontWeight: 500 }}
                              >
                                Use Form
                              </Typography>
                              <StyledSelect
                                MenuProps={{
                                  MenuListProps: {
                                    sx: {
                                      border: '1px solid gray',
                                      borderRadius: '4px',
                                      backgroundColor: 'white',
                                      padding: '0px 5px',
                                      marginTop: '2px',
                                      maxHeight: '250px',
                                      overflowY: 'auto',
                                    },
                                  },
                                }}
                                value={disposition.formName || ''}
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    'formName',
                                    e.target.value
                                  )
                                }
                              >
                                {stageForms.map((form) => (
                                  <StyledMenuItem
                                    key={form}
                                    value={form}
                                    style={{
                                      borderBottom: '1px solid lightgray',
                                    }}
                                  >
                                    {form}
                                  </StyledMenuItem>
                                ))}
                              </StyledSelect>
                            </FormControl>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setDeletePinIndex(index)}
                      >
                        Delete Pin
                      </Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Change Default Disposition'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`The disposition "
            ${workingDispositions.find((d) => d.isDefault)?.title}" is currently
            set as the default. Please confirm you want to change it to "
            ${workingDispositions[newDefaultDisposition?.index]?.title}".`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDefaultChange} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={iconDialogOpen}
        onClose={() => setIconDialogOpen(false)}
        aria-labelledby="icon-dialog-title"
        aria-describedby="icon-dialog-description"
      >
        <DialogTitle id="icon-dialog-title">{'Select Icon'}</DialogTitle>
        <DialogContent style={{ maxHeight: '60vh' }}>
          <DialogContentText id="icon-dialog-description">
            {'Please select an icon from the available list.'}
          </DialogContentText>
          <DialogContentText>
            {'Icons with red border are already used in other pins.'}
          </DialogContentText>
          <Box
            display="flex"
            flexWrap="wrap"
            style={{ maxWidth: '45vw', gap: '8px', marginTop: '5px' }}
          >
            {Object.entries(pinItems).map(([iconName, iconUrl]) => (
              <Tooltip key={iconName} title={iconName} placement="top">
                <img
                  className="pin-icon"
                  style={{
                    width: '80px',
                    height: '80px',
                    padding: 5,
                    borderRadius: '4px',
                    cursor: 'pointer',
                    border: workingDispositions.some(
                      (disposition) => disposition.iconName === iconName
                    )
                      ? '3px solid red'
                      : '2px solid transparent',
                  }}
                  src={iconUrl}
                  alt={iconName}
                  onClick={() => selectIcon(iconName)}
                />
              </Tooltip>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIconDialogOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(deletePinIndex)}
        onClose={() => setDeletePinIndex(null)}
        aria-labelledby="delete-pin-dialog-title"
        aria-describedby="delete-pin-dialog-description"
      >
        <DialogTitle id="delete-pin-dialog-title">
          {'Delete Pin Confirmation'}
        </DialogTitle>
        <DialogContent>
          {Boolean(deletePinIndex) && (
            <DialogContentText id="delete-pin-dialog-description">
              {`Are you sure you want to delete the pin titled "${workingDispositions[deletePinIndex].title}"? This action cannot be undone.`}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletePinIndex(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={deletePin} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StageDispositionsEditor;
