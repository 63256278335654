/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateFeedBlast = /* GraphQL */ `
  mutation UpdateFeedBlast(
    $id: ID!
    $header: String!
    $details: String!
    $imageName: String!
    $imageType: String!
    $showAfterEpoch: Float
    $showUntilEpoch: Float
  ) {
    updateFeedBlast(
      id: $id
      header: $header
      details: $details
      imageName: $imageName
      imageType: $imageType
      showAfterEpoch: $showAfterEpoch
      showUntilEpoch: $showUntilEpoch
    )
  }
`;
export const deleteFeedBlast = /* GraphQL */ `
  mutation DeleteFeedBlast($id: ID!) {
    deleteFeedBlast(id: $id)
  }
`;
export const circleFeedBlast = /* GraphQL */ `
  mutation CircleFeedBlast(
    $circleID: String!
    $header: String!
    $details: String!
    $imageName: String!
    $imageType: String!
    $blastEvent: String!
    $userID: String!
    $chartType: String
    $chartData: String
    $alertMessage: String
    $alertDetails: String
    $createdBy: String!
    $ccUserID: String!
    $feedType: String
    $showAfterEpoch: Float
    $showUntilEpoch: Float
  ) {
    circleFeedBlast(
      circleID: $circleID
      header: $header
      details: $details
      imageName: $imageName
      imageType: $imageType
      blastEvent: $blastEvent
      userID: $userID
      chartType: $chartType
      chartData: $chartData
      alertMessage: $alertMessage
      alertDetails: $alertDetails
      createdBy: $createdBy
      ccUserID: $ccUserID
      feedType: $feedType
      showAfterEpoch: $showAfterEpoch
      showUntilEpoch: $showUntilEpoch
    )
  }
`;
export const feedBlast = /* GraphQL */ `
  mutation FeedBlast(
    $header: String!
    $details: String!
    $imageName: String!
    $imageType: String!
    $blastEvent: String!
    $userID: String!
    $chartType: String
    $chartData: String
    $alertMessage: String
    $alertDetails: String
    $createdBy: String!
    $ccUserID: String!
    $feedType: String
    $showAfterEpoch: Float
    $showUntilEpoch: Float
  ) {
    feedBlast(
      header: $header
      details: $details
      imageName: $imageName
      imageType: $imageType
      blastEvent: $blastEvent
      userID: $userID
      chartType: $chartType
      chartData: $chartData
      alertMessage: $alertMessage
      alertDetails: $alertDetails
      createdBy: $createdBy
      ccUserID: $ccUserID
      feedType: $feedType
      showAfterEpoch: $showAfterEpoch
      showUntilEpoch: $showUntilEpoch
    )
  }
`;
export const circleApproverFeedBlast = /* GraphQL */ `
  mutation CircleApproverFeedBlast(
    $circleID: String!
    $header: String!
    $details: String!
    $imageName: String!
    $imageType: String!
    $blastEvent: String!
    $userID: String!
    $chartType: String
    $chartData: String
    $alertMessage: String
    $alertDetails: String
    $createdBy: String!
  ) {
    circleApproverFeedBlast(
      circleID: $circleID
      header: $header
      details: $details
      imageName: $imageName
      imageType: $imageType
      blastEvent: $blastEvent
      userID: $userID
      chartType: $chartType
      chartData: $chartData
      alertMessage: $alertMessage
      alertDetails: $alertDetails
      createdBy: $createdBy
    )
  }
`;
export const personAdminFunctions = /* GraphQL */ `
  mutation PersonAdminFunctions(
    $userID: ID!
    $username: String!
    $action: String!
    $users: AWSJSON
  ) {
    personAdminFunctions(
      userID: $userID
      username: $username
      action: $action
      users: $users
    )
  }
`;
export const acceptReward = /* GraphQL */ `
  mutation AcceptReward($rewardID: String!, $action: String!) {
    acceptReward(rewardID: $rewardID, action: $action)
  }
`;
export const completeMission = /* GraphQL */ `
  mutation CompleteMission($missionInfo: AWSJSON!) {
    completeMission(missionInfo: $missionInfo)
  }
`;
export const maintainDeal = /* GraphQL */ `
  mutation MaintainDeal($dealInfo: AWSJSON!) {
    maintainDeal(dealInfo: $dealInfo)
  }
`;
export const maintainDealV2 = /* GraphQL */ `
  mutation MaintainDealV2($dealInfo: AWSJSON!) {
    maintainDealV2(dealInfo: $dealInfo)
  }
`;
export const maintainDealV3 = /* GraphQL */ `
  mutation MaintainDealV3($dealInfo: AWSJSON!) {
    maintainDealV3(dealInfo: $dealInfo)
  }
`;
export const maintainProduct = /* GraphQL */ `
  mutation MaintainProduct($productInfo: AWSJSON!) {
    maintainProduct(productInfo: $productInfo)
  }
`;
export const deleteDealObject = /* GraphQL */ `
  mutation DeleteDealObject($id: ID!) {
    deleteDealObject(id: $id)
  }
`;
export const assignMissionToCircleMembers = /* GraphQL */ `
  mutation AssignMissionToCircleMembers($missionID: ID!, $circleID: ID!) {
    assignMissionToCircleMembers(missionID: $missionID, circleID: $circleID)
  }
`;
export const saveMission = /* GraphQL */ `
  mutation SaveMission($mission: AWSJSON!, $doFeedBlast: Boolean) {
    saveMission(mission: $mission, doFeedBlast: $doFeedBlast)
  }
`;
export const modifyPerson = /* GraphQL */ `
  mutation ModifyPerson($person: AWSJSON!) {
    modifyPerson(person: $person)
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson($person: AWSJSON!) {
    createPerson(person: $person)
  }
`;
export const modifyCircleMember = /* GraphQL */ `
  mutation ModifyCircleMember(
    $circleID: ID!
    $personID: ID!
    $userIDs: [ID]
    $status: String
    $role: String
    $priority: Int
  ) {
    modifyCircleMember(
      circleID: $circleID
      personID: $personID
      userIDs: $userIDs
      status: $status
      role: $role
      priority: $priority
    )
  }
`;
export const deleteMissionFunction = /* GraphQL */ `
  mutation DeleteMissionFunction($missionID: ID!) {
    deleteMissionFunction(missionID: $missionID)
  }
`;
export const maintainKPIGoals = /* GraphQL */ `
  mutation MaintainKPIGoals($KPIGoal: AWSJSON!) {
    maintainKPIGoals(KPIGoal: $KPIGoal)
  }
`;
export const listGiftcardCatalog = /* GraphQL */ `
  mutation ListGiftcardCatalog($orgID: String!) {
    listGiftcardCatalog(orgID: $orgID)
  }
`;
export const maintainCircleHeirarchy = /* GraphQL */ `
  mutation MaintainCircleHeirarchy(
    $circleID: ID!
    $parentCircleID: ID!
    $function: String!
  ) {
    maintainCircleHeirarchy(
      circleID: $circleID
      parentCircleID: $parentCircleID
      function: $function
    )
  }
`;
export const requestGiftcard = /* GraphQL */ `
  mutation RequestGiftcard($request: AWSJSON) {
    requestGiftcard(request: $request)
  }
`;
export const bookCalendarSlot = /* GraphQL */ `
  mutation BookCalendarSlot($request: AWSJSON) {
    bookCalendarSlot(request: $request)
  }
`;
export const cancelCalendarSlot = /* GraphQL */ `
  mutation CancelCalendarSlot($request: AWSJSON) {
    cancelCalendarSlot(request: $request)
  }
`;
export const deleteFeedItem = /* GraphQL */ `
  mutation DeleteFeedItem($request: AWSJSON) {
    deleteFeedItem(request: $request)
  }
`;
export const assignMissionToCircle = /* GraphQL */ `
  mutation AssignMissionToCircle($request: AWSJSON) {
    assignMissionToCircle(request: $request)
  }
`;
export const assignMissionToPerson = /* GraphQL */ `
  mutation AssignMissionToPerson($request: AWSJSON) {
    assignMissionToPerson(request: $request)
  }
`;
export const unAssignMissionFromCircle = /* GraphQL */ `
  mutation UnAssignMissionFromCircle($request: AWSJSON) {
    unAssignMissionFromCircle(request: $request)
  }
`;
export const unAssignMissionFromPerson = /* GraphQL */ `
  mutation UnAssignMissionFromPerson($request: AWSJSON) {
    unAssignMissionFromPerson(request: $request)
  }
`;
export const exportData = /* GraphQL */ `
  mutation ExportData($request: AWSJSON) {
    exportData(request: $request)
  }
`;
export const tangoListCreditCards = /* GraphQL */ `
  mutation TangoListCreditCards($request: AWSJSON!) {
    tangoListCreditCards(request: $request)
  }
`;
export const tangoAddCreditCard = /* GraphQL */ `
  mutation TangoAddCreditCard($request: AWSJSON!) {
    tangoAddCreditCard(request: $request)
  }
`;
export const tangoRemoveCreditCard = /* GraphQL */ `
  mutation TangoRemoveCreditCard($request: AWSJSON!) {
    tangoRemoveCreditCard(request: $request)
  }
`;
export const tangoListCreditCardDeposits = /* GraphQL */ `
  mutation TangoListCreditCardDeposits($request: AWSJSON!) {
    tangoListCreditCardDeposits(request: $request)
  }
`;
export const tangoCreditCardDeposit = /* GraphQL */ `
  mutation TangoCreditCardDeposit($request: AWSJSON!) {
    tangoCreditCardDeposit(request: $request)
  }
`;
export const tangoListAccounts = /* GraphQL */ `
  mutation TangoListAccounts($request: AWSJSON!) {
    tangoListAccounts(request: $request)
  }
`;
export const tangoCreateAccount = /* GraphQL */ `
  mutation TangoCreateAccount($request: AWSJSON!) {
    tangoCreateAccount(request: $request)
  }
`;
export const tangoCreateCustomer = /* GraphQL */ `
  mutation TangoCreateCustomer($request: AWSJSON!) {
    tangoCreateCustomer(request: $request)
  }
`;
export const getKPILeadeboards = /* GraphQL */ `
  mutation GetKPILeadeboards($request: AWSJSON!) {
    getKPILeadeboards(request: $request)
  }
`;
export const boostActivityFeedItem = /* GraphQL */ `
  mutation BoostActivityFeedItem($request: AWSJSON!) {
    boostActivityFeedItem(request: $request)
  }
`;
export const maintainStage = /* GraphQL */ `
  mutation MaintainStage($request: AWSJSON!) {
    maintainStage(request: $request)
  }
`;
export const maintainKPIRule = /* GraphQL */ `
  mutation MaintainKPIRule($request: AWSJSON!) {
    maintainKPIRule(request: $request)
  }
`;
export const giveGift = /* GraphQL */ `
  mutation GiveGift(
    $giveToUserID: ID!
    $points: Int
    $rewardID: ID
    $fromAdmin: Boolean!
  ) {
    giveGift(
      giveToUserID: $giveToUserID
      points: $points
      rewardID: $rewardID
      fromAdmin: $fromAdmin
    )
  }
`;
export const removeDealsAndPins = /* GraphQL */ `
  mutation RemoveDealsAndPins($request: AWSJSON!) {
    removeDealsAndPins(request: $request)
  }
`;
export const maintainDealOwners = /* GraphQL */ `
  mutation MaintainDealOwners($request: AWSJSON!) {
    maintainDealOwners(request: $request)
  }
`;
export const createLesson = /* GraphQL */ `
  mutation CreateLesson(
    $input: CreateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    createLesson(input: $input, condition: $condition) {
      id
      orgID
      name
      description
      isDeleted
      startDate
      endDate
      details {
        items {
          id
          lessonID
          orgID
          name
          description
          order
          imageType
          imageName
          youTubeURL
          flexFields
          startDate
          endDate
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateLesson = /* GraphQL */ `
  mutation UpdateLesson(
    $input: UpdateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    updateLesson(input: $input, condition: $condition) {
      id
      orgID
      name
      description
      isDeleted
      startDate
      endDate
      details {
        items {
          id
          lessonID
          orgID
          name
          description
          order
          imageType
          imageName
          youTubeURL
          flexFields
          startDate
          endDate
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteLesson = /* GraphQL */ `
  mutation DeleteLesson(
    $input: DeleteLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    deleteLesson(input: $input, condition: $condition) {
      id
      orgID
      name
      description
      isDeleted
      startDate
      endDate
      details {
        items {
          id
          lessonID
          orgID
          name
          description
          order
          imageType
          imageName
          youTubeURL
          flexFields
          startDate
          endDate
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createLessonDetail = /* GraphQL */ `
  mutation CreateLessonDetail(
    $input: CreateLessonDetailInput!
    $condition: ModelLessonDetailConditionInput
  ) {
    createLessonDetail(input: $input, condition: $condition) {
      id
      lessonID
      orgID
      name
      description
      order
      imageType
      imageName
      youTubeURL
      flexFields
      startDate
      endDate
      isDeleted
      posterImageName
      category
      assignments {
        items {
          id
          lessonDetailID
          orgID
          assignedToID
          assignedToType
          flexAttributes
          startedTmst
          completedTmst
          percentComplete
          isStarted
          isComplete
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      lesson {
        id
        orgID
        name
        description
        isDeleted
        startDate
        endDate
        details {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateLessonDetail = /* GraphQL */ `
  mutation UpdateLessonDetail(
    $input: UpdateLessonDetailInput!
    $condition: ModelLessonDetailConditionInput
  ) {
    updateLessonDetail(input: $input, condition: $condition) {
      id
      lessonID
      orgID
      name
      description
      order
      imageType
      imageName
      youTubeURL
      flexFields
      startDate
      posterImageName
      endDate
      isDeleted
      assignments {
        items {
          id
          lessonDetailID
          orgID
          assignedToID
          assignedToType
          flexAttributes
          startedTmst
          completedTmst
          percentComplete
          isStarted
          isComplete
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      lesson {
        id
        orgID
        name
        description
        isDeleted
        startDate
        endDate
        details {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteLessonDetail = /* GraphQL */ `
  mutation DeleteLessonDetail(
    $input: DeleteLessonDetailInput!
    $condition: ModelLessonDetailConditionInput
  ) {
    deleteLessonDetail(input: $input, condition: $condition) {
      id
      lessonID
      orgID
      name
      description
      order
      imageType
      imageName
      youTubeURL
      flexFields
      startDate
      endDate
      isDeleted
      assignments {
        items {
          id
          lessonDetailID
          orgID
          assignedToID
          assignedToType
          flexAttributes
          startedTmst
          completedTmst
          percentComplete
          isStarted
          isComplete
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      lesson {
        id
        orgID
        name
        description
        isDeleted
        startDate
        endDate
        details {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createLessonAssignment = /* GraphQL */ `
  mutation CreateLessonAssignment(
    $input: CreateLessonAssignmentInput!
    $condition: ModelLessonAssignmentConditionInput
  ) {
    createLessonAssignment(input: $input, condition: $condition) {
      id
      lessonDetailID
      orgID
      assignedToID
      assignedToType
      flexAttributes
      startedTmst
      completedTmst
      percentComplete
      isStarted
      isComplete
      isDeleted
      lessonDetail {
        id
        lessonID
        orgID
        name
        description
        order
        imageType
        imageName
        youTubeURL
        flexFields
        startDate
        endDate
        isDeleted
        assignments {
          nextToken
        }
        lesson {
          id
          orgID
          name
          description
          isDeleted
          startDate
          endDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateLessonAssignment = /* GraphQL */ `
  mutation UpdateLessonAssignment(
    $input: UpdateLessonAssignmentInput!
    $condition: ModelLessonAssignmentConditionInput
  ) {
    updateLessonAssignment(input: $input, condition: $condition) {
      id
      lessonDetailID
      orgID
      assignedToID
      assignedToType
      flexAttributes
      startedTmst
      completedTmst
      percentComplete
      isStarted
      isComplete
      isDeleted
      lessonDetail {
        id
        lessonID
        orgID
        name
        description
        order
        imageType
        imageName
        youTubeURL
        flexFields
        startDate
        endDate
        isDeleted
        assignments {
          nextToken
        }
        lesson {
          id
          orgID
          name
          description
          isDeleted
          startDate
          endDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteLessonAssignment = /* GraphQL */ `
  mutation DeleteLessonAssignment(
    $input: DeleteLessonAssignmentInput!
    $condition: ModelLessonAssignmentConditionInput
  ) {
    deleteLessonAssignment(input: $input, condition: $condition) {
      id
      lessonDetailID
      orgID
      assignedToID
      assignedToType
      flexAttributes
      startedTmst
      completedTmst
      percentComplete
      isStarted
      isComplete
      isDeleted
      lessonDetail {
        id
        lessonID
        orgID
        name
        description
        order
        imageType
        imageName
        youTubeURL
        flexFields
        startDate
        endDate
        isDeleted
        assignments {
          nextToken
        }
        lesson {
          id
          orgID
          name
          description
          isDeleted
          startDate
          endDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createCreditCardAuths = /* GraphQL */ `
  mutation CreateCreditCardAuths(
    $input: CreateCreditCardAuthsInput!
    $condition: ModelcreditCardAuthsConditionInput
  ) {
    createCreditCardAuths(input: $input, condition: $condition) {
      id
      orgID
      amount
      amountCharged
      feePercent
      referenceDepositID
      status
      creditCardToken
      userID
      creditCardLabel
      lastFourDigits
      expirationDate
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateCreditCardAuths = /* GraphQL */ `
  mutation UpdateCreditCardAuths(
    $input: UpdateCreditCardAuthsInput!
    $condition: ModelcreditCardAuthsConditionInput
  ) {
    updateCreditCardAuths(input: $input, condition: $condition) {
      id
      orgID
      amount
      amountCharged
      feePercent
      referenceDepositID
      status
      creditCardToken
      userID
      creditCardLabel
      lastFourDigits
      expirationDate
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteCreditCardAuths = /* GraphQL */ `
  mutation DeleteCreditCardAuths(
    $input: DeleteCreditCardAuthsInput!
    $condition: ModelcreditCardAuthsConditionInput
  ) {
    deleteCreditCardAuths(input: $input, condition: $condition) {
      id
      orgID
      amount
      amountCharged
      feePercent
      referenceDepositID
      status
      creditCardToken
      userID
      creditCardLabel
      lastFourDigits
      expirationDate
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createExportableData = /* GraphQL */ `
  mutation CreateExportableData(
    $input: CreateExportableDataInput!
    $condition: ModelExportableDataConditionInput
  ) {
    createExportableData(input: $input, condition: $condition) {
      id
      name
      description
      allowStartDate
      allowEndDate
      allowAll
      allowChangeEmail
      createdAt
      updatedAt
    }
  }
`;
export const updateExportableData = /* GraphQL */ `
  mutation UpdateExportableData(
    $input: UpdateExportableDataInput!
    $condition: ModelExportableDataConditionInput
  ) {
    updateExportableData(input: $input, condition: $condition) {
      id
      name
      description
      allowStartDate
      allowEndDate
      allowAll
      allowChangeEmail
      createdAt
      updatedAt
    }
  }
`;
export const deleteExportableData = /* GraphQL */ `
  mutation DeleteExportableData(
    $input: DeleteExportableDataInput!
    $condition: ModelExportableDataConditionInput
  ) {
    deleteExportableData(input: $input, condition: $condition) {
      id
      name
      description
      allowStartDate
      allowEndDate
      allowAll
      allowChangeEmail
      createdAt
      updatedAt
    }
  }
`;
export const createExportedData = /* GraphQL */ `
  mutation CreateExportedData(
    $input: CreateExportedDataInput!
    $condition: ModelExportedDataConditionInput
  ) {
    createExportedData(input: $input, condition: $condition) {
      id
      orgID
      exportDataID
      lastExportTmst
      exportableData {
        id
        name
        description
        allowStartDate
        allowEndDate
        allowAll
        allowChangeEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateExportedData = /* GraphQL */ `
  mutation UpdateExportedData(
    $input: UpdateExportedDataInput!
    $condition: ModelExportedDataConditionInput
  ) {
    updateExportedData(input: $input, condition: $condition) {
      id
      orgID
      exportDataID
      lastExportTmst
      exportableData {
        id
        name
        description
        allowStartDate
        allowEndDate
        allowAll
        allowChangeEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteExportedData = /* GraphQL */ `
  mutation DeleteExportedData(
    $input: DeleteExportedDataInput!
    $condition: ModelExportedDataConditionInput
  ) {
    deleteExportedData(input: $input, condition: $condition) {
      id
      orgID
      exportDataID
      lastExportTmst
      exportableData {
        id
        name
        description
        allowStartDate
        allowEndDate
        allowAll
        allowChangeEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createExportedDataHistory = /* GraphQL */ `
  mutation CreateExportedDataHistory(
    $input: CreateExportedDataHistoryInput!
    $condition: ModelExportedDataHistoryConditionInput
  ) {
    createExportedDataHistory(input: $input, condition: $condition) {
      id
      orgID
      exportDataID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateExportedDataHistory = /* GraphQL */ `
  mutation UpdateExportedDataHistory(
    $input: UpdateExportedDataHistoryInput!
    $condition: ModelExportedDataHistoryConditionInput
  ) {
    updateExportedDataHistory(input: $input, condition: $condition) {
      id
      orgID
      exportDataID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteExportedDataHistory = /* GraphQL */ `
  mutation DeleteExportedDataHistory(
    $input: DeleteExportedDataHistoryInput!
    $condition: ModelExportedDataHistoryConditionInput
  ) {
    deleteExportedDataHistory(input: $input, condition: $condition) {
      id
      orgID
      exportDataID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createServiceArea = /* GraphQL */ `
  mutation CreateServiceArea(
    $input: CreateServiceAreaInput!
    $condition: ModelServiceAreaConditionInput
  ) {
    createServiceArea(input: $input, condition: $condition) {
      id
      orgID
      name
      description
      coords {
        lat
        lon
      }
      zipCodes
      calendars {
        items {
          id
          orgID
          scheduleDate
          areaID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateServiceArea = /* GraphQL */ `
  mutation UpdateServiceArea(
    $input: UpdateServiceAreaInput!
    $condition: ModelServiceAreaConditionInput
  ) {
    updateServiceArea(input: $input, condition: $condition) {
      id
      orgID
      name
      description
      coords {
        lat
        lon
      }
      zipCodes
      calendars {
        items {
          id
          orgID
          scheduleDate
          areaID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteServiceArea = /* GraphQL */ `
  mutation DeleteServiceArea(
    $input: DeleteServiceAreaInput!
    $condition: ModelServiceAreaConditionInput
  ) {
    deleteServiceArea(input: $input, condition: $condition) {
      id
      orgID
      name
      description
      coords {
        lat
        lon
      }
      zipCodes
      calendars {
        items {
          id
          orgID
          scheduleDate
          areaID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createCalendar = /* GraphQL */ `
  mutation CreateCalendar(
    $input: CreateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    createCalendar(input: $input, condition: $condition) {
      id
      orgID
      scheduleDate
      areaID
      area {
        id
        orgID
        name
        description
        coords {
          lat
          lon
        }
        zipCodes
        calendars {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      slots {
        items {
          id
          orgID
          calendarID
          startTime
          endTime
          available
          scheduled
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateCalendar = /* GraphQL */ `
  mutation UpdateCalendar(
    $input: UpdateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    updateCalendar(input: $input, condition: $condition) {
      id
      orgID
      scheduleDate
      areaID
      area {
        id
        orgID
        name
        description
        coords {
          lat
          lon
        }
        zipCodes
        calendars {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      slots {
        items {
          id
          orgID
          calendarID
          startTime
          endTime
          available
          scheduled
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteCalendar = /* GraphQL */ `
  mutation DeleteCalendar(
    $input: DeleteCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    deleteCalendar(input: $input, condition: $condition) {
      id
      orgID
      scheduleDate
      areaID
      area {
        id
        orgID
        name
        description
        coords {
          lat
          lon
        }
        zipCodes
        calendars {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      slots {
        items {
          id
          orgID
          calendarID
          startTime
          endTime
          available
          scheduled
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createCalendarSlot = /* GraphQL */ `
  mutation CreateCalendarSlot(
    $input: CreateCalendarSlotInput!
    $condition: ModelCalendarSlotConditionInput
  ) {
    createCalendarSlot(input: $input, condition: $condition) {
      id
      orgID
      calendarID
      startTime
      endTime
      appointments {
        items {
          id
          orgID
          slotID
          dealID
          notes
          status
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      available
      scheduled
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateCalendarSlot = /* GraphQL */ `
  mutation UpdateCalendarSlot(
    $input: UpdateCalendarSlotInput!
    $condition: ModelCalendarSlotConditionInput
  ) {
    updateCalendarSlot(input: $input, condition: $condition) {
      id
      orgID
      calendarID
      startTime
      endTime
      appointments {
        items {
          id
          orgID
          slotID
          dealID
          notes
          status
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      available
      scheduled
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteCalendarSlot = /* GraphQL */ `
  mutation DeleteCalendarSlot(
    $input: DeleteCalendarSlotInput!
    $condition: ModelCalendarSlotConditionInput
  ) {
    deleteCalendarSlot(input: $input, condition: $condition) {
      id
      orgID
      calendarID
      startTime
      endTime
      appointments {
        items {
          id
          orgID
          slotID
          dealID
          notes
          status
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      available
      scheduled
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createCalendarAppointment = /* GraphQL */ `
  mutation CreateCalendarAppointment(
    $input: CreateCalendarAppointmentInput!
    $condition: ModelCalendarAppointmentConditionInput
  ) {
    createCalendarAppointment(input: $input, condition: $condition) {
      id
      orgID
      slotID
      dealID
      notes
      status
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      slot {
        id
        orgID
        calendarID
        startTime
        endTime
        appointments {
          nextToken
        }
        available
        scheduled
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateCalendarAppointment = /* GraphQL */ `
  mutation UpdateCalendarAppointment(
    $input: UpdateCalendarAppointmentInput!
    $condition: ModelCalendarAppointmentConditionInput
  ) {
    updateCalendarAppointment(input: $input, condition: $condition) {
      id
      orgID
      slotID
      dealID
      notes
      status
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      slot {
        id
        orgID
        calendarID
        startTime
        endTime
        appointments {
          nextToken
        }
        available
        scheduled
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteCalendarAppointment = /* GraphQL */ `
  mutation DeleteCalendarAppointment(
    $input: DeleteCalendarAppointmentInput!
    $condition: ModelCalendarAppointmentConditionInput
  ) {
    deleteCalendarAppointment(input: $input, condition: $condition) {
      id
      orgID
      slotID
      dealID
      notes
      status
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      slot {
        id
        orgID
        calendarID
        startTime
        endTime
        appointments {
          nextToken
        }
        available
        scheduled
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createMessageThrottle = /* GraphQL */ `
  mutation CreateMessageThrottle(
    $input: CreateMessageThrottleInput!
    $condition: ModelMessageThrottleConditionInput
  ) {
    createMessageThrottle(input: $input, condition: $condition) {
      id
      lastReceivedTmst
      lastSentTmst
      throttledMessage
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageThrottle = /* GraphQL */ `
  mutation UpdateMessageThrottle(
    $input: UpdateMessageThrottleInput!
    $condition: ModelMessageThrottleConditionInput
  ) {
    updateMessageThrottle(input: $input, condition: $condition) {
      id
      lastReceivedTmst
      lastSentTmst
      throttledMessage
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageThrottle = /* GraphQL */ `
  mutation DeleteMessageThrottle(
    $input: DeleteMessageThrottleInput!
    $condition: ModelMessageThrottleConditionInput
  ) {
    deleteMessageThrottle(input: $input, condition: $condition) {
      id
      lastReceivedTmst
      lastSentTmst
      throttledMessage
      createdAt
      updatedAt
    }
  }
`;
export const createPersonCommission = /* GraphQL */ `
  mutation CreatePersonCommission(
    $input: CreatePersonCommissionInput!
    $condition: ModelPersonCommissionConditionInput
  ) {
    createPersonCommission(input: $input, condition: $condition) {
      id
      orgID
      userID
      commissionEndDate
      amount
      status
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updatePersonCommission = /* GraphQL */ `
  mutation UpdatePersonCommission(
    $input: UpdatePersonCommissionInput!
    $condition: ModelPersonCommissionConditionInput
  ) {
    updatePersonCommission(input: $input, condition: $condition) {
      id
      orgID
      userID
      commissionEndDate
      amount
      status
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deletePersonCommission = /* GraphQL */ `
  mutation DeletePersonCommission(
    $input: DeletePersonCommissionInput!
    $condition: ModelPersonCommissionConditionInput
  ) {
    deletePersonCommission(input: $input, condition: $condition) {
      id
      orgID
      userID
      commissionEndDate
      amount
      status
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createPersonCommissionDetails = /* GraphQL */ `
  mutation CreatePersonCommissionDetails(
    $input: CreatePersonCommissionDetailsInput!
    $condition: ModelPersonCommissionDetailsConditionInput
  ) {
    createPersonCommissionDetails(input: $input, condition: $condition) {
      id
      orgID
      userID
      personCommissionID
      dealID
      stageID
      dispositionID
      amount
      status
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updatePersonCommissionDetails = /* GraphQL */ `
  mutation UpdatePersonCommissionDetails(
    $input: UpdatePersonCommissionDetailsInput!
    $condition: ModelPersonCommissionDetailsConditionInput
  ) {
    updatePersonCommissionDetails(input: $input, condition: $condition) {
      id
      orgID
      userID
      personCommissionID
      dealID
      stageID
      dispositionID
      amount
      status
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deletePersonCommissionDetails = /* GraphQL */ `
  mutation DeletePersonCommissionDetails(
    $input: DeletePersonCommissionDetailsInput!
    $condition: ModelPersonCommissionDetailsConditionInput
  ) {
    deletePersonCommissionDetails(input: $input, condition: $condition) {
      id
      orgID
      userID
      personCommissionID
      dealID
      stageID
      dispositionID
      amount
      status
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createPersonCommissionModel = /* GraphQL */ `
  mutation CreatePersonCommissionModel(
    $input: CreatePersonCommissionModelInput!
    $condition: ModelPersonCommissionModelConditionInput
  ) {
    createPersonCommissionModel(input: $input, condition: $condition) {
      id
      orgID
      stageID
      dispositionID
      userID
      commissionModelID
      commissionType
      scheduleType
      schedule {
        valueType
        tierMaxValue
        tierPaymentValue
        tierPaymentPercent
      }
      startDate
      endDate
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updatePersonCommissionModel = /* GraphQL */ `
  mutation UpdatePersonCommissionModel(
    $input: UpdatePersonCommissionModelInput!
    $condition: ModelPersonCommissionModelConditionInput
  ) {
    updatePersonCommissionModel(input: $input, condition: $condition) {
      id
      orgID
      stageID
      dispositionID
      userID
      commissionModelID
      commissionType
      scheduleType
      schedule {
        valueType
        tierMaxValue
        tierPaymentValue
        tierPaymentPercent
      }
      startDate
      endDate
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deletePersonCommissionModel = /* GraphQL */ `
  mutation DeletePersonCommissionModel(
    $input: DeletePersonCommissionModelInput!
    $condition: ModelPersonCommissionModelConditionInput
  ) {
    deletePersonCommissionModel(input: $input, condition: $condition) {
      id
      orgID
      stageID
      dispositionID
      userID
      commissionModelID
      commissionType
      scheduleType
      schedule {
        valueType
        tierMaxValue
        tierPaymentValue
        tierPaymentPercent
      }
      startDate
      endDate
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createCommissionModel = /* GraphQL */ `
  mutation CreateCommissionModel(
    $input: CreateCommissionModelInput!
    $condition: ModelCommissionModelConditionInput
  ) {
    createCommissionModel(input: $input, condition: $condition) {
      id
      orgID
      stageID
      dispositionID
      name
      description
      commissionType
      scheduleType
      schedule {
        valueType
        tierMaxValue
        tierPaymentValue
        tierPaymentPercent
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateCommissionModel = /* GraphQL */ `
  mutation UpdateCommissionModel(
    $input: UpdateCommissionModelInput!
    $condition: ModelCommissionModelConditionInput
  ) {
    updateCommissionModel(input: $input, condition: $condition) {
      id
      orgID
      stageID
      dispositionID
      name
      description
      commissionType
      scheduleType
      schedule {
        valueType
        tierMaxValue
        tierPaymentValue
        tierPaymentPercent
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteCommissionModel = /* GraphQL */ `
  mutation DeleteCommissionModel(
    $input: DeleteCommissionModelInput!
    $condition: ModelCommissionModelConditionInput
  ) {
    deleteCommissionModel(input: $input, condition: $condition) {
      id
      orgID
      stageID
      dispositionID
      name
      description
      commissionType
      scheduleType
      schedule {
        valueType
        tierMaxValue
        tierPaymentValue
        tierPaymentPercent
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createUserAddress = /* GraphQL */ `
  mutation CreateUserAddress(
    $input: CreateUserAddressInput!
    $condition: ModelUserAddressConditionInput
  ) {
    createUserAddress(input: $input, condition: $condition) {
      id
      userID
      orgID
      type
      address1
      address2
      city
      region
      country
      postalCode
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateUserAddress = /* GraphQL */ `
  mutation UpdateUserAddress(
    $input: UpdateUserAddressInput!
    $condition: ModelUserAddressConditionInput
  ) {
    updateUserAddress(input: $input, condition: $condition) {
      id
      userID
      orgID
      type
      address1
      address2
      city
      region
      country
      postalCode
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteUserAddress = /* GraphQL */ `
  mutation DeleteUserAddress(
    $input: DeleteUserAddressInput!
    $condition: ModelUserAddressConditionInput
  ) {
    deleteUserAddress(input: $input, condition: $condition) {
      id
      userID
      orgID
      type
      address1
      address2
      city
      region
      country
      postalCode
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      orgID
      street
      address
      city
      region
      country
      postalCode
      location {
        lat
        lon
      }
      lastVisited
      nextVisit
      currentOwnerID
      lastVisitedByID
      lastVisitedByName
      currentDispositionID
      status
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      orgID
      street
      address
      city
      region
      country
      postalCode
      location {
        lat
        lon
      }
      lastVisited
      nextVisit
      currentOwnerID
      lastVisitedByID
      lastVisitedByName
      currentDispositionID
      status
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      orgID
      street
      address
      city
      region
      country
      postalCode
      location {
        lat
        lon
      }
      lastVisited
      nextVisit
      currentOwnerID
      lastVisitedByID
      lastVisitedByName
      currentDispositionID
      status
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createGeoFence = /* GraphQL */ `
  mutation CreateGeoFence(
    $input: CreateGeoFenceInput!
    $condition: ModelgeoFenceConditionInput
  ) {
    createGeoFence(input: $input, condition: $condition) {
      id
      orgID
      points {
        lat
        lon
      }
      assigneeID
      assigneeType
      currentUserID
      status
      name
      description
      possibleAddresses
      knockedAddresses
      tags
      lastVisited
      scheduledDate
      createdBy
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      currentUser {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateGeoFence = /* GraphQL */ `
  mutation UpdateGeoFence(
    $input: UpdateGeoFenceInput!
    $condition: ModelgeoFenceConditionInput
  ) {
    updateGeoFence(input: $input, condition: $condition) {
      id
      orgID
      points {
        lat
        lon
      }
      assigneeID
      assigneeType
      currentUserID
      status
      name
      description
      possibleAddresses
      knockedAddresses
      tags
      lastVisited
      scheduledDate
      createdBy
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      currentUser {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteGeoFence = /* GraphQL */ `
  mutation DeleteGeoFence(
    $input: DeleteGeoFenceInput!
    $condition: ModelgeoFenceConditionInput
  ) {
    deleteGeoFence(input: $input, condition: $condition) {
      id
      orgID
      points {
        lat
        lon
      }
      assigneeID
      assigneeType
      currentUserID
      status
      name
      description
      possibleAddresses
      knockedAddresses
      tags
      lastVisited
      scheduledDate
      createdBy
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      currentUser {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createDealOwnerHistory = /* GraphQL */ `
  mutation CreateDealOwnerHistory(
    $input: CreateDealOwnerHistoryInput!
    $condition: ModelDealOwnerHistoryConditionInput
  ) {
    createDealOwnerHistory(input: $input, condition: $condition) {
      id
      dealID
      orgID
      userID
      ownerType
      isActive
      percent
      isCalculateKPIs
      isMissionCredit
      notes
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateDealOwnerHistory = /* GraphQL */ `
  mutation UpdateDealOwnerHistory(
    $input: UpdateDealOwnerHistoryInput!
    $condition: ModelDealOwnerHistoryConditionInput
  ) {
    updateDealOwnerHistory(input: $input, condition: $condition) {
      id
      dealID
      orgID
      userID
      ownerType
      isActive
      percent
      isCalculateKPIs
      isMissionCredit
      notes
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteDealOwnerHistory = /* GraphQL */ `
  mutation DeleteDealOwnerHistory(
    $input: DeleteDealOwnerHistoryInput!
    $condition: ModelDealOwnerHistoryConditionInput
  ) {
    deleteDealOwnerHistory(input: $input, condition: $condition) {
      id
      dealID
      orgID
      userID
      ownerType
      isActive
      percent
      isCalculateKPIs
      isMissionCredit
      notes
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createDeal = /* GraphQL */ `
  mutation CreateDeal(
    $input: CreateDealInput!
    $condition: ModelDealConditionInput
  ) {
    createDeal(input: $input, condition: $condition) {
      id
      orgID
      categoryID
      extID
      name
      sortName
      description
      searchString
      ownersString
      value
      notes
      status
      currentStageID
      currentOwnerID
      defaultCircleID
      currentOwnerType
      currentDispositionID
      disposition {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextFollowupDate
      reminderSentTmst
      pinAddress {
        id
        orgID
        street
        address
        city
        region
        country
        postalCode
        location {
          lat
          lon
        }
        lastVisited
        nextVisit
        currentOwnerID
        lastVisitedByID
        lastVisitedByName
        currentDispositionID
        status
        deal {
          id
          orgID
          categoryID
          extID
          name
          sortName
          description
          searchString
          ownersString
          value
          notes
          status
          currentStageID
          currentOwnerID
          defaultCircleID
          currentOwnerType
          currentDispositionID
          nextFollowupDate
          reminderSentTmst
          flexAttributes
          previousFlexAttributes
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      stage {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      flexAttributes
      previousFlexAttributes
      actions {
        items {
          id
          userMissionID
          userID
          missionID
          ownerID
          KPIRuleID
          actionCount
          currentActionCount
          rewardCount
          currentRewardCount
          comments
          imageName
          imageType
          extID
          dealID
          flexAttributes
          goalCount
          actionTimestamp
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      images {
        items {
          id
          orgID
          dealID
          description
          type
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      comments {
        items {
          id
          orgID
          dealID
          comment
          dispositionID
          followupDate
          contactType
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      appointments {
        items {
          id
          orgID
          slotID
          dealID
          notes
          status
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      transactions {
        items {
          id
          dealID
          orgID
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      ownerHistory {
        items {
          id
          dealID
          orgID
          userID
          ownerType
          isActive
          percent
          isCalculateKPIs
          isMissionCredit
          notes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateDeal = /* GraphQL */ `
  mutation UpdateDeal(
    $input: UpdateDealInput!
    $condition: ModelDealConditionInput
  ) {
    updateDeal(input: $input, condition: $condition) {
      id
      orgID
      categoryID
      extID
      name
      sortName
      description
      searchString
      ownersString
      value
      notes
      status
      currentStageID
      currentOwnerID
      defaultCircleID
      currentOwnerType
      currentDispositionID
      disposition {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextFollowupDate
      reminderSentTmst
      pinAddress {
        id
        orgID
        street
        address
        city
        region
        country
        postalCode
        location {
          lat
          lon
        }
        lastVisited
        nextVisit
        currentOwnerID
        lastVisitedByID
        lastVisitedByName
        currentDispositionID
        status
        deal {
          id
          orgID
          categoryID
          extID
          name
          sortName
          description
          searchString
          ownersString
          value
          notes
          status
          currentStageID
          currentOwnerID
          defaultCircleID
          currentOwnerType
          currentDispositionID
          nextFollowupDate
          reminderSentTmst
          flexAttributes
          previousFlexAttributes
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      stage {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      flexAttributes
      previousFlexAttributes
      actions {
        items {
          id
          userMissionID
          userID
          missionID
          ownerID
          KPIRuleID
          actionCount
          currentActionCount
          rewardCount
          currentRewardCount
          comments
          imageName
          imageType
          extID
          dealID
          flexAttributes
          goalCount
          actionTimestamp
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      images {
        items {
          id
          orgID
          dealID
          description
          type
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      comments {
        items {
          id
          orgID
          dealID
          comment
          dispositionID
          followupDate
          contactType
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      appointments {
        items {
          id
          orgID
          slotID
          dealID
          notes
          status
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      transactions {
        items {
          id
          dealID
          orgID
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      ownerHistory {
        items {
          id
          dealID
          orgID
          userID
          ownerType
          isActive
          percent
          isCalculateKPIs
          isMissionCredit
          notes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteDeal = /* GraphQL */ `
  mutation DeleteDeal(
    $input: DeleteDealInput!
    $condition: ModelDealConditionInput
  ) {
    deleteDeal(input: $input, condition: $condition) {
      id
      orgID
      categoryID
      extID
      name
      sortName
      description
      searchString
      ownersString
      value
      notes
      status
      currentStageID
      currentOwnerID
      defaultCircleID
      currentOwnerType
      currentDispositionID
      disposition {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextFollowupDate
      reminderSentTmst
      pinAddress {
        id
        orgID
        street
        address
        city
        region
        country
        postalCode
        location {
          lat
          lon
        }
        lastVisited
        nextVisit
        currentOwnerID
        lastVisitedByID
        lastVisitedByName
        currentDispositionID
        status
        deal {
          id
          orgID
          categoryID
          extID
          name
          sortName
          description
          searchString
          ownersString
          value
          notes
          status
          currentStageID
          currentOwnerID
          defaultCircleID
          currentOwnerType
          currentDispositionID
          nextFollowupDate
          reminderSentTmst
          flexAttributes
          previousFlexAttributes
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      stage {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      flexAttributes
      previousFlexAttributes
      actions {
        items {
          id
          userMissionID
          userID
          missionID
          ownerID
          KPIRuleID
          actionCount
          currentActionCount
          rewardCount
          currentRewardCount
          comments
          imageName
          imageType
          extID
          dealID
          flexAttributes
          goalCount
          actionTimestamp
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      images {
        items {
          id
          orgID
          dealID
          description
          type
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      comments {
        items {
          id
          orgID
          dealID
          comment
          dispositionID
          followupDate
          contactType
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      appointments {
        items {
          id
          orgID
          slotID
          dealID
          notes
          status
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      transactions {
        items {
          id
          dealID
          orgID
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      ownerHistory {
        items {
          id
          dealID
          orgID
          userID
          ownerType
          isActive
          percent
          isCalculateKPIs
          isMissionCredit
          notes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createDealTransactions = /* GraphQL */ `
  mutation CreateDealTransactions(
    $input: CreateDealTransactionsInput!
    $condition: ModelDealTransactionsConditionInput
  ) {
    createDealTransactions(input: $input, condition: $condition) {
      id
      dealID
      orgID
      flexAttributes
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateDealTransactions = /* GraphQL */ `
  mutation UpdateDealTransactions(
    $input: UpdateDealTransactionsInput!
    $condition: ModelDealTransactionsConditionInput
  ) {
    updateDealTransactions(input: $input, condition: $condition) {
      id
      dealID
      orgID
      flexAttributes
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteDealTransactions = /* GraphQL */ `
  mutation DeleteDealTransactions(
    $input: DeleteDealTransactionsInput!
    $condition: ModelDealTransactionsConditionInput
  ) {
    deleteDealTransactions(input: $input, condition: $condition) {
      id
      dealID
      orgID
      flexAttributes
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createDealComments = /* GraphQL */ `
  mutation CreateDealComments(
    $input: CreateDealCommentsInput!
    $condition: ModelDealCommentsConditionInput
  ) {
    createDealComments(input: $input, condition: $condition) {
      id
      orgID
      dealID
      comment
      dispositionID
      disposition {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      followupDate
      contactType
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateDealComments = /* GraphQL */ `
  mutation UpdateDealComments(
    $input: UpdateDealCommentsInput!
    $condition: ModelDealCommentsConditionInput
  ) {
    updateDealComments(input: $input, condition: $condition) {
      id
      orgID
      dealID
      comment
      dispositionID
      disposition {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      followupDate
      contactType
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteDealComments = /* GraphQL */ `
  mutation DeleteDealComments(
    $input: DeleteDealCommentsInput!
    $condition: ModelDealCommentsConditionInput
  ) {
    deleteDealComments(input: $input, condition: $condition) {
      id
      orgID
      dealID
      comment
      dispositionID
      disposition {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      followupDate
      contactType
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createDealImage = /* GraphQL */ `
  mutation CreateDealImage(
    $input: CreateDealImageInput!
    $condition: ModelDealImageConditionInput
  ) {
    createDealImage(input: $input, condition: $condition) {
      id
      orgID
      dealID
      description
      type
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateDealImage = /* GraphQL */ `
  mutation UpdateDealImage(
    $input: UpdateDealImageInput!
    $condition: ModelDealImageConditionInput
  ) {
    updateDealImage(input: $input, condition: $condition) {
      id
      orgID
      dealID
      description
      type
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteDealImage = /* GraphQL */ `
  mutation DeleteDealImage(
    $input: DeleteDealImageInput!
    $condition: ModelDealImageConditionInput
  ) {
    deleteDealImage(input: $input, condition: $condition) {
      id
      orgID
      dealID
      description
      type
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createStageGoal = /* GraphQL */ `
  mutation CreateStageGoal(
    $input: CreateStageGoalInput!
    $condition: ModelStageGoalConditionInput
  ) {
    createStageGoal(input: $input, condition: $condition) {
      id
      orgID
      ownerID
      ownerType
      stageID
      goalCount
      goalValue
      startDate
      endDate
      frequency
      parentStageGoalID
      stage {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateStageGoal = /* GraphQL */ `
  mutation UpdateStageGoal(
    $input: UpdateStageGoalInput!
    $condition: ModelStageGoalConditionInput
  ) {
    updateStageGoal(input: $input, condition: $condition) {
      id
      orgID
      ownerID
      ownerType
      stageID
      goalCount
      goalValue
      startDate
      endDate
      frequency
      parentStageGoalID
      stage {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteStageGoal = /* GraphQL */ `
  mutation DeleteStageGoal(
    $input: DeleteStageGoalInput!
    $condition: ModelStageGoalConditionInput
  ) {
    deleteStageGoal(input: $input, condition: $condition) {
      id
      orgID
      ownerID
      ownerType
      stageID
      goalCount
      goalValue
      startDate
      endDate
      frequency
      parentStageGoalID
      stage {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createKPIRuleGoal = /* GraphQL */ `
  mutation CreateKPIRuleGoal(
    $input: CreateKPIRuleGoalInput!
    $condition: ModelKPIRuleGoalConditionInput
  ) {
    createKPIRuleGoal(input: $input, condition: $condition) {
      id
      orgID
      dimensionID
      KPIRuleID
      value
      parentKPIRuleID
      dimension
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      parentKPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      startDate
      endDate
      frequency
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateKPIRuleGoal = /* GraphQL */ `
  mutation UpdateKPIRuleGoal(
    $input: UpdateKPIRuleGoalInput!
    $condition: ModelKPIRuleGoalConditionInput
  ) {
    updateKPIRuleGoal(input: $input, condition: $condition) {
      id
      orgID
      dimensionID
      KPIRuleID
      value
      parentKPIRuleID
      dimension
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      parentKPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      startDate
      endDate
      frequency
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteKPIRuleGoal = /* GraphQL */ `
  mutation DeleteKPIRuleGoal(
    $input: DeleteKPIRuleGoalInput!
    $condition: ModelKPIRuleGoalConditionInput
  ) {
    deleteKPIRuleGoal(input: $input, condition: $condition) {
      id
      orgID
      dimensionID
      KPIRuleID
      value
      parentKPIRuleID
      dimension
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      parentKPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      startDate
      endDate
      frequency
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createStage = /* GraphQL */ `
  mutation CreateStage(
    $input: CreateStageInput!
    $condition: ModelStageConditionInput
  ) {
    createStage(input: $input, condition: $condition) {
      id
      orgID
      title
      description
      action
      actionPastTense
      measure
      measurePlural
      valueUnit
      valueUnitPlural
      calendarAppointmentStatus
      countUnit
      countUnitPlural
      iconName
      iconType
      iconColor
      flexAttributes
      sequence
      winProbability
      pinColor
      icon
      isMapDefault
      isDisplay
      isActive
      isNoDealDefault
      categoryID
      category {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        enablePindrop
        enableDeal
        stages {
          nextToken
        }
        dispositions {
          nextToken
        }
        flexAttributes
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateStage = /* GraphQL */ `
  mutation UpdateStage(
    $input: UpdateStageInput!
    $condition: ModelStageConditionInput
  ) {
    updateStage(input: $input, condition: $condition) {
      id
      orgID
      title
      description
      action
      actionPastTense
      measure
      measurePlural
      valueUnit
      valueUnitPlural
      calendarAppointmentStatus
      countUnit
      countUnitPlural
      iconName
      iconType
      iconColor
      flexAttributes
      sequence
      winProbability
      pinColor
      icon
      isMapDefault
      isDisplay
      isActive
      isNoDealDefault
      categoryID
      category {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        enablePindrop
        enableDeal
        stages {
          nextToken
        }
        dispositions {
          nextToken
        }
        flexAttributes
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteStage = /* GraphQL */ `
  mutation DeleteStage(
    $input: DeleteStageInput!
    $condition: ModelStageConditionInput
  ) {
    deleteStage(input: $input, condition: $condition) {
      id
      orgID
      title
      description
      action
      actionPastTense
      measure
      measurePlural
      valueUnit
      valueUnitPlural
      countUnit
      countUnitPlural
      iconName
      iconType
      iconColor
      flexAttributes
      sequence
      winProbability
      pinColor
      icon
      isMapDefault
      isDisplay
      isActive
      isNoDealDefault
      categoryID
      category {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        enablePindrop
        enableDeal
        stages {
          nextToken
        }
        dispositions {
          nextToken
        }
        flexAttributes
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createStageCategory = /* GraphQL */ `
  mutation CreateStageCategory(
    $input: CreateStageCategoryInput!
    $condition: ModelStageCategoryConditionInput
  ) {
    createStageCategory(input: $input, condition: $condition) {
      id
      orgID
      title
      iconName
      iconType
      iconColor
      isDefault
      enablePindrop
      enableDeal
      stages {
        items {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      dispositions {
        items {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      flexAttributes
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateStageCategory = /* GraphQL */ `
  mutation UpdateStageCategory(
    $input: UpdateStageCategoryInput!
    $condition: ModelStageCategoryConditionInput
  ) {
    updateStageCategory(input: $input, condition: $condition) {
      id
      orgID
      title
      iconName
      iconType
      iconColor
      isDefault
      enablePindrop
      enableDeal
      stages {
        items {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      dispositions {
        items {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      flexAttributes
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteStageCategory = /* GraphQL */ `
  mutation DeleteStageCategory(
    $input: DeleteStageCategoryInput!
    $condition: ModelStageCategoryConditionInput
  ) {
    deleteStageCategory(input: $input, condition: $condition) {
      id
      orgID
      title
      iconName
      iconType
      iconColor
      isDefault
      enablePindrop
      enableDeal
      stages {
        items {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      dispositions {
        items {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      flexAttributes
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createStageDisposition = /* GraphQL */ `
  mutation CreateStageDisposition(
    $input: CreateStageDispositionInput!
    $condition: ModelStageDispositionConditionInput
  ) {
    createStageDisposition(input: $input, condition: $condition) {
      id
      orgID
      categoryID
      title
      isSetupDeal
      requireDate
      deleteType
      order
      iconName
      iconType
      iconColor
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateStageDisposition = /* GraphQL */ `
  mutation UpdateStageDisposition(
    $input: UpdateStageDispositionInput!
    $condition: ModelStageDispositionConditionInput
  ) {
    updateStageDisposition(input: $input, condition: $condition) {
      id
      orgID
      categoryID
      title
      isSetupDeal
      requireDate
      deleteType
      order
      iconName
      iconType
      iconColor
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteStageDisposition = /* GraphQL */ `
  mutation DeleteStageDisposition(
    $input: DeleteStageDispositionInput!
    $condition: ModelStageDispositionConditionInput
  ) {
    deleteStageDisposition(input: $input, condition: $condition) {
      id
      orgID
      categoryID
      title
      isSetupDeal
      requireDate
      deleteType
      order
      iconName
      iconType
      iconColor
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createTrophy = /* GraphQL */ `
  mutation CreateTrophy(
    $input: CreateTrophyInput!
    $condition: ModelTrophyConditionInput
  ) {
    createTrophy(input: $input, condition: $condition) {
      id
      orgID
      title
      description
      imageName
      imageType
      place
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateTrophy = /* GraphQL */ `
  mutation UpdateTrophy(
    $input: UpdateTrophyInput!
    $condition: ModelTrophyConditionInput
  ) {
    updateTrophy(input: $input, condition: $condition) {
      id
      orgID
      title
      description
      imageName
      imageType
      place
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteTrophy = /* GraphQL */ `
  mutation DeleteTrophy(
    $input: DeleteTrophyInput!
    $condition: ModelTrophyConditionInput
  ) {
    deleteTrophy(input: $input, condition: $condition) {
      id
      orgID
      title
      description
      imageName
      imageType
      place
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createMissionTrophy = /* GraphQL */ `
  mutation CreateMissionTrophy(
    $input: CreateMissionTrophyInput!
    $condition: ModelMissionTrophyConditionInput
  ) {
    createMissionTrophy(input: $input, condition: $condition) {
      id
      orgID
      missionID
      title
      description
      imageName
      imageType
      place
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateMissionTrophy = /* GraphQL */ `
  mutation UpdateMissionTrophy(
    $input: UpdateMissionTrophyInput!
    $condition: ModelMissionTrophyConditionInput
  ) {
    updateMissionTrophy(input: $input, condition: $condition) {
      id
      orgID
      missionID
      title
      description
      imageName
      imageType
      place
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteMissionTrophy = /* GraphQL */ `
  mutation DeleteMissionTrophy(
    $input: DeleteMissionTrophyInput!
    $condition: ModelMissionTrophyConditionInput
  ) {
    deleteMissionTrophy(input: $input, condition: $condition) {
      id
      orgID
      missionID
      title
      description
      imageName
      imageType
      place
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createPersonTrophy = /* GraphQL */ `
  mutation CreatePersonTrophy(
    $input: CreatePersonTrophyInput!
    $condition: ModelPersonTrophyConditionInput
  ) {
    createPersonTrophy(input: $input, condition: $condition) {
      id
      orgID
      userID
      count
      title
      description
      imageName
      imageType
      place
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updatePersonTrophy = /* GraphQL */ `
  mutation UpdatePersonTrophy(
    $input: UpdatePersonTrophyInput!
    $condition: ModelPersonTrophyConditionInput
  ) {
    updatePersonTrophy(input: $input, condition: $condition) {
      id
      orgID
      userID
      count
      title
      description
      imageName
      imageType
      place
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deletePersonTrophy = /* GraphQL */ `
  mutation DeletePersonTrophy(
    $input: DeletePersonTrophyInput!
    $condition: ModelPersonTrophyConditionInput
  ) {
    deletePersonTrophy(input: $input, condition: $condition) {
      id
      orgID
      userID
      count
      title
      description
      imageName
      imageType
      place
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createKPIRule = /* GraphQL */ `
  mutation CreateKPIRule(
    $input: CreateKPIRuleInput!
    $condition: ModelKPIRuleConditionInput
  ) {
    createKPIRule(input: $input, condition: $condition) {
      id
      orgID
      type
      abbreviation
      timeframe
      title
      sourceType
      formula
      triggerID
      dimensions
      action
      actionPastTense
      valueUnit
      valueUnitPlural
      categoryID
      isHidden
      stage {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      category {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        enablePindrop
        enableDeal
        stages {
          nextToken
        }
        dispositions {
          nextToken
        }
        flexAttributes
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      disposition {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      goals {
        items {
          id
          orgID
          dimensionID
          KPIRuleID
          value
          parentKPIRuleID
          dimension
          startDate
          endDate
          frequency
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      aggs {
        items {
          id
          orgID
          userKey
          count
          sum
          val
          goal
          dimensionID
          KPIRuleID
          dimension
          currentPosition
          previousPosition
          mostRecentDimensionID
          leaderboard
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      format
      roundPrecision
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateKPIRule = /* GraphQL */ `
  mutation UpdateKPIRule(
    $input: UpdateKPIRuleInput!
    $condition: ModelKPIRuleConditionInput
  ) {
    updateKPIRule(input: $input, condition: $condition) {
      id
      orgID
      type
      timeframe
      title
      sourceType
      formula
      scorecardViewerRoles
      leaderboardViewerRoles
      triggerID
      dimensions
      action
      actionPastTense
      valueUnit
      valueUnitPlural
      categoryID
      isHidden
      stage {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      category {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        enablePindrop
        enableDeal
        stages {
          nextToken
        }
        dispositions {
          nextToken
        }
        flexAttributes
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      disposition {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      goals {
        items {
          id
          orgID
          dimensionID
          KPIRuleID
          value
          parentKPIRuleID
          dimension
          startDate
          endDate
          frequency
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      aggs {
        items {
          id
          orgID
          userKey
          count
          sum
          val
          goal
          dimensionID
          KPIRuleID
          dimension
          currentPosition
          previousPosition
          mostRecentDimensionID
          leaderboard
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      format
      roundPrecision
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteKPIRule = /* GraphQL */ `
  mutation DeleteKPIRule(
    $input: DeleteKPIRuleInput!
    $condition: ModelKPIRuleConditionInput
  ) {
    deleteKPIRule(input: $input, condition: $condition) {
      id
      orgID
      type
      timeframe
      title
      sourceType
      formula
      triggerID
      dimensions
      action
      actionPastTense
      valueUnit
      valueUnitPlural
      categoryID
      isHidden
      stage {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      category {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        enablePindrop
        enableDeal
        stages {
          nextToken
        }
        dispositions {
          nextToken
        }
        flexAttributes
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      disposition {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      goals {
        items {
          id
          orgID
          dimensionID
          KPIRuleID
          value
          parentKPIRuleID
          dimension
          startDate
          endDate
          frequency
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      aggs {
        items {
          id
          orgID
          userKey
          count
          sum
          val
          goal
          dimensionID
          KPIRuleID
          dimension
          currentPosition
          previousPosition
          mostRecentDimensionID
          leaderboard
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      format
      roundPrecision
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createKPIValueAggs = /* GraphQL */ `
  mutation CreateKPIValueAggs(
    $input: CreateKPIValueAggsInput!
    $condition: ModelKPIValueAggsConditionInput
  ) {
    createKPIValueAggs(input: $input, condition: $condition) {
      id
      orgID
      userKey
      count
      sum
      val
      goal
      dimensionID
      KPIRuleID
      dimension
      currentPosition
      previousPosition
      mostRecentDimensionID
      leaderboard
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateKPIValueAggs = /* GraphQL */ `
  mutation UpdateKPIValueAggs(
    $input: UpdateKPIValueAggsInput!
    $condition: ModelKPIValueAggsConditionInput
  ) {
    updateKPIValueAggs(input: $input, condition: $condition) {
      id
      orgID
      userKey
      count
      sum
      val
      goal
      dimensionID
      KPIRuleID
      dimension
      currentPosition
      previousPosition
      mostRecentDimensionID
      leaderboard
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteKPIValueAggs = /* GraphQL */ `
  mutation DeleteKPIValueAggs(
    $input: DeleteKPIValueAggsInput!
    $condition: ModelKPIValueAggsConditionInput
  ) {
    deleteKPIValueAggs(input: $input, condition: $condition) {
      id
      orgID
      userKey
      count
      sum
      val
      goal
      dimensionID
      KPIRuleID
      dimension
      currentPosition
      previousPosition
      mostRecentDimensionID
      leaderboard
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createPersonActivityFeed = /* GraphQL */ `
  mutation CreatePersonActivityFeed(
    $input: CreatePersonActivityFeedInput!
    $condition: ModelPersonActivityFeedConditionInput
  ) {
    createPersonActivityFeed(input: $input, condition: $condition) {
      id
      userID
      isRead
      orgID
      type
      showAfter
      showAfterEpoch
      showUntilEpoch
      extID
      createdAt
      updatedAt
      activityFeed {
        id
        event
        header
        details
        imageName
        imageType
        chartType
        chartData
        orgID
        type
        showAfterEpoch
        showUntilEpoch
        showAfter
        extID
        createdAt
        createdBy
        createdByUser {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        people {
          nextToken
        }
        emoticons {
          nextToken
        }
        changedID
        changedComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        changedEmoticon {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        changedCommentEmoticon {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        updatedAt
        lastUpdatedBy
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
    }
  }
`;
export const updatePersonActivityFeed = /* GraphQL */ `
  mutation UpdatePersonActivityFeed(
    $input: UpdatePersonActivityFeedInput!
    $condition: ModelPersonActivityFeedConditionInput
  ) {
    updatePersonActivityFeed(input: $input, condition: $condition) {
      id
      userID
      isRead
      orgID
      type
      showAfter
      showAfterEpoch
      showUntilEpoch
      extID
      createdAt
      updatedAt
      activityFeed {
        id
        event
        header
        details
        imageName
        imageType
        chartType
        chartData
        orgID
        type
        showAfterEpoch
        showUntilEpoch
        showAfter
        extID
        createdAt
        createdBy
        createdByUser {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        people {
          nextToken
        }
        emoticons {
          nextToken
        }
        changedID
        changedComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        changedEmoticon {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        changedCommentEmoticon {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        updatedAt
        lastUpdatedBy
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
    }
  }
`;
export const deletePersonActivityFeed = /* GraphQL */ `
  mutation DeletePersonActivityFeed(
    $input: DeletePersonActivityFeedInput!
    $condition: ModelPersonActivityFeedConditionInput
  ) {
    deletePersonActivityFeed(input: $input, condition: $condition) {
      id
      userID
      isRead
      orgID
      type
      showAfter
      showAfterEpoch
      showUntilEpoch
      extID
      createdAt
      updatedAt
      activityFeed {
        id
        event
        header
        details
        imageName
        imageType
        chartType
        chartData
        orgID
        type
        showAfterEpoch
        showUntilEpoch
        showAfter
        extID
        createdAt
        createdBy
        createdByUser {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        people {
          nextToken
        }
        emoticons {
          nextToken
        }
        changedID
        changedComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        changedEmoticon {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        changedCommentEmoticon {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        updatedAt
        lastUpdatedBy
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
    }
  }
`;
export const createActivityFeed = /* GraphQL */ `
  mutation CreateActivityFeed(
    $input: CreateActivityFeedInput!
    $condition: ModelActivityFeedConditionInput
  ) {
    createActivityFeed(input: $input, condition: $condition) {
      id
      event
      header
      details
      imageName
      imageType
      chartType
      chartData
      orgID
      type
      showAfterEpoch
      showUntilEpoch
      showAfter
      extID
      createdAt
      createdBy
      createdByUser {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      comments {
        items {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      people {
        items {
          id
          userID
          isRead
          orgID
          type
          showAfter
          showAfterEpoch
          showUntilEpoch
          extID
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      emoticons {
        items {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        nextToken
      }
      changedID
      changedComment {
        id
        content
        userID
        imageName
        imageType
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        parentComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        emoticons {
          nextToken
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      changedEmoticon {
        id
        activityFeedID
        type
        userID
        orgID
        createdAt
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        updatedAt
      }
      changedCommentEmoticon {
        id
        commentID
        type
        userID
        orgID
        createdAt
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        updatedAt
      }
      updatedAt
      lastUpdatedBy
      isDeleted
    }
  }
`;
export const updateActivityFeed = /* GraphQL */ `
  mutation UpdateActivityFeed(
    $input: UpdateActivityFeedInput!
    $condition: ModelActivityFeedConditionInput
  ) {
    updateActivityFeed(input: $input, condition: $condition) {
      id
      event
      header
      details
      imageName
      imageType
      chartType
      chartData
      orgID
      type
      showAfterEpoch
      showUntilEpoch
      showAfter
      extID
      createdAt
      createdBy
      createdByUser {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      comments {
        items {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      people {
        items {
          id
          userID
          isRead
          orgID
          type
          showAfter
          showAfterEpoch
          showUntilEpoch
          extID
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      emoticons {
        items {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        nextToken
      }
      changedID
      changedComment {
        id
        content
        userID
        imageName
        imageType
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        parentComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        emoticons {
          nextToken
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      changedEmoticon {
        id
        activityFeedID
        type
        userID
        orgID
        createdAt
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        updatedAt
      }
      changedCommentEmoticon {
        id
        commentID
        type
        userID
        orgID
        createdAt
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        updatedAt
      }
      updatedAt
      lastUpdatedBy
      isDeleted
    }
  }
`;
export const deleteActivityFeed = /* GraphQL */ `
  mutation DeleteActivityFeed(
    $input: DeleteActivityFeedInput!
    $condition: ModelActivityFeedConditionInput
  ) {
    deleteActivityFeed(input: $input, condition: $condition) {
      id
      event
      header
      details
      imageName
      imageType
      chartType
      chartData
      orgID
      type
      showAfterEpoch
      showUntilEpoch
      showAfter
      extID
      createdAt
      createdBy
      createdByUser {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      comments {
        items {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      people {
        items {
          id
          userID
          isRead
          orgID
          type
          showAfter
          showAfterEpoch
          showUntilEpoch
          extID
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      emoticons {
        items {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        nextToken
      }
      changedID
      changedComment {
        id
        content
        userID
        imageName
        imageType
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        parentComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        emoticons {
          nextToken
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      changedEmoticon {
        id
        activityFeedID
        type
        userID
        orgID
        createdAt
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        updatedAt
      }
      changedCommentEmoticon {
        id
        commentID
        type
        userID
        orgID
        createdAt
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        updatedAt
      }
      updatedAt
      lastUpdatedBy
      isDeleted
    }
  }
`;
export const createActivityFeedEmoticon = /* GraphQL */ `
  mutation CreateActivityFeedEmoticon(
    $input: CreateActivityFeedEmoticonInput!
    $condition: ModelActivityFeedEmoticonConditionInput
  ) {
    createActivityFeedEmoticon(input: $input, condition: $condition) {
      id
      activityFeedID
      type
      userID
      orgID
      createdAt
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      updatedAt
    }
  }
`;
export const updateActivityFeedEmoticon = /* GraphQL */ `
  mutation UpdateActivityFeedEmoticon(
    $input: UpdateActivityFeedEmoticonInput!
    $condition: ModelActivityFeedEmoticonConditionInput
  ) {
    updateActivityFeedEmoticon(input: $input, condition: $condition) {
      id
      activityFeedID
      type
      userID
      orgID
      createdAt
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      updatedAt
    }
  }
`;
export const deleteActivityFeedEmoticon = /* GraphQL */ `
  mutation DeleteActivityFeedEmoticon(
    $input: DeleteActivityFeedEmoticonInput!
    $condition: ModelActivityFeedEmoticonConditionInput
  ) {
    deleteActivityFeedEmoticon(input: $input, condition: $condition) {
      id
      activityFeedID
      type
      userID
      orgID
      createdAt
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      updatedAt
    }
  }
`;
export const createCommentEmoticon = /* GraphQL */ `
  mutation CreateCommentEmoticon(
    $input: CreateCommentEmoticonInput!
    $condition: ModelCommentEmoticonConditionInput
  ) {
    createCommentEmoticon(input: $input, condition: $condition) {
      id
      commentID
      type
      userID
      orgID
      createdAt
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      updatedAt
    }
  }
`;
export const updateCommentEmoticon = /* GraphQL */ `
  mutation UpdateCommentEmoticon(
    $input: UpdateCommentEmoticonInput!
    $condition: ModelCommentEmoticonConditionInput
  ) {
    updateCommentEmoticon(input: $input, condition: $condition) {
      id
      commentID
      type
      userID
      orgID
      createdAt
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      updatedAt
    }
  }
`;
export const deleteCommentEmoticon = /* GraphQL */ `
  mutation DeleteCommentEmoticon(
    $input: DeleteCommentEmoticonInput!
    $condition: ModelCommentEmoticonConditionInput
  ) {
    deleteCommentEmoticon(input: $input, condition: $condition) {
      id
      commentID
      type
      userID
      orgID
      createdAt
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      content
      userID
      imageName
      imageType
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      activityFeed {
        id
        event
        header
        details
        imageName
        imageType
        chartType
        chartData
        orgID
        type
        showAfterEpoch
        showUntilEpoch
        showAfter
        extID
        createdAt
        createdBy
        createdByUser {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        people {
          nextToken
        }
        emoticons {
          nextToken
        }
        changedID
        changedComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        changedEmoticon {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        changedCommentEmoticon {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        updatedAt
        lastUpdatedBy
        isDeleted
      }
      parentComment {
        id
        content
        userID
        imageName
        imageType
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        parentComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        emoticons {
          nextToken
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      comments {
        items {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      emoticons {
        items {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        nextToken
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      content
      userID
      imageName
      imageType
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      activityFeed {
        id
        event
        header
        details
        imageName
        imageType
        chartType
        chartData
        orgID
        type
        showAfterEpoch
        showUntilEpoch
        showAfter
        extID
        createdAt
        createdBy
        createdByUser {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        people {
          nextToken
        }
        emoticons {
          nextToken
        }
        changedID
        changedComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        changedEmoticon {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        changedCommentEmoticon {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        updatedAt
        lastUpdatedBy
        isDeleted
      }
      parentComment {
        id
        content
        userID
        imageName
        imageType
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        parentComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        emoticons {
          nextToken
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      comments {
        items {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      emoticons {
        items {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        nextToken
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      content
      userID
      imageName
      imageType
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      activityFeed {
        id
        event
        header
        details
        imageName
        imageType
        chartType
        chartData
        orgID
        type
        showAfterEpoch
        showUntilEpoch
        showAfter
        extID
        createdAt
        createdBy
        createdByUser {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        people {
          nextToken
        }
        emoticons {
          nextToken
        }
        changedID
        changedComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        changedEmoticon {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        changedCommentEmoticon {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        updatedAt
        lastUpdatedBy
        isDeleted
      }
      parentComment {
        id
        content
        userID
        imageName
        imageType
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        parentComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        emoticons {
          nextToken
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      comments {
        items {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      emoticons {
        items {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        nextToken
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
    }
  }
`;
export const createMission = /* GraphQL */ `
  mutation CreateMission(
    $input: CreateMissionInput!
    $condition: ModelMissionConditionInput
  ) {
    createMission(input: $input, condition: $condition) {
      id
      type
      contestType
      name
      description
      rewardLimit
      rewardCount
      rewardType
      actionPastTense
      actionTypePlural
      rewardID
      rewardName
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      scope
      ownerID
      ownerType
      startDate
      endDate
      launchDate
      startDateEpoch
      endDateEpoch
      launchDateEpoch
      KPIRuleID
      tieKPIRuleID
      imageName
      imageType
      questType
      questOrder
      location
      actionCount
      action
      actionType
      minGoal
      actionCountType
      completePictureRequired
      isDeleted
      nextLaunchFeedBlast
      nextExpireFeedBlast
      tags
      bracket
      orgID
      mostRecentUserID
      trophiesAwarded
      rewardsAwarded
      org {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      tieKPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      autoAssign
      assignToChildCircles
      autoCreateMonths
      permissions
      categoryID
      flexFields
      useStepCounter
      isCircleOnly
      isPublic
      rewardDisposition
      useAutoGoal
      autoGoalTimeframe
      autoGoalTimeframeCount
      autoGoalFactor
      minAutoGoal
      roundGoalPrecision
      advanceType
      nextMissionID
      topNumberAdvance
      useAutoCreate
      autoCreateTimeframe
      autoCreateTimeframeCount
      autoCreateStartDate
      autoCreateEndDate
      isHidden
      category {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        doNotShow
        missions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circles {
        items {
          id
          circleID
          orgID
          startDate
          endDate
          missionID
          isPaid
          currentPosition
          previousPosition
          rewardDisposition
          doneActionCount
          goalActionCount
          rewardCount
          trophiesAwarded
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          playMission
          missionID
          circleID
          status
          isCompleted
          isVerified
          isPaid
          imageName
          imageType
          doneActionCount
          goalActionCount
          location
          rewardCount
          rewardDisposition
          currentPosition
          previousPosition
          permissions
          orgID
          KPIRuleID
          action
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          flexFields
          useStepCounter
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      missions {
        items {
          id
          questID
          missionID
          reservedByID
          reservationExpiration
          isComplete
          sequence
          permissions
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      trophies {
        items {
          id
          orgID
          missionID
          title
          description
          imageName
          imageType
          place
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      rewards {
        items {
          id
          orgID
          missionID
          rewardID
          points
          minActionCount
          place
          isTie
          awardedToIDs
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateMission = /* GraphQL */ `
  mutation UpdateMission(
    $input: UpdateMissionInput!
    $condition: ModelMissionConditionInput
  ) {
    updateMission(input: $input, condition: $condition) {
      id
      type
      contestType
      name
      description
      rewardLimit
      rewardCount
      rewardType
      actionPastTense
      actionTypePlural
      rewardID
      rewardName
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      scope
      ownerID
      ownerType
      startDate
      endDate
      launchDate
      startDateEpoch
      endDateEpoch
      launchDateEpoch
      KPIRuleID
      tieKPIRuleID
      imageName
      imageType
      questType
      questOrder
      location
      actionCount
      action
      actionType
      minGoal
      actionCountType
      completePictureRequired
      isDeleted
      nextLaunchFeedBlast
      nextExpireFeedBlast
      tags
      bracket
      orgID
      mostRecentUserID
      trophiesAwarded
      rewardsAwarded
      org {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      tieKPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      autoAssign
      assignToChildCircles
      autoCreateMonths
      permissions
      categoryID
      flexFields
      useStepCounter
      isCircleOnly
      isPublic
      rewardDisposition
      useAutoGoal
      autoGoalTimeframe
      autoGoalTimeframeCount
      autoGoalFactor
      minAutoGoal
      roundGoalPrecision
      advanceType
      nextMissionID
      topNumberAdvance
      useAutoCreate
      autoCreateTimeframe
      autoCreateTimeframeCount
      autoCreateStartDate
      autoCreateEndDate
      isHidden
      category {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        doNotShow
        missions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circles {
        items {
          id
          circleID
          orgID
          startDate
          endDate
          missionID
          isPaid
          currentPosition
          previousPosition
          rewardDisposition
          doneActionCount
          goalActionCount
          rewardCount
          trophiesAwarded
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          playMission
          missionID
          circleID
          status
          isCompleted
          isVerified
          isPaid
          imageName
          imageType
          doneActionCount
          goalActionCount
          location
          rewardCount
          rewardDisposition
          currentPosition
          previousPosition
          permissions
          orgID
          KPIRuleID
          action
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          flexFields
          useStepCounter
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      missions {
        items {
          id
          questID
          missionID
          reservedByID
          reservationExpiration
          isComplete
          sequence
          permissions
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      trophies {
        items {
          id
          orgID
          missionID
          title
          description
          imageName
          imageType
          place
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      rewards {
        items {
          id
          orgID
          missionID
          rewardID
          points
          minActionCount
          place
          isTie
          awardedToIDs
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteMission = /* GraphQL */ `
  mutation DeleteMission(
    $input: DeleteMissionInput!
    $condition: ModelMissionConditionInput
  ) {
    deleteMission(input: $input, condition: $condition) {
      id
      type
      contestType
      name
      description
      rewardLimit
      rewardCount
      rewardType
      actionPastTense
      actionTypePlural
      rewardID
      rewardName
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      scope
      ownerID
      ownerType
      startDate
      endDate
      launchDate
      startDateEpoch
      endDateEpoch
      launchDateEpoch
      KPIRuleID
      tieKPIRuleID
      imageName
      imageType
      questType
      questOrder
      location
      actionCount
      action
      actionType
      minGoal
      actionCountType
      completePictureRequired
      isDeleted
      nextLaunchFeedBlast
      nextExpireFeedBlast
      tags
      bracket
      orgID
      mostRecentUserID
      trophiesAwarded
      rewardsAwarded
      org {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      tieKPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      autoAssign
      assignToChildCircles
      autoCreateMonths
      permissions
      categoryID
      flexFields
      useStepCounter
      isCircleOnly
      isPublic
      rewardDisposition
      useAutoGoal
      autoGoalTimeframe
      autoGoalTimeframeCount
      autoGoalFactor
      minAutoGoal
      roundGoalPrecision
      advanceType
      nextMissionID
      topNumberAdvance
      useAutoCreate
      autoCreateTimeframe
      autoCreateTimeframeCount
      autoCreateStartDate
      autoCreateEndDate
      isHidden
      category {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        doNotShow
        missions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circles {
        items {
          id
          circleID
          orgID
          startDate
          endDate
          missionID
          isPaid
          currentPosition
          previousPosition
          rewardDisposition
          doneActionCount
          goalActionCount
          rewardCount
          trophiesAwarded
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          playMission
          missionID
          circleID
          status
          isCompleted
          isVerified
          isPaid
          imageName
          imageType
          doneActionCount
          goalActionCount
          location
          rewardCount
          rewardDisposition
          currentPosition
          previousPosition
          permissions
          orgID
          KPIRuleID
          action
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          flexFields
          useStepCounter
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      missions {
        items {
          id
          questID
          missionID
          reservedByID
          reservationExpiration
          isComplete
          sequence
          permissions
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      trophies {
        items {
          id
          orgID
          missionID
          title
          description
          imageName
          imageType
          place
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      rewards {
        items {
          id
          orgID
          missionID
          rewardID
          points
          minActionCount
          place
          isTie
          awardedToIDs
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createMissionCategory = /* GraphQL */ `
  mutation CreateMissionCategory(
    $input: CreateMissionCategoryInput!
    $condition: ModelMissionCategoryConditionInput
  ) {
    createMissionCategory(input: $input, condition: $condition) {
      id
      orgID
      title
      iconName
      iconType
      iconColor
      isDefault
      doNotShow
      missions {
        items {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          isHidden
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateMissionCategory = /* GraphQL */ `
  mutation UpdateMissionCategory(
    $input: UpdateMissionCategoryInput!
    $condition: ModelMissionCategoryConditionInput
  ) {
    updateMissionCategory(input: $input, condition: $condition) {
      id
      orgID
      title
      iconName
      iconType
      iconColor
      isDefault
      doNotShow
      missions {
        items {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          isHidden
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteMissionCategory = /* GraphQL */ `
  mutation DeleteMissionCategory(
    $input: DeleteMissionCategoryInput!
    $condition: ModelMissionCategoryConditionInput
  ) {
    deleteMissionCategory(input: $input, condition: $condition) {
      id
      orgID
      title
      iconName
      iconType
      iconColor
      isDefault
      doNotShow
      missions {
        items {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          isHidden
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createMissionReward = /* GraphQL */ `
  mutation CreateMissionReward(
    $input: CreateMissionRewardInput!
    $condition: ModelMissionRewardConditionInput
  ) {
    createMissionReward(input: $input, condition: $condition) {
      id
      orgID
      missionID
      rewardID
      points
      minActionCount
      place
      isTie
      awardedToIDs
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateMissionReward = /* GraphQL */ `
  mutation UpdateMissionReward(
    $input: UpdateMissionRewardInput!
    $condition: ModelMissionRewardConditionInput
  ) {
    updateMissionReward(input: $input, condition: $condition) {
      id
      orgID
      missionID
      rewardID
      points
      minActionCount
      place
      isTie
      awardedToIDs
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteMissionReward = /* GraphQL */ `
  mutation DeleteMissionReward(
    $input: DeleteMissionRewardInput!
    $condition: ModelMissionRewardConditionInput
  ) {
    deleteMissionReward(input: $input, condition: $condition) {
      id
      orgID
      missionID
      rewardID
      points
      minActionCount
      place
      isTie
      awardedToIDs
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createQuestMission = /* GraphQL */ `
  mutation CreateQuestMission(
    $input: CreateQuestMissionInput!
    $condition: ModelQuestMissionConditionInput
  ) {
    createQuestMission(input: $input, condition: $condition) {
      id
      questID
      quest {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      reservedByID
      reservationExpiration
      isComplete
      sequence
      permissions
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateQuestMission = /* GraphQL */ `
  mutation UpdateQuestMission(
    $input: UpdateQuestMissionInput!
    $condition: ModelQuestMissionConditionInput
  ) {
    updateQuestMission(input: $input, condition: $condition) {
      id
      questID
      quest {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      reservedByID
      reservationExpiration
      isComplete
      sequence
      permissions
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteQuestMission = /* GraphQL */ `
  mutation DeleteQuestMission(
    $input: DeleteQuestMissionInput!
    $condition: ModelQuestMissionConditionInput
  ) {
    deleteQuestMission(input: $input, condition: $condition) {
      id
      questID
      quest {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      reservedByID
      reservationExpiration
      isComplete
      sequence
      permissions
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createCircleLevel = /* GraphQL */ `
  mutation CreateCircleLevel(
    $input: CreateCircleLevelInput!
    $condition: ModelCircleLevelConditionInput
  ) {
    createCircleLevel(input: $input, condition: $condition) {
      id
      name
      description
      levelNum
      orgID
      circles {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateCircleLevel = /* GraphQL */ `
  mutation UpdateCircleLevel(
    $input: UpdateCircleLevelInput!
    $condition: ModelCircleLevelConditionInput
  ) {
    updateCircleLevel(input: $input, condition: $condition) {
      id
      name
      description
      levelNum
      orgID
      circles {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteCircleLevel = /* GraphQL */ `
  mutation DeleteCircleLevel(
    $input: DeleteCircleLevelInput!
    $condition: ModelCircleLevelConditionInput
  ) {
    deleteCircleLevel(input: $input, condition: $condition) {
      id
      name
      description
      levelNum
      orgID
      circles {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createCircle = /* GraphQL */ `
  mutation CreateCircle(
    $input: CreateCircleInput!
    $condition: ModelCircleConditionInput
  ) {
    createCircle(input: $input, condition: $condition) {
      id
      extID
      name
      description
      location
      scope
      inviterType
      approverType
      status
      isActive
      isHidden
      imageName
      imageType
      avatarImageName
      communityID
      startDate
      endDate
      launchDate
      orgID
      nextLaunchFeedBlast
      nextExpireFeedBlast
      permissions
      parentCircleID
      levelID
      tangoAccountID
      isLeague
      level {
        id
        name
        description
        levelNum
        orgID
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      org {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missions {
        items {
          id
          circleID
          orgID
          startDate
          endDate
          missionID
          isPaid
          currentPosition
          previousPosition
          rewardDisposition
          doneActionCount
          goalActionCount
          rewardCount
          trophiesAwarded
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          circleID
          status
          memberType
          priority
          circlePoints
          lifetimeCirclePoints
          isActive
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      rewards {
        items {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      parent {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      children {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      preferences {
        items {
          id
          orgID
          isHidden
          preferenceType
          name
          entityType
          entityID
          preferences
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateCircle = /* GraphQL */ `
  mutation UpdateCircle(
    $input: UpdateCircleInput!
    $condition: ModelCircleConditionInput
  ) {
    updateCircle(input: $input, condition: $condition) {
      id
      extID
      name
      description
      location
      scope
      inviterType
      approverType
      status
      isActive
      isHidden
      imageName
      imageType
      avatarImageName
      communityID
      startDate
      endDate
      launchDate
      orgID
      nextLaunchFeedBlast
      nextExpireFeedBlast
      permissions
      parentCircleID
      levelID
      tangoAccountID
      isLeague
      level {
        id
        name
        description
        levelNum
        orgID
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      org {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missions {
        items {
          id
          circleID
          orgID
          startDate
          endDate
          missionID
          isPaid
          currentPosition
          previousPosition
          rewardDisposition
          doneActionCount
          goalActionCount
          rewardCount
          trophiesAwarded
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          circleID
          status
          memberType
          priority
          circlePoints
          lifetimeCirclePoints
          isActive
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      rewards {
        items {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      parent {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      children {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      preferences {
        items {
          id
          orgID
          isHidden
          preferenceType
          name
          entityType
          entityID
          preferences
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteCircle = /* GraphQL */ `
  mutation DeleteCircle(
    $input: DeleteCircleInput!
    $condition: ModelCircleConditionInput
  ) {
    deleteCircle(input: $input, condition: $condition) {
      id
      extID
      name
      description
      location
      scope
      inviterType
      approverType
      status
      isActive
      isHidden
      imageName
      imageType
      avatarImageName
      communityID
      startDate
      endDate
      launchDate
      orgID
      nextLaunchFeedBlast
      nextExpireFeedBlast
      permissions
      parentCircleID
      levelID
      tangoAccountID
      isLeague
      level {
        id
        name
        description
        levelNum
        orgID
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      org {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missions {
        items {
          id
          circleID
          orgID
          startDate
          endDate
          missionID
          isPaid
          currentPosition
          previousPosition
          rewardDisposition
          doneActionCount
          goalActionCount
          rewardCount
          trophiesAwarded
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          circleID
          status
          memberType
          priority
          circlePoints
          lifetimeCirclePoints
          isActive
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      rewards {
        items {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      parent {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      children {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      preferences {
        items {
          id
          orgID
          isHidden
          preferenceType
          name
          entityType
          entityID
          preferences
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      extID
      username
      email
      phoneNumber
      name
      birthdate
      imageName
      imageType
      location
      nameVisibility
      phoneNumberVisibility
      emailVisibility
      birthdateVisibility
      pictureVisibility
      locationVisibility
      initials
      pronoun
      role
      status
      expoToken
      orgID
      title
      avatarID
      levelID
      circleLevel {
        id
        name
        description
        levelNum
        orgID
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      agreedToTerms
      agreedToTermsAt
      tags
      addresses {
        items {
          id
          userID
          orgID
          type
          address1
          address2
          city
          region
          country
          postalCode
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      circles {
        items {
          id
          userID
          circleID
          status
          memberType
          priority
          circlePoints
          lifetimeCirclePoints
          isActive
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      rewards {
        items {
          id
          userID
          rewardID
          purchaseType
          status
          purchaseCount
          permissions
          orgID
          approverID
          errorMessages
          statusTmst
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      orgs {
        items {
          id
          userID
          orgID
          status
          memberType
          orgPoints
          lifetimeOrgPoints
          isActive
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      kpis {
        items {
          id
          orgID
          userKey
          count
          sum
          val
          goal
          dimensionID
          KPIRuleID
          dimension
          currentPosition
          previousPosition
          mostRecentDimensionID
          leaderboard
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      trophies {
        items {
          id
          orgID
          userID
          count
          title
          description
          imageName
          imageType
          place
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      lessonAssignments {
        items {
          id
          lessonDetailID
          orgID
          assignedToID
          assignedToType
          flexAttributes
          startedTmst
          completedTmst
          percentComplete
          isStarted
          isComplete
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      extID
      username
      email
      phoneNumber
      name
      birthdate
      imageName
      imageType
      location
      nameVisibility
      phoneNumberVisibility
      emailVisibility
      birthdateVisibility
      pictureVisibility
      locationVisibility
      initials
      pronoun
      role
      status
      expoToken
      orgID
      title
      avatarID
      levelID
      circleLevel {
        id
        name
        description
        levelNum
        orgID
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      agreedToTerms
      agreedToTermsAt
      tags
      addresses {
        items {
          id
          userID
          orgID
          type
          address1
          address2
          city
          region
          country
          postalCode
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      circles {
        items {
          id
          userID
          circleID
          status
          memberType
          priority
          circlePoints
          lifetimeCirclePoints
          isActive
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      rewards {
        items {
          id
          userID
          rewardID
          purchaseType
          status
          purchaseCount
          permissions
          orgID
          approverID
          errorMessages
          statusTmst
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      orgs {
        items {
          id
          userID
          orgID
          status
          memberType
          orgPoints
          lifetimeOrgPoints
          isActive
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      kpis {
        items {
          id
          orgID
          userKey
          count
          sum
          val
          goal
          dimensionID
          KPIRuleID
          dimension
          currentPosition
          previousPosition
          mostRecentDimensionID
          leaderboard
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      trophies {
        items {
          id
          orgID
          userID
          count
          title
          description
          imageName
          imageType
          place
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      lessonAssignments {
        items {
          id
          lessonDetailID
          orgID
          assignedToID
          assignedToType
          flexAttributes
          startedTmst
          completedTmst
          percentComplete
          isStarted
          isComplete
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      extID
      username
      email
      phoneNumber
      name
      birthdate
      imageName
      imageType
      location
      nameVisibility
      phoneNumberVisibility
      emailVisibility
      birthdateVisibility
      pictureVisibility
      locationVisibility
      initials
      pronoun
      role
      status
      expoToken
      orgID
      title
      avatarID
      levelID
      circleLevel {
        id
        name
        description
        levelNum
        orgID
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      agreedToTerms
      agreedToTermsAt
      tags
      addresses {
        items {
          id
          userID
          orgID
          type
          address1
          address2
          city
          region
          country
          postalCode
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      circles {
        items {
          id
          userID
          circleID
          status
          memberType
          priority
          circlePoints
          lifetimeCirclePoints
          isActive
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      rewards {
        items {
          id
          userID
          rewardID
          purchaseType
          status
          purchaseCount
          permissions
          orgID
          approverID
          errorMessages
          statusTmst
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      orgs {
        items {
          id
          userID
          orgID
          status
          memberType
          orgPoints
          lifetimeOrgPoints
          isActive
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      kpis {
        items {
          id
          orgID
          userKey
          count
          sum
          val
          goal
          dimensionID
          KPIRuleID
          dimension
          currentPosition
          previousPosition
          mostRecentDimensionID
          leaderboard
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      trophies {
        items {
          id
          orgID
          userID
          count
          title
          description
          imageName
          imageType
          place
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      lessonAssignments {
        items {
          id
          lessonDetailID
          orgID
          assignedToID
          assignedToType
          flexAttributes
          startedTmst
          completedTmst
          percentComplete
          isStarted
          isComplete
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createPreferences = /* GraphQL */ `
  mutation CreatePreferences(
    $input: CreatePreferencesInput!
    $condition: ModelPreferencesConditionInput
  ) {
    createPreferences(input: $input, condition: $condition) {
      id
      orgID
      isHidden
      preferenceType
      name
      entityType
      entityID
      preferences
      createdAt
      updatedAt
    }
  }
`;
export const updatePreferences = /* GraphQL */ `
  mutation UpdatePreferences(
    $input: UpdatePreferencesInput!
    $condition: ModelPreferencesConditionInput
  ) {
    updatePreferences(input: $input, condition: $condition) {
      id
      orgID
      isHidden
      preferenceType
      name
      entityType
      entityID
      preferences
      createdAt
      updatedAt
    }
  }
`;
export const deletePreferences = /* GraphQL */ `
  mutation DeletePreferences(
    $input: DeletePreferencesInput!
    $condition: ModelPreferencesConditionInput
  ) {
    deletePreferences(input: $input, condition: $condition) {
      id
      orgID
      isHidden
      preferenceType
      name
      entityType
      entityID
      preferences
      createdAt
      updatedAt
    }
  }
`;
export const createOrg = /* GraphQL */ `
  mutation CreateOrg(
    $input: CreateOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    createOrg(input: $input, condition: $condition) {
      id
      name
      domains
      contactEmail
      contactName
      contactAddress
      contactAddress2
      contactCity
      contactState
      contactZip
      contactPhone
      configParms
      hubspotID
      restrictionType
      circles {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          orgID
          status
          memberType
          orgPoints
          lifetimeOrgPoints
          isActive
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      preferences {
        items {
          id
          orgID
          isHidden
          preferenceType
          name
          entityType
          entityID
          preferences
          createdAt
          updatedAt
        }
        nextToken
      }
      permissions
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateOrg = /* GraphQL */ `
  mutation UpdateOrg(
    $input: UpdateOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    updateOrg(input: $input, condition: $condition) {
      id
      name
      domains
      contactEmail
      contactName
      contactAddress
      contactAddress2
      contactCity
      contactState
      contactZip
      contactPhone
      configParms
      hubspotID
      restrictionType
      circles {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          orgID
          status
          memberType
          orgPoints
          lifetimeOrgPoints
          isActive
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      preferences {
        items {
          id
          orgID
          isHidden
          preferenceType
          name
          entityType
          entityID
          preferences
          createdAt
          updatedAt
        }
        nextToken
      }
      permissions
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteOrg = /* GraphQL */ `
  mutation DeleteOrg(
    $input: DeleteOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    deleteOrg(input: $input, condition: $condition) {
      id
      name
      domains
      contactEmail
      contactName
      contactAddress
      contactAddress2
      contactCity
      contactState
      contactZip
      contactPhone
      configParms
      hubspotID
      restrictionType
      circles {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          orgID
          status
          memberType
          orgPoints
          lifetimeOrgPoints
          isActive
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      preferences {
        items {
          id
          orgID
          isHidden
          preferenceType
          name
          entityType
          entityID
          preferences
          createdAt
          updatedAt
        }
        nextToken
      }
      permissions
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createOrgMember = /* GraphQL */ `
  mutation CreateOrgMember(
    $input: CreateOrgMemberInput!
    $condition: ModelOrgMemberConditionInput
  ) {
    createOrgMember(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      orgID
      org {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      status
      memberType
      orgPoints
      lifetimeOrgPoints
      isActive
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateOrgMember = /* GraphQL */ `
  mutation UpdateOrgMember(
    $input: UpdateOrgMemberInput!
    $condition: ModelOrgMemberConditionInput
  ) {
    updateOrgMember(input: $input, condition: $condition) {
      id
      userID
      roleID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        functionalRoles
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        agreedToTerms
        agreedToTermsAt
        tags
        colorCode
        hasWanderMode
        hasRoundRobin
        flexAttributes
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
        __typename
      }
      orgID
      org {
        id
        stripeID
        gamifyBundle
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        countryCode
        integrations
        restrictionType
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
        __typename
      }
      status
      memberType
      orgPoints
      lifetimeOrgPoints
      isActive
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
      __typename
    }
  }
`;
export const deleteOrgMember = /* GraphQL */ `
  mutation DeleteOrgMember(
    $input: DeleteOrgMemberInput!
    $condition: ModelOrgMemberConditionInput
  ) {
    deleteOrgMember(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      orgID
      org {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      status
      memberType
      orgPoints
      lifetimeOrgPoints
      isActive
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createCircleMember = /* GraphQL */ `
  mutation CreateCircleMember(
    $input: CreateCircleMemberInput!
    $condition: ModelCircleMemberConditionInput
  ) {
    createCircleMember(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circleID
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      status
      memberType
      priority
      circlePoints
      lifetimeCirclePoints
      isActive
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateCircleMember = /* GraphQL */ `
  mutation UpdateCircleMember(
    $input: UpdateCircleMemberInput!
    $condition: ModelCircleMemberConditionInput
  ) {
    updateCircleMember(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circleID
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      status
      memberType
      priority
      circlePoints
      lifetimeCirclePoints
      isActive
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteCircleMember = /* GraphQL */ `
  mutation DeleteCircleMember(
    $input: DeleteCircleMemberInput!
    $condition: ModelCircleMemberConditionInput
  ) {
    deleteCircleMember(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circleID
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      status
      memberType
      priority
      circlePoints
      lifetimeCirclePoints
      isActive
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createCircleMission = /* GraphQL */ `
  mutation CreateCircleMission(
    $input: CreateCircleMissionInput!
    $condition: ModelCircleMissionConditionInput
  ) {
    createCircleMission(input: $input, condition: $condition) {
      id
      circleID
      orgID
      startDate
      endDate
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      isPaid
      currentPosition
      previousPosition
      rewardDisposition
      doneActionCount
      goalActionCount
      rewardCount
      trophiesAwarded
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateCircleMission = /* GraphQL */ `
  mutation UpdateCircleMission(
    $input: UpdateCircleMissionInput!
    $condition: ModelCircleMissionConditionInput
  ) {
    updateCircleMission(input: $input, condition: $condition) {
      id
      circleID
      orgID
      startDate
      endDate
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      isPaid
      currentPosition
      previousPosition
      rewardDisposition
      doneActionCount
      goalActionCount
      rewardCount
      trophiesAwarded
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteCircleMission = /* GraphQL */ `
  mutation DeleteCircleMission(
    $input: DeleteCircleMissionInput!
    $condition: ModelCircleMissionConditionInput
  ) {
    deleteCircleMission(input: $input, condition: $condition) {
      id
      circleID
      orgID
      startDate
      endDate
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      isPaid
      currentPosition
      previousPosition
      rewardDisposition
      doneActionCount
      goalActionCount
      rewardCount
      trophiesAwarded
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createUserMission = /* GraphQL */ `
  mutation CreateUserMission(
    $input: CreateUserMissionInput!
    $condition: ModelUserMissionConditionInput
  ) {
    createUserMission(input: $input, condition: $condition) {
      id
      userID
      playMission
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      circleID
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      status
      isCompleted
      isVerified
      isPaid
      imageName
      imageType
      doneActionCount
      goalActionCount
      location
      rewardCount
      rewardDisposition
      currentPosition
      previousPosition
      permissions
      orgID
      KPIRuleID
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      action
      startDate
      endDate
      launchDate
      startDateEpoch
      endDateEpoch
      launchDateEpoch
      flexFields
      useStepCounter
      actions {
        items {
          id
          userMissionID
          userID
          missionID
          ownerID
          KPIRuleID
          actionCount
          currentActionCount
          rewardCount
          currentRewardCount
          comments
          imageName
          imageType
          extID
          dealID
          flexAttributes
          goalCount
          actionTimestamp
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateUserMission = /* GraphQL */ `
  mutation UpdateUserMission(
    $input: UpdateUserMissionInput!
    $condition: ModelUserMissionConditionInput
  ) {
    updateUserMission(input: $input, condition: $condition) {
      id
      userID
      playMission
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      circleID
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      status
      isCompleted
      isVerified
      isPaid
      imageName
      imageType
      doneActionCount
      goalActionCount
      location
      rewardCount
      rewardDisposition
      currentPosition
      previousPosition
      permissions
      orgID
      KPIRuleID
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      action
      startDate
      endDate
      launchDate
      startDateEpoch
      endDateEpoch
      launchDateEpoch
      flexFields
      useStepCounter
      actions {
        items {
          id
          userMissionID
          userID
          missionID
          ownerID
          KPIRuleID
          actionCount
          currentActionCount
          rewardCount
          currentRewardCount
          comments
          imageName
          imageType
          extID
          dealID
          flexAttributes
          goalCount
          actionTimestamp
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteUserMission = /* GraphQL */ `
  mutation DeleteUserMission(
    $input: DeleteUserMissionInput!
    $condition: ModelUserMissionConditionInput
  ) {
    deleteUserMission(input: $input, condition: $condition) {
      id
      userID
      playMission
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      circleID
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      status
      isCompleted
      isVerified
      isPaid
      imageName
      imageType
      doneActionCount
      goalActionCount
      location
      rewardCount
      rewardDisposition
      currentPosition
      previousPosition
      permissions
      orgID
      KPIRuleID
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      action
      startDate
      endDate
      launchDate
      startDateEpoch
      endDateEpoch
      launchDateEpoch
      flexFields
      useStepCounter
      actions {
        items {
          id
          userMissionID
          userID
          missionID
          ownerID
          KPIRuleID
          actionCount
          currentActionCount
          rewardCount
          currentRewardCount
          comments
          imageName
          imageType
          extID
          dealID
          flexAttributes
          goalCount
          actionTimestamp
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createUserMissionAction = /* GraphQL */ `
  mutation CreateUserMissionAction(
    $input: CreateUserMissionActionInput!
    $condition: ModelUserMissionActionConditionInput
  ) {
    createUserMissionAction(input: $input, condition: $condition) {
      id
      userMissionID
      userID
      missionID
      ownerID
      KPIRuleID
      actionCount
      currentActionCount
      rewardCount
      currentRewardCount
      comments
      imageName
      imageType
      extID
      dealID
      flexAttributes
      goalCount
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      userMission {
        id
        userID
        playMission
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missionID
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          isHidden
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        circleID
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        isCompleted
        isVerified
        isPaid
        imageName
        imageType
        doneActionCount
        goalActionCount
        location
        rewardCount
        rewardDisposition
        currentPosition
        previousPosition
        permissions
        orgID
        KPIRuleID
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        action
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        flexFields
        useStepCounter
        actions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      actionTimestamp
      orgID
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateUserMissionAction = /* GraphQL */ `
  mutation UpdateUserMissionAction(
    $input: UpdateUserMissionActionInput!
    $condition: ModelUserMissionActionConditionInput
  ) {
    updateUserMissionAction(input: $input, condition: $condition) {
      id
      userMissionID
      userID
      missionID
      ownerID
      KPIRuleID
      actionCount
      currentActionCount
      rewardCount
      currentRewardCount
      comments
      imageName
      imageType
      extID
      dealID
      flexAttributes
      goalCount
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      userMission {
        id
        userID
        playMission
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missionID
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          isHidden
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        circleID
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        isCompleted
        isVerified
        isPaid
        imageName
        imageType
        doneActionCount
        goalActionCount
        location
        rewardCount
        rewardDisposition
        currentPosition
        previousPosition
        permissions
        orgID
        KPIRuleID
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        action
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        flexFields
        useStepCounter
        actions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      actionTimestamp
      orgID
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteUserMissionAction = /* GraphQL */ `
  mutation DeleteUserMissionAction(
    $input: DeleteUserMissionActionInput!
    $condition: ModelUserMissionActionConditionInput
  ) {
    deleteUserMissionAction(input: $input, condition: $condition) {
      id
      userMissionID
      userID
      missionID
      ownerID
      KPIRuleID
      actionCount
      currentActionCount
      rewardCount
      currentRewardCount
      comments
      imageName
      imageType
      extID
      dealID
      flexAttributes
      goalCount
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      userMission {
        id
        userID
        playMission
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missionID
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          isHidden
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        circleID
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        isCompleted
        isVerified
        isPaid
        imageName
        imageType
        doneActionCount
        goalActionCount
        location
        rewardCount
        rewardDisposition
        currentPosition
        previousPosition
        permissions
        orgID
        KPIRuleID
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        action
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        flexFields
        useStepCounter
        actions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      actionTimestamp
      orgID
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          enableDeal
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createReward = /* GraphQL */ `
  mutation CreateReward(
    $input: CreateRewardInput!
    $condition: ModelRewardConditionInput
  ) {
    createReward(input: $input, condition: $condition) {
      id
      name
      description
      rewardType
      personLimit
      limitCount
      value
      valueType
      remainingCount
      imageName
      imageType
      tags
      validFrom
      validTo
      launchDate
      isActive
      cost
      costType
      giftcardValue
      minGiftcardAmount
      maxGiftcardAmount
      utid
      tangoAccountID
      ownerType
      ownerID
      approverType
      location
      giftcardDisclaimer
      missions {
        items {
          id
          orgID
          missionID
          rewardID
          points
          minActionCount
          place
          isTie
          awardedToIDs
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          rewardID
          purchaseType
          status
          purchaseCount
          permissions
          orgID
          approverID
          errorMessages
          statusTmst
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      userdetails {
        items {
          id
          userRewardID
          userID
          rewardID
          orgID
          missionID
          purchaseType
          status
          errorMessages
          statusTmst
          purchaseCount
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      orgID
      nextLaunchFeedBlast
      nextExpireFeedBlast
      permissions
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateReward = /* GraphQL */ `
  mutation UpdateReward(
    $input: UpdateRewardInput!
    $condition: ModelRewardConditionInput
  ) {
    updateReward(input: $input, condition: $condition) {
      id
      name
      description
      rewardType
      personLimit
      limitCount
      value
      valueType
      remainingCount
      imageName
      imageType
      tags
      validFrom
      validTo
      launchDate
      isActive
      cost
      costType
      giftcardValue
      minGiftcardAmount
      maxGiftcardAmount
      utid
      tangoAccountID
      ownerType
      ownerID
      approverType
      location
      giftcardDisclaimer
      missions {
        items {
          id
          orgID
          missionID
          rewardID
          points
          minActionCount
          place
          isTie
          awardedToIDs
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          rewardID
          purchaseType
          status
          purchaseCount
          permissions
          orgID
          approverID
          errorMessages
          statusTmst
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      userdetails {
        items {
          id
          userRewardID
          userID
          rewardID
          orgID
          missionID
          purchaseType
          status
          errorMessages
          statusTmst
          purchaseCount
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      orgID
      nextLaunchFeedBlast
      nextExpireFeedBlast
      permissions
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteReward = /* GraphQL */ `
  mutation DeleteReward(
    $input: DeleteRewardInput!
    $condition: ModelRewardConditionInput
  ) {
    deleteReward(input: $input, condition: $condition) {
      id
      name
      description
      rewardType
      personLimit
      limitCount
      value
      valueType
      remainingCount
      imageName
      imageType
      tags
      validFrom
      validTo
      launchDate
      isActive
      cost
      costType
      giftcardValue
      minGiftcardAmount
      maxGiftcardAmount
      utid
      tangoAccountID
      ownerType
      ownerID
      approverType
      location
      giftcardDisclaimer
      missions {
        items {
          id
          orgID
          missionID
          rewardID
          points
          minActionCount
          place
          isTie
          awardedToIDs
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          rewardID
          purchaseType
          status
          purchaseCount
          permissions
          orgID
          approverID
          errorMessages
          statusTmst
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      userdetails {
        items {
          id
          userRewardID
          userID
          rewardID
          orgID
          missionID
          purchaseType
          status
          errorMessages
          statusTmst
          purchaseCount
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      orgID
      nextLaunchFeedBlast
      nextExpireFeedBlast
      permissions
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createUserReward = /* GraphQL */ `
  mutation CreateUserReward(
    $input: CreateUserRewardInput!
    $condition: ModelUserRewardConditionInput
  ) {
    createUserReward(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      rewardID
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      purchaseType
      status
      purchaseCount
      permissions
      orgID
      approverID
      details {
        items {
          id
          userRewardID
          userID
          rewardID
          orgID
          missionID
          purchaseType
          status
          errorMessages
          statusTmst
          purchaseCount
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      errorMessages
      statusTmst
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateUserReward = /* GraphQL */ `
  mutation UpdateUserReward(
    $input: UpdateUserRewardInput!
    $condition: ModelUserRewardConditionInput
  ) {
    updateUserReward(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      rewardID
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      purchaseType
      status
      purchaseCount
      permissions
      orgID
      approverID
      details {
        items {
          id
          userRewardID
          userID
          rewardID
          orgID
          missionID
          purchaseType
          status
          errorMessages
          statusTmst
          purchaseCount
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      errorMessages
      statusTmst
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteUserReward = /* GraphQL */ `
  mutation DeleteUserReward(
    $input: DeleteUserRewardInput!
    $condition: ModelUserRewardConditionInput
  ) {
    deleteUserReward(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      rewardID
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      purchaseType
      status
      purchaseCount
      permissions
      orgID
      approverID
      details {
        items {
          id
          userRewardID
          userID
          rewardID
          orgID
          missionID
          purchaseType
          status
          errorMessages
          statusTmst
          purchaseCount
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      errorMessages
      statusTmst
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createUserRewardDetails = /* GraphQL */ `
  mutation CreateUserRewardDetails(
    $input: CreateUserRewardDetailsInput!
    $condition: ModelUserRewardDetailsConditionInput
  ) {
    createUserRewardDetails(input: $input, condition: $condition) {
      id
      userRewardID
      userID
      rewardID
      orgID
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      userReward {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        rewardID
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        purchaseType
        status
        purchaseCount
        permissions
        orgID
        approverID
        details {
          nextToken
        }
        errorMessages
        statusTmst
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      purchaseType
      status
      errorMessages
      statusTmst
      purchaseCount
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateUserRewardDetails = /* GraphQL */ `
  mutation UpdateUserRewardDetails(
    $input: UpdateUserRewardDetailsInput!
    $condition: ModelUserRewardDetailsConditionInput
  ) {
    updateUserRewardDetails(input: $input, condition: $condition) {
      id
      userRewardID
      userID
      rewardID
      orgID
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      userReward {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        rewardID
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        purchaseType
        status
        purchaseCount
        permissions
        orgID
        approverID
        details {
          nextToken
        }
        errorMessages
        statusTmst
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      purchaseType
      status
      errorMessages
      statusTmst
      purchaseCount
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteUserRewardDetails = /* GraphQL */ `
  mutation DeleteUserRewardDetails(
    $input: DeleteUserRewardDetailsInput!
    $condition: ModelUserRewardDetailsConditionInput
  ) {
    deleteUserRewardDetails(input: $input, condition: $condition) {
      id
      userRewardID
      userID
      rewardID
      orgID
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        isHidden
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      userReward {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        rewardID
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        purchaseType
        status
        purchaseCount
        permissions
        orgID
        approverID
        details {
          nextToken
        }
        errorMessages
        statusTmst
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      purchaseType
      status
      errorMessages
      statusTmst
      purchaseCount
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createUserGiftcardAccount = /* GraphQL */ `
  mutation CreateUserGiftcardAccount(
    $input: CreateUserGiftcardAccountInput!
    $condition: ModelUserGiftcardAccountConditionInput
  ) {
    createUserGiftcardAccount(input: $input, condition: $condition) {
      id
      userID
      orgID
      name
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      rewards {
        items {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      circles {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const updateUserGiftcardAccount = /* GraphQL */ `
  mutation UpdateUserGiftcardAccount(
    $input: UpdateUserGiftcardAccountInput!
    $condition: ModelUserGiftcardAccountConditionInput
  ) {
    updateUserGiftcardAccount(input: $input, condition: $condition) {
      id
      userID
      orgID
      name
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      rewards {
        items {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      circles {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const deleteUserGiftcardAccount = /* GraphQL */ `
  mutation DeleteUserGiftcardAccount(
    $input: DeleteUserGiftcardAccountInput!
    $condition: ModelUserGiftcardAccountConditionInput
  ) {
    deleteUserGiftcardAccount(input: $input, condition: $condition) {
      id
      userID
      orgID
      name
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      rewards {
        items {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      circles {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const createIntegration = /* GraphQL */ `
  mutation CreateIntegration(
    $input: CreateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    createIntegration(input: $input, condition: $condition) {
      id
      partner
      orgID
      partnerCode
      refreshToken
      accessToken
      expiration
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateIntegration = /* GraphQL */ `
  mutation UpdateIntegration(
    $input: UpdateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    updateIntegration(input: $input, condition: $condition) {
      id
      partner
      orgID
      partnerCode
      refreshToken
      accessToken
      expiration
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteIntegration = /* GraphQL */ `
  mutation DeleteIntegration(
    $input: DeleteIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    deleteIntegration(input: $input, condition: $condition) {
      id
      partner
      orgID
      partnerCode
      refreshToken
      accessToken
      expiration
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createIntegrationXref = /* GraphQL */ `
  mutation CreateIntegrationXref(
    $input: CreateIntegrationXrefInput!
    $condition: ModelIntegrationXrefConditionInput
  ) {
    createIntegrationXref(input: $input, condition: $condition) {
      id
      orgID
      partnerID
      partnerKeyName
      partnerKeyValue
      ourKeyName
      ourKeyValue
      mappingJSON
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateIntegrationXref = /* GraphQL */ `
  mutation UpdateIntegrationXref(
    $input: UpdateIntegrationXrefInput!
    $condition: ModelIntegrationXrefConditionInput
  ) {
    updateIntegrationXref(input: $input, condition: $condition) {
      id
      orgID
      partnerID
      partnerKeyName
      partnerKeyValue
      ourKeyName
      ourKeyValue
      mappingJSON
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteIntegrationXref = /* GraphQL */ `
  mutation DeleteIntegrationXref(
    $input: DeleteIntegrationXrefInput!
    $condition: ModelIntegrationXrefConditionInput
  ) {
    deleteIntegrationXref(input: $input, condition: $condition) {
      id
      orgID
      partnerID
      partnerKeyName
      partnerKeyValue
      ourKeyName
      ourKeyValue
      mappingJSON
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createUserRole = /* GraphQL */ `
  mutation CreateUserRole(
    $input: CreateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    createUserRole(input: $input, condition: $condition) {
      id
      orgID
      isDeleted
      roleName
      permissions
      isSuper
      iconName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserRole = /* GraphQL */ `
  mutation UpdateUserRole(
    $input: UpdateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    updateUserRole(input: $input, condition: $condition) {
      id
      orgID
      isDeleted
      roleName
      permissions
      isSuper
      iconName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserRole = /* GraphQL */ `
  mutation DeleteUserRole(
    $input: DeleteUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    deleteUserRole(input: $input, condition: $condition) {
      id
      orgID
      isDeleted
      roleName
      permissions
      isSuper
      iconName
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      orgID
      categoryID
      extID
      name
      description
      frequency
      reservice
      defaultCharge
      defaultLength
      defaultInitialCharge
      minRecurringCharge
      minInitialCharge
      regularService
      initialService
      seasonStart
      seasonEnd
      defaultFollowupDelay
      flexAttributes
      productCode
      productFamily
      listPrice
      startDate
      endDate
      units
      status
      isDeleted
      orders {
        nextToken
        __typename
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      orgID
      categoryID
      extID
      name
      description
      frequency
      reservice
      defaultCharge
      defaultLength
      defaultInitialCharge
      minRecurringCharge
      minInitialCharge
      regularService
      initialService
      seasonStart
      seasonEnd
      defaultFollowupDelay
      flexAttributes
      productCode
      productFamily
      listPrice
      startDate
      endDate
      units
      status
      isDeleted
      orders {
        nextToken
        __typename
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
