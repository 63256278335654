import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import gql from 'graphql-tag';
import { Role } from '../components/RolesAndPermissions/RoleAndPermissions.types';

// Define the GraphQL mutation
const createUserRoleMutation = gql`
  mutation CreateUserRole($input: CreateUserRoleInput!) {
    createUserRole(input: $input) {
      id
      roleName
      permissions
      isDeleted
      isSuper
      iconName
    }
  }
`;

// Define the structure of the mutation input
interface CreateUserRoleInput {
  id: string;
  roleName: string;
  orgID: string;
  iconName: string;
  permissions: string;
  isSuper: boolean;
  isDeleted: boolean;
}

// Define the structure of the mutation response
interface CreateUserRoleResponse {
  createUserRole: {
    id: string;
    roleName: string;
    permissions: string;
    isSuper: boolean;
    iconName: string;
    createdAt: string;
    updatedAt: string;
  };
}

// Function to create a new role in the database
export async function createUserRole(role: Role): Promise<void> {
  try {
    const input: CreateUserRoleInput = {
      id: role.id,
      // @ts-ignore
      orgID: global.me.orgID,
      roleName: role.roleName,
      iconName: role.iconName,
      isSuper: false,
      isDeleted: false,
      permissions: JSON.stringify(role.permissions), // Stringify the permissions object
    };
    console.log('INPUT');
    console.log(input);
    // Perform the mutation
    const response = (await API.graphql(
      graphqlOperation(createUserRoleMutation, { input })
    )) as GraphQLResult<CreateUserRoleResponse>;

    const createdRole = response.data?.createUserRole;
    if (createdRole) {
      console.log(`Role ${createdRole.roleName} created successfully.`);
    } else {
      console.error(`Failed to create role with ID: ${role.id}`);
    }
  } catch (error) {
    console.error('Error creating role:', error);
  }
}
