import React, { useState } from 'react';
import CommentType from '../_types/CommentType';
// import Me from '../../../_types/Me';
import { usePermissions } from '../../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../../context/PermissionsContext/permissionTypes';

interface Props {
  // me: Me;
  comment: CommentType;
  onDeleteComment: (deleteComment: CommentType) => void;
  onChangeIsUpdating: (newIsEditing: boolean) => void;
}

const EditDeleteEllipseComment = ({
  // me,
  comment,
  onDeleteComment,
  onChangeIsUpdating,
}: Props) => {
  const [isEllipseClick, setIsEllipseClick] = useState(false);
  const { hasPermission } = usePermissions();
  const canEditComment = hasPermission(PermissionKeys.Other_EditComments, [
    comment.userID,
  ]);
  const canDeleteComment = hasPermission(PermissionKeys.Other_RemoveComments, [
    comment.userID,
  ]);

  // NOTE: This check is no longer needed with new permissions
  // if (!me.isAdmin || comment.userID !== me.id) {
  //   return null;
  // }

  return (
    <div style={{ position: 'relative' }}>
      <img
        onClick={(e) => {
          e.stopPropagation();

          setIsEllipseClick(!isEllipseClick);
        }}
        src="/images/three-dots.svg"
        width={30}
        height={30}
        style={{ cursor: 'pointer' }}
      />
      {isEllipseClick ? (
        <div
          style={{
            position: 'absolute',
            backgroundColor: '#f5f5f5',
            padding: '10px 20px',
            borderRadius: 10,
            right: 0,
            top: 28,
          }}
        >
          {canEditComment && (
            <div
              style={{
                fontSize: 14,
                fontWeight: 500,
                cursor: 'pointer',
                marginBottom: 10,
              }}
              onClick={(e) => {
                e.stopPropagation();
                onChangeIsUpdating(true);
              }}
            >
              Edit
            </div>
          )}
          {canDeleteComment && (
            <div
              style={{
                fontSize: 14,
                color: '#FF5C77',
                fontWeight: 700,
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteComment(comment);
                setIsEllipseClick(!isEllipseClick);
              }}
            >
              Delete
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default EditDeleteEllipseComment;
