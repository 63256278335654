import React, { useEffect /*, useLayoutEffect */ } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { SettingsSideBar } from './components/SettingsSideBar';
import { SettingsProvider } from './context/SettingsContext';
import { SettingsContainer } from './components/SettingsContainer';

import { useLocation } from 'react-router-dom';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';

const SettingsPage = () => {
  const { isSuperAdmin } = usePermissions();

  // console.log('MY PRINT -- ', isSuperAdmin());
  const location = useLocation();
  useEffect(() => {
    // this isAdmin check needs to be updated to use roles and permissions
    // @ts-ignore
    if (location.pathname.startsWith('/settings') && !isSuperAdmin()) {
      console.log('not admin');
      if (window.history.length > 1) {
        window.history.back();
      } else {
        window.location.href = '/feed';
      }
    }
  }, [location.pathname]);

  // useLayoutEffect(() => {
  //   document.body.style.backgroundColor = '#eee';
  // }, []);

  return (
    <SettingsProvider>
      <Box sx={{ display: 'flex', backgroundColor: '#eee' }}>
        <CssBaseline />
        <SettingsSideBar />
        <SettingsContainer />
      </Box>
    </SettingsProvider>
  );
};

export { SettingsPage };
