import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useSettingsContext } from '../context/SettingsContext';
import { GamifyIcon } from '../../../components/GamifyIcon';

const drawerWidth = 285;

const settingsNav = [
  {
    label: 'Roles & Permissions',
    value: 'roles',
    icon: 'role',
    disabled: false,
  },
  {
    label: 'Deals & Properties',
    value: 'deals',
    icon: 'notes',
    disabled: true,
  },
  {
    label: 'Hierarchy',
    value: 'hierarchy',
    icon: 'teams',
    disabled: true,
  },
];

const SettingsSideBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setSelectedSetting, hasUnsavedChanges } = useSettingsContext();

  const handleNavigation = (value: string) => {
    console.log('Navigating to', value);
    if (pathname.includes('roles') && hasUnsavedChanges) {
      const confirmNavigation = window.confirm(
        'You have unsaved changes. Do you want to discard them and navigate to the new page?'
      );
      if (confirmNavigation) {
        setSelectedSetting(value as any); // Update the context
        navigate(`/settings/${value}`); // Navigate to the correct settings page
      } else return;
    }
    // console.log(value);
    setSelectedSetting(value as any); // Update the context
    navigate(`/settings/${value}`); // Navigate to the correct settings page
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          marginTop: 11,
          marginLeft: 0,
          borderRadius: 0,
        },
      }}
      variant="persistent"
      anchor="left"
      open={true} // Drawer is always open in this case
    >
      <Divider />
      <List sx={{ mt: 3, pr: 5, pl: 5 }}>
        <Typography variant="h6" sx={{ pt: 5, pb: 5, fontWeight: 780 }}>
          Settings
        </Typography>
        <Typography
          color="#838383"
          variant="body1"
          sx={{ pt: 0, pb: 2, fontWeight: 400 }}
        >
          Account Setup
        </Typography>
        {settingsNav.map((setting) => (
          <ListItem key={setting.value} disablePadding>
            <ListItemButton
              disabled={setting.disabled}
              selected={pathname.includes(setting.value)} // Highlight the selected item based on URL
              sx={{ pl: 0, pr: 0 }}
              onClick={() => handleNavigation(setting.value)} // Navigate on click
            >
              {/* <CustomIcon src={setting.icon} style={{ marginRight: 5 }} />
               */}
              <GamifyIcon icon={setting.icon} color="#323232" />
              <ListItemText
                primaryTypographyProps={{
                  marginLeft: 1,
                  fontWeight: pathname.includes(setting.value) ? 500 : 'normal',
                }}
                primary={setting.label}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export { SettingsSideBar };
