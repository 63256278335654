import React from 'react';
import { Box } from '@mui/material';
import PermissionWithSwitch from './formComponents/PermissionWithSwitch';
import PermissionWithSelect from './formComponents/PermissionWithSelect';
import { useRolesPermissionsContext } from '../../context/RolesAndPermissionsContext';
import {
  permissionValue,
  PermissionCategoryProps,
  PermissionKeys,
} from './RoleAndPermissions.types';
import '../../settingsStyles.css';
import SectionHeader from './formComponents/SectionHeader';

const Territory = React.memo(({ selectedRole }: PermissionCategoryProps) => {
  if (!selectedRole) return <></>;

  const { updateRole } = useRolesPermissionsContext();

  const handleRoleUpdate = (
    permissionKey: PermissionKeys,
    newValue: permissionValue | boolean
  ) => {
    updateRole(selectedRole.id, permissionKey, newValue);
  };
  return (
    <Box sx={{ px: 3, py: 2 }}>
      <SectionHeader title="Pins" iconName="pin" />

      <Box className="permission-section">
        <PermissionWithSwitch
          label="Drop pin"
          value={selectedRole.permissions[PermissionKeys.Territory_DropPin]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Territory_DropPin, val)
          }
        />

        <PermissionWithSelect
          label="Edit pin"
          value={selectedRole.permissions[PermissionKeys.Territory_EditPin]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Territory_EditPin, val)
          }
          excludeOptions={['myTeam']}
        />
        <PermissionWithSelect
          label="Delete pin"
          value={selectedRole.permissions[PermissionKeys.Territory_DeletePin]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Territory_DeletePin, val)
          }
          excludeOptions={['myTeam']}
        />
      </Box>
      <SectionHeader title="Areas" iconName="areas" />

      <Box className="permission-section">
        <PermissionWithSelect
          label="Create area"
          value={selectedRole.permissions[PermissionKeys.Territory_CreateArea]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Territory_CreateArea, val)
          }
        />
        <PermissionWithSelect
          label="Update area"
          value={selectedRole.permissions[PermissionKeys.Territory_UpdateArea]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Territory_UpdateArea, val)
          }
        />
        <PermissionWithSelect
          label="Delete area"
          value={selectedRole.permissions[PermissionKeys.Territory_DeleteArea]}
          onUpdate={(val) =>
            handleRoleUpdate(PermissionKeys.Territory_DeleteArea, val)
          }
        />
      </Box>
    </Box>
  );
});

Territory.displayName = 'Territory';
export default Territory;
