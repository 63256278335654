import { Box, Typography } from '@mui/material';
import React from 'react';
import { GamifyIcon } from '../../../../../components/GamifyIcon';

interface SectionHeaderProps {
  title: string;
  iconName?: string;
}

const SectionHeader = ({ title, iconName }: SectionHeaderProps) => {
  return (
    <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
      <GamifyIcon icon={iconName} />
      <Typography sx={{ fontWeight: 700, color: '#878787' }}>
        {title}
      </Typography>
    </Box>
  );
};

SectionHeader.displayName = 'SectionHeader';
export default SectionHeader;
