import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import gql from 'graphql-tag';
import { Role } from '../components/RolesAndPermissions/RoleAndPermissions.types';

interface ListRolesByOrgResponse {
  listRolesByOrg: {
    items: Role[];
    nextToken: string | null;
  };
}

interface ListRolesByOrgVariables {
  orgID: string;
  nextToken?: string;
}

const listRolesByOrgQuery = gql`
  query ListRolesByOrg($orgID: ID!, $nextToken: String) {
    listRolesByOrg(
      orgID: $orgID
      nextToken: $nextToken
      filter: { isDeleted: { eq: false } }
    ) {
      items {
        id
        roleName
        permissions
        isSuper
        iconName
      }
      nextToken
    }
  }
`;

export default async function fetchUserRoles(orgID: string): Promise<Role[]> {
  try {
    let nextToken: string | null = null;
    const roles: Role[] = [];

    do {
      const response = (await API.graphql(
        graphqlOperation(listRolesByOrgQuery, {
          orgID,
          nextToken,
        } as ListRolesByOrgVariables)
      )) as GraphQLResult<ListRolesByOrgResponse>;

      const listRolesByOrg = response.data?.listRolesByOrg;

      if (listRolesByOrg) {
        roles.push(...listRolesByOrg.items);
        nextToken = listRolesByOrg.nextToken;
      } else {
        break;
      }
    } while (nextToken);
    roles.forEach((role) => {
      if (typeof role.permissions === 'string') {
        role.permissions = JSON.parse(role.permissions);
      }
    });

    roles.sort((a, b) => {
      if (a.isSuper && !b.isSuper) {
        return 1; // `a` is super admin, move it to the end
      }
      if (!a.isSuper && b.isSuper) {
        return -1; // `b` is super admin, move it to the end
      }
      return a.roleName.localeCompare(b.roleName); // Sort alphabetically by roleName
    });
    console.log('Roles', roles);
    return roles;
  } catch (error) {
    console.error('Error fetching user roles:', error);
    return [];
  }
}
